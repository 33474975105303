.weAreGlad {
  position: relative;
  letter-spacing: -0.02em;
  line-height: 36px;
  display: inline-block;
  width: 398px;
}
.youWillNeed {
  margin: 0;
}
.youWillNeedContainer {
  position: relative;
  font-size: var(--p2-semibold-size);
  line-height: 26px;
  color: var(--grey-20);
  display: inline-block;
  width: 398px;
}
.weAreGladYoureHereParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-11xs);
}
.frameWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.hiFiveIcon {
  position: relative;
  width: 240px;
  height: 240px;
  overflow: hidden;
  flex-shrink: 0;
}
.icons20pxplaceholder {
  position: relative;
  width: 20px;
  height: 20px;
  display: none;
}
.label {
  position: relative;
  font-size: var(--p3-semibold-size);
  line-height: 24px;
  font-family: var(--caption-bold);
  color: var(--white);
  text-align: center;
}
.largeButtons {
  cursor: pointer;
  border: 0;
  padding: var(--padding-5xs) 0;
  background-color: var(--logg-green);
  border-radius: var(--br-8xs);
  width: 200px;
  height: 48px;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
}
.skipForNow {
  position: relative;
  line-height: 26px;
  font-weight: 500;
  display: inline-block;
  width: 398px;
  cursor: pointer;
}
.frameParent,
.largeButtonsParent {
  display: flex;
  flex-direction: column;
}
.largeButtonsParent {
  align-items: center;
  justify-content: center;
  gap: var(--gap-base);
  font-size: var(--p2-semibold-size);
  color: var(--grey-20);
}
.frameParent {
  padding: 0 var(--padding-31xl) 0 0;
  align-items: center;
  justify-content: center;
  gap: var(--gap-53xl);
}
.gladhere,
.gladhereInner {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.gladhereInner {
  align-self: stretch;
  flex: 1;
  justify-content: center;
}
.gladhere {
  position: relative;
  background-color: var(--white);
  width: 100%;
  overflow: hidden;
  justify-content: space-between;
  text-align: center;
  font-size: var(--h4-bold1-size);
  color: var(--grey-0);
  font-family: var(--caption-bold);
}
.gladheremodal {
  align-items: center;
  justify-content: center;
  display: flex;
  box-sizing: border-box;
  position: relative;
  background-color: var(--white);
  overflow: hidden;
  text-align: center;
  font-size: var(--h4-bold1-size);
  color: var(--grey-0);
  font-family: var(--caption-bold);
}
@media screen and (max-width: 960px) {
  .frameParent {
    padding-right: 0;
    box-sizing: border-box;
  }
  .gladhere {
    align-items: center;
    justify-content: center;
    padding-top: 5rem;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 430px) {
  .largeButtons {
width:300px
}
}
