.navChild {
  position: absolute;
  top: 27px;
  left: 20px;
  width: 125px;
  height: 20px;
}
.iconshome {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.home {
  position: relative;
  line-height: 24px;
  font-weight: 500;
}
.default,
.iconshomeParent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.iconshomeParent {
  flex-direction: row;
  gap: var(--gap-5xs);
}
.default {
  align-self: stretch;
  border-radius: var(--br-11xs);
  overflow: hidden;
  flex-direction: column;
  padding: var(--padding-5xs);
  cursor: pointer;
}
.accountIcon {
  position: relative;
  width: 24px;
  height: 24px;
}
.default5,
.defaultParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.default5 {
  align-self: stretch;
  border-radius: var(--br-9xs);
  background-color: var(--grey-90);
  overflow: hidden;
  padding: var(--padding-5xs);
  color: var(--primary-10);
}
.defaultParent {
  position: absolute;
  top: 87px;
  left: 20px;
  width: 181px;
  gap: var(--gap-xs);
}
.nav {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--grey-100);
}
.navPrototype {
  position: relative;
  width: 221px;
  height: 1325px;
}
.back1 {
  position: absolute;
  top: 5px;
  left: 26px;
  line-height: 155%;
  font-weight: 500;
}
.frameIcon {
  position: absolute;
  top: 0;
  left: 0;
  width: 32px;
  height: 32px;
  overflow: hidden;
}
.back {
  position: relative;
  width: 59px;
  height: 32px;
}
.subscriptions {
  position: relative;
  font-size: var(--h4-semibold1-size);
  font-weight: 600;
  color: var(--grey-900);
}
.titles {
  align-self: stretch;
  width: 100%;
  background-color: var(--white);
  overflow: hidden;
  display: none;
  flex-direction: row;
  padding: var(--padding-6xs) 0;
  align-items: center;
  justify-content: space-between;
  color: var(--grey-800);
}
.icons16pxadd {
  position: relative;
  width: 16px;
  height: 16px;
  overflow: hidden;
  flex-shrink: 0;
}
.button {
  position: relative;
  line-height: 100%;
  font-weight: 600;
}
.icons16pxaddParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-9xs);
}
.navBarChild,
.smallButtons {
  position: absolute;
  top: 14px;
  height: 40px;
}
.smallButtons {
  right: 235px;
  border-radius: var(--br-9xs);
  background-color: var(--primary-20base);
  width: 107px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.navBarChild {
  left: 1139px;
  border-radius: 50%;
  width: 40px;
  object-fit: cover;
}
.component98 {
  position: absolute;
  top: -2px;
  left: 1024px;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  padding: var(--padding-xs) 0;
  align-items: center;
  justify-content: center;
  color: var(--grey-10);
}
.icons20pxsearch {
  position: relative;
  width: 20px;
  height: 20px;
}
.trySearchingCarpenter {
  position: relative;
  line-height: 26px;
}
.icons20pxsearchParent {
  position: absolute;
  top: 8px;
  left: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-9xs);
}
.searchBarChild {
  position: absolute;
  top: -0.5px;
  left: 263.5px;
  border-right: 1px solid var(--grey-60);
  box-sizing: border-box;
  width: 1px;
  height: 45px;
}
.iconslocationParent {
  position: absolute;
  top: 8px;
  left: 276px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.navBar,
.searchBar {
  background-color: var(--white);
  box-sizing: border-box;
}
.searchBar {
  position: absolute;
  top: 12px;
  left: 40px;
  border-radius: var(--br-9xs);
  border: 1px solid var(--grey-60);
  width: 416px;
  height: 44px;
  text-align: left;
  font-size: var(--p2-semibold-size);
  color: var(--grey-50);
}
.navBar {
  position: relative;
  border-bottom: 1px solid var(--grey-80);
  width: 1219px;
  height: 68px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.icon {
  position: absolute;
  top: 0;
  left: 0;
  border: 1px solid var(--grey-80);
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs);
  align-items: center;
  justify-content: center;
}
.subscriptions1 {
  position: absolute;
  top: 6px;
  left: 194px;
  line-height: 28px;
}
.smallButtons1 {
  position: absolute;
  top: 0;
  right: 387px;
  border-radius: var(--br-9xs);
  background-color: var(--primary-20base);
  width: 107px;
  height: 40px;
  display: none;
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: var(--p3-bold-size);
  color: var(--white);
}
.border,
.merge {
  position: relative;
}
.merge {
  width: 100%;
  height: 40px;
}
.border {
  align-self: stretch;
  border-top: 1px solid var(--grey-80);
  box-sizing: border-box;
  height: 1px;
}
.pageTitle,
.topNavigation {
  display: flex;
  flex-direction: column;
}
.pageTitle {
  background-color: var(--white);
  width: 100%;
  padding: var(--padding-xs) var(--padding-21xl) 0;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-xs);
  text-align: left;
  font-size: var(--h5-bold-size);
  color: var(--grey-0);
}
.topNavigation {
  align-self: stretch;
  align-items: center;
  justify-content: flex-start;
  color: var(--white);
}
.payAsYou {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.02em;
  line-height: 36px;
}
.payAsYou,
.span {
  font-weight: 600;
}
.commissionJob1 {
  font-weight: 500;
  color: var(--grey-20);
}
.commissionJob {
  align-self: stretch;
  position: relative;
  font-size: var(--p2-semibold-size);
  line-height: 26px;
}
.payAsYouGoParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.iconsscrew {
  position: relative;
  width: 20px;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.jobManagement {
  flex: 1;
  position: relative;
  line-height: 26px;
  font-weight: 600;
}
.iconsscrewParent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.automaticallyManageYour {
  flex: 1;
  position: relative;
  line-height: 20px;
}
.automaticallyManageYourJobsWrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  padding: 0 0 0 var(--padding-9xl);
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--caption-regular-size);
  color: var(--grey-20);
}
.subscriptionList {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-11xs);
}
.frameContainer,
.frameDiv,
.subscriptionListWrapper {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
}
.subscriptionListWrapper {
  border-radius: var(--br-9xs);
  background-color: var(--grey-100);
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-xl);
  align-items: center;
}
.frameContainer,
.frameDiv {
  flex-direction: column;
  align-items: flex-start;
}
.frameDiv {
  gap: var(--gap-5xs);
  font-size: var(--p2-semibold-size);
}
.frameContainer {
  gap: var(--gap-xl);
}
.iconsmessage1 {
  position: relative;
  width: 20px;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.label {
  position: relative;
  line-height: 24px;
}
.largeButtons {
  align-self: stretch;
  border-radius: var(--br-8xs);
  background-color: var(--primary-20base);
  height: 44px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs) 0;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
}
.largeButtonsWrapper {
  padding: var(--padding-xs) 0 0;
  align-items: flex-start;
  text-align: center;
  font-size: var(--p3-bold-size);
  color: var(--white);
}
.frameGroup,
.frameWrapper,
.largeButtonsWrapper {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.frameGroup {
  align-items: center;
  gap: var(--gap-xs);
}
.frameWrapper {
  border-radius: var(--br-9xs);
  background-color: var(--white);
  border: 1px solid var(--grey-80);
  padding: var(--padding-xl) var(--padding-12xs) 0;
  align-items: flex-start;
  width: 100%;
}
.changePlan,
.chooseFromMonthly {
  position: relative;
  line-height: 24px;
}
.changePlan {
  font-weight: 600;
}
.chooseFromMonthly {
  font-size: var(--p3-bold-size);
  color: var(--grey-20);
}
.changePlanParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.commissionjob,
.div,
.payg {
  position: relative;
  line-height: 26px;
  font-weight: 600;
}
.payg {
  align-self: stretch;
  display: inline-block;
  flex-shrink: 0;
}
.commissionjob,
.div {
  display: flex;
  align-items: center;
}
.commissionjob {
  font-size: var(--caption-regular-size);
  line-height: 20px;
  font-weight: 500;
  color: var(--primary-100);
}
.parent,
.subscriptionList3 {
  display: flex;
  align-items: flex-start;
}
.parent {
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-11xs);
  text-align: right;
}
.subscriptionList3 {
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
}
.subscriptionListWrapper1 {
  align-self: stretch;
  border-radius: var(--br-9xs);
  background-color: var(--primary-20base);
  display: flex;
  flex-direction: row;
  padding: var(--padding-xs) var(--padding-xl);
  align-items: center;
  justify-content: flex-start;
}
.monthlyprofessional {
  position: relative;
  font-size: var(--caption-regular-size);
  line-height: 20px;
  font-weight: 500;
  color: var(--grey-30);
  display: flex;
  align-items: center;
}
.largeButtons1,
.plans,
.subscriptionListWrapper2 {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
}
.subscriptionListWrapper2 {
  border-radius: var(--br-9xs);
  background-color: var(--grey-80);
  flex-direction: row;
  padding: var(--padding-xs) var(--padding-xl);
  align-items: center;
  color: var(--grey-0);
}
.largeButtons1,
.plans {
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-xl);
  font-size: var(--p2-semibold-size);
  color: var(--white);
}
.largeButtons1 {
  border-radius: var(--br-8xs);
  border: 1px solid var(--primary-20base);
  box-sizing: border-box;
  height: 44px;
  flex-direction: row;
  padding: var(--padding-5xs) 0;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
  cursor: pointer;
  text-align: center;
  font-size: var(--p3-bold-size);
  color: var(--primary-20base);
}
.frameParent,
.frameParent1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.frameParent1 {
  align-self: stretch;
  gap: var(--gap-xl);
  font-size: var(--p1-semibold-size);
  color: var(--grey-0);
}
.frameParent {
  gap: var(--gap-13xl);
  text-align: left;
  font-size: var(--h4-semibold-size);
}
.monthly1 {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.02em;
  line-height: 36px;
  font-weight: 600;
}
.frameParent4,
.frameParent5 {
  align-self: stretch;
  align-items: flex-start;
}
.frameParent5 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-5xs);
  font-size: var(--p2-semibold-size);
}
.frameParent4 {
  gap: var(--gap-xl);
}
.frameParent3,
.frameParent4,
.frameWrapper1 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.frameParent3 {
  align-self: stretch;
  align-items: center;
  gap: var(--gap-xs);
}
.frameWrapper1 {
  border-radius: var(--br-9xs);
  background-color: var(--white);
  border: 1px solid var(--grey-80);
  box-sizing: border-box;
  padding: var(--padding-xl) var(--padding-12xs) 0;
  align-items: flex-start;
}
.div2,
.payg1 {
  position: relative;
  line-height: 26px;
  font-weight: 600;
}
.div2 {
  align-self: stretch;
}
.commissionjob1,
.monthlyprofessional3 {
  align-self: stretch;
  position: relative;
  font-size: var(--caption-regular-size);
  line-height: 20px;
  font-weight: 500;
  color: var(--primary-100);
}
.monthlyprofessional3 {
  color: var(--grey-30);
}
.frameParent2 {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-13xl);
  text-align: left;
  font-size: var(--h4-semibold-size);
}
.security,
.titlesParent {
  display: flex;
  justify-content: flex-start;
}
.titlesParent {
  flex: 1;
  flex-direction: column;
  align-items: center;
  gap: var(--gap-xs);
  padding: 8px;
}
.security {
  position: relative;
  background-color: var(--color-gray-100);
  width: 100%;
  overflow-y: auto;
  flex-direction: row;
  align-items: flex-start;
  text-align: center;
  font-size: var(--p3-bold-size);
  color: var(--grey-10);
  font-family: var(--p3-bold);
}
@media screen and (max-width: 960px) {
  .navPrototype {
    display: none;
  }
  .titles {
    display: flex;
  }
  .topNavigation {
    display: none;
  }
  .frameContainer,
  .frameDiv,
  .frameGroup,
  .frameParent,
  .frameParent2,
  .frameWrapper,
  .frameWrapper1,
  .payAsYou,
  .payAsYouGoParent {
    align-self: stretch;
    width: auto;
  }
}
@media screen and (max-width: 430px) {
  .titles {
    display: flex;
  }
}
