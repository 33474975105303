.navChild {
  position: absolute;
  top: 27px;
  left: 20px;
  width: 125px;
  height: 20px;
}
.iconshome {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.home {
  position: relative;
  line-height: 24px;
  font-weight: 500;
}
.default,
.default1,
.iconshomeParent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.iconshomeParent {
  flex-direction: row;
  gap: var(--gap-5xs);
}
.default,
.default1 {
  width: 181px;
  overflow: hidden;
  flex-direction: column;
  padding: var(--padding-5xs);
  box-sizing: border-box;
}
.default {
  border-radius: var(--br-11xs);
  cursor: pointer;
}
.default1 {
  border-radius: var(--br-9xs);
  background-color: var(--grey-90);
  color: var(--primary-10);
}
.accountIcon {
  position: relative;
  width: 24px;
  height: 24px;
}
.default5,
.defaultParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.default5 {
  border-radius: var(--br-11xs);
  width: 181px;
  overflow: hidden;
  padding: var(--padding-5xs);
  box-sizing: border-box;
}
.defaultParent {
  position: absolute;
  top: 87px;
  left: 20px;
  gap: var(--gap-xs);
}
.nav {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--grey-50);
}
.navPrototype {
  position: relative;
  width: 221px;
  height: 100vh;
}
.activities {
  position: relative;
  line-height: 120%;
  font-weight: 600;
}
.messageCircleIcon {
  position: absolute;
  top: 7px;
  left: 0;
  width: 24px;
  height: 24px;
  overflow: hidden;
}
.tickerChild {
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-5xs);
  background-color: var(--logg-green);
}
.div,
.ticker,
.tickerChild {
  position: absolute;
}
.div {
  height: 55.56%;
  width: 66.67%;
  top: 22.22%;
  left: 16.67%;
  letter-spacing: -0.04em;
  line-height: 100%;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ticker {
  height: 58.06%;
  width: 75%;
  top: 0;
  right: 0;
  bottom: 41.94%;
  left: 25%;
}
.bellIcon,
.messageCircleParent {
  top: 0;
  left: 58px;
  width: 32px;
  height: 31px;

}
.bellIcon {
  left: 0;
  width: 24px;
  height: 24px;
  overflow: hidden;
}
.rectangleParent,
.ticker1 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.ticker1 {
  height: 75%;
  top: -29.17%;
  right: -41.67%;
  bottom: 54.17%;
  left: 41.67%;
  display: none;
}
.bellParent {
  top: 7px;
  left: 0;
  width: 24px;
  height: 24px;
}
.groupParent {
  position: relative;
  width: 90px;
  height: 31px;
  text-align: center;
  font-size: var(--caption-bold-size);
  color: var(--white);
  display: flex;
  justify-content: space-between;
}
.titles {
  align-self: stretch;
  background-color: var(--white);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.04);
  overflow: hidden;
  display: none;
  flex-direction: row;
  padding: var(--padding-xs) var(--padding-xl);
  align-items: center;
  justify-content: space-between;
}
.licenseAndCertification {
  position: relative;
  line-height: 28px;
}
.component102 {
  width: 100%;
  background-color: var(--white);
  border-bottom: 1px solid var(--grey-80);
  display: flex;
  flex-direction: column;
  padding: 1rem;
  padding-left: 10%;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--h5-semibold-size);
  color: var(--grey-900);
}
.tabSwitchChild {
  position: absolute;
  top: 44.5px;
  left: -0.5px;
  border-top: 1px solid var(--grey-80);
  box-sizing: border-box;
  width: 559px;
  height: 1px;
}
.booking {
  position: relative;
  line-height: 26px;
  font-weight: 600;
  mix-blend-mode: normal;
}
.all {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  box-sizing: border-box;
  width: 186px;
  height: 44px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs);
  align-items: center;
  justify-content: center;
}
.booking1 {
  position: relative;
  line-height: 26px;
  mix-blend-mode: normal;
}
.all1 {
  left: 186px;
  width: 186px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.all1,
.all2,
.tabSwitch {
  position: absolute;
  top: 0;
}
.all2 {
  left: 372px;
  width: 186px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.tabSwitch {
  height: 100%;
  width: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--color-gray-100);
  overflow: hidden;
}
.jobPrototypes {
  position: relative;
  width: 558px;
  height: 45px;
}
.jobPrototypesWrapper {
  align-self: stretch;
  background-color: var(--color-gray-100);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  font-size: var(--p2-semibold-size);
  color: var(--grey-20);
}
.avatarIcon {
  position: relative;
  width: 40px;
  height: 40px;
}
.peoplename {
  font-weight: 600;
}
.date,
.peoplename {
  position: relative;
  line-height: 24px;
}
.dateTimeChild {
  position: relative;
  background-color: var(--grey-20);
  width: 1px;
  height: 16px;
}
.dateTime,
.merge,
.profile {
  display: flex;
  justify-content: flex-start;
}
.dateTime {
  flex-direction: row;
  align-items: center;
  gap: var(--gap-9xs);
  font-size: var(--p3-semibold-size);
  color: var(--grey-20);
}
.merge,
.profile {
  flex-direction: column;
  align-items: flex-start;
}
.profile {
  flex: 1;
  flex-direction: row;
  align-items: center;
  gap: var(--gap-5xs);
}
.iconsmoreVertical {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
  cursor: pointer;
}
.frameGroup,
.iconscalendarParent,
.profileParent {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.profileParent {
  align-self: stretch;
  align-items: flex-start;
  gap: var(--gap-316xl);
}
.frameGroup,
.iconscalendarParent {
  align-items: center;
  gap: var(--gap-9xs);
}
.frameGroup {
  align-self: stretch;
  align-items: flex-start;
  gap: var(--gap-xl);
}
.citiessummary3 {
  position: relative;
  line-height: 24px;
  display: flex;
  align-items: center;
  width: 300px;
  height: 17px;
  flex-shrink: 0;
}
.frameParent,
.iconslocationParent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.frameParent {
  flex-direction: column;
  align-items: flex-start;
  font-size: var(--p3-semibold-size);
  color: var(--grey-20);
}
.electricianRequiredTo {
  align-self: stretch;
  position: relative;
  font-size: var(--p2-semibold-size);
  line-height: 26px;
  color: var(--grey-10);
}
.success {
  position: relative;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  font-weight: 600;
}
.transactionPills {
  border-radius: var(--br-81xl);
  background-color: var(--primary-90);
  display: flex;
  flex-direction: row;
  padding: var(--padding-9xs) var(--padding-7xs);
  align-items: center;
  justify-content: flex-end;
}
.transactionPills2 {
  border-radius: var(--br-81xl);
  background-color: var(--red-100);
  display: flex;
  flex-direction: row;
  padding: var(--padding-9xs) var(--padding-7xs);
  align-items: center;
  color: red;
  justify-content: flex-end;
}
.iconsstar {
  position: relative;
  width: 12px;
  height: 12px;
  overflow: hidden;
  flex-shrink: 0;
}
.iconsstarParent,
.rating {
  flex-direction: row;
  align-items: center;
}
.iconsstarParent {
  display: flex;
  justify-content: flex-start;
  gap: var(--gap-11xs);
}
.rating {
  align-self: stretch;
  justify-content: space-between;
  text-align: right;
  font-size: var(--font-size-5xs);
  color: var(--logg-green);
  display:flex
}
.jobs3 {
  width: 558px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xs);
}
.jobs2 {
  border-bottom: 1px solid var(--grey-80);
}
.component102Parent,
.jobs2,
.jobs6 {
  display: flex;
  flex-direction: column;
  padding: 0 var(--padding-mini) var(--padding-xl);
  align-items: flex-start;
  justify-content: flex-start;
}
.component102Parent {
  padding: var(--padding-xl) 0;
  align-items: center;
  gap: var(--gap-xl);
  font-size: var(--p1-semibold-size);
  color: var(--grey-0);
}
.brandLogoIcon,
.home7 {
  position: absolute;
  left: 0;
}
.home7 {
  top: 63.46%;
  line-height: 155%;
  font-weight: 500;
}
.brandLogoIcon {
  height: 57.69%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 42.31%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.home6 {
  position: relative;
  width: 34px;
  height: 52px;
}
.jobs9 {
  position: absolute;
  top: 62%;
  left: 0;
  line-height: 155%;
}
.bibriefcaseFillIcon {
  position: absolute;
  height: 52%;
  width: 89.66%;
  top: 0;
  right: 6.9%;
  bottom: 48%;
  left: 3.45%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.jobs8 {
  position: relative;
  width: 29px;
  height: 50px;
  color: var(--logg-green);
}
.wallet1 {
  position: absolute;
  top: 61.22%;
  left: 0;
  line-height: 155%;
  font-weight: 500;
}
.zondiconswallet {
  position: absolute;
  height: 48.98%;
  width: 66.67%;
  top: 0;
  right: 16.67%;
  bottom: 51.02%;
  left: 16.67%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.wallet {
  position: relative;
  width: 36px;
  height: 49px;
}
.mdiaccountIcon {
  position: absolute;
  height: 55.77%;
  width: 60.42%;
  top: 0;
  right: 18.75%;
  bottom: 44.23%;
  left: 20.83%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.account {
  position: relative;
  width: 48px;
  height: 52px;
}
.bottomNav {
  align-self: stretch;
  background-color: var(--white);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  display: none;
  flex-direction: row;
  padding: var(--padding-smi) var(--padding-5xl);
  align-items: center;
  justify-content: space-between;
  text-align: center;
  font-size: var(--caption-bold-size);
  color: var(--grey-600);
}
.jobs1,
.titlesParent {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
}
.titlesParent {
  flex: 1;
  flex-direction: column;
  align-items: center;
  gap: var(--gap-5xs);
  text-align: left;
  font-size: var(--h5-semibold-size);
  color: var(--grey-900);
  height: 100vh;
  overflow-y: auto;
}
.jobs1,
.titles1 {
  background-color: var(--color-gray-100);
  overflow: hidden;
  flex-direction: row;
  align-items: flex-start;
}
.titles1 {
  align-self: stretch;
  display: none;
  justify-content: flex-start;
}
.titles1 {
  background-color: var(--white);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.04);
  padding: var(--padding-xs) var(--padding-xl);
  align-items: center;
  justify-content: space-between;
}
.emailChild {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-9xs);
  background-color: var(--grey-50);
  border: 1px solid var(--grey-70);
  box-sizing: border-box;
}
.ddMm,
.jackDorsey {
  position: absolute;
  top: 42.5%;
  left: 2.67%;
  letter-spacing: -0.04em;
  display: none;
}
.jackDorsey {
  top: 40%;
  left: 3.21%;
  font-size: var(--h4-bold-size);
  color: var(--gray-1);
}
.frameIcon {
  position: absolute;
  height: 50%;
  width: 5.35%;
  top: 25%;
  right: 92.51%;
  bottom: 25%;
  left: 2.14%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.search {
  position: absolute;
  top: 20%;
  left: 9.63%;
  line-height: 155%;
  font-weight: 500;
  color: var(--grey-600);
}
.input {
  position: relative;
  width: 374px;
  height: 40px;
}
.jobTabsChild {
  background-color: var(--white);
  width: 414px;
  height: 41px;
}
.all3,
.current,
.past,
.pending,
.upcoming {
  position: absolute;
  top: 0;
  line-height: 155%;
  mix-blend-mode: normal;
}
.current {
  left: 8.56%;
  font-weight: 600;
}
.all3,
.past,
.pending,
.upcoming {
  left: 39.57%;
  font-weight: 500;
}
.all3,
.past,
.pending {
  left: 78.88%;
}
.all3,
.pending {
  left: 78.07%;
  display: none;
}
.all3 {
  left: 102.94%;
}
.titlesChild {
  border-radius: var(--br-11xs) var(--br-11xs) 0 0;
  width: 124.67px;
  height: 2px;
  margin: 0px auto;
}
.titles2 {
  width: 100%;
  height: 32px;
  display: flex;
  gap: 1rem;
}
.jobTabsItem {
  position: absolute;
  top: 50.5px;
  left: -0.5px;
  border-top: 1px solid var(--grey-100);
  box-sizing: border-box;
  width: 415px;
  height: 1px;
}
.jobTabs {
  position: relative;
  width: 414px;
  height: 51px;
  text-align: center;
  color: var(--grey-600);
}
.topBar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: var(--p2-semibold-size);
  color: var(--gray-3);
}
.frameChild {
  position: relative;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  object-fit: cover;
}
.peoplename3 {
  position: relative;
  font-weight: 600;
  display: flex;
  align-items: center;
  width: 221px;
  flex-shrink: 0;
}
.peoplenameParent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-62xl);
}
.citiessummary12,
.icons {
  position: relative;
  flex-shrink: 0;
}
.citiessummary12 {
  font-size: var(--caption-bold-size);
  line-height: 155%;
  font-weight: 500;
  color: var(--grey-700);
  display: flex;
  align-items: center;
  width: 174px;
  height: 17px;
}
.icons {
  width: 14px;
  height: 15px;
  overflow: hidden;
}
.citiessummary13,
.iconsParent {
  display: flex;
  align-items: center;
}
.citiessummary13 {
  position: relative;
  line-height: 155%;
  font-weight: 500;
  width: 318px;
  flex-shrink: 0;
}
.iconsParent {
  flex-direction: row;
  justify-content: center;
  gap: var(--gap-9xs);
}
.icons1 {
  position: relative;
  width: 14px;
  height: 14px;
  overflow: hidden;
  flex-shrink: 0;
}
.citiessummary14 {
  position: relative;
  line-height: 155%;
  font-weight: 500;
}
.frameParent7,
.frameParent8 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
}
.frameParent7 {
  width: 374px;
  flex-direction: column;
  gap: var(--gap-9xs);
  font-size: var(--p3-semibold-size);
  color: var(--grey-600);
}
.completed,
.iAmEnjoying {
  position: relative;
  line-height: 155%;
}
.iAmEnjoying {
  font-size: var(--p2-semibold-size);
  font-weight: 500;
  color: var(--grey-800);
  display: flex;
  align-items: center;
  width: 374px;
}
.completed {
  letter-spacing: 0.1em;
  text-transform: uppercase;
}
.cancelledWrapper,
.completedWrapper {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-5xl);
  background-color: var(--green-100);
  display: flex;
  flex-direction: row;
  padding: var(--padding-11xs) var(--padding-5xs);
  align-items: center;
  justify-content: center;
}
.cancelledWrapper {
  background-color: var(--red-100);
  color: var(--red);
}
.frameParent9,
.statusChild {
  position: relative;
  width: 77px;
  height: 18px;
}
.statusChild {
  width: 87px;
  height: 15.98px;
}
.status {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-191xl);
  font-size: var(--caption-block-size);
  color: var(--logg-green);
}
.frameParent4 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xs);
}
.frameInner {
  position: relative;
  border-top: 1px solid var(--grey-100);
  box-sizing: border-box;
  width: 374px;
  height: 1px;
  display: none;
}
.frameParent3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
}
.bottomNav1,
.instanceParent {
  align-self: stretch;
  display: flex;
  align-items: center;
}
.instanceParent {
  flex-direction: column;
  padding: var(--padding-xl) 0;
  justify-content: flex-start;
  gap: var(--gap-13xl);
}
.bottomNav1 {
  background-color: var(--white);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  flex-direction: row;
  padding: var(--padding-smi) var(--padding-5xl);
  justify-content: space-between;
  text-align: center;
  font-size: var(--caption-bold-size);
  color: var(--grey-600);
}
.currentJobs,
.jobs {
  flex-direction: column;
  justify-content: flex-start;
}
.currentJobs {
  align-self: stretch;
  background-color: var(--white);
  overflow: hidden;
  display: none;
  align-items: center;
  text-align: left;
  font-size: var(--h5-semibold-size);
  color: var(--grey-900);
}
.jobs {
  position: relative;
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: var(--gap-3xs);
  text-align: center;
  font-size: var(--p3-semibold-size);
  color: var(--grey-10);
  font-family: var(--caption-bold);
}
@media screen and (max-width: 960px) {
  .navPrototype {
    display: none;
  }
  .titles {
    display: flex;
  }
  .component102 {
    display: none;
  }
  .jobs2,
  .jobs6 {
    align-self: stretch;
    width: auto;
  }
  .bottomNav,
  .jobs1,
  .titlesParent {
    display: flex;
  }
  .currentJobs {
    display: none;
  }
  .titles1 {
    align-self: stretch;
    display: flex;
    justify-content: flex-start;
  }
  .titles1 {
    background-color: var(--white);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.04);
    padding: var(--padding-xs) var(--padding-xl);
    align-items: center;
    justify-content: space-between;
  }
}
@media screen and (max-width: 430px) {
  .bottomNav,
  .jobs1,
  .titles,
  .titlesParent {
    display: none;
  }
  .currentJobs {
    display: flex;
  }
  .titles1 {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
}
.titles1 {
  background-color: var(--white);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.04);
  padding: var(--padding-xs) var(--padding-xl);
  align-items: center;
  justify-content: space-between;
}
}
