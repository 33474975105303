.navChild {
  position: absolute;
  top: 27px;
  left: 20px;
  width: 125px;
  height: 20px;
}
.iconshome {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.home {
  position: relative;
  line-height: 24px;
  font-weight: 500;
}
.default,
.iconshomeParent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.iconshomeParent {
  flex-direction: row;
  gap: var(--gap-5xs);
}
.default {
  align-self: stretch;
  border-radius: var(--br-11xs);
  overflow: hidden;
  flex-direction: column;
  padding: var(--padding-5xs);
  cursor: pointer;
}
.accountIcon {
  position: relative;
  width: 24px;
  height: 24px;
}
.home5 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
}
.default5,
.defaultParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.default5 {
  align-self: stretch;
  border-radius: var(--br-9xs);
  background-color: var(--grey-90);
  overflow: hidden;
  padding: var(--padding-5xs);
  color: var(--primary-10);
}
.defaultParent {
  position: absolute;
  top: 87px;
  left: 20px;
  width: 181px;
  gap: var(--gap-xs);
}
.nav {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--grey-100);
}
.navPrototype {
  position: relative;
  width: 221px;
  height: 1325px;
}
.back1 {
  position: absolute;
  top: 5px;
  left: 26px;
  line-height: 155%;
  font-weight: 500;
}
.frameIcon {
  position: absolute;
  top: 0;
  left: 0;
  width: 32px;
  height: 32px;
  overflow: hidden;
}
.back {
  position: relative;
  width: 59px;
  height: 32px;
}
.notifications {
  position: relative;
  font-size: var(--h4-semibold-size);
  font-weight: 600;
  color: var(--grey-900);
}
.titles {
  align-self: stretch;
  background-color: var(--white);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.04);
  overflow: hidden;
  display: none;
  flex-direction: row;
  padding: var(--padding-xs) var(--padding-4xs);
  align-items: center;
  justify-content: space-between;
  color: var(--grey-800);
}
.iconsadd {
  position: relative;
  width: 16px;
  height: 16px;
  overflow: hidden;
  flex-shrink: 0;
}
.button {
  position: relative;
  line-height: 100%;
  font-weight: 600;
}
.iconsaddParent,
.smallButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.iconsaddParent {
  gap: var(--gap-9xs);
}
.smallButtons {
  position: absolute;
  top: 14px;
  left: 80%;
  border-radius: var(--br-9xs);
  background-color: var(--primary-10);
  width: 146px;
  height: 40px;
  padding: var(--padding-5xs) var(--padding-xl);
  box-sizing: border-box;
  cursor: pointer;
}
.licenseAndCertification {
  position: absolute;
  top: 18px;
  left: 234px;
  font-size: var(--h5-semibold-size);
  line-height: 28px;
  color: var(--grey-900);
  text-align: left;
}
.icon {
  position: absolute;
  top: 14px;
  left: 52px;
  border: 1px solid var(--grey-80);
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs);
  align-items: center;
  justify-content: center;
}
.component102 {
  align-self: stretch;
  position: relative;
  background-color: var(--white);
  border-bottom: 1px solid var(--grey-80);
  box-sizing: border-box;
  height: 68px;
  overflow: hidden;
  flex-shrink: 0;
  color: var(--white);
}
.file11 {
  position: relative;
  width: 89.53px;
  height: 136px;
  opacity: 0.5;
}
.tapThePlus {
  position: relative;
  line-height: 26px;
  display: inline-block;
  width: 258px;
}
.file11Parent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xl);
  font-size: var(--p2-bold-size);
}
.frameChild {
  position: relative;
  border-radius: var(--br-9xs);
  width: 48px;
  height: 48px;
  overflow: hidden;
  flex-shrink: 0;
}
.title {
  position: relative;
  font-size: var(--p2-bold-size);
  line-height: 26px;
  font-weight: 600;
  color: var(--grey-10);
}
.subSubTitle,
.subTitle {
  position: relative;
  line-height: 24px;
}
.subSubTitle {
  display: none;
}
.titleParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.frameGroup,
.frameParent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.frameGroup {
  justify-content: flex-start;
  gap: var(--gap-xs);
}
.frameParent {
  align-self: stretch;
  justify-content: space-between;
}
.verificationRequirements {
  border-bottom: 1px solid var(--grey-80);
}
.verificationRequirements,
.verificationRequirements1 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  padding: var(--padding-base) 0;
  align-items: flex-start;
  justify-content: flex-start;
}
.verificationRequirementsParent {
  width: 558px;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}
.licenseproof,
.titlesParent,
.verificationRequirementsParent {
  display: flex;
  justify-content: flex-start;
}
.titlesParent {
  align-self: stretch;
  flex: 1;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  color: var(--grey-20);
  padding: 0 1rem;
}
.licenseproof {
  position: relative;
  background-color: var(--color-gray-100);
  width: 100%;
  height: 100vh;
  overflow: hidden;
  flex-direction: row;
  align-items: flex-start;
  text-align: center;
  font-size: var(--p3-medium-size);
  color: var(--grey-10);
  font-family: var(--p3-bold);
}
.Fab{
  display: none;
}
@media screen and (max-width: 960px) {
  .Fab{
    display: inline;
  }
  .navPrototype {
    display: none;
  }
  .titles {
    display: flex;
  }
  .component102{
    display: none;
  }
  .verificationRequirementsParent{
    width:100%
  }
}

