.helloCraigIm,
.pm {
  position: relative;
  line-height: 24px;
}
.pm {
  font-size: var(--caption-regular-size);
  line-height: 20px;
  color: var(--grey-20);
  text-align: right;
}
.chatRightInner,
.helloCraigImDonaldParent {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}
.helloCraigImDonaldParent {
  gap: var(--gap-5xs);
}
.chatRightInner {
  border-radius: var(--br-9xs) 0 var(--br-9xs) var(--br-9xs);
  background-color: var(--primary-80);
  padding: var(--padding-5xs) var(--padding-base) var(--padding-5xs)
    var(--padding-xs);
}
.chatRightChild {
  position: relative;
  width: 9px;
  height: 12px;
}
.chatRight {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  text-align: left;
  font-size: var(--p3-bold-size);
  color: var(--grey-0);
  font-family: var(--p3-bold);
}
