.iconWithBg,
.unionIcon {
  position: relative;
  border-radius: var(--br-8xs);
  width: 531px;
  height: 131.6px;
}
.iconWithBg {
  border-radius: var(--br-9xs);
  width: 32px;
  height: 32px;
}
.iconsplaceholder {
  position: relative;
  width: 24px;
  height: 24px;
  display: none;
}
.quote {
  position: relative;
  line-height: 24px;
}
.left {
  cursor: pointer;
}
.left,
.left2,
.leftParent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gap-9xs);
}
.leftParent {
  position: absolute;
  top: 15px;
  left: 20px;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-13xl);
}
.attachment {
  position: relative;
  width: 499px;
  height: 100px;
  text-align: left;
  font-size: var(--button-small-text-size);
  color: var(--grey-10);
  font-family: var(--button-small-text);
}
