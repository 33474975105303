.activities {
  position: absolute;
  top: 16px;
  left: 20px;
  line-height: 120%;
  font-weight: 600;
}
.titles,
.walletChild {
  background-color: var(--white);
}
.titles {
  align-self: stretch;
  position: relative;
  height: 56px;
  overflow: hidden;
  flex-shrink: 0;
}
.walletChild {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 247px;
}
.icons,
.proceed {
  position: relative;
}
.icons {
  width: 20px;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.proceed {
  line-height: 155%;
}
.iconsParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
}
.button,
.frameChild {
  position: absolute;
  border-radius: var(--br-9xs);
  width: 100%;
}
.button {
  top: 183px;
  background-color: var(--logg-green);
  height: 48px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-xs) 0 var(--padding-smi);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.frameChild {
  top: 0;
  left: 0;
  background-color: var(--gray-1);
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.06);
  height: 151px;
}
.frameItem {
  top: -56px;
  left: 307.54px;
  background-color: var(--color-darkslategray-200);
  width: 96.97px;
  height: 272.24px;
  transform: rotate(40.15deg);
  transform-origin: 0 0;
}
.b,
.frameInner,
.frameItem {
  position: absolute;
}
.frameInner {
  top: -73px;
  left: 115.54px;
  background-color: var(--color-darkslategray-200);
  width: 96.97px;
  height: 272.24px;
  transform: rotate(40.15deg);
  transform-origin: 0 0;
}
.b {
  top: 49px;
  left: 122px;
  font-size: var(--h1-size);
  color: var(--white);
}
.availableBalance {
  position: absolute;
  top: 21px;
  left: 124px;
  line-height: 155%;
  font-weight: 500;
  text-align: left;
}
.pendingBalance {
  font-weight: 500;
}
.span {
  color: var(--gray-5);
}
.b1 {
  color: var(--white);
}
.pendingBalance3560Container {
  position: absolute;
  top: 120px;
  left: 105px;
  line-height: 155%;
}
.lineDiv {
  position: absolute;
  top: 110.5px;
  left: -0.5px;
  border-top: 1px solid #414141;
  box-sizing: border-box;
  width: 100%;
  height: 1px;
}
.rectangleParent {
  position: absolute;
  top: 16px;
  border-radius: var(--br-5xs);
  width: 100%;
  height: 151px;
  overflow: hidden;
  font-size: var(--caption-bold-size);
  color: var(--grey-300);
}
.wallet1 {
  position: relative;
  width: 100%;
  height: 247px;
  overflow: hidden;
  flex-shrink: 0;
}
.recentTransactions {
  position: relative;
  font-size: 17px;
  line-height: 155%;
  color: var(--grey-900);
  width: 100%;
}
.iconStatus {
  position: relative;
  width: 40px;
  height: 40px;
}
.div,
.withdrawalToBank {
  position: relative;
  line-height: 155%;
  font-weight: 600;
  display: inline-block;
  flex-shrink: 0;
}
.div {
  color: var(--grey-900);
  text-align: right;
}
.withdrawalToBankParent {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}
.pm {
  position: relative;
  line-height: 155%;
  font-weight: 500;
  display: inline-block;
  flex-shrink: 0;
}
.successChild {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-81xl);
  background-color: var(--green-100);
  width: 53px;
  height: 16px;
}
.success1 {
  position: absolute;
  top: 3px;
  left: 6px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  font-weight: 600;
}
.success {
  position: absolute;
  top: 0;
  left: 0;
  width: 53px;
  height: 16px;
}
.pendingChild {
  left: 0;
  border-radius: var(--br-81xl);
  background-color: var(--yellow-100);
  width: 50px;
}
.failed,
.failedChild,
.pending,
.pendingChild {
  position: absolute;
  top: 0;
  height: 16px;
}
.pending {
  left: 3px;
  width: 50px;
  color: var(--yellow-600);
}
.failed,
.failedChild {
  width: 41px;
}
.failedChild {
  left: 0;
  border-radius: var(--br-81xl);
  background-color: var(--red-100);
}
.failed {
  left: 12px;
  color: var(--red-500);
}
.status {
  position: relative;
  width: 53px;
  height: 16px;
  font-size: var(--font-size-5xs);
  color: var(--logg-green);
}
.pmParent {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: var(--gap-56xl);
  font-size: var(--p3-semibold-size);
  color: var(--grey-600);
}
.frameGroup,
.iconStatusParent {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.iconStatusParent {
  width: 100%;
  flex-direction: row;
  align-items: center;
  gap: var(--gap-3xs);
}
.frameChild1 {
  position: relative;
  border-top: 1px solid var(--grey-100);
  box-sizing: border-box;
  width: 100%;
  height: 1px;
}
.frameParent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.wallettransactions {
  position: relative;
  width: 100%;
  height: 63px;
}
.failed4,
.pending4,
.success4 {
  position: absolute;
  top: 0;
  height: 16px;
}
.success4 {
  left: -3px;
  width: 53px;
  display: none;
}
.failed4,
.pending4 {
  left: 0;
  width: 50px;
  color: var(--yellow-600);
}
.failed4 {
  left: 9px;
  width: 41px;
  display: none;
  color: var(--red-500);
}
.status2 {
  position: relative;
  width: 50px;
  height: 16px;
  font-size: var(--font-size-5xs);
  color: var(--logg-green);
}
.frameChild6 {
  position: relative;
  border-top: 1px solid var(--grey-100);
  box-sizing: border-box;
  width: 100%;
  height: 1px;
  display: none;
}
.proceedWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.button1,
.transactions,
.walletParent {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.button1 {
  border-radius: var(--br-9xs);
  background-color: var(--white);
  border: 1px solid var(--logg-green);
  box-sizing: border-box;
  width: 181px;
  height: 40px;
  padding: var(--padding-3xs) 53px var(--padding-3xs) var(--padding-33xl);
  justify-content: center;
  text-align: center;
  font-size: var(--p3-semibold-size);
  color: var(--logg-green);
}
.transactions,
.walletParent {
  justify-content: flex-start;
}
.transactions {
  background-color: var(--white);
  overflow: hidden;
  gap: 17px;
  text-align: left;
  font-size: var(--p2-semibold-size);
  color: var(--grey-800);
  width: 100%;
}
.walletParent {
  gap: var(--gap-7xs);
  text-align: center;
  font-size: var(--p2-medium-size);
  color: var(--white);
  margin-bottom: 5rem;
  
}
.frameChild7,
.frameChild8,
.frameChild9 {
  position: absolute;
  top: -69.64px;
  left: 299.32px;
  background-color: var(--color-darkslategray-200);
  width: 94.37px;
  height: 264.96px;
  transform: rotate(40.15deg);
  transform-origin: 0 0;
}
.frameChild8,
.frameChild9 {
  top: -67.64px;
  left: 507.32px;
  height: 286.14px;
}
.frameChild9 {
  top: -91.05px;
  left: 116.34px;
  height: 264.96px;
}
.availableBalance1,
.div6 {
  position: relative;
  font-weight: 600;
}
.availableBalance1 {
  letter-spacing: 0.1em;
  text-transform: uppercase;
}
.div6 {
  align-self: stretch;
  font-size: var(--h3-semibold-size);
  letter-spacing: -0.02em;
  line-height: 40px;
  color: var(--white);
  text-align: center;
}
.availableBalanceParent,
.pendingBalanceParent {
  position: absolute;
  display: flex;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.availableBalanceParent {
  top: 20px;
  left: calc(50% - 60px);
  flex-direction: column;
  align-items: center;
}
.pendingBalanceParent {
  bottom: 12px;
  left: calc(50% - 79px);
  flex-direction: row;
  align-items: flex-start;
}
.frameChild10 {
  position: absolute;
  top: 95.5px;
  left: 30.5px;
  border-top: 1px solid var(--grey-30);
  box-sizing: border-box;
  width: 496px;
  height: 1px;
}
.rectangleGroup {
  position: relative;
  border-radius: var(--br-8xs);
  background-color: var(--gray-1);
  width: 558px;
  height: 128px;
  overflow: hidden;
  flex-shrink: 0;
}
.icons20pxplaceholder {
  position: relative;
  width: 20px;
  height: 20px;
  display: none;
}
.label {
  position: relative;
  line-height: 24px;
}
.largeButtons {
  border-radius: var(--br-8xs);
  background-color: var(--logg-green);
  width: 558px;
  height: 48px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs) 0;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
  cursor: pointer;
  text-align: center;
  font-size: var(--p3-semibold-size);
  color: var(--white);
}
.top {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xs);
}
.recentTransactions1,
.seeAll {
  position: relative;
  line-height: 26px;
  font-weight: 600;
}
.seeAll {
  font-weight: 500;
  color: var(--logg-green);
  cursor: pointer;
}
.title {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.deposit,
.div8 {
  position: relative;
  line-height: 26px;
  font-weight: 500;
  display: inline-block;
  width: 340px;
  flex-shrink: 0;
}
.div8 {
  text-align: right;
  width: 170px;
}
.depositParent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.pending12,
.pm6 {
  position: relative;
}
.pm6 {
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  width: 219px;
  flex-shrink: 0;
}
.pending12 {
  letter-spacing: 0.04em;
  text-transform: uppercase;
  font-weight: 600;
}
.transactionPills {
  position: absolute;
  top: 0;
  right: 0;
  border-radius: var(--br-81xl);
  background-color: var(--tertiary-100);
  display: flex;
  flex-direction: row;
  padding: var(--padding-9xs) var(--padding-7xs);
  align-items: center;
  justify-content: flex-end;
}
.transactionPillsWrapper {
  position: relative;
  width: 170px;
  height: 18px;
  text-align: right;
  font-size: var(--font-size-5xs);
  color: var(--tertiary-10);
}
.pmParent4 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-102xl);
  font-size: var(--caption-bold-size);
  color: var(--grey-30);
}
.frameParent9 {
  flex-direction: column;
  gap: var(--gap-11xs);
}
.frameParent9,
.transactionHistory,
.transactionIconsParent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.transactionIconsParent {
  flex-direction: row;
  gap: var(--gap-5xs);
}
.transactionHistory {
  border-bottom: 1px solid var(--grey-80);
  flex-direction: column;
  padding: 0 0 var(--padding-xs);
}
.transactionPills1 {
  position: absolute;
  top: 0;
  right: 0;
  border-radius: var(--br-81xl);
  background-color: var(--green-100);
  display: flex;
  flex-direction: row;
  padding: var(--padding-9xs) var(--padding-7xs);
  align-items: center;
  justify-content: flex-end;
}
.transactionPillsContainer {
  position: relative;
  width: 170px;
  height: 18px;
  text-align: right;
  font-size: var(--font-size-5xs);
  color: var(--logg-green);
}
.merge,
.transactionHistory4 {
  display: flex;
  flex-direction: column;
}
.transactionHistory4 {
  padding: 0 0 var(--padding-xs);
  align-items: flex-start;
  justify-content: flex-start;
}
.merge {
  gap: var(--gap-base);
  font-size: var(--p2-semibold-size);
  color: var(--grey-0);
  width: 100%;
}
.merge,
.walletHome,
.walletHomeWrapper {
  align-items: flex-start;
  justify-content: flex-start;
}
.walletHome {
  display: flex;
  flex-direction: column;
  gap: var(--gap-5xl);
}
.walletHomeWrapper {
  background-color: var(--color-gray-100);
  display: none;
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-5xs) var(--padding-21xl);
  font-size: var(--capitalised-10-size);
  color: var(--gray-5);
}
.brandLogoIcon,
.home1 {
  position: absolute;
  width: 97.14%;
}
.home1 {
  top: 63.46%;
  left: 0;
  line-height: 155%;
  font-weight: 500;
  display: inline-block;
}
.brandLogoIcon {
  height: 57.69%;
  top: 0;
  right: 0;
  bottom: 42.31%;
  left: 2.86%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.home {
  position: relative;
  width: 28px;
  height: 52px;
}
.jobs1 {
  position: absolute;
  height: 38%;
  width: 100%;
  top: 62%;
  left: 0;
  line-height: 155%;
  font-weight: 500;
  display: inline-block;
}
.bibriefcaseFillIcon {
  position: absolute;
  height: 52%;
  width: 92.86%;
  top: 0;
  right: 3.57%;
  bottom: 48%;
  left: 3.57%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.wallet3 {
  position: absolute;
  height: 38.78%;
  width: 100%;
  top: 61.22%;
  left: 0;
  line-height: 155%;
  display: inline-block;
}
.zondiconswallet {
  position: absolute;
  height: 48.98%;
  width: 63.16%;
  top: 0;
  right: 18.4%;
  bottom: 51.02%;
  left: 18.44%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.wallet2 {
  position: relative;
  width: 40px;
  height: 52px;
  color: var(--logg-green);
}
.account1 {
  position: absolute;
  height: 53.52%;
  width: 100%;
  top: 55.48%;
  left: 0;
  line-height: 155%;
  font-weight: 500;
  display: inline-block;
}
.mdiaccountIcon {
  position: absolute;
  height: 57.69%;
  width: 75%;
  top: 0;
  right: 4.18%;
  bottom: 42.31%;
  left: 20.82%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.bottomNav,
.walletmob {
  align-self: stretch;
  align-items: center;
}
.bottomNav {
  background-color: var(--white);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: row;
  padding: var(--padding-smi) 83px;
  justify-content: space-between;
  text-align: center;
  font-size: var(--caption-bold-size);
  color: var(--grey-600);
}
.walletmob {
  background-color: var(--color-gray-100);
  overflow: hidden;
  display: none;
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-7xs);
  padding: 0 1rem;
}
.navChild {
  position: absolute;
  top: 27px;
  left: 20px;
  width: 125px;
  height: 20px;
}
.iconshome {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.home2 {
  position: relative;
  line-height: 24px;
  font-weight: 500;
  
}
.default {
  border-radius: var(--br-11xs);
  width: 181px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: var(--padding-5xs);
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
  cursor: pointer;
}
.home5 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
}
.default3 {
  border-radius: var(--br-9xs);
  background-color: var(--grey-90);
  width: 181px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: var(--padding-5xs);
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
  color: var(--primary-10);
}
.accountIcon {
  position: relative;
  width: 24px;
  height: 24px;
}
.default5,
.defaultParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.default5 {
  border-radius: var(--br-11xs);
  width: 181px;
  overflow: hidden;
  padding: var(--padding-5xs);
  box-sizing: border-box;
}
.defaultParent {
  position: absolute;
  top: 87px;
  left: 20px;
  gap: var(--gap-xs);
}
.nav {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--grey-50);
}
.navPrototype {
  position: relative;
  width: 221px;
  height: 100vh;
}
.activities1 {
  position: relative;
  line-height: 120%;
  font-weight: 600;
}
.titles1 {
  align-self: stretch;
  background-color: var(--white);
  overflow: hidden;
  display: none;
  flex-direction: row;
  padding: var(--padding-base) var(--padding-xl);
  align-items: center;
  justify-content: flex-start;
}
.licenseAndCertification {
  position: relative;
  line-height: 28px;
}
.component102 {
  align-self: stretch;
  background-color: var(--white);
  border-bottom: 1px solid var(--grey-80);
  padding: 1rem;
  padding-left: 10%;
}
.component102,
.top1,
.walletHome1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.top1 {
  gap: var(--gap-xs);
  font-size: var(--capitalised-10-size);
  color: var(--gray-5);
}
.walletHome1 {
  gap: var(--gap-9xs);
}
.walletHomeContainer {
  background-color: var(--color-gray-100);
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-5xs) var(--padding-21xl);
  align-items: flex-start;
}
.titlesParent,
.wallet,
.wallet4,
.walletHomeContainer {
  display: flex;
  justify-content: flex-start;
}
.titlesParent {
  flex: 1;
  flex-direction: column;
  align-items: center;
  gap: var(--gap-xs);
  text-align: left;
  font-size: var(--h5-semibold-size);
  color: var(--grey-900);
  height: 100vh;
  overflow-y: auto;
}
.wallet,
.wallet4 {
  align-items: flex-start;
}
.wallet4 {
  align-self: stretch;
  background-color: var(--color-gray-100);
  overflow-y: auto;
  flex-direction: row;
  text-align: center;
  font-size: var(--p3-semibold-size);
  color: var(--grey-10);
}
.wallet {
  position: relative;
  width: 100%;
  flex-direction: column;
  text-align: left;
  font-size: var(--h5-semibold-size);
  color: var(--grey-900);
  font-family: var(--caption-bold);
}
@media screen and (max-width: 960px) {
  .walletParent {
    display: none;
  }
  .walletHomeWrapper,
  .walletmob {
    display: flex;
  }
  .navPrototype {
    display: none;
  }
  .titles1 {
    display: flex;
  }
  .component102,
  .wallet4 {
    display: none;
  }
}
@media screen and (max-width: 430px) {
  .walletParent {
    display: flex;
    width:100%
  }
  .titles1,
  .walletHomeWrapper {
    display: none;
  }
}
