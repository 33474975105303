.monthly {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.02em;
  line-height: 36px;
  font-weight: 600;
}
.monthlyprofessional1 {
  font-weight: 500;
  color: var(--grey-20);
}
.monthlyprofessional {
  align-self: stretch;
  position: relative;
  font-size: var(--p2-semibold-size);
  line-height: 26px;
}
.title {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.iconsscrew {
  position: relative;
  width: 20px;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.jobManagement {
  flex: 1;
  position: relative;
  line-height: 26px;
  font-weight: 600;
}
.iconsscrewParent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.automaticallyManageYour {
  flex: 1;
  position: relative;
  line-height: 20px;
}
.automaticallyManageYourJobsWrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  padding: 0 0 0 var(--padding-9xl);
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--caption-regular-size);
  color: var(--grey-20);
}
.subscriptionList {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-11xs);
}
.div,
.points {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
}
.div {
  border-radius: var(--br-9xs);
  background-color: var(--grey-100);
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-xl);
  align-items: center;
}
.points {
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-5xs);
  font-size: var(--p2-semibold-size);
}
.iconsmessage {
  position: relative;
  width: 20px;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.label {
  position: relative;
  line-height: 24px;
}
.largeButtons,
.merge1 {
  align-self: stretch;
  align-items: center;
}
.largeButtons {
  border-radius: var(--br-8xs);
  background-color: var(--primary-20base);
  height: 44px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs) 0;
  box-sizing: border-box;
  justify-content: center;
  gap: var(--gap-5xs);
  cursor: pointer;
  text-align: center;
  font-size: var(--p3-bold-size);
  color: var(--white);
}
.merge1 {
  padding: 0 var(--padding-5xl);
  gap: var(--gap-xl);
}
.changeplanmonth,
.merge,
.merge1 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.merge {
  align-self: stretch;
  padding: 0 0 var(--padding-xl);
  align-items: center;
}
.changeplanmonth {
  position: relative;
  border-radius: var(--br-9xs);
  background-color: var(--white);
  border: 1px solid var(--grey-80);
  box-sizing: border-box;
  width: 655px;
  padding: var(--padding-xl) var(--padding-12xs) 0;
  align-items: flex-start;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
  text-align: left;
  font-size: var(--h4-semibold-size);
  color: var(--grey-10);
  font-family: var(--p3-bold);
}
