.component29Child {
  background-color: var(--color-whitesmoke-100);
}
.component29Child,
.frameChild,
.rectangleWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 414px;
  height: 184px;
}
.frameChild {
  object-fit: cover;
}
.rectangleWrapper {
  overflow: hidden;
}
.frameDiv,
.rectangleContainer,
.rectangleFrame,
.rectangleWrapper1 {
  position: absolute;
  top: 0;
  left: 414px;
  width: 414px;
  height: 184px;
  overflow: hidden;
}
.frameDiv,
.rectangleFrame,
.rectangleWrapper1 {
  left: 828px;
}
.frameDiv,
.rectangleWrapper1 {
  left: 1242px;
}
.rectangleWrapper1 {
  left: 1656px;
}
.component29Item,
.frameParent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 184px;
}
.component29Item {
  top: 77px;
  background: linear-gradient(
    180deg,
    rgba(34, 34, 34, 0),
    rgba(34, 34, 34, 0.7)
  );
  width: 414px;
  height: 107px;
  opacity: 0.8;
}
.component29Inner {
  position: absolute;
  top: 166px;
  left: 180px;
  width: 54px;
  height: 6px;
}
.edit2Icon {
  position: relative;
  width: 12px;
  height: 12px;
  overflow: hidden;
  flex-shrink: 0;
}
.edit {
  position: relative;
  line-height: 155%;
  font-weight: 600;
}
.button,
.edit2Parent {
  display: flex;
  align-items: center;
  justify-content: center;
}
.edit2Parent {
  flex-direction: row;
  gap: var(--gap-9xs);
}
.button {
  position: absolute;
  top: 12px;
  left: 336px;
  border-radius: var(--br-9xs);
  background-color: var(--white);
  border: 1px solid var(--logg-green);
  box-sizing: border-box;
  width: 66px;
  flex-direction: column;
  padding: var(--padding-8xs) var(--padding-xs);
}
.component29 {
  position: relative;
  width: 100%;
  height: 184px;
  overflow: hidden;
  flex-shrink: 0;
  font-size: var(--p3-semibold-size);
  color: var(--logg-green);
}
.groupChild {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-81xl);
  width: 48px;
  height: 48px;
  object-fit: cover;
}
.groupDiv {
  position: relative;
  width: 48px;
  height: 48px;
}
.smithsConstruction {
  position: relative;
  line-height: 155%;
}
.guarantee1Icon {
  position: relative;
  width: 16px;
  height: 16px;
  overflow: hidden;
  flex-shrink: 0;
}
.applianceRepair {
  position: absolute;
  top: -2px;
  left: 0;
  line-height: 155%;
  font-weight: 500;
}
.applianceRepairWrapper {
  position: relative;
  width: 99px;
  height: 14px;
  overflow: hidden;
  flex-shrink: 0;
  font-size: var(--caption-bold-size);
  color: var(--grey-600);
}
.groupParent {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
}
.rookieChild {
  position: absolute;
  top: 6px;
  left: 1px;
  width: 24px;
  height: 24px;
}
.rookieInner,
.rookieItem {
  position: absolute;
  top: 31px;
  left: 7px;
  width: 12px;
  height: 12px;
}
.rookieInner {
  top: 0;
  left: 0;
  width: 25px;
  height: 5px;
  display: none;
}
.vectorIcon {
  position: absolute;
  top: 22px;
  left: 7.5px;
  border-radius: var(--br-12xs);
  width: 11px;
  height: 4px;
}
.intermediate,
.rookie {
  position: absolute;
  top: 0;
  left: 0;
  width: 26px;
  height: 43px;
  overflow: hidden;
}
.intermediate {
  display: none;
  color: var(--grey-50);
}
.skilled,
.skilledInner {
  position: absolute;
  top: 0;
  left: 10px;
  width: 5px;
  height: 5px;
}
.skilled {
  left: 0;
  width: 26px;
  height: 43px;
  overflow: hidden;
  display: none;
  color: var(--grey-900);
}
.semiPro,
.semiProInner {
  position: absolute;
  top: 0;
  left: 8px;
  width: 10px;
  height: 5px;
}
.semiPro {
  left: 0;
  width: 26px;
  height: 43px;
  overflow: hidden;
  display: none;
}
.legendary,
.lengendaryInner,
.professionalInner {
  position: absolute;
  top: 0;
  left: 5px;
  width: 15px;
  height: 5px;
}
.legendary,
.lengendaryInner {
  left: 3px;
  width: 20px;
}
.legendary {
  top: 8.5px;
  left: 5.5px;
  line-height: 250%;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 6px;
}
.ambassadorInner,
.awards {
  position: absolute;
  top: 0;
  left: 0;
  width: 25px;
  height: 5px;
}
.awards {
  top: 2px;
  left: 348px;
  width: 26px;
  height: 43px;
  overflow: hidden;
  text-align: center;
  font-size: 2.37px;
  color: var(--white);
}
.frameContainer,
.icons {
  position: relative;
  width: 100%;
  height: 58px;
  overflow: hidden;
  flex-shrink: 0;
}
.icons {
  width: 20px;
  height: 20px;
  display: none;
}
.proceedWrapper {
  flex-direction: row;
}
.button1,
.iconsParent,
.proceedWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.iconsParent {
  flex-direction: row;
  gap: var(--gap-5xs);
}
.button1 {
  border-radius: var(--br-9xs);
  background-color: var(--logg-green);
  width: 181px;
  height: 40px;
  flex-direction: column;
  padding: var(--padding-3xs) 0;
  box-sizing: border-box;
  cursor: pointer;
}
.icons1 {
  position: relative;
  width: 20px;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.button2 {
  border-radius: var(--br-9xs);
  background-color: var(--white);
  border: 1px solid var(--logg-green);
  box-sizing: border-box;
  width: 181px;
  height: 40px;
  display: flex;
  flex-direction: column;
  padding: var(--padding-3xs) 0;
  align-items: center;
  justify-content: center;
  color: var(--logg-green);
}
.buttonParent,
.frameGroup {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.buttonParent {
  flex-direction: row;
  gap: var(--gap-xs);
  text-align: center;
  font-size: var(--p3-semibold-size);
  color: var(--white);
}
.frameGroup {
  flex-direction: column;
  gap: var(--gap-xl);
}
.settingsChild,
.title {
  background-color: var(--white);
}
.title {
  display: flex;
  flex-direction: column;
  padding: var(--padding-base) var(--padding-xl) var(--padding-xl);
  align-items: flex-start;
  justify-content: flex-start;
}
.settingsChild {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.tabChild,
.tabItem {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-9xs);
  background-color: var(--grey-50);
  width: 374px;
  height: 36px;
}
.tabItem {
  top: 3px;
  left: 3px;
  background-color: var(--white);
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.05);
  width: 184px;
  height: 30px;
}
.account1 {
  top: 6px;
  left: 60px;
  line-height: 155%;
  font-weight: 600;
}
.account1,
.settings1,
.tab {
  position: absolute;
}
.settings1 {
  top: 6px;
  left: 250px;
  font-size: var(--p2-medium-size);
  line-height: 155%;
  font-weight: 500;
  color: var(--grey-700);
}
.tab {
  height: 81.82%;
  width: 90.34%;
  top: 9.09%;
  right: 4.83%;
  bottom: 9.09%;
  left: 4.83%;
  overflow: hidden;
}
.settings {
  position: relative;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
  width: 100%;
  height: 44px;
  text-align: center;
  font-size: var(--p2-semibold-size);
  color: var(--logg-green);
}
.div,
.totalPoints {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 120%;
  font-weight: 600;
}
.totalPoints {
  top: 28px;
  font-size: var(--p3-semibold-size);
  font-weight: 500;
  color: var(--grey-600);
}
.points {
  position: relative;
  width: 79px;
  height: 50px;
}
.nextLevel {
  margin: 0;
  font-weight: 500;
}
.intermediate2 {
  margin: 0;
  color: var(--grey-900);
}
.nextLevelIntermediateContainer {
  position: relative;
  font-size: var(--caption-bold-size);
  line-height: 155%;
  text-align: right;
  color: var(--grey-600);
}
.pointsParent {
  width: 374px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.progressBarChild {
  position: absolute;
  top: 3px;
  left: 0;
  border-radius: var(--br-9xs);
  background-color: var(--grey-100);
  width: 378px;
  height: 4px;
}
.progressBarInner,
.progressBarItem {
  position: absolute;
  background-color: var(--color-green-100);
}
.progressBarItem {
  top: 3px;
  left: 0;
  border-radius: var(--br-9xs);
  width: 172px;
  height: 4px;
}
.progressBarInner {
  top: -3px;
  left: 167px;
  border-radius: 50%;
  border: 3px solid var(--grey-100);
  box-sizing: border-box;
  width: 16px;
  height: 16px;
}
.progressBar {
  position: relative;
  width: 378px;
  height: 10px;
}
.frameParent3 {
  width: 378px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.earn950Points {
  position: relative;
  font-size: var(--caption-bold-size);
  line-height: 155%;
  font-weight: 500;
  color: var(--grey-600);
  text-align: center;
}
.frameParent2 {
  width: 374px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xs);
}
.howToEarn {
  position: relative;
  line-height: 155%;
  font-weight: 500;
}
.alertCircleIcon {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.howToEarnPoints {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  padding: var(--padding-mini) 0;
  align-items: flex-start;
  justify-content: space-between;
  font-size: var(--p2-medium-size);
  color: var(--grey-800);
}
.pointsCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-3xs);
  font-size: var(--h5-semibold-size);
}
.verification1 {
  position: relative;
  font-size: var(--h4-bold-size);
  font-weight: 600;
  color: var(--grey-900);
  margin-left: 10px;
}
.instanceChild {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-5xs);
  background-color: var(--grey-800);
  width: 374px;
  height: 97px;
}
.instanceItem {
  position: absolute;
  top: 0;
  left: 81px;
  width: 256.5px;
  height: 113px;
}
.completeVerificationTo,
.youAreUnverified {
  position: absolute;
  left: 0;
  letter-spacing: -0.04em;
  width: 300px
}
.youAreUnverified {
  top: 0;
  font-weight: 600;
}
.completeVerificationTo {
  top: 23px;
  font-size: var(--p3-semibold-size);
  color: #d7d7d7;
}
.youAreUnverifiedParent {
  position: absolute;
  height: 41.24%;
  width: 58.02%;
  top: 20.62%;
  right: 24.87%;
  bottom: 38.14%;
  left: 17.11%;
}
.exclamationMark1Icon {
  position: absolute;
  top: 24px;
  left: 24px;
  width: 32px;
  height: 32px;
  overflow: hidden;
}
.rectangleDiv {
  top: 0;
  left: 0;
  border-radius: var(--br-xl);
  background-color: var(--grey-600);
  width: 326px;
}
.div1,
.div2,
.rectangleDiv {
  position: absolute;
  height: 6px;
}
.div1 {
  top: 0;
  left: 0;
  border-radius: var(--br-xl);
  background-color: var(--color-limegreen-100);
  width: 54px;
  display: none;
}
.div2 {
  width: 108px;
}
.div2,
.div3,
.div4 {
  top: 0;
  left: 0;
  border-radius: var(--br-xl);
  background-color: var(--color-limegreen-100);
  display: none;
}
.div3 {
  position: absolute;
  width: 162px;
  height: 6px;
}
.div4 {
  width: 216px;
}
.div4,
.div5,
.div6,
.progressBar1 {
  position: absolute;
  height: 6px;
}
.div5 {
  top: 0;
  left: 0;
  border-radius: var(--br-xl);
  background-color: var(--color-limegreen-100);
  width: 270px;
  display: none;
}
.div6,
.progressBar1 {
  width: 326px;
}
.div6 {
  top: 0;
  left: 0;
  border-radius: var(--br-xl);
  background-color: var(--color-limegreen-100);
  display: none;
}
.progressBar1 {
  top: 71px;
  left: 24px;
}
.rectangleParent {
  position: relative;
  border-radius: var(--br-5xs);
  width: 100%;
  height: 97px;
  overflow: hidden;
  flex-shrink: 0;
  font-size: var(--p1-semibold-size);
  color: var(--white);
}
.button3 {
  border-radius: var(--br-9xs);
  background-color: var(--white);
  border: 1px solid var(--logg-green);
  box-sizing: border-box;
  width: 350px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-4xs) var(--padding-128xl);
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: var(--p3-semibold-size);
  color: var(--logg-green);
}
.certificationDependingOnSerParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xs);
  font-size: var(--caption-bold-size);
  color: var(--grey-600);
}
.licenseAndCertificationParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
.verificationInner {
  border-radius: var(--br-5xs);
  background-color: var(--white);
  border: 1px solid var(--gray-5);
  display: flex;
  flex-direction: column;
  padding: var(--padding-xs);
  align-items: center;
  justify-content: center;
}
.certificationDependingOnSerGroup,
.verification {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xs);
  font-size: var(--caption-bold-size);
  color: var(--gray-3);
}
.verification {
  justify-content: center;
  gap: var(--gap-3xs);
  font-size: var(--p2-semibold-size);
  color: var(--grey-800);
}
.groupChild1,
.groupChild2,
.groupInner,
.groupItem {
  position: absolute;
  height: 4.55%;
  width: 1.69%;
  top: 23.08%;
  right: 96.26%;
  bottom: 72.38%;
  left: 2.05%;
  background-color: var(--logg-green);
}
.groupChild1,
.groupChild2,
.groupInner {
  top: 2.11%;
  right: 47.28%;
  bottom: 93.35%;
  left: 51.03%;
  background-color: var(--color-royalblue-100);
}
.groupChild1,
.groupChild2 {
  top: 10.14%;
  right: 70.59%;
  bottom: 85.32%;
  left: 27.72%;
  background-color: var(--red);
}
.groupChild2 {
  top: 97.2%;
  right: 62.15%;
  bottom: -1.74%;
  left: 36.16%;
  background-color: var(--logg-green);
  transform: rotate(-164.28deg);
  transform-origin: 0 0;
  opacity: 0.47;
}
.groupChild10,
.groupChild3,
.groupChild4,
.groupChild5,
.groupChild6,
.groupChild7,
.groupChild8,
.groupChild9 {
  position: absolute;
  height: 4.55%;
  width: 1.69%;
  top: 9.44%;
  right: 83.43%;
  bottom: 86.01%;
  left: 14.89%;
  background-color: var(--logg-green);
  transform: rotate(-92.79deg);
  transform-origin: 0 0;
  opacity: 0.16;
}
.groupChild10,
.groupChild4,
.groupChild5,
.groupChild6,
.groupChild7,
.groupChild8,
.groupChild9 {
  top: 86.71%;
  right: 79.71%;
  bottom: 8.74%;
  left: 18.6%;
  transform: rotate(-162.76deg);
  opacity: 0.62;
}
.groupChild10,
.groupChild5,
.groupChild6,
.groupChild7,
.groupChild8,
.groupChild9 {
  top: 81.28%;
  right: 32.42%;
  bottom: 14.17%;
  left: 65.89%;
  transform: rotate(-63.38deg);
  opacity: 0.4;
}
.groupChild10,
.groupChild6,
.groupChild7,
.groupChild8,
.groupChild9 {
  top: 83.89%;
  right: 90.66%;
  bottom: 11.57%;
  left: 7.65%;
  transform: rotate(-156.68deg);
  opacity: 0.28;
}
.groupChild10,
.groupChild7,
.groupChild8,
.groupChild9 {
  top: 54.9%;
  right: 92.88%;
  bottom: 40.56%;
  left: 5.43%;
  transform: rotate(168.84deg);
  opacity: 0.6;
}
.groupChild10,
.groupChild8,
.groupChild9 {
  top: 9.44%;
  right: 2.7%;
  bottom: 86.01%;
  left: 95.61%;
  transform: rotate(-91.78deg);
  opacity: 0.78;
}
.groupChild10,
.groupChild9 {
  top: 5.1%;
  right: 58.03%;
  bottom: 90.35%;
  left: 40.28%;
  transform: rotate(-146.54deg);
  opacity: 0.25;
}
.groupChild10 {
  top: 12.23%;
  right: 96.6%;
  bottom: 83.22%;
  left: 1.71%;
  background-color: var(--color-royalblue-100);
  transform: rotate(-110.03deg);
  opacity: 0.74;
}
.groupChild11,
.groupChild12,
.groupChild13,
.groupChild14,
.groupChild15,
.groupChild16,
.groupChild17,
.groupChild18 {
  position: absolute;
  height: 4.55%;
  width: 1.69%;
  top: 60.77%;
  right: 2.63%;
  bottom: 34.69%;
  left: 95.68%;
  background-color: var(--color-royalblue-100);
  transform: rotate(169.86deg);
  transform-origin: 0 0;
  opacity: 0.55;
}
.groupChild12,
.groupChild13,
.groupChild14,
.groupChild15,
.groupChild16,
.groupChild17,
.groupChild18 {
  top: 34.31%;
  right: 83.12%;
  bottom: 61.15%;
  left: 15.2%;
  transform: rotate(108.51deg);
  opacity: 0.2;
}
.groupChild13,
.groupChild14,
.groupChild15,
.groupChild16,
.groupChild17,
.groupChild18 {
  top: 100%;
  right: 89.84%;
  bottom: -4.55%;
  left: 8.47%;
  transform: rotate(-122.71deg);
  opacity: 0.03;
}
.groupChild14,
.groupChild15,
.groupChild16,
.groupChild17,
.groupChild18 {
  top: 0;
  right: 29.72%;
  bottom: 95.45%;
  left: 68.59%;
  transform: rotate(75.04deg);
  opacity: 0.33;
}
.groupChild15,
.groupChild16,
.groupChild17,
.groupChild18 {
  top: 39.86%;
  right: 9.21%;
  bottom: 55.59%;
  left: 89.1%;
  transform: rotate(78.08deg);
  opacity: 0.75;
}
.groupChild16,
.groupChild17,
.groupChild18 {
  top: 97.9%;
  right: 14.86%;
  bottom: -2.44%;
  left: 83.45%;
  background-color: var(--color-chocolate-100);
  transform: rotate(87.72deg);
  opacity: 0.32;
}
.groupChild17,
.groupChild18 {
  top: 75.81%;
  right: 64.51%;
  bottom: 19.65%;
  left: 33.8%;
  transform: rotate(-21.3deg);
}
.groupChild18 {
  top: 22.73%;
  right: 26.01%;
  bottom: 72.73%;
  left: 72.31%;
  transform: rotate(-59.32deg);
  opacity: 0.88;
}
.groupChild19,
.groupChild20,
.groupChild21,
.groupChild22,
.groupChild23,
.groupChild24,
.groupChild25,
.groupChild26 {
  position: absolute;
  height: 4.55%;
  width: 1.69%;
  top: 91.26%;
  right: 41.84%;
  bottom: 4.19%;
  left: 56.47%;
  background-color: var(--color-chocolate-100);
  transform: rotate(179.49deg);
  transform-origin: 0 0;
  opacity: 0.86;
}
.groupChild20,
.groupChild21,
.groupChild22,
.groupChild23,
.groupChild24,
.groupChild25,
.groupChild26 {
  top: 33.22%;
  right: 21.95%;
  bottom: 62.24%;
  left: 76.36%;
  transform: rotate(149.58deg);
  opacity: 0.06;
}
.groupChild21,
.groupChild22,
.groupChild23,
.groupChild24,
.groupChild25,
.groupChild26 {
  top: 81.82%;
  right: 54.38%;
  bottom: 13.64%;
  left: 43.93%;
  transform: rotate(59.83deg);
  opacity: 0.34;
}
.groupChild22,
.groupChild23,
.groupChild24,
.groupChild25,
.groupChild26 {
  top: 76.54%;
  right: 13.17%;
  bottom: 18.91%;
  left: 85.14%;
  transform: rotate(-35.49deg);
  opacity: 0.68;
}
.groupChild23,
.groupChild24,
.groupChild25,
.groupChild26 {
  top: 24.71%;
  right: 76.83%;
  bottom: 70.75%;
  left: 21.48%;
  background-color: var(--red);
  transform: rotate(97.35deg);
  opacity: 0.26;
}
.groupChild24,
.groupChild25,
.groupChild26 {
  top: 13.29%;
  right: 5.4%;
  bottom: 82.17%;
  left: 92.91%;
  transform: rotate(110.53deg);
  opacity: 0.19;
}
.groupChild25,
.groupChild26 {
  top: 65.73%;
  right: 96.94%;
  bottom: 29.73%;
  left: 1.38%;
  transform: rotate(-144.51deg);
  opacity: 0.67;
}
.groupChild26 {
  top: 28.62%;
  right: 6.64%;
  bottom: 66.83%;
  left: 91.67%;
  transform: rotate(-93.8deg);
  opacity: 0.76;
}
.rectangleGroup {
  position: absolute;
  height: 186.93%;
  width: 39.84%;
  top: 10.39%;
  right: 4.81%;
  bottom: -97.32%;
  left: 55.35%;
  opacity: 0.3;
}
.giftBoxIcon {
  position: absolute;
  height: 99.06%;
  width: 17.45%;
  top: 0.47%;
  right: 82.55%;
  bottom: 0.47%;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.referABusiness,
.referEarn {
  position: relative;
  line-height: 120%;
  font-weight: 600;
}
.referABusiness {
  font-size: var(--caption-bold-size);
  line-height: 155%;
  font-weight: 500;
  color: var(--grey-700);
  width:15rem
}
.referEarnParent {
  position: absolute;
  top: 0;
  left: 57px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-11xs);
}
.giftBoxParent {
  position: absolute;
  height: 58.44%;
  width: 62.83%;
  top: 20.78%;
  right: 29.41%;
  bottom: 20.78%;
  left: 7.75%;
}
.referAndEarn {
  position: relative;
  border-radius: var(--br-5xs);
  background-color: var(--green-100);
  border: 1px solid var(--grey-300);
  box-sizing: border-box;
  width: 374px;
  height: 77px;
  overflow: hidden;
  flex-shrink: 0;
  font-size: var(--h5-semibold-size);
}
.connectSocialAccounts {
  position: relative;
  font-weight: 600;
}
.groupIcon {
  position: relative;
  width: 32px;
  height: 32px;
}
.groupContainer {
  width: 311.59px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}
.connectSocialAccount {
  align-self: stretch;
  height: 143px;
  display: flex;
  flex-direction: column;
  padding: var(--padding-3xs) 22px;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-21xl);
  text-align: center;
  margin-bottom:5rem ;
}
.home1 {
  position: absolute;
  top: 63.46%;
  left: 0;
  line-height: 155%;
  font-weight: 500;
}
.brandLogoIcon {
  height: 57.69%;
  width: 97.14%;
  top: 0;
  right: 0;
  bottom: 42.31%;
  left: 2.86%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.brandLogoIcon,
.home,
.wallet1 {
  position: absolute;
}
.home {
  height: 65.82%;
  width: 8.45%;
  top: 17.72%;
  right: 85.75%;
  bottom: 16.46%;
  left: 5.8%;
}
.wallet1 {
  top: 61.22%;
  left: 0;
  line-height: 155%;
  font-weight: 500;
}
.zondiconswallet {
  position: absolute;
  height: 48.98%;
  width: 66.67%;
  top: 0;
  right: 16.67%;
  bottom: 51.02%;
  left: 16.67%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.wallet {
  position: absolute;
  height: 62.03%;
  width: 8.7%;
  top: 21.52%;
  right: 35.51%;
  bottom: 16.46%;
  left: 55.8%;
}
.account3 {
  position: absolute;
  top: 63.46%;
  left: 0;
  line-height: 155%;
}
.mdiaccountIcon {
  position: absolute;
  height: 55.77%;
  width: 58%;
  top: 0;
  right: 20%;
  bottom: 44.23%;
  left: 22%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.account2 {
  position: absolute;
  height: 65.82%;
  width: 12.08%;
  top: 17.72%;
  right: 6.04%;
  bottom: 16.46%;
  left: 81.88%;
  color: var(--logg-green);
}
.jobs1 {
  position: absolute;
  top: 62%;
  left: 0;
  line-height: 155%;
  font-weight: 500;
}
.bibriefcaseFillIcon {
  position: absolute;
  height: 52%;
  width: 92.86%;
  top: 0;
  right: 3.57%;
  bottom: 48%;
  left: 3.57%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.jobs {
  position: absolute;
  height: 63.29%;
  width: 6.76%;
  top: 20.25%;
  right: 61.59%;
  bottom: 16.46%;
  left: 31.64%;
}
.bottomNav {
  position: relative;
  background-color: var(--white);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  width: 414px;
  height: 79px;
  text-align: center;
  font-size: var(--caption-bold-size);
  color: var(--grey-600);
}
.account {
  background-color: var(--color-gray-100);
  overflow: hidden;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.navChild {
  position: absolute;
  top: 27px;
  left: 20px;
  width: 125px;
  height: 20px;
}
.home2 {
  position: relative;
  line-height: 24px;
  font-weight: 500;
}
.default,
.iconshomeParent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.iconshomeParent {
  flex-direction: row;
  gap: var(--gap-5xs);
}
.default {
  align-self: stretch;
  border-radius: var(--br-11xs);
  overflow: hidden;
  flex-direction: column;
  padding: var(--padding-5xs);
  cursor: pointer;
}
.accountIcon {
  position: relative;
  width: 24px;
  height: 24px;
}
.home7 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
}
.default5,
.defaultParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.default5 {
  align-self: stretch;
  border-radius: var(--br-9xs);
  background-color: var(--grey-90);
  overflow: hidden;
  padding: var(--padding-5xs);
  color: var(--primary-10);
}
.defaultParent {
  position: absolute;
  top: 87px;
  left: 20px;
  width: 181px;
  gap: var(--gap-xs);
}
.nav {
  height: 100%;
  width: 221px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--grey-50);
}
.navPrototype {
  position: relative;
  width: 221px;
  height: 1325px;
}
.licenseAndCertification6 {
  position: relative;
  line-height: 28px;
}
.component102 {
  width: 100%;
  background-color: var(--white);
  border-bottom: 1px solid var(--grey-80);
  display: flex;
  flex-direction: row;
  padding: 1rem;
  align-items: center;
  justify-content: flex-start;
  padding-left: 10%;
}
.imgContainerChild,
.imgContainerItem {
  position: absolute;
  top: 16px;
  left: 502px;
  width: 40px;
  height: 40px;
  display: none;
}
.imgContainerItem {
  left: 450px;
}
.contentChild,
.contentItem {
  position: relative;
  border-radius: 50%;
  background-color: var(--logg-green);
  width: 10px;
  height: 10px;
}
.contentItem {
  background-color: var(--grey-80);
}
.content {
  position: absolute;
  top: 178px;
  left: 238px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.button9 {
  position: relative;
  line-height: 100%;
  font-weight: 600;
}
.smallButtons {
  position: absolute;
  top: 12px;
  left: 80%;
  border-radius: var(--br-9xs);
  background-color: var(--white);
  border: 1px solid var(--logg-green);
  box-sizing: border-box;
  width: 80px;
  height: 32px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-xl);
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.imgContainer {
  position: relative;
  border-radius: var(--br-5xs);
  width: 558px;
  height: 203px;
  background-color: #d7d7d7;
  overflow: hidden;
  flex-shrink: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.avatarIcon {
  position: relative;
  width: 56px;
  height: 56px;
}
.dysonElectricals,
.success {
  position: relative;
  font-weight: 600;
}
.dysonElectricals {
  line-height: 28px;
}
.success {
  letter-spacing: 0.04em;
  text-transform: uppercase;
}
.transactionPills {
  border-radius: var(--br-81xl);
  background-color: var(--green-100);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  text-align: right;
  font-size: var(--font-size-5xs);
  color: var(--logg-green);
}
.avatarParent,
.frameParent6 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.avatarParent {
  flex-direction: row;
  gap: var(--gap-xs);
}
.ambassadorChild5 {
  position: absolute;
  top: 22px;
  left: 9.57px;
  border-radius: var(--br-12xs);
  width: 11px;
  height: 4px;
}
.awards1 {
  position: relative;
  width: 26px;
  height: 43px;
  overflow: hidden;
  flex-shrink: 0;
  text-align: center;
  font-size: 2.37px;
  color: var(--grey-900);
}
.profileHeading {
  width: 558px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-257xl);
  text-align: left;
  font-size: var(--h5-semibold-size);
  color: var(--grey-0);
}
.icons20pxplaceholder {
  position: relative;
  width: 20px;
  height: 20px;
  display: none;
}
.label {
  position: relative;
  font-size: var(--p3-semibold-size);
  line-height: 24px;
  font-family: var(--caption-bold);
  color: var(--white);
  text-align: center;
}
.largeButtons {
  cursor: pointer;
  border: 0;
  padding: var(--padding-5xs) 0;
  background-color: var(--logg-green);
  border-radius: var(--br-8xs);
  width: 420px;
  height: 44px;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
}
.share {
  position: relative;
  line-height: 26px;
}
.buttonsInner,
.shareParent {
  display: flex;
  align-items: center;
}
.shareParent {
  flex-direction: row;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.buttonsInner {
  border-radius: var(--br-8xs);
  background-color: var(--white);
  border: 1px solid var(--logg-green);
  box-sizing: border-box;
  width: 122px;
  flex-direction: column;
  padding: var(--padding-5xs) var(--padding-8xl);
  justify-content: center;
  color:  var(--logg-green);
}
.buttons {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.tab1 {
  position: relative;
  line-height: 26px;
  font-weight: 600;
}
.tab1Wrapper {
  position: absolute;
  width: 49.28%;
  top: 4px;
  right: 50%;
  left: 0.72%;
  border-radius: var(--br-9xs);
  background-color: var(--white);
  height: 44px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-9xs) var(--padding-5xs);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.tab2 {
  position: relative;
  line-height: 26px;
  font-weight: 500;
}
.tab2Wrapper {
  position: absolute;
  width: 49.28%;
  top: 4px;
  right: 0.72%;
  left: 50%;
  border-radius: var(--br-9xs);
  height: 44px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-9xs) var(--padding-5xs);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: var(--grey-30);
}
.twoTabSwitch {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-8xs);
  background-color: var(--grey-90);
}
.prototypeSettings {
  position: relative;
  width: 558px;
  height: 52px;
}
.profile3,
.profileSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
  font-size: var(--p2-semibold-size);
}
.profileSection {
  text-align: center;
  font-size: var(--p3-semibold-size);
  color: var(--logg-green);
}
.totalPoints1 {
  position: relative;
  font-size: var(--caption-bold-size);
  line-height: 20px;
  font-weight: 500;
  color: var(--grey-30);
}
.parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-11xs);
}
.intermediate4,
.nextLevel1 {
  position: relative;
  line-height: 20px;
  font-weight: 500;
}
.intermediate4 {
  color: var(--grey-10);
}
.nextLevelParent {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  text-align: right;
  font-size: var(--caption-bold-size);
  color: var(--grey-30);
}
.frameParent10 {
  width: 559px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.frameChild4 {
  align-self: stretch;
  position: relative;
  background-color: var(--grey-80);
  height: 4px;
}
.progressBarInner1 {
  margin: 0 !important;
  position: absolute;
  top: 0;
  left: 186px;
  width: 372px;
  height: 20px;
  display: flex;
  flex-direction: column;
  padding: var(--padding-5xs) 0;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: center;
  z-index: 0;
}
.frameChild5 {
  align-self: stretch;
  position: relative;
  background-color: var(--logg-green);
  height: 4px;
}
.rectangleWrapper2 {
  align-self: stretch;
  width: 166px;
  display: flex;
  flex-direction: column;
  padding: var(--padding-5xs) 0;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: center;
}
.frameChild6 {
  position: relative;
  border-radius: 50%;
  background-color: var(--logg-green);
  border: 2px solid var(--primary-90);
  box-sizing: border-box;
  width: 24px;
  height: 24px;
}
.frameParent11 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  z-index: 1;
}
.progressBar2 {
  position: relative;
}
.frameParent9,
.progressBar2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.earn950Points1 {
  position: relative;
  font-size: var(--p2-semibold-size);
  line-height: 26px;
  color: var(--grey-10);
}
.howToEarn1 {
  position: relative;
  line-height: 24px;
}
.iconswarning {
  position: relative;
  width: 16px;
  height: 16px;
}
.frameParent8,
.howToEarnPointsAndLevelUParent {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.howToEarnPointsAndLevelUParent {
  flex-direction: row;
  cursor: pointer;
  font-size: var(--p3-semibold-size);
  color: var(--grey-20);
}
.frameParent8 {
  flex-direction: column;
}
.verificationProgressBarChild {
  position: absolute;
  top: 0;
  left: 225px;
  width: 256.5px;
  height: 113px;
}
.exclamationMark1Icon1 {
  position: relative;
  width: 32px;
  height: 32px;
  overflow: hidden;
  flex-shrink: 0;
}
.completeVerificationTo1 {
  position: relative;
  font-size: var(--p2-semibold-size);
  line-height: 26px;
  color: var(--grey-70);
}
.exclamationMark1Parent,
.youAreUnverifiedGroup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.exclamationMark1Parent {
  position: absolute;
  top: 26px;
  left: 24px;
  flex-direction: row;
  align-items: center;
  gap: var(--gap-xs);
}
.progress,
.progressBar3 {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-3xs);
  width: 82px;
  height: 6px;
}
.progressBar3 {
  top: 88px;
  left: 68px;
  width: 445px;
}
.verificationProgressBar {
  align-self: stretch;
  position: relative;
  border-radius: var(--br-5xs);
  background-color: var(--gray-1);
  height: 112px;
  overflow: hidden;
  width: 100%;
  flex-shrink: 0;
  color: var(--grey-90);
}
.subtitle {
  position: relative;
  font-size: var(--caption-bold-size);
  line-height: 20px;
  color: var(--grey-30);
}
.titleParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.button10 {
  position: relative;
  font-size: var(--p3-semibold-size);
  line-height: 100%;
  font-weight: 600;
  font-family: var(--caption-bold);
  color: var(--logg-green);
  text-align: center;
}
.smallButtons1 {
  cursor: pointer;
  border: 1px solid var(--logg-green);
  padding: var(--padding-5xs) var(--padding-xl);
  background-color: transparent;
  border-radius: var(--br-9xs);
  box-sizing: border-box;
  width: 160px;
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.frameParent12,
.profileList {
  width: 558px;
  display: flex;
  justify-content: space-between;
}
.frameParent12 {
  flex-direction: row;
  align-items: flex-start;
}
.profileList {
  background-color: var(--color-gray-100);
  border-bottom: 1px solid var(--grey-80);
  height: 80px;
  align-items: center;
  cursor: pointer;
}
.profileList,
.profileList1,
.smallButtons2 {
  box-sizing: border-box;
  flex-direction: row;
  padding: var(--padding-5xs) 0;
}
.smallButtons2 {
  cursor: pointer;
  border: 1px solid var(--logg-green);
  background-color: transparent;
  border-radius: var(--br-9xs);
  width: 160px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.profileList1 {
  background-color: var(--color-gray-100);
  border-bottom: 1px solid var(--grey-80);
  width: 558px;
  height: 80px;
  justify-content: space-between;
}
.frameWrapper,
.profileList1,
.profileList5 {
  display: flex;
  align-items: center;
}
.profileList5 {
  background-color: var(--color-gray-100);
  width: 558px;
  height: 80px;
  flex-direction: row;
  padding: var(--padding-5xs) 0;
  box-sizing: border-box;
  justify-content: space-between;
}
.frameWrapper {
  flex-direction: column;
  justify-content: flex-start;
  font-size: var(--p2-semibold-size);
  color: var(--grey-10);
}
.frameParent7 {
  flex-direction: column;
  align-items: flex-end;
  gap: var(--gap-xl);
  color: var(--grey-0);
}
.frameParent7,
.profile2,
.profileWrapper {
  display: flex;
  justify-content: flex-start;
}
.profile2 {
  flex-direction: column;
  align-items: center;
  gap: var(--gap-base);
}
.profileWrapper {
  background-color: var(--color-gray-100);
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-5xs) var(--padding-21xl);
  align-items: flex-start;
}
.connectSocialAccounts1 {
  position: absolute;
  top: 16px;
  left: 16px;
  line-height: 26px;
  font-weight: 500;
}
.frameChild7,
.frameChild8,
.frameChild9 {
  position: absolute;
  height: 38.24%;
  width: 14.29%;
  top: 47.06%;
  right: 56.04%;
  bottom: 14.71%;
  left: 29.67%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.frameChild8,
.frameChild9 {
  right: 81.32%;
  left: 4.4%;
}
.frameChild9 {
  right: 30.77%;
  left: 54.95%;
}
.connectSocialAccountsParent {
  position: relative;
  border-radius: var(--br-5xs);
  background-color: var(--grey-50);
  border: 1px solid var(--grey-90);
  box-sizing: border-box;
  width: 364px;
  height: 136px;
  overflow: hidden;
  flex-shrink: 0;
}
.groupChild30,
.groupChild31 {
  position: absolute;
  height: 4.54%;
  width: 1.69%;
  top: 97.2%;
  right: 62.14%;
  bottom: -1.74%;
  left: 36.16%;
  background-color: var(--logg-green);
  transform: rotate(-163.87deg);
  transform-origin: 0 0;
  opacity: 0.47;
}
.groupChild31 {
  height: 4.42%;
  width: 1.74%;
  top: 9.44%;
  right: 83.38%;
  bottom: 86.13%;
  left: 14.89%;
  transform: rotate(-92.71deg);
  opacity: 0.16;
}
.groupChild32,
.groupChild33,
.groupChild34,
.groupChild35,
.groupChild36,
.groupChild37 {
  position: absolute;
  height: 4.53%;
  width: 1.69%;
  top: 86.71%;
  right: 79.7%;
  bottom: 8.75%;
  left: 18.6%;
  background-color: var(--logg-green);
  transform: rotate(-162.32deg);
  transform-origin: 0 0;
  opacity: 0.62;
}
.groupChild33,
.groupChild34,
.groupChild35,
.groupChild36,
.groupChild37 {
  height: 4.45%;
  width: 1.73%;
  top: 81.28%;
  right: 32.39%;
  bottom: 14.27%;
  left: 65.89%;
  transform: rotate(-64deg);
  opacity: 0.4;
}
.groupChild34,
.groupChild35,
.groupChild36,
.groupChild37 {
  height: 4.53%;
  width: 1.7%;
  top: 83.89%;
  right: 90.65%;
  bottom: 11.59%;
  left: 7.66%;
  transform: rotate(-156.11deg);
  opacity: 0.28;
}
.groupChild35,
.groupChild36,
.groupChild37 {
  height: 4.54%;
  width: 1.69%;
  top: 54.9%;
  right: 92.88%;
  bottom: 40.56%;
  left: 5.43%;
  transform: rotate(168.55deg);
  opacity: 0.6;
}
.groupChild36,
.groupChild37 {
  height: 4.42%;
  width: 1.74%;
  top: 9.44%;
  right: 2.65%;
  bottom: 86.13%;
  left: 95.61%;
  transform: rotate(-91.73deg);
  opacity: 0.78;
}
.groupChild37 {
  height: 4.51%;
  width: 1.7%;
  top: 5.1%;
  right: 58.02%;
  bottom: 90.39%;
  left: 40.28%;
  transform: rotate(-145.82deg);
  opacity: 0.25;
}
.groupChild38,
.groupChild39,
.groupChild40,
.groupChild41,
.groupChild42,
.groupChild43 {
  position: absolute;
  height: 4.44%;
  width: 1.73%;
  top: 12.23%;
  right: 96.55%;
  bottom: 83.33%;
  left: 1.72%;
  background-color: var(--color-royalblue-100);
  transform: rotate(-109.53deg);
  transform-origin: 0 0;
  opacity: 0.74;
}
.groupChild39,
.groupChild40,
.groupChild41,
.groupChild42,
.groupChild43 {
  height: 4.54%;
  width: 1.69%;
  top: 60.77%;
  right: 2.63%;
  bottom: 34.69%;
  left: 95.68%;
  transform: rotate(169.59deg);
  opacity: 0.55;
}
.groupChild40,
.groupChild41,
.groupChild42,
.groupChild43 {
  height: 4.44%;
  width: 1.73%;
  top: 34.31%;
  right: 83.07%;
  bottom: 61.26%;
  left: 15.2%;
  transform: rotate(108.04deg);
  opacity: 0.2;
}
.groupChild41,
.groupChild42,
.groupChild43 {
  height: 4.46%;
  width: 1.72%;
  top: 100%;
  right: 89.81%;
  bottom: -4.46%;
  left: 8.47%;
  transform: rotate(-122deg);
  opacity: 0.03;
}
.groupChild42,
.groupChild43 {
  height: 4.43%;
  width: 1.73%;
  top: 0;
  right: 29.67%;
  bottom: 95.57%;
  left: 68.59%;
  transform: rotate(75.42deg);
  opacity: 0.33;
}
.groupChild43 {
  top: 39.86%;
  right: 9.17%;
  bottom: 55.71%;
  left: 89.1%;
  transform: rotate(78.39deg);
  opacity: 0.75;
}
.groupChild44,
.groupChild45,
.groupChild46,
.groupChild47,
.groupChild48,
.groupChild49,
.groupChild50 {
  position: absolute;
  height: 4.42%;
  width: 1.74%;
  top: 97.9%;
  right: 14.81%;
  bottom: -2.32%;
  left: 83.45%;
  background-color: var(--color-chocolate-100);
  transform: rotate(87.78deg);
  transform-origin: 0 0;
  opacity: 0.32;
}
.groupChild45,
.groupChild46,
.groupChild47,
.groupChild48,
.groupChild49,
.groupChild50 {
  height: 4.53%;
  width: 1.69%;
  top: 75.81%;
  right: 64.51%;
  bottom: 19.66%;
  left: 33.8%;
  transform: rotate(-21.83deg);
}
.groupChild46,
.groupChild47,
.groupChild48,
.groupChild49,
.groupChild50 {
  height: 4.46%;
  width: 1.72%;
  top: 22.73%;
  right: 25.97%;
  bottom: 72.82%;
  left: 72.31%;
  transform: rotate(-60deg);
  opacity: 0.88;
}
.groupChild47,
.groupChild48,
.groupChild49,
.groupChild50 {
  height: 4.55%;
  width: 1.69%;
  top: 91.26%;
  right: 41.84%;
  bottom: 4.19%;
  left: 56.47%;
  transform: rotate(179.48deg);
  opacity: 0.86;
}
.groupChild48,
.groupChild49,
.groupChild50 {
  height: 4.51%;
  width: 1.7%;
  top: 33.22%;
  right: 21.94%;
  bottom: 62.27%;
  left: 76.36%;
  transform: rotate(148.89deg);
  opacity: 0.06;
}
.groupChild49,
.groupChild50 {
  height: 4.45%;
  width: 1.72%;
  top: 81.82%;
  right: 54.34%;
  bottom: 13.73%;
  left: 43.93%;
  transform: rotate(60.5deg);
  opacity: 0.34;
}
.groupChild50 {
  height: 4.5%;
  width: 1.7%;
  top: 76.54%;
  right: 13.15%;
  bottom: 18.95%;
  left: 85.14%;
  transform: rotate(-36.23deg);
  opacity: 0.68;
}
.groupChild51,
.groupChild52,
.groupChild53,
.groupChild54 {
  position: absolute;
  height: 4.43%;
  width: 1.73%;
  top: 24.71%;
  right: 76.78%;
  bottom: 70.87%;
  left: 21.48%;
  background-color: var(--red);
  transform: rotate(97.16deg);
  transform-origin: 0 0;
  opacity: 0.26;
}
.groupChild52,
.groupChild53,
.groupChild54 {
  height: 4.44%;
  top: 13.29%;
  right: 5.36%;
  bottom: 82.27%;
  left: 92.91%;
  transform: rotate(110.03deg);
  opacity: 0.19;
}
.groupChild53,
.groupChild54 {
  height: 4.5%;
  width: 1.7%;
  top: 65.73%;
  right: 96.92%;
  bottom: 29.77%;
  left: 1.38%;
  transform: rotate(-143.77deg);
  opacity: 0.67;
}
.groupChild54 {
  height: 4.42%;
  width: 1.73%;
  top: 28.62%;
  right: 6.59%;
  bottom: 66.96%;
  left: 91.67%;
  transform: rotate(-93.7deg);
  opacity: 0.76;
}
.rectangleParent1 {
  position: absolute;
  height: 159.93%;
  width: 39.84%;
  top: 8.89%;
  right: 4.81%;
  bottom: -68.82%;
  left: 55.35%;
  opacity: 0.3;
}
.giftBoxIcon1 {
  position: relative;
  width: 41px;
  height: 44.58px;
}
.referABusiness1 {
  position: relative;
  font-size: var(--p2-semibold-size);
  line-height: 26px;
  color: var(--grey-700);
}
.giftBoxGroup {
  position: absolute;
  top: 16px;
  left: 24px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.referAndEarn1 {
  position: relative;
  border-radius: var(--br-5xs);
  background-color: var(--green-100);
  border: 1px solid var(--grey-300);
  box-sizing: border-box;
  width: 364px;
  height: 90px;
  overflow: hidden;
  flex-shrink: 0;
  font-size: var(--h5-semibold-size);
  color: var(--grey-900);
  margin-bottom: 5rem;
}
.frameParent18 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
  font-size: var(--p2-semibold-size);
  color: var(--grey-10);
}
.frameParent5 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 113px;
}
.frameParent19 {
  display: none;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
  font-size: var(--p2-semibold-size);
  color: var(--grey-10);
}
.home8,
.jobs2 {
  position: relative;
  width: 35px;
  height: 52px;
}
.jobs2 {
  width: 28px;
  height: 50px;
}
.account4,
.wallet2 {
  position: relative;
  width: 36px;
  height: 49px;
}
.account4 {
  width: 50px;
  height: 52px;
  color: var(--logg-green);
}
.bottomNav1 {
  align-self: stretch;
  background-color: var(--white);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  display: none;
  flex-direction: row;
  padding: var(--padding-smi) var(--padding-5xl);
  align-items: center;
  justify-content: space-between;
  text-align: center;
  font-size: var(--caption-bold-size);
  color: var(--grey-600);
}
.frameParent4,
.profile,
.profile1 {
  display: flex;
  justify-content: flex-start;
}
.frameParent4 {
  flex: 1;
  flex-direction: column;
  align-items: center;
  gap: var(--gap-xs);
  text-align: left;
  font-size: var(--h5-semibold-size);
  color: var(--grey-900);
}
.profile,
.profile1 {
  align-items: flex-start;
}
.profile1 {
  background-color: var(--color-gray-100);
  width: 100%;
  overflow-y: auto;
  flex-direction: row;
  text-align: center;
  font-size: var(--p3-semibold-size);
  color: var(--grey-10);
}
.profile {
  position: relative;
  width: 100%;
  flex-direction: column;
  gap: var(--gap-3xs);
  text-align: left;
  color: var(--grey-900);
  font-family: var(--caption-bold);
}
@media screen and (max-width: 960px) {
  .frameParent18,
  .navPrototype {
    display: none;
  }
  .bottomNav1,
  .frameParent19 {
    display: flex;
  }
  .profile,
  .profile1 {
    width: 100%;
  }
}
@media screen and (max-width: 430px) {
  .account {
    display: flex;
    width:100%
  }
  .bottomNav{
    width:100%
  }
  .bottomNav1,
  .frameParent19,
  .profile1 {
    display: none;
  }
  .imgContainer {
    align-self: stretch;
    width: auto;
    height: 190px;
  }
}

