.jobDetails {
  position: absolute;
  top: calc(50% - 16px);
  left: 32px;
  line-height: 28px;
  font-weight: 600;
}
.iconsclose {
  position: absolute;
  top: calc(50% - 12px);
  right: 32px;
  width: 24px;
  height: 24px;
  overflow: hidden;
  cursor: pointer;
}
.modalTitle {
  position: relative;
  background-color: var(--white);
  width: 100%;
  height: 64px;
  overflow: hidden;
  flex-shrink: 0;
}
.input {
  border: 0;
  background-color: transparent;
}
.avatarIcon {
  position: relative;
  width: 32px;
  height: 32px;
}
.peoplename {
  position: relative;
  line-height: 26px;
  font-weight: 500;
}
.avatarParent {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.checkboxIcon {
  position: relative;
  border-radius: var(--br-9xs);
  width: 24px;
  height: 24px;
  display: none;
}
.contacts {
  width: 447px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-213xl);
}
.checkbox {
  position: relative;
  border-radius: var(--br-9xs);
  background-color: var(--grey-50);
  border: 1px solid var(--grey-70);
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  display: none;
}
.contactsParent {
  align-items: flex-start;
  gap: var(--gap-base);
  width: 100%;
}
.contactsParent,
.recentchat,
.searchContacts {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.searchContacts {
  padding: 0 0 var(--padding-21xl);
  align-items: flex-start;
  gap: var(--gap-base);
  font-size: var(--p2-semibold-size);
  color: var(--grey-10);
  width: 95%;
}
.recentchat {
  position: relative;
  border-radius: var(--br-8xs);
  background-color: var(--white);
  box-shadow: var(--popup);
  overflow: auto;
  align-items: center;
  gap: var(--gap-5xs);
  max-height: 100%;
  text-align: left;
  font-size: var(--h5-semibold-size);
  color: var(--grey-0);
  font-family: var(--caption-bold);
  padding: 1rem;
  width: 450px;
}
@media screen and (max-width: 960px) {
  .recentchat{
    width: 300px;
  }
}