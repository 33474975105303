.personalInformation,
.tellUsAbout {
  position: relative;
  display: inline-block;
  width: 398px;
}
.personalInformation {
  letter-spacing: -0.02em;
  line-height: 36px;
}
.tellUsAbout {
  font-size: var(--p2-semibold-size);
  line-height: 26px;
  color: var(--grey-20);
}
.personalInformationParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-11xs);
}
.frameWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.progressBarChild,
.progressBarItem {
  flex: 1;
  position: relative;
  border-radius: var(--br-9xs);
  background-color: var(--logg-green);
  height: 4px;
}
.progressBarItem {
  background-color: var(--grey-80);
}
.progressBar {
  width: 398px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xl);
}
.label {
  align-self: stretch;
  position: relative;
  line-height: 26px;
}
.userInput {
  position: absolute;
  top: 10px;
  left: 12px;
  line-height: 26px;
}
.iconsplaceholder {
  position: absolute;
  top: 12px;
  right: 8px;
  width: 24px;
  height: 24px;
  display: none;
}
.dotChild {
  position: relative;
  border-radius: 50%;
  background-color: var(--grey-10);
  width: 8px;
  height: 8px;
}
.dot {
  position: absolute;
  top: 20px;
  left: 12px;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-6xs);
}
.field {
  position: relative;
  border-radius: var(--br-8xs);
  background-color: var(--grey-50);
  border: 1px solid var(--grey-70);
  box-sizing: border-box;
  width: 398px;
  height: 48px;
  color: var(--grey-10);
}
.thisIsA,
.thisIsA1 {
  position: relative;
  line-height: 24px;
}
.thisIsA1 {
  font-weight: 500;
  display: none;
}
.helperText,
.largeInputs {
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.helperText {
  align-self: stretch;
  display: none;
  flex-direction: row;
  align-items: center;
  font-size: var(--p3-semibold-size);
}
.largeInputs {
  width: 398px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.label2 {
  position: relative;
  line-height: 26px;
}
.iconscalendar {
  position: absolute;
  top: 12px;
  right: 8px;
  width: 24px;
  height: 24px;
  overflow: hidden;
}
.field2 {
  position: relative;
  border-radius: var(--br-8xs);
  background-color: var(--grey-50);
  border: 1px solid var(--primary-10);
  box-sizing: border-box;
  width: 398px;
  height: 48px;
  color: var(--grey-10);
}
.largeInputs2,
.largeInputsParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.largeInputsParent {
  gap: var(--gap-xs);
}
.terms1 {
  text-decoration: underline;
}
.terms {
  color: var(--primary-10);
}
.byClickingContinueContainer {
  position: relative;
  letter-spacing: -0.02em;
  line-height: 36px;
  font-weight: 500;
  display: inline-block;
  width: 398px;
}
.byClickingContinueYouAgWrapper,
.frameContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.frameContainer {
  align-items: center;
  justify-content: center;
  text-align: center;
}
.icons20pxplaceholder {
  position: relative;
  width: 20px;
  height: 20px;
  display: none;
}
.label3 {
  position: relative;
  font-size: var(--p3-semibold-size);
  line-height: 24px;
  font-family: var(--caption-bold);
  color: var(--white);
  text-align: center;
}
.largeButtons {
  cursor: pointer;
  border: 0;
  padding: var(--padding-5xs) 0;
  background-color: var(--logg-green);
  border-radius: var(--br-8xs);
  width: 398px;
  height: 48px;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
}
.frameGroup {
  text-align: left;
  font-size: var(--p2-semibold-size);
  color: var(--grey-20);
}
.frameGroup,
.frameParent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xl);
}
.personalinfo,
.personalinfoInner {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.personalinfoInner {
  align-self: stretch;
  flex: 1;
  justify-content: center;
}
.personalinfo {
  position: relative;
  background-color: var(--white);
  width: 100%;
  overflow: hidden;
  padding: 0 var(--padding-31xl) 0 0;
  box-sizing: border-box;
  justify-content: space-between;
  text-align: center;
  font-size: var(--h4-bold1-size);
  color: var(--grey-0);
  font-family: var(--caption-bold);
}

@media screen and (max-width: 960px) {
  .personalinfoInner {
    flex: unset;
    align-self: stretch;
  }
  .personalinfo {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 2rem;
    padding-right: 0;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 430px) {
  .personalinfo {
    box-sizing: border-box;
  }
  .personalInformation,
  .largeInputs,
  .frameGroup,
.tellUsAbout {
  width: 100%;
}
.largeButtons{
  width: 95%;
}
}
