.addANew {
  position: relative;
  line-height: 28px;
  font-weight: 600;
}
.iconsclose {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.addANewCardParent,
.modalTitle {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.modalTitle {
  overflow: hidden;
  flex-direction: column;
  padding: var(--padding-base) var(--padding-13xl);
  justify-content: flex-start;
  font-size: var(--h5-semibold-size);
}
.crosbyOdennehy {
  position: relative;
  line-height: 24px;
  font-weight: 500;
}
.success {
  position: relative;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  font-weight: 600;
}
.crosbyOdennehyParent,
.transactionPills {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.transactionPills {
  border-radius: var(--br-81xl);
  background-color: var(--primary-20base);
  padding: var(--padding-9xs) var(--padding-7xs);
  justify-content: flex-end;
  text-align: right;
  font-size: var(--font-size-5xs);
  color: var(--primary-100);
}
.crosbyOdennehyParent {
  width: 187px;
  justify-content: flex-start;
  gap: var(--gap-xs);
}
.div {
  position: relative;
  font-size: var(--h4-semibold-size);
  letter-spacing: -0.02em;
  line-height: 36px;
  font-weight: 600;
}
.frameParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xs);
}
.iconsplaceholder,
.mastercard1Icon {
  position: relative;
  width: 24px;
  height: 24px;
}
.mastercard1Icon {
  width: 36px;
  height: 30px;
  overflow: hidden;
  flex-shrink: 0;
}
.mastercard1Parent {
  width: 84px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: var(--gap-5xl);
}
.iconsplaceholderParent {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: var(--gap-mini);
}
.savedCards {
  align-self: stretch;
  border-radius: var(--br-8xs);
  background-color: var(--primary-90);
  border: 1px solid var(--grey-80);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: var(--padding-base) var(--padding-xl);
  align-items: flex-end;
  justify-content: space-between;
}
.iconsadd {
  position: relative;
  width: 20px;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.addAnotherCard {
  position: relative;
  line-height: 24px;
}
.iconsaddParent,
.savedCardsParent {
  display: flex;
  justify-content: flex-start;
}
.iconsaddParent {
  flex-direction: row;
  align-items: center;
  gap: var(--gap-5xs);
  text-align: center;
  color: var(--primary-20base);
}
.savedCardsParent {
  width: 496px;
  flex-direction: column;
  align-items: flex-end;
  gap: var(--gap-base);
  color: var(--grey-10);
}
.icons20pxplaceholder {
  position: relative;
  width: 20px;
  height: 20px;
  display: none;
}
.largeButtons,
.promopaymodal {
  border-radius: var(--br-8xs);
  display: flex;
  align-items: center;
}
.largeButtons {
  background-color: var(--primary-20base);
  width: 496px;
  height: 48px;
  flex-direction: row;
  box-sizing: border-box;
  justify-content: center;
  gap: var(--gap-5xs);
  cursor: pointer;
  text-align: center;
  color: var(--white);
}
.promopaymodal {
  background-color: var(--white);
  box-shadow: var(--popup);
  width: 560px;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 100%;
  overflow: auto;
  text-align: left;
  font-size: var(--p3-bold-size);
  color: var(--color-black);
  font-family: var(--p3-bold);
  padding-bottom:1rem ;
}
@media screen and (max-width: 430px) {
  .largeButtons,
  .savedCardsParent {
    width: 300px;
  }
  .promopaymodal {
    width: 400px;
  }
}
