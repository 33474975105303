.groupChild,
.groupInner,
.groupItem {
  position: absolute;
  top: 94.62px;
  left: 8.7px;
  background-color: var(--primary-20base);
  width: 7.17px;
  height: 18.64px;
}
.groupInner,
.groupItem {
  top: 8.62px;
  left: 216.57px;
  background-color: var(--color-royalblue-100);
}
.groupInner {
  top: 41.57px;
  left: 117.66px;
  background-color: var(--red);
}
.groupChild1,
.groupChild2,
.groupChild3,
.groupChild4,
.groupChild5,
.groupChild6,
.groupChild7,
.groupChild8,
.rectangleDiv {
  position: absolute;
  top: 398.53px;
  left: 153.49px;
  background-color: var(--primary-20base);
  width: 7.17px;
  height: 18.64px;
  transform: rotate(-164.28deg);
  transform-origin: 0 0;
  opacity: 0.47;
}
.groupChild1,
.groupChild2,
.groupChild3,
.groupChild4,
.groupChild5,
.groupChild6,
.groupChild7,
.groupChild8 {
  top: 38.71px;
  left: 63.18px;
  transform: rotate(-92.79deg);
  opacity: 0.16;
}
.groupChild2,
.groupChild3,
.groupChild4,
.groupChild5,
.groupChild6,
.groupChild7,
.groupChild8 {
  top: 355.52px;
  left: 78.95px;
  transform: rotate(-162.76deg);
  opacity: 0.62;
}
.groupChild3,
.groupChild4,
.groupChild5,
.groupChild6,
.groupChild7,
.groupChild8 {
  top: 333.26px;
  left: 279.65px;
  transform: rotate(-63.38deg);
  opacity: 0.4;
}
.groupChild4,
.groupChild5,
.groupChild6,
.groupChild7,
.groupChild8 {
  top: 343.94px;
  left: 32.49px;
  transform: rotate(-156.68deg);
  opacity: 0.28;
}
.groupChild5,
.groupChild6,
.groupChild7,
.groupChild8 {
  top: 225.07px;
  left: 23.04px;
  transform: rotate(168.84deg);
  opacity: 0.6;
}
.groupChild6,
.groupChild7,
.groupChild8 {
  top: 67.74px;
  left: 247.22px;
  transform: rotate(-91.78deg);
  opacity: 0.78;
}
.groupChild7,
.groupChild8 {
  top: 20.93px;
  left: 170.95px;
  transform: rotate(-146.54deg);
  opacity: 0.25;
}
.groupChild8 {
  top: 50.17px;
  left: 7.27px;
  background-color: var(--color-royalblue-100);
  transform: rotate(-110.03deg);
  opacity: 0.74;
}
.groupChild10,
.groupChild11,
.groupChild12,
.groupChild13,
.groupChild14,
.groupChild15,
.groupChild16,
.groupChild9 {
  position: absolute;
  top: 249.15px;
  left: 406.1px;
  background-color: var(--color-royalblue-100);
  width: 7.17px;
  height: 18.64px;
  transform: rotate(169.86deg);
  transform-origin: 0 0;
  opacity: 0.55;
}
.groupChild10,
.groupChild11,
.groupChild12,
.groupChild13,
.groupChild14,
.groupChild15,
.groupChild16 {
  top: 140.67px;
  left: 64.49px;
  transform: rotate(108.51deg);
  opacity: 0.2;
}
.groupChild11,
.groupChild12,
.groupChild13,
.groupChild14,
.groupChild15,
.groupChild16 {
  top: 410px;
  left: 35.94px;
  transform: rotate(-122.71deg);
  opacity: 0.03;
}
.groupChild12,
.groupChild13,
.groupChild14,
.groupChild15,
.groupChild16 {
  top: 0;
  left: 291.12px;
  transform: rotate(75.04deg);
  opacity: 0.33;
}
.groupChild13,
.groupChild14,
.groupChild15,
.groupChild16 {
  top: 163.43px;
  left: 378.16px;
  transform: rotate(78.08deg);
  opacity: 0.75;
}
.groupChild14,
.groupChild15,
.groupChild16 {
  top: 401.4px;
  left: 354.19px;
  background-color: var(--color-chocolate-100);
  transform: rotate(87.72deg);
  opacity: 0.32;
}
.groupChild15,
.groupChild16 {
  top: 310.82px;
  left: 143.46px;
  transform: rotate(-21.3deg);
}
.groupChild16 {
  top: 93.18px;
  left: 306.89px;
  transform: rotate(-59.32deg);
  opacity: 0.88;
}
.groupChild17,
.groupChild18,
.groupChild19,
.groupChild20,
.groupChild21,
.groupChild22,
.groupChild23,
.groupChild24 {
  position: absolute;
  top: 374.16px;
  left: 239.67px;
  background-color: var(--color-chocolate-100);
  width: 7.17px;
  height: 18.64px;
  transform: rotate(179.49deg);
  transform-origin: 0 0;
  opacity: 0.86;
}
.groupChild18,
.groupChild19,
.groupChild20,
.groupChild21,
.groupChild22,
.groupChild23,
.groupChild24 {
  top: 136.19px;
  left: 324.09px;
  transform: rotate(149.58deg);
  opacity: 0.06;
}
.groupChild19,
.groupChild20,
.groupChild21,
.groupChild22,
.groupChild23,
.groupChild24 {
  top: 335.46px;
  left: 186.46px;
  transform: rotate(59.83deg);
  opacity: 0.34;
}
.groupChild20,
.groupChild21,
.groupChild22,
.groupChild23,
.groupChild24 {
  top: 313.81px;
  left: 361.36px;
  transform: rotate(-35.49deg);
  opacity: 0.68;
}
.groupChild21,
.groupChild22,
.groupChild23,
.groupChild24 {
  top: 101.3px;
  left: 91.18px;
  background-color: var(--red);
  transform: rotate(97.35deg);
  opacity: 0.26;
}
.groupChild22,
.groupChild23,
.groupChild24 {
  top: 26.54px;
  left: 379.96px;
  transform: rotate(110.53deg);
  opacity: 0.19;
}
.groupChild23,
.groupChild24 {
  top: 269.51px;
  left: 5.84px;
  transform: rotate(-144.51deg);
  opacity: 0.67;
}
.groupChild24 {
  top: 117.34px;
  left: 389.08px;
  transform: rotate(-93.8deg);
  opacity: 0.76;
}
.rectangleParent {
  position: absolute;
  top: -9px;
  left: 18px;
  width: 407.67px;
  height: 410px;
  opacity: 0.3;
}
.priceTag1Icon {
  position: relative;
  width: 100px;
  height: 100px;
  overflow: hidden;
  flex-shrink: 0;
}
.yourPromotionRequest {
  position: relative;
  line-height: 28px;
  font-weight: 600;
  display: inline-block;
  width: 294px;
}
.youWillRecieve {
  position: relative;
  font-size: var(--p3-bold-size);
  line-height: 24px;
  color: var(--grey-20);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 308px;
}
.frameWrapper,
.yourPromotionRequestHasBeeParent {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.yourPromotionRequestHasBeeParent {
  justify-content: center;
  gap: var(--gap-9xs);
}
.frameWrapper {
  justify-content: flex-start;
}
.icons20pxplaceholder {
  position: relative;
  width: 20px;
  height: 20px;
  display: none;
}
.label {
  position: relative;
  line-height: 24px;
}
.largeButtons {
  border-radius: var(--br-8xs);
  background-color: var(--primary-20base);
  width: 306px;
  height: 48px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-158xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
  cursor: pointer;
  font-size: var(--p3-bold-size);
  color: var(--white);
}
.priceTag1Parent {
  position: absolute;
  top: 64px;
  left: 77px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xl);
}
.promosuccessful {
  border-radius: var(--br-8xs);
  background-color: var(--white);
  box-shadow: var(--popup);
  width: 461px;
  height: 391px;
  overflow: hidden;
  max-width: 100%;
  max-height: 100%;
  text-align: center;
  font-size: var(--h5-semibold-size);
  color: var(--grey-0);
  font-family: var(--p3-bold);
}
@media screen and (max-width: 430px) {
  .promosuccessful {
    width: 300px;
  }
}
