.iconsclose {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.modalTitle {
  align-self: stretch;
  background-color: var(--white);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: var(--padding-xl) var(--padding-13xl);
}
.trashLineIcon {
  position: relative;
  width: 69px;
  height: 62.9px;
}
.areYouSure,
.cancelBooking1 {
  align-self: stretch;
  position: relative;
}
.cancelBooking1 {
  line-height: 28px;
  font-weight: 600;
}
.areYouSure {
  font-size: var(--p3-bold-size);
  line-height: 24px;
  color: var(--grey-20);
}
.cancelBookingParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gap-9xs);
}
.label {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 28px;
  font-weight: 500;
}
.baseField,
.labelContainer {
  align-self: stretch;
  position: relative;
  height: 28px;
}
.baseField {
  border: 1px solid var(--grey-70);
  background-color: var(--grey-100);
  border-radius: var(--br-8xs);
  box-sizing: border-box;
  height: 48px;
  overflow: hidden;
  flex-shrink: 0;
}
.div,
.thisIsA {
  position: absolute;
  top: 0;
  line-height: 24px;
}
.thisIsA {
  left: 0;
}
.div {
  right: 0;
  text-align: right;
}
.helperTextContainer {
  align-self: stretch;
  position: relative;
  height: 24px;
  display: none;
  font-size: var(--p3-bold-size);
}
.input {
  width: 357px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-11xs);
  cursor: pointer;
  text-align: left;
  font-size: var(--p2-regular-size);
  color: var(--grey-20);
}
.icons20pxplaceholder {
  position: relative;
  width: 20px;
  height: 20px;
  display: none;
}
.label1 {
  position: relative;
  line-height: 24px;
}
.largeButtons,
.largeButtons1 {
  border-radius: var(--br-8xs);
  background-color: var(--grey-80);
  width: 170px;
  height: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xs) 0;
  box-sizing: border-box;
  gap: var(--gap-5xs);
}
.largeButtons1 {
  background-color: red;
  color: white;
}
.frameParent,
.largeButtonsParent {
  align-self: stretch;
  align-items: center;
}
.largeButtonsParent {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: var(--gap-base);
  font-size: var(--p3-bold-size);
  color: var(--grey-10);
}
.frameParent {
  gap: var(--gap-5xl);
}
.cancelBooking,
.frameParent,
.modalTitleParent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.modalTitleParent {
  align-self: stretch;
  align-items: center;
  gap: var(--gap-xl);
}
.cancelBooking {
  border-radius: var(--br-9xs);
  background-color: var(--white);
  box-shadow: var(--popup);
  width: 456px;
  overflow: hidden;
  align-items: flex-start;
  padding: 0 0 var(--padding-3xs);
  box-sizing: border-box;
  max-width: 100%;
  max-height: 100%;
  text-align: center;
  font-size: var(--h5-semibold-size);
  color: var(--grey-0);
  font-family: var(--p3-bold);
}
@media screen and (max-width: 430px) {
  .cancelBooking {
    width: 400px;
  }
}
