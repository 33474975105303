.imInNeed,
.requestForElectrician {
  align-self: stretch;
  position: relative;
}
.requestForElectrician {
  line-height: 24px;
  font-weight: 600;
}
.imInNeed {
  font-size: var(--caption-regular-size);
  line-height: 20px;
  color: var(--grey-20);
  display: -webkit-inline-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.refdescription,
.requestForElectricianParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.requestForElectricianParent {
  align-self: stretch;
  gap: var(--gap-9xs);
}
.refdescription {
  background-color: var(--grey-100);
  border: 1px solid var(--grey-80);
  box-sizing: border-box;
  width: 100%;
  padding: var(--padding-5xs) var(--padding-21xl);
  text-align: left;
  font-size: var(--p3-semibold-size);
  color: #2e2e2e;
  font-family: var(--caption-regular);
}
