.groupChild {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  object-fit: cover;
}
.peoplename {
  position: absolute;
  top: 3px;
  left: 44px;
  letter-spacing: -0.02em;
  line-height: 155%;
  display: flex;
  align-items: center;
  width: 50vw;
  overflow: auto; 
  text-overflow: ellipsis;
  white-space: nowrap;
}
.backIcon,
.ellipseParent {
  position: absolute;
  top: 0;
  left: 48px;
  width: 186px;
  height: 32px;
}
.backIcon {
  top: 1px;
  left: 0;
  width: 32px;
}
.chatHeaderChild,
.groupParent {
  position: relative;
  width: 234px;
  height: 33px;
}
.chatHeaderChild {
  width: 70px;
  height: 24px;
}
.chatHeader {
  align-self: stretch;
  background-color: var(--white);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.04);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: var(--padding-smi) var(--padding-3xs);
  align-items: center;
  justify-content: space-between;
}
.groupItem {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-81xl);
  background-color: var(--grey-100);
  width: 66px;
  height: 24px;
}
.today {
  position: absolute;
  top: 2px;
  left: 15px;
  line-height: 155%;
  font-weight: 500;
}
.rectangleParent {
  position: absolute;
  top: 0;
  left: 0;
  width: 66px;
  height: 24px;
}
.groupWrapper {
  position: relative;
  width: 66px;
  height: 24px;
  font-size: var(--caption-regular-size);
  color: var(--gray-2);
}
.helloCraigIm,
.pm {
  position: relative;
  line-height: 155%;
  font-weight: 500;
}
.pm {
  font-size: var(--p3-bold-size);
  color: var(--grey-600);
  text-align: right;
}
.chatRightInner,
.helloCraigImWandrackParent {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}
.helloCraigImWandrackParent {
  gap: var(--gap-5xs);
}
.chatRightInner {
  border-radius: var(--br-7xs) 0 var(--br-7xs) var(--br-7xs);
  background-color: var(--color-aliceblue);
  padding: var(--padding-base) var(--padding-base) var(--padding-base)
    var(--padding-xl);
}
.chatRightChild {
  position: relative;
  width: 9px;
  height: 12px;
}
.chatRight {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
}
.hiWandrackNice {
  margin: 0;
}
.pm1 {
  position: relative;
  font-size: var(--p3-bold-size);
  line-height: 155%;
  font-weight: 500;
  color: var(--grey-600);
}
.chatLeftInner,
.hiWandrackNiceToMeetYouParent {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.hiWandrackNiceToMeetYouParent {
  align-items: flex-start;
  gap: var(--gap-5xs);
}
.chatLeftInner {
  border-radius: 0 var(--br-7xs) var(--br-7xs) var(--br-7xs);
  background-color: var(--green-100);
  padding: var(--padding-base) var(--padding-xl) var(--padding-base)
    var(--padding-base);
  align-items: flex-end;
}
.chatLeft,
.helloCraigImWandrackGroup {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.helloCraigImWandrackGroup {
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  gap: var(--gap-5xs);
}
.pm4 {
  position: absolute;
  bottom: 12px;
  left: 16px;
  line-height: 155%;
  font-weight: 500;
  width:80%
}
.quoteBtnChild {
  position: relative;
  border-radius: var(--br-7xs);
  width: 247px;
  height: 104px;
  object-fit: cover;
}
.quoteBtn {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.quote {
  position: absolute;
  top: 8px;
  left: 16px;
  width: 245px;
  height: 104px;
}
.pmParent {
  position: relative;
  border-radius: 0 var(--br-7xs) var(--br-7xs) var(--br-7xs);
  background-color: var(--green-100);
  width: 279px;
  height: 152px;
}
.chatLeft2,
.groupContainer {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--p3-bold-size);
  color: var(--grey-600);
}
.groupContainer {
  flex-direction: column;
  padding: 0 var(--padding-xl);
  align-items: center;
  gap: var(--gap-xs);
  font-size: var(--p2-semibold-size);
  color: var(--grey-800);
}
.cameraIcon {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.frameChild {
  border: 0;
  background-color: transparent;
  flex: 1;
  position: relative;
}
.cameraParent,
.chatInput,
.signup {
  align-items: center;
  justify-content: flex-start;
}
.cameraParent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
}
.chatInput,
.signup {
  background-color: var(--white);
  overflow: hidden;
  flex-direction: column;
}
.chatInput {
  align-self: stretch;
  box-shadow: 0-1px 2px rgba(0, 0, 0, 0.04);
  display: flex;
  padding: var(--padding-base) var(--padding-5xl);
}
.signup {
  display: none;
  gap: var(--gap-7xs);
}
.navChild {
  position: absolute;
  top: 27px;
  left: 20px;
  width: 125px;
  height: 20px;
}
.home1 {
  position: relative;
  line-height: 24px;
  font-weight: 500;
}
.default,
.iconshomeParent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.iconshomeParent {
  flex-direction: row;
  gap: var(--gap-5xs);
}
.default {
  border-radius: var(--br-11xs);
  width: 181px;
  overflow: hidden;
  flex-direction: column;
  padding: var(--padding-5xs);
  box-sizing: border-box;
  cursor: pointer;
}
.home5 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
}
.default4 {
  border-radius: var(--br-9xs);
  background-color: var(--grey-90);
  width: 181px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: var(--padding-5xs);
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
  cursor: pointer;
  color: var(--primary-10);
}
.accountIcon {
  position: relative;
  width: 24px;
  height: 24px;
}
.defaultParent {
  position: absolute;
  top: 87px;
  left: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xs);
}
.nav {
  position: relative;
  background-color: var(--grey-100);
  width: 221px;
  height: 100vh;
  text-align: center;
  color: var(--grey-10);
}
.peoplename1 {
  position: relative;
  line-height: 24px;
  display: flex;
  align-items: center;
  width: 142px;
  flex-shrink: 0;
}
.avatarname,
.avatarnameWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.avatarnameWrapper {
  position: absolute;
  top: 0;
  left: 36px;
  width: 364px;
  height: 56px;
  flex-direction: column;
  padding: var(--padding-5xs);
  box-sizing: border-box;
  align-items: flex-start;
}
.avatarIcon {
  position: relative;
  width: 40px;
  height: 40px;
}
.peoplename2 {
  height: 19px;
  font-size: 18px;
  display: -webkit-inline-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.namedate,
.yesterday {
  display: flex;
  align-items: center;
}
.yesterday {
  position: relative;
  font-size: var(--caption-regular-size);
  line-height: 20px;
  font-weight: 500;
  color: var(--grey-30);
  text-align: right;
  height: 15px;
  flex-shrink: 0;
}
.namedate {
  align-self: stretch;
  flex-direction: row;
  justify-content: flex-start;
  gap: var(--gap-4xl);
}
.citiessummary {
  position: relative;
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 261px;
  height: 24px;
  flex-shrink: 0;
  opacity: 0.7;
  mix-blend-mode: normal;
}
.div {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}
.chatCounter {
  border-radius: var(--br-5xs);
  background-color: var(--primary-20base);
  display: none;
  flex-direction: column;
  padding: 0 var(--padding-5xs);
  align-items: center;
  justify-content: center;
  text-align: right;
  font-size: var(--caption-regular-size);
  color: var(--white);
}
.msgCounter {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-4xl);
  font-size: var(--p3-bold-size);
  color: var(--grey-10);
}
.avatarParent,
.namedateParent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.avatarParent {
  flex-direction: row;
  padding: var(--padding-5xs);
  gap: var(--gap-5xs);
}
.chat1 {
  border-radius: var(--br-5xs);
  background-color: var(--grey-90);
}
.chat1,
.chat2 {
  width: 372px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.chatCounter2,
.chatParent {
  display: flex;
  flex-direction: column;
}
.chatCounter2 {
  border-radius: var(--br-5xs);
  background-color: var(--primary-20base);
  padding: 0 var(--padding-5xs);
  align-items: center;
  justify-content: center;
  text-align: right;
  font-size: var(--caption-regular-size);
  color: var(--white);
}
.chatParent {
  position: absolute;
  top: 124px;
  left: 28px;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xs);
  color: var(--grey-0);
}
.label {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 26px;
  font-weight: 500;
}
.labelContainer {
  align-self: stretch;
  position: relative;
  height: 28px;
  display: none;
}
.iconsplaceholder {
  position: absolute;
  top: 12px;
  right: 8px;
  width: 24px;
  height: 24px;
  display: none;
}
.userInput {
  position: absolute;
  width: calc(100% - 16px);
  top: 11px;
  left: 8px;
  line-height: 26px;
  display: inline-block;
}
.dotChild {
  position: relative;
  border-radius: 50%;
  background-color: var(--grey-10);
  width: 8px;
  height: 8px;
}
.dot {
  position: absolute;
  top: 20px;
  left: 12px;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-6xs);
}
.baseField {
  align-self: stretch;
  position: relative;
  border-radius: var(--br-8xs);
  background-color: var(--grey-100);
  border: 1px solid var(--grey-70);
  box-sizing: border-box;
  height: 48px;
  overflow: hidden;
  flex-shrink: 0;
  color: var(--grey-40);
}
.div6,
.thisIsA {
  position: absolute;
  top: 0;
  line-height: 24px;
}
.thisIsA {
  left: 0;
}
.div6 {
  right: 0;
  text-align: right;
}
.helperTextContainer {
  align-self: stretch;
  position: relative;
  height: 24px;
  display: none;
  font-size: var(--p3-bold-size);
}
.input {
  position: absolute;
  top: 56px;
  left: 36px;
  width: 364px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-11xs);
  font-size: var(--p2-semibold-size);
  color: var(--grey-20);
}
.frameParent {
  position: relative;
  background-color: var(--white);
  border-right: 1px solid var(--grey-80);
  box-sizing: border-box;
  width: 428px;
  height: 795px;
  overflow: hidden;
  flex-shrink: 0;
  font-size: var(--p1-semibold-size);
}
.avatarIcon6 {
  position: relative;
  width: 32px;
  height: 32px;
}
.peoplename8 {
  position: relative;
  line-height: 26px;
  font-weight: 600;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.avatarname1,
.chatHeader1 {
  display: flex;
  justify-content: center;
}
.avatarname1 {
  flex-direction: row;
  align-items: center;
  gap: var(--gap-5xs);
}
.chatHeader1 {
  background-color: var(--white);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.08);
  width: 100%;
  flex-direction: column;
  padding: var(--padding-xs) var(--padding-5xs) var(--padding-xs)
    var(--padding-21xl);
  box-sizing: border-box;
  align-items: flex-start;
}
.groupInner {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-81xl);
  background-color: var(--gray-6);
  width: 66px;
  height: 24px;
}
.today1 {
  position: absolute;
  top: 2px;
  left: 16px;
  line-height: 155%;
  font-weight: 500;
}
.rectangleGroup {
  position: relative;
  width: 66px;
  height: 24px;
  font-size: var(--caption-regular-size);
  color: var(--grey-700);
}
.hiDonaldNice {
  position: relative;
  line-height: 24px;
  display: inline-block;
  width: 300px;
}
.pm5 {
  position: relative;
  font-size: var(--caption-regular-size);
  line-height: 20px;
  color: var(--grey-30);
}
.hiDonaldNiceToMeetYouHParent {
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-9xs);
}
.chatLeftInner2 {
  border-radius: 0 var(--br-9xs) var(--br-9xs) var(--br-9xs);
  background-color: var(--color-aliceblue);
  padding: var(--padding-5xs) var(--padding-xs) var(--padding-5xs)
    var(--padding-base);
  align-items: flex-start;
  justify-content: center;
}
.chatLeftInner2,
.chatLeftInner3,
.groupParent1 {
  display: flex;
  flex-direction: column;
}
.chatLeftInner3 {
  border-radius: 0 var(--br-9xs) var(--br-9xs) var(--br-9xs);
  background-color: var(--color-aliceblue);
  width: 312px;
  padding: var(--padding-5xs) var(--padding-xs) var(--padding-5xs)
    var(--padding-base);
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: center;
}
.groupParent1 {
  align-self: stretch;
  flex: 1;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xl);
  font-size: var(--p3-bold-size);
  color: var(--grey-0);
}
.inputChild {
  left: 703px;
  border-radius: var(--br-8xs);
  width: 48px;
  height: 48px;
}
.inputChild,
.inputItem,
.messageInput {
  top: 16px;
}
.messageInput {
  border: 0;
  background-color: transparent;
}
.inputItem {
  left: 651px;
  border-radius: var(--br-8xs);
  width: 48px;
  height: 48px;
}
.input1 {
  background-color: var(--white);
  box-shadow: 0-1px 2px rgba(0, 0, 0, 0.04);
  width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 100px;
}
.chat,
.chatHeaderParent,
.home {
  display: flex;
  justify-content: flex-start;
}
.chatHeaderParent {
  flex: 1;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  gap: var(--gap-9xs);
  font-size: var(--p2-semibold-size);
  width: 60%;
}
.chat,
.home {
  align-items: flex-start;
}
.home {
  background-color: var(--color-gray-100);
  width: 100%;
  overflow: hidden;
  flex-direction: row;
  font-size: var(--p3-bold-size);
}
.chat {
  position: relative;
  width: 100%;
  flex-direction: column;
  gap: var(--gap-3xs);
  text-align: left;
  font-size: var(--h4-semibold-size);
  color: var(--grey-900);
  font-family: var(--p3-bold);
}
@media screen and (max-width: 960px) {
  .groupContainer,
  .signup {
    align-self: stretch;
    width: auto;
  }
  .signup {
    display: flex;
  }
  .home {
    display: none;
  }
  .chat {
    align-items: center;
    justify-content: flex-start;
  }
}
