.greatSessionDani {
  position: relative;
  line-height: 26px;
  font-weight: 500;
  display: inline-block;
  width: 410px;
}
.frameChild {
  position: relative;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  object-fit: cover;
}
.cameronWilliamson {
  position: relative;
  line-height: 26px;
  font-weight: 600;
}
.ellipseParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
}
.icons16pxstar {
  position: relative;
  width: 16px;
  height: 16px;
  overflow: hidden;
  flex-shrink: 0;
}
.frameParent,
.starRating {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.frameParent {
  align-items: center;
  justify-content: center;
  gap: var(--gap-133xl);
  color: var(--white);
}
.greatSessionDaniWasSuperParent {
  gap: var(--gap-base);
}
.greatSessionDaniWasSuperParent,
.img,
.testimonial {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.testimonial {
  border-radius: var(--br-5xs);
  background-color: var(--color-gray-200);
  backdrop-filter: blur(60px);
  border: 1px solid var(--grey-60);
  padding: var(--padding-base);
}
.logoIcon {
  position: relative;
  width: 197.34px;
  height: 32px;
}
.greatSessionDani {
  position: relative;
  line-height: 26px;
  font-weight: 500;
  display: inline-block;
  width: 410px;
}
.img {
  width: 50%;
  min-height: 100vh;
  padding: var(--padding-9xl) var(--padding-41xl) var(--padding-9xl)
    var(--padding-61xl);
  box-sizing: border-box;
  gap: var(--gap-496xl);
  background-image: url(/public/img1@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.label,
.logIn {
  position: relative;
}
.logIn {
  letter-spacing: -0.02em;
  line-height: 36px;
}
.label {
  font-size: var(--p2-semibold-size);
  line-height: 26px;
  font-family: var(--caption-bold);
  color: var(--grey-20);
  text-align: left;
}
.tobiasFlagOfTheUnitedKingIcon {
  position: relative;
  width: 20px;
  height: 15px;
  overflow: hidden;
  flex-shrink: 0;
}
.tobiasFlagOfTheUnitedKingWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.iconschevronDown {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.countrySelector {
  position: absolute;
  top: 0;
  left: 0;
  border-right: 1px solid var(--grey-60);
  display: flex;
  flex-direction: row;
  padding: var(--padding-xs) var(--padding-5xs);
  align-items: center;
  justify-content: center;
  gap: var(--gap-9xs);
}
.activeSection {
  position: absolute;
  top: 0;
  left: 63px;
  border-radius: 0 var(--br-8xs) var(--br-8xs) 0;
  background-color: var(--grey-50);
  border: 1.5px solid var(--logg-green);
  box-sizing: border-box;
  width: 335px;
  height: 48px;
}
.div {
  position: absolute;
  top: 10px;
  left: 72px;
  font-size: var(--p2-semibold-size);
  line-height: 26px;
  font-family: var(--caption-bold);
  color: var(--grey-10);
  text-align: left;
}
.iconsplaceholder {
  position: absolute;
  top: 12px;
  right: 8px;
  width: 24px;
  height: 24px;
  display: none;
}
.field {
  position: relative;
  width: 398px;
  height: 48px;
}
.thisIsA,
.thisIsA1 {
  position: relative;
  font-size: var(--p3-semibold-size);
  line-height: 24px;
  font-family: var(--caption-bold);
  color: var(--grey-20);
  text-align: left;
}
.thisIsA1 {
  font-weight: 500;
  display: none;
}
.helperText,
.largeInputs {
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.helperText {
  align-self: stretch;
  display: none;
  flex-direction: row;
  align-items: center;
}
.largeInputs {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer;
}
.field1,
.label1 {
  position: relative;
}
.label1 {
  align-self: stretch;
  font-size: var(--p2-semibold-size);
  line-height: 26px;
  font-family: var(--caption-bold);
  color: var(--grey-20);
  text-align: left;
}
.field1 {
  border: 1px solid var(--grey-70);
  background-color: var(--grey-50);
  border-radius: var(--br-8xs);
  box-sizing: border-box;
  width: 398px;
  height: 48px;
}
.thisIsA2 {
  position: relative;
  font-size: var(--p3-semibold-size);
  text-decoration: underline;
  line-height: 24px;
  font-family: var(--caption-bold);
  color: var(--grey-20);
  text-align: left;
  cursor: pointer;
}
.helperText1 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: var(--gap-9xs);
}
.inputs,
.largeInputs1 {
  display: flex;
  flex-direction: column;
}
.largeInputs1 {
  width: 398px;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.inputs {
  align-items: center;
  justify-content: center;
  gap: var(--gap-xs);
}
.icons20pxplaceholder {
  position: relative;
  width: 20px;
  height: 20px;
  display: none;
}
.label2 {
  position: relative;
  font-size: var(--p3-semibold-size);
  line-height: 24px;
  font-family: var(--caption-bold);
  color: var(--white);
  text-align: center;
}
.largeButtons,
.verifyForm {
  display: flex;
  align-items: center;
  justify-content: center;
}
.largeButtons {
  cursor: pointer;
  border: 0;
  padding: var(--padding-5xs) 0;
  background-color: var(--logg-green);
  border-radius: var(--br-8xs);
  width: 398px;
  height: 48px;
  flex-direction: row;
  box-sizing: border-box;
  gap: var(--gap-5xs);
}
.verifyForm {
  flex-direction: column;
  gap: var(--gap-xl);
}
.or {
  position: relative;
  font-size: var(--caption-bold-size);
  line-height: 20px;
  font-weight: 500;
  font-family: var(--caption-bold);
  color: var(--grey-30);
  text-align: center;
}
.orWrapper {
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  padding: var(--padding-9xs) var(--padding-5xs);
  align-items: center;
  justify-content: center;
}
.demarcationChild {
  position: relative;
  background-color: var(--color-silver);
  width: 398px;
  height: 1px;
  margin-top: -15px;
}
.demarcation {
  width: 398px;
  height: 28px;
  display: flex;
  flex-direction: column;
  padding: var(--padding-smi) 0 var(--padding-sm);
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-end;
}
.iconsfacebook,
.iconsfacebook1 {
  position: relative;
  width: 20px;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.iconsfacebook1 {
  display: none;
}
.largeButtons1 {
  cursor: pointer;
  border: 0;
  padding: var(--padding-5xs) 0;
  background-color: var(--color-royalblue);
  border-radius: var(--br-8xs);
  width: 398px;
  height: 48px;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
}
.label4 {
  position: relative;
  font-size: var(--p3-semibold-size);
  line-height: 24px;
  font-family: var(--caption-bold);
  color: var(--color-dodgerblue);
  text-align: center;
}
.largeButtons2,
.socialLogin {
  display: flex;
  align-items: center;
  justify-content: center;
}
.largeButtons2 {
  cursor: pointer;
  border: 1px solid var(--color-royalblue);
  padding: var(--padding-5xs) 0;
  background-color: transparent;
  border-radius: var(--br-8xs);
  box-sizing: border-box;
  width: 398px;
  height: 48px;
  flex-direction: row;
  gap: var(--gap-5xs);
}
.socialLogin {
  flex-direction: column;
  gap: var(--gap-base);
}
.dontHaveAn,
.signUp {
  font-weight: 500;
  font-family: var(--caption-bold);
  color: var(--grey-800);
}
.signUp {
  font-weight: 600;
  color: var(--logg-green);
}
.dontHaveAnContainer {
  position: relative;
  font-size: var(--p3-semibold-size);
  line-height: 24px;
  text-align: center;
  display: inline-block;
  width: 300px;
  cursor: pointer;
}
.login,
.loginform {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xl);
}
.loginform {
  padding: var(--padding-xl);
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-13xl);
}
.loginformWrapper,
.signIn {
  display: flex;
  align-items: center;
}
.loginformWrapper {
  align-self: stretch;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-size: var(--h4-bold1-size);
  color: var(--grey-900);
}
.signIn {
  position: relative;
  background-color: var(--white);
  width: 100%;
  overflow: hidden;
  flex-direction: row;
  padding: 0 var(--padding-31xl) 0 0;
  box-sizing: border-box;
  justify-content: space-between;
  text-align: left;
  font-size: var(--p2-semibold-size);
  color: var(--grey-90);
  font-family: var(--caption-bold);
}
.testimonial{
  font-size: var(--p2-semibold-size);
  color: var(--grey-90);
  font-family: var(--caption-bold);
}
.confirmYourEmail {
  position: relative;
  font-size: var(--h4-bold-size);
  letter-spacing: -0.02em;
  line-height: 36px;
  display: inline-block;
  color: var(--grey-0);
  width: 398px;
}
.edit,
.emailemailcom,
.weSentA {
  position: relative;
  line-height: 26px;
}
.weSentA {
  display: inline-block;
  width: 398px;
}
.edit,
.emailemailcom {
  font-weight: 600;
}
.edit {
  text-decoration: underline;
  font-weight: 500;
  color: var(--primary-20base);
  text-align: right;
}
.emailemailcomParent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 110px;
  gap: 10px;
}
.confirmYourEmailAddressParent {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: var(--gap-11xs);
}
.didntGetAny,
.pleaseCheckYour,
.resendEmail {
  position: relative;
  line-height: 26px;
  display: inline-block;
}
.pleaseCheckYour {
  font-size: var(--p3-bold-size);
  width: 424px;
}
.didntGetAny,
.resendEmail {
  width: 222px;
  flex-shrink: 0;
}
.resendEmail {
  font-weight: 600;
  color: var(--primary-20base);
  width: 109px;
}
.didntGetAnyConfirmationParent {
  width: 338px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.confirm {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 41px;
  text-align: center;
  font-size: var(--p2-semibold-size);
  color: var(--grey-20);
  font-family: var(--p2-semibold);
}

.mdisuccessCircleOutlineIcon {
  position: relative;
  width: 232px;
  height: 232px;
  overflow: hidden;
  flex-shrink: 0;
}
.emailSuccessfullyConfirmed {
  width: 424px;
}
.completeProfileSetup,
.emailSuccessfullyConfirmed,
.youWillBe {
  position: relative;
  line-height: 26px;
  display: inline-block;
}
.youWillBe {
  font-size: var(--caption-medium-size);
  width: 424px;
}
.completeProfileSetup {
  text-decoration: underline;
  font-weight: 600;
  width: 234px;
  flex-shrink: 0;
}
.completeProfileSetupWrapper {
  width: 234px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  color: var(--primary-20base);
}
.emailverify {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 17px;
  text-align: center;
  font-size: var(--p2-semibold-size);
  color: var(--grey-20);
  font-family: var(--p2-semibold);
}


@media screen and (max-width: 960px) {
  .img {
    display: none;
  }
  .loginform,
  .signIn {
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
  }
  .loginform {
    gap: var(--gap-13xl);
    padding: 0;
  }
  .signIn {
    padding-right: 0;
  }
}
@media screen and (max-width: 430px) {
.field{
  width:100%
}
.largeInputs{
  width:100%
}
.largeInputs1{
  width:100%
}
.inputs,.largeButtons,.verifyForm,.greatSessionDaniWasSuperParent,.demarcation,
.demarcationChild,.socialLogin,.largeButtons1,.largeButtons2,.loginform,.login
{
  width:100%
}
.loginform{
  padding:0px 10px;
}
}
