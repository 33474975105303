.addANew {
  position: relative;
  line-height: 28px;
  font-weight: 600;
}
.iconsclose {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.addANewCardParent,
.modalTitle {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.modalTitle {
  overflow: hidden;
  flex-direction: column;
  padding: var(--padding-base) var(--padding-13xl);
  justify-content: flex-start;
  font-size: var(--h5-semibold-size);
}
.label {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 26px;
  font-weight: 500;
}
.baseField,
.labelContainer {
  align-self: stretch;
  position: relative;
}
.labelContainer {
  height: 28px;
}
.baseField {
  border: 0;
  background-color: transparent;
  cursor: pointer;
}
.div,
.thisIsA {
  position: absolute;
  top: 0;
  line-height: 24px;
}
.thisIsA {
  left: 0;
}
.div {
  right: 0;
  text-align: right;
}
.helperTextContainer {
  align-self: stretch;
  position: relative;
  height: 24px;
  display: none;
  font-size: var(--p3-regular-size);
}
.input {
  width: 397px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-11xs);
  color: var(--grey-20);
}
.selectWithdrawalMethod {
  position: relative;
  font-size: var(--p3-regular-size);
  line-height: 24px;
  font-weight: 500;
  color: var(--grey-0);
}
.iconWithBg,
.iconWithBg1 {
  position: relative;
  border-radius: var(--br-9xs);
  width: 32px;
  height: 32px;
}
.iconWithBg1 {
  width: 24px;
  height: 24px;
  display: none;
}
.list1 {
  position: relative;
  line-height: 26px;
}
.main {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xs);
}
.subText {
  position: relative;
  line-height: 20px;
}
.left,
.subTextWrapper {
  display: flex;
  align-items: flex-start;
}
.subTextWrapper {
  flex-direction: row;
  padding: 0 var(--padding-25xl);
  justify-content: flex-start;
  font-size: var(--caption-regular-size);
  color: var(--grey-30);
}
.left {
  flex-direction: column;
  justify-content: center;
}
.toggleChild {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-31xl);
  background-color: var(--grey-60);
}
.toggleItem {
  position: absolute;
  height: 87.5%;
  width: 50%;
  top: 6.25%;
  right: 46.43%;
  bottom: 6.25%;
  left: 3.57%;
  border-radius: 50%;
  background-color: var(--white);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}
.toggle {
  position: relative;
  width: 56px;
  height: 32px;
  display: none;
}
.content,
.right {
  flex-direction: row;
  align-items: center;
}
.right {
  display: flex;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.content {
  height: 48px;
  justify-content: space-between;
}
.content,
.content1,
.list {
  align-self: stretch;
  display: flex;
}
.list {
  border-bottom: 1px solid var(--grey-80);
  flex-direction: column;
  padding: var(--padding-base) 0;
  align-items: flex-start;
  justify-content: flex-start;
}
.content1 {
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.list2,
.selectWithdrawalMethodParent,
.withdrawmodal {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.list2 {
  align-self: stretch;
  height: 80px;
  padding: var(--padding-base) 0;
  box-sizing: border-box;
}
.selectWithdrawalMethodParent,
.withdrawmodal {
  width: 397px;
  color: var(--grey-10);
}
.withdrawmodal {
  border-radius: var(--br-8xs);
  background-color: var(--white);
  box-shadow: var(--popup);
  width: 461px;
  overflow: auto;
  align-items: center;
  gap: var(--gap-xl);
  max-width: 100%;
  max-height: 100%;
  text-align: left;
  font-size: var(--p2-medium-size);
  color: var(--color-black);
  font-family: var(--p3-bold);
  padding-bottom: 1rem;
}
@media screen and (max-width: 430px) {
  .input,
  .selectWithdrawalMethodParent {
    width: 300px;
  }
  .withdrawmodal {
    width: 400px;
  }
}
