.back1 {
  position: absolute;
  top: 5px;
  left: 26px;
  line-height: 155%;
  font-weight: 500;
}
.frameIcon {
  position: absolute;
  top: 0;
  left: 0;
  width: 32px;
  height: 32px;
  overflow: hidden;
}
.back {
  position: relative;
  width: 59px;
  height: 32px;
}
.activities {
  position: relative;
  font-size: var(--h4-semibold-size);
  font-weight: 600;
  color: var(--grey-900);
}
.titles {
  align-self: stretch;
  background-color: var(--white);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.04);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: var(--padding-xs) var(--padding-4xs);
  align-items: center;
  justify-content: space-between;
  text-align: center;
}
.input {
  border: 0;
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
}
.inputWrapper,
.peopleavatarIcon {
  position: relative;
  width: 374px;
  height: 40px;
}
.peopleavatarIcon {
  border-radius: 50%;
  width: 40px;
  object-fit: cover;
}
.peoplename,
.yesterday {
  position: relative;
  font-weight: 600;
  display: flex;
  align-items: center;
  height: 19px;
  flex-shrink: 0;
}
.peoplename{
  display: -webkit-inline-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.yesterday {
  font-size: var(--caption-bold-size);
  line-height: 155%;
  font-weight: 500;
  color: var(--grey-600);
  text-align: right;
  height: 15px;
}
.peoplenameParent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.citiessummary {
  position: relative;
  line-height: 155%;
  font-weight: 500;
  display: inline-block;
  width: 322px;
  height: 18px;
  flex-shrink: 0;
  mix-blend-mode: normal;
}
.groupChild {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-5xs);
  background-color: var(--logg-green);
}
.div {
  position: absolute;
  height: 55.56%;
  width: 66.67%;
  top: 22.22%;
  left: 16.67%;
  letter-spacing: -0.04em;
  line-height: 100%;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}
.rectangleParent {
  position: relative;
  width: 24px;
  height: 18px;
  display: none;
  text-align: center;
  font-size: var(--caption-bold-size);
  color: var(--white);
}
.citiessummaryParent,
.frameGroup,
.peopleavatarParent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.citiessummaryParent {
  width: 324px;
  flex-direction: row;
  gap: var(--gap-14xl);
  font-size: var(--p3-semibold-size);
  color: var(--grey-700);
}
.frameGroup,
.peopleavatarParent {
  flex-direction: column;
  gap: var(--gap-11xs);
}
.peopleavatarParent {
  flex-direction: row;
  gap: var(--gap-3xs);
}
.instanceChild {
  position: relative;
  border-top: 1px solid var(--grey-100);
  box-sizing: border-box;
  width: 375px;
  height: 1px;
}
.frameParent {
  width: 374px;
  align-items: flex-start;
  cursor: pointer;
  margin-top: 1rem;
}
.frameContainer,
.frameParent,
.instanceParent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.frameContainer {
  width: 374px;
  align-items: flex-start;
}
.instanceParent {
  align-self: stretch;
  align-items: center;
  font-size: var(--h4-bold-size);
  color: var(--grey-900);
}
.avatarIcon {
  position: relative;
  width: 40px;
  height: 40px;
}
.peoplename5 {
  flex: 1;
  position: relative;
  line-height: 24px;
  font-weight: 600;
  height: 19px;
}
.namedate,
.peoplename5,
.yesterday5 {
  display: flex;
  align-items: center;
}
.yesterday5 {
  position: relative;
  font-size: var(--caption-bold-size);
  line-height: 20px;
  font-weight: 500;
  color: var(--grey-30);
  text-align: right;
  width: 71px;
  height: 15px;
  flex-shrink: 0;
}
.namedate {
  align-self: stretch;
  flex-direction: row;
  justify-content: flex-start;
  gap: var(--gap-4xl);
}
.citiessummary5 {
  position: relative;
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 261px;
  height: 24px;
  flex-shrink: 0;
  opacity: 0.7;
  mix-blend-mode: normal;
}
.div5 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}
.chatCounter {
  border-radius: var(--br-5xs);
  background-color: var(--logg-green);
  display: none;
  flex-direction: column;
  padding: 0 var(--padding-5xs);
  align-items: center;
  justify-content: center;
  text-align: right;
  font-size: var(--caption-bold-size);
  color: var(--white);
}
.msgCounter {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-4xl);
  font-size: var(--p3-semibold-size);
  color: var(--grey-10);
}
.avatarParent,
.namedateParent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.avatarParent {
  flex-direction: row;
  padding: var(--padding-5xs);
  gap: var(--gap-5xs);
}
.chat {
  cursor: pointer;
}
.chat,
.chat1 {
  width: 372px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.chatCounter2,
.chatParent,
.signup {
  flex-direction: column;
  align-items: center;
}
.chatCounter2 {
  border-radius: var(--br-5xs);
  background-color: var(--logg-green);
  display: flex;
  padding: 0 var(--padding-5xs);
  justify-content: center;
  text-align: right;
  font-size: var(--caption-bold-size);
  color: var(--white);
}
.chatParent,
.signup {
  align-self: stretch;
  display: none;
  justify-content: flex-start;
}
.chatParent {
  gap: var(--gap-xs);
  font-size: var(--p1-semibold-size);
  color: var(--grey-0);
}
.signup {
  background-color: var(--white);
  padding: 0 var(--padding-3xs);
  gap: var(--gap-lg);
}
.navChild {
  position: absolute;
  top: 27px;
  left: 20px;
  width: 125px;
  height: 20px;
}
.iconshome {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.home1 {
  position: relative;
  line-height: 24px;
  font-weight: 500;
}
.default,
.iconshomeParent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.iconshomeParent {
  flex-direction: row;
  gap: var(--gap-5xs);
}
.default {
  border-radius: var(--br-11xs);
  width: 181px;
  overflow: hidden;
  flex-direction: column;
  padding: var(--padding-5xs);
  box-sizing: border-box;
  cursor: pointer;
}
.home5 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
}
.default4 {
  border-radius: var(--br-9xs);
  background-color: var(--grey-90);
  width: 181px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: var(--padding-5xs);
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
  cursor: pointer;
  color: var(--primary-10);
}
.accountIcon {
  position: relative;
  width: 24px;
  height: 24px;
}
.default5,
.defaultParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.default5 {
  border-radius: var(--br-11xs);
  width: 181px;
  overflow: hidden;
  padding: var(--padding-5xs);
  box-sizing: border-box;
}
.defaultParent {
  position: absolute;
  top: 87px;
  left: 20px;
  gap: var(--gap-xs);
}
.nav {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--grey-50);
}
.navPrototype {
  align-self: stretch;
  position: relative;
  width: 221px;
  text-align: center;
}
.avatarname,
.peoplename11 {
  display: flex;
  align-items: center;
}
.peoplename1 {
  font-size: 18px;
  display: -webkit-inline-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.peoplename11 {
  position: relative;
  line-height: 24px;
  width: 142px;
  flex-shrink: 0;
}
.avatarname {
  flex-direction: row;
  justify-content: center;
}
.avatarnameWrapper,
.chatGroup {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.avatarnameWrapper {
  top: 0;
  left: 36px;
  width: 364px;
  height: 56px;
  padding: var(--padding-5xs);
  box-sizing: border-box;
  justify-content: center;
}
.chatGroup {
  top: 124px;
  left: 28px;
  justify-content: flex-start;
  gap: var(--gap-xs);
  color: var(--grey-0);
}
.label {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 26px;
  font-weight: 500;
}
.labelContainer {
  align-self: stretch;
  position: relative;
  height: 28px;
  display: none;
}
.iconsplaceholder {
  position: absolute;
  top: 12px;
  right: 8px;
  width: 24px;
  height: 24px;
  display: none;
}
.userInput {
  position: absolute;
  width: calc(100% - 16px);
  top: 11px;
  left: 8px;
  line-height: 26px;
  display: inline-block;
}
.dotChild {
  position: relative;
  border-radius: 50%;
  background-color: var(--grey-10);
  width: 8px;
  height: 8px;
}
.dot {
  position: absolute;
  top: 20px;
  left: 12px;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-6xs);
}
.baseField {
  align-self: stretch;
  position: relative;
  border-radius: var(--br-8xs);
  background-color: var(--grey-50);
  border: 1px solid var(--grey-70);
  box-sizing: border-box;
  height: 48px;
  overflow: hidden;
  flex-shrink: 0;
  color: var(--grey-40);
}
.div17,
.thisIsA {
  position: absolute;
  top: 0;
  line-height: 24px;
}
.thisIsA {
  left: 0;
}
.div17 {
  right: 0;
  text-align: right;
}
.helperTextContainer {
  align-self: stretch;
  position: relative;
  height: 24px;
  display: none;
  font-size: var(--p3-semibold-size);
}
.input1 {
  position: absolute;
  top: 56px;
  left: 36px;
  width: 364px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-11xs);
  font-size: var(--p2-semibold-size);
  color: var(--grey-20);
}
.frameParent7 {
  align-self: stretch;
  position: relative;
  background-color: var(--white);
  border-right: 1px solid var(--grey-80);
  box-sizing: border-box;
  width: 428px;
  overflow: hidden;
  flex-shrink: 0;
  font-size: var(--p1-semibold-size);
  color: var(--grey-900);
}
.inboxLineIcon {
  position: relative;
  width: 197.36px;
  height: 175.15px;
}
.inbox {
  position: relative;
  letter-spacing: -0.02em;
  line-height: 36px;
}
.content,
.inboxLineParent {
  display: flex;
  align-items: center;
}
.inboxLineParent {
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-4xl);
}
.content {
  flex: 1;
  background-color: var(--white);
  flex-direction: row;
  justify-content: center;
  font-size: var(--h4-bold1-size);
  color: var(--grey-40);
  width:60%;
  height: 100vh;
}
.home,
.message {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.home {
  background-color: var(--color-gray-100);
  width: 100%;
  overflow: hidden;
  flex-direction: row;
  color: var(--grey-10);
}
.message {
  position: relative;
  width: 100%;
  height: 100%;
  flex-direction: column;
  gap: var(--gap-3xs);
  text-align: left;
  font-size: var(--p3-semibold-size);
  color: var(--grey-800);
  font-family: var(--caption-bold);
}
@media screen and (max-width: 960px) {
  .instanceParent,
  .titles {
    align-self: stretch;
    width: auto;
  }
  .titles {
    display: flex;
  }
  .instanceParent {
    display: none;
  }
  .chatParent,
  .signup {
    display: flex;
  }
  .signup {
    align-self: stretch;
    width: auto;
  }
  .home,
  .navPrototype {
    display: none;
  }
  .message {
    align-items: center;
    justify-content: flex-start;
  }
}
@media screen and (max-width: 430px) {
  .instanceParent {
    display: flex;
  }
  .chatParent {
    display: none;
  }
  .instanceParent{
    font-size: 17px;
  }
  .frameParent {
    width: 100%;
    padding: 0px 10px;
  }
  .inputWrapper{
    width:100%
  }
  .peoplenameParent{
    flex-wrap: wrap;
  }
}
