.addANew {
  position: relative;
  line-height: 28px;
  font-weight: 600;
}
.iconsclose {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.addANewCardParent,
.modalTitle {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.modalTitle {
  overflow: hidden;
  flex-direction: column;
  padding: var(--padding-13xl);
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--h5-semibold-size);
}
.tab1 {
  position: relative;
  line-height: 26px;
  font-weight: 600;
}
.tab1Wrapper {
  border-radius: var(--br-9xs);
  background-color: var(--white);
  width: 244.44px;
  height: 44px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-9xs) var(--padding-5xs);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.tab2 {
  position: relative;
  font-size: var(--p2-regular-size);
  line-height: 26px;
  font-weight: 500;
  font-family: var(--p2-regular);
  color: var(--grey-30);
  text-align: center;
}
.tab2Wrapper,
.twoTabSwitch {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.tab2Wrapper {
  cursor: pointer;
  border: 0;
  padding: var(--padding-9xs) var(--padding-5xs);
  background-color: transparent;
  border-radius: var(--br-9xs);
  width: 244.44px;
  height: 44px;
  box-sizing: border-box;
  justify-content: center;
}
.twoTabSwitch {
  align-self: stretch;
  border-radius: var(--br-8xs);
  background-color: var(--grey-90);
  padding: var(--padding-9xs);
  justify-content: flex-start;
}
.label {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 26px;
  font-weight: 500;
}
.baseFieldIcon,
.labelContainer {
  align-self: stretch;
  position: relative;
  height: 28px;
}
.baseFieldIcon {
  border-radius: var(--br-8xs);
  max-width: 100%;
  overflow: hidden;
  height: 158px;
  flex-shrink: 0;
}
.useMyCurrent {
  position: relative;
  text-decoration: underline;
  line-height: 28px;
}
.iconslocationParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-9xs);
  color: var(--primary-20base);
}
.labelContainerParent,
.twoTabSwitchParent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.labelContainerParent {
  align-self: stretch;
  align-items: flex-start;
  gap: var(--gap-11xs);
  text-align: left;
  color: var(--grey-20);
}
.twoTabSwitchParent {
  align-items: center;
  gap: var(--gap-xs);
  text-align: center;
  color: var(--primary-20base);
}
.reach {
  position: relative;
  line-height: 26px;
  font-weight: 500;
}
.bgChild {
  position: absolute;
  top: 5px;
  left: 0;
  border-radius: var(--br-xl);
  background-color: var(--grey-80);
  width: 495px;
  height: 6px;
}
.bg,
.sliderChild {
  position: absolute;
  top: 0;
  left: 0;
  width: 496px;
  height: 16px;
}
.sliderChild {
  top: 5px;
  border-radius: var(--br-xl);
  background-color: var(--grey-10);
  width: 270px;
  height: 6px;
}
.groupChild,
.slider,
.slider1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 275px;
  height: 16px;
}
.groupChild,
.slider1 {
  left: 197px;
  width: 78px;
}
.groupChild {
  left: 0;
  border-radius: var(--br-11xs);
  background-color: var(--white);
  border: 0.5px solid var(--color-black);
  box-sizing: border-box;
  width: 40px;
}
.div {
  position: absolute;
  top: 3px;
  left: 14px;
  line-height: 26px;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 16px;
  height: 12px;
}
.rectangleParent {
  position: absolute;
  top: -0.5px;
  left: 235px;
  width: 40px;
  height: 16px;
}
.bgParent {
  position: relative;
  width: 495px;
  height: 16px;
  font-size: var(--caption-regular-size);
}
.reachParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
  color: var(--grey-20);
}
.icons20pxplaceholder {
  position: relative;
  width: 20px;
  height: 20px;
  display: none;
}
.label1 {
  position: relative;
  font-size: var(--p3-regular-size);
  line-height: 24px;
  font-family: var(--p2-regular);
  color: white;
  text-align: center;
}
.largeButtons,
.locationmodal {
  border-radius: var(--br-8xs);
  display: flex;
  align-items: center;
}
.largeButtons {
  cursor: pointer;
  border: 0;
  padding: var(--padding-5xs) 0;
  background-color: var(--logg-green);
  width: 496px;
  height: 48px;
  flex-direction: row;
  box-sizing: border-box;
  justify-content: center;
  gap: var(--gap-5xs);
}
.locationmodal {
  position: relative;
  background-color: var(--white);
  box-shadow: var(--popup);
  width: 560px;
  height: 578px;
  overflow: hidden;
  flex-direction: column;
  justify-content: flex-start;
  gap: 29px;
  max-width: 100%;
  max-height: 100%;
  text-align: left;
  font-size: var(--p2-regular-size);
  color: var(--color-black);
  font-family: var(--p2-regular);
}
@media screen and (max-width: 430px) {
  .tab1Wrapper,
  .tab2Wrapper {
    width: 50%;
  }
  .twoTabSwitch {
    align-self: stretch;
    width: auto;
  }
  .labelContainer {
    width: 100%;
    border-radius: 0;
  }
  .labelContainerParent {
    align-self: stretch;
    width: auto;
  }
  .twoTabSwitchParent {
    width: 300px;
  }
  .bgParent {
   width: 300px;
  }
  .reachParent {
    width: auto;
  }
  .largeButtons,
  .reachParent {
    width: 300px;
  }
  .locationmodal {
    width: 400px;
  }
}
