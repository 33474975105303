.addANew {
  position: relative;
  line-height: 28px;
  font-weight: 600;
}
.iconsclose {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
  cursor: pointer;
}
.addANewCardParent {
  position: absolute;
  width: calc(100% - 64px);
  top: 16px;
  right: 32px;
  left: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.dealOfTheDayChild,
.modalTitle {
  align-self: stretch;
  position: relative;
  height: 64px;
  overflow: hidden;
  flex-shrink: 0;
}
.dealOfTheDayChild {
  border-radius: var(--br-9xs) var(--br-9xs) 0 0;
  max-width: 100%;
  height: 118px;
  object-fit: cover;
}
.avatarIcon,
.businessName {
  position: relative;
  width: 24px;
  height: 24px;
}
.businessName {
  line-height: 24px;
  font-weight: 600;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 224px;
  flex-shrink: 0;
}
.avatarName {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.icons16pxstar {
  display: none;
}
.icons16pxstar,
.icons16pxstar3 {
  position: relative;
  width: 16px;
  height: 16px;
  overflow: hidden;
  flex-shrink: 0;
}
.starRating {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.ratingNumber {
  position: relative;
  line-height: 20px;
}
.col,
.ratings {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.ratings {
  width: 86px;
  justify-content: flex-end;
  gap: var(--gap-11xs);
  font-size: var(--caption-semibold-size);
  color: var(--grey-20);
}
.col {
  align-self: stretch;
  justify-content: space-between;
}
.colParent,
.jobCategoryWrapper {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.jobCategoryWrapper {
  flex-direction: row;
  padding: 0 var(--padding-11xs);
  text-align: right;
  font-size: var(--caption-semibold-size);
  color: var(--grey-20);
}
.colParent {
  flex-direction: column;
  gap: var(--gap-9xs);
}
.countdown,
.span {
  font-weight: 600;
  color: var(--primary-20base);
}
.countdown {
  position: relative;
  line-height: 20px;
  color: var(--error-10);
  text-align: right;
}
.frameGroup,
.offerParent {
  align-self: stretch;
  display: flex;
  align-items: center;
}
.offerParent {
  flex-direction: row;
  justify-content: space-between;
  font-size: var(--caption-semibold-size);
  color: var(--grey-10);
}
.frameGroup {
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.button {
  position: relative;
  line-height: 100%;
  font-weight: 600;
}
.buttonWrapper,
.frameParent,
.smallButtons {
  display: flex;
  align-items: center;
  justify-content: center;
}
.buttonWrapper {
  flex-direction: row;
}
.frameParent,
.smallButtons {
  align-self: stretch;
}
.smallButtons {
  border-radius: var(--br-9xs);
  border: 1px solid var(--primary-20base);
  box-sizing: border-box;
  height: 32px;
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-xl);
  text-align: center;
  color: var(--primary-20base);
}
.frameParent {
  flex-direction: column;
  gap: var(--gap-5xs);
}
.dealOfTheDay,
.dealOfTheDayInner,
.promoprev {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.dealOfTheDayInner {
  align-self: stretch;
  background-color: var(--white);
  padding: var(--padding-5xs);
}
.dealOfTheDay,
.promoprev {
  border-radius: var(--br-9xs);
  box-shadow: var(--popup);
  width: 358px;
  overflow: hidden;
  font-size: var(--p3-bold-size);
  color: var(--grey-0);
}
.promoprev {
  border-radius: var(--br-8xs);
  background-color: var(--white);
  width: 560px;
  height: 378px;
  align-items: center;
  gap: var(--gap-13xl);
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
  text-align: left;
  font-size: var(--h5-semibold-size);
  color: var(--color-black);
  font-family: var(--p3-bold);
}
@media screen and (max-width: 430px) {
  .dealOfTheDay {
    width: 300px;
  }
  .promoprev {
    width: 400px;
  }
}
