.addANew {
  position: relative;
  line-height: 28px;
  font-weight: 600;
}
.iconsclose {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.addANewCardParent,
.modalTitle {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.modalTitle {
  overflow: hidden;
  flex-direction: column;
  padding:  var(--padding-13xl);
  justify-content: flex-start;
}
.tab1 {
  position: relative;
  font-size: var(--p2-regular-size);
  line-height: 26px;
  font-weight: 500;
  font-family: var(--p2-regular);
  color: var(--grey-30);
  text-align: center;
}
.tab1Wrapper {
  cursor: pointer;
  border: 0;
  padding: var(--padding-9xs) var(--padding-5xs);
  background-color: transparent;
  position: absolute;
  width: 48.57%;
  top: 4px;
  right: 50%;
  left: 1.43%;
  border-radius: var(--br-9xs);
  height: 44px;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.tab2 {
  position: relative;
  line-height: 26px;
  font-weight: 600;
}
.tab2Wrapper {
  position: absolute;
  width: 49.28%;
  top: 4px;
  right: 0.72%;
  left: 50%;
  border-radius: var(--br-9xs);
  background-color: var(--white);
  height: 44px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-9xs) var(--padding-5xs);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.twoTabSwitch {
  align-self: stretch;
  position: relative;
  border-radius: var(--br-8xs);
  background-color: var(--grey-90);
  height: 52px;
}
.twoTabSwitchWrapper {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.toggleToGo {
  line-height: 24px;
  font-weight: 500;
}
.toggle,
.toggleToGo {
  position: relative;
}
.toggleToGoOnlineOrOfflineParent {
  width: 496px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}
.toggle1 {
  position: relative;
  cursor: pointer;
}
.createAScheduleParent {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}
.useThisTo {
  position: relative;
  font-size: var(--p2-regular-size);
  line-height: 26px;
  color: var(--grey-30);
}
.frameParent1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
  width: 100%;
}
.input,
.label {
  font-weight: 500;
}
.input {
  border: 0;
  font-family: var(--p2-regular);
  font-size: var(--p2-regular-size);
  background-color: transparent;
  width: 238px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.label {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 26px;
}
.baseField,
.labelContainer {
  align-self: stretch;
  position: relative;
}
.labelContainer {
  height: 28px;
}
.baseField {
  border: 0;
  background-color: transparent;
}
.div,
.thisIsA {
  position: absolute;
  top: 0;
  line-height: 24px;
}
.thisIsA {
  left: 0;
}
.div {
  right: 0;
  text-align: right;
}
.helperTextContainer {
  align-self: stretch;
  position: relative;
  height: 24px;
  display: none;
  font-size: var(--p3-regular-size);
}
.input1 {
  width: 238px;
  flex-direction: column;
  gap: var(--gap-11xs);
}
.input1,
.inputParent,
.timeParent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.inputParent {
  flex-direction: row;
  gap: var(--gap-xl);
  font-size: var(--p2-regular-size);
  color: var(--grey-20);
}
.timeParent {
  flex-direction: column;
  gap: var(--gap-base);
}
.monday {
  position: relative;
  line-height: 26px;
}
.mondayParent {
  width: 238px;
  justify-content: space-between;
}
.frameParent3,
.mondayParent,
.sundayParent {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.frameParent3 {
  justify-content: flex-start;
  gap: var(--gap-xl);
}
.sundayParent {
  width: 238px;
  justify-content: space-between;
}
.frameParent2 {
  gap: var(--gap-base);
  font-size: var(--p2-regular-size);
}
.frameContainer,
.frameDiv,
.frameParent2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.frameDiv {
  gap: var(--gap-xl);
  font-size: var(--h5-semibold-size);
}
.frameContainer {
  gap: var(--gap-5xl);
  width: 100%;
}
.icons20pxplaceholder {
  position: relative;
  width: 20px;
  height: 20px;
  display: none;
}
.label1 {
  position: relative;
  font-size: var(--p3-regular-size);
  line-height: 24px;
  font-family: var(--p2-regular);
  color: white;
  text-align: center;
 
}
.largeButtons {
  cursor: pointer;
  border: 0;
  padding: var(--padding-5xs) ;
  background-color: var(--logg-green);
  border-radius: var(--br-8xs);
  width: 496px;
  height: 48px;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
}
.frameGroup {
  align-items: flex-start;
  gap: var(--gap-xl);
  text-align: left;
  font-size: var(--p1-medium-size);
  color: var(--grey-10);
}
.availmodal,
.frameGroup,
.frameParent,
.modalTitleParent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
}

.frameParent {
  align-self: stretch;
  align-items: flex-start;
  gap: var(--gap-xs);
  text-align: center;
  font-size: var(--p2-regular-size);
  color: var(--primary-20base);
}
.availmodal,
.modalTitleParent {
  align-items: center;
}
.availmodal {
  position: relative;
  border-radius: var(--br-8xs);
  background-color: var(--white);
  box-shadow: var(--popup);
  width: 560px;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: center;
  max-width: 100%;
  overflow-y: scroll;
  text-align: left;
  font-size: var(--h5-semibold-size);
  color: var(--color-black);
  font-family: var(--p3-bold);
  padding:  3.5rem 0;
}
@media screen and (max-width: 430px) {
  .tab1Wrapper,
  .tab2Wrapper {
    width: 50%;
  }
  .frameDiv,
  .frameParent1,
  .frameParent2,
  .frameParent3,
  .inputParent,
  .sundayParent,
  .timeParent,
  .toggleToGoOnlineOrOfflineParent,
  .useThisTo {
    align-self: stretch;
    width: auto;
  }
  .largeButtons {
    width: 300px;
  }
  .frameGroup {
    align-self: stretch;
    width: auto;
  }
  .frameParent {
    width: 300px;
  }
  .availmodal {
    width: 400px;
    height: 90%;
  }
}
