.payAsYou {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.02em;
  line-height: 36px;
}
.payAsYou,
.span {
  font-weight: 600;
}
.commissionJob1 {
  font-weight: 500;
  color: var(--grey-20);
}
.commissionJob {
  align-self: stretch;
  position: relative;
  font-size: var(--p2-semibold-size);
  line-height: 26px;
}
.payAsYouGoParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.iconsscrew {
  position: relative;
  width: 20px;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.jobManagement {
  flex: 1;
  position: relative;
  line-height: 26px;
  font-weight: 600;
}
.iconsscrewParent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.automaticallyManageYour {
  flex: 1;
  position: relative;
  line-height: 20px;
}
.automaticallyManageYourJobsWrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  padding: 0 0 0 var(--padding-9xl);
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--caption-regular-size);
  color: var(--grey-20);
}
.subscriptionList {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-11xs);
}
.frameContainer,
.frameGroup,
.subscriptionListWrapper {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
}
.subscriptionListWrapper {
  border-radius: var(--br-9xs);
  background-color: var(--grey-100);
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-xl);
  align-items: center;
}
.frameContainer,
.frameGroup {
  flex-direction: column;
  align-items: flex-start;
}
.frameContainer {
  gap: var(--gap-5xs);
  font-size: var(--p2-semibold-size);
}
.frameGroup {
  gap: var(--gap-xl);
}
.iconsmessage {
  position: relative;
  width: 20px;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.label {
  position: relative;
  line-height: 24px;
}
.largeButtons {
  align-self: stretch;
  border-radius: var(--br-8xs);
  background-color: var(--primary-20base);
  height: 44px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs) 0;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
}
.largeButtonsWrapper {
  padding: var(--padding-xs) 0 0;
  align-items: flex-start;
  text-align: center;
  font-size: var(--p3-bold-size);
  color: var(--white);
}
.frameParent,
.largeButtonsWrapper,
.mergeInner {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.frameParent {
  align-items: center;
  gap: var(--gap-xs);
}
.mergeInner {
  border-radius: var(--br-9xs);
  background-color: var(--white);
  border: 1px solid var(--grey-80);
  padding: var(--padding-xl) var(--padding-12xs) 0;
  align-items: flex-start;
}
.merge1 {
  align-self: stretch;
  padding: 0 var(--padding-5xl);
  align-items: center;
}
.changeplanpsgl,
.merge,
.merge1 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.merge {
  align-self: stretch;
  padding: 0 0 var(--padding-xl);
  align-items: center;
}
.changeplanpsgl {
  position: relative;
  border-radius: var(--br-9xs);
  background-color: var(--white);
  border: 1px solid var(--grey-80);
  box-sizing: border-box;
  width: 655px;
  padding: var(--padding-xl) var(--padding-12xs) 0;
  align-items: flex-start;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
  text-align: left;
  font-size: var(--h4-semibold-size);
  color: var(--grey-10);
  font-family: var(--p3-bold);
}
@media screen and (max-width: 960px) {
  .frameContainer,
  .frameGroup,
  .frameParent,
  .mergeInner,
  .payAsYou,
  .payAsYouGoParent {
    align-self: stretch;
    width: auto;
  }
}
