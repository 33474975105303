.jobDetails {
  position: relative;
  line-height: 28px;
  font-weight: 600;
  display: none;
}
.iconsclose {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.modalTitle {
  align-self: stretch;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 20px 32px;
  gap: 10px;
}
.illustrationsIcon {
  position: relative;
  width: 124px;
  height: 123px;
  overflow: hidden;
  flex-shrink: 0;
}
.withdrawalSuccessful {
  position: relative;
  line-height: 28px;
  font-weight: 600;
  display: inline-block;
  width: 294px;
}
.youWillGet {
  position: relative;
  font-size: 14px;
  line-height: 24px;
  color: #4b4b4b;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 253px;
}
.illustrationsParent,
.withdrawalSuccessfulParent,
.withdrawsucc {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
}
.illustrationsParent,
.withdrawsucc {
  justify-content: flex-start;
  gap: 24px;
  text-align: center;
}
.withdrawsucc {
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08), 0 8px 16px rgba(0, 0, 0, 0.15);
  width: 461px;
  height: 391px;
  overflow: hidden;
  gap: 28px;
  text-align: left;
  font-size: 20px;
  color: #0d0d0d;
  font-family: Inter;
}
@media screen and (max-width: 420px) {
  .withdrawsucc {
    width: 300px;
  }
}
