.jobCategory,
.whatTypeOf {
  position: relative;
  display: inline-block;
  width: 398px;
}
.jobCategory {
  letter-spacing: -0.02em;
  line-height: 36px;
}
.whatTypeOf {
  font-size: var(--p2-semibold-size);
  line-height: 26px;
  color: var(--grey-20);
}
.frameWrapper,
.jobCategoryParent {
  display: flex;
  flex-direction: column;
}
.jobCategoryParent {
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-11xs);
}
.frameWrapper {
  align-items: center;
  justify-content: center;
}
.progressBarChild,
.progressBarInner {
  flex: 1;
  position: relative;
  border-radius: var(--br-9xs);
  background-color: var(--grey-80);
  height: 4px;
}
.progressBarInner {
  background-color: var(--logg-green);
}
.progressBar {
  width: 398px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xl);
}
.label {
  align-self: stretch;
  position: relative;
  line-height: 26px;
}
.userInput {
  position: absolute;
  top: 10px;
  left: 12px;
  line-height: 26px;
}
.iconschevronDown {
  position: absolute;
  top: 12px;
  right: 8px;
  width: 24px;
  height: 24px;
  overflow: hidden;
}
.dotChild {
  position: relative;
  border-radius: 50%;
  background-color: var(--grey-10);
  width: 8px;
  height: 8px;
}
.dot {
  position: absolute;
  top: 20px;
  left: 12px;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-6xs);
}
.field {
  position: relative;
  border-radius: var(--br-8xs);
  background-color: var(--grey-50);
  border: 1px solid var(--grey-70);
  box-sizing: border-box;
  width: 398px;
  height: 48px;
  color: var(--grey-10);
}
.thisIsA,
.thisIsA1 {
  position: relative;
  line-height: 24px;
}
.thisIsA1 {
  font-weight: 500;
  display: none;
}
.helperText,
.largeInputs {
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.helperText {
  align-self: stretch;
  display: none;
  flex-direction: row;
  align-items: center;
  font-size: var(--p3-semibold-size);
}
.largeInputs {
  width: 398px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.field1,
.label1 {
  position: relative;
}
.label1 {
  line-height: 26px;
}
.field1 {
  border-radius: var(--br-8xs);
  background-color: var(--grey-50);
  border: 1px solid var(--primary-10);
  box-sizing: border-box;
  width: 398px;
  height: 48px;
  color: var(--grey-10);
}
.largeInputs1,
.largeInputsParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.largeInputsParent {
  gap: var(--gap-xs);
}
.icons20pxplaceholder {
  position: relative;
  width: 20px;
  height: 20px;
  display: none;
}
.label2 {
  position: relative;
  font-size: var(--p3-semibold-size);
  line-height: 24px;
  font-family: var(--caption-bold);
  color: var(--white);
  text-align: center;
}
.largeButtons {
  cursor: pointer;
  border: 0;
  padding: var(--padding-5xs) 0;
  background-color: var(--logg-green);
  border-radius: var(--br-8xs);
  width: 398px;
  height: 48px;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
}
.frameGroup,
.frameParent {
  flex-direction: column;
  justify-content: center;
  gap: var(--gap-5xl);
}
.frameGroup {
  display: flex;
  align-items: center;
  text-align: left;
  font-size: var(--p2-semibold-size);
  color: var(--grey-20);
}
.frameParent {
  padding: 0 var(--padding-31xl) 0 0;
}
.frameParent,
.jobcat,
.jobcatInner {
  display: flex;
  align-items: center;
}
.jobcatInner {
  align-self: stretch;
  flex: 1;
  flex-direction: column;
  justify-content: center;
}
.jobcat {
  position: relative;
  background-color: var(--white);
  width: 100%;
  overflow: hidden;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  font-size: var(--h4-bold1-size);
  color: var(--grey-0);
  font-family: var(--caption-bold);
}
@media screen and (max-width: 960px) {
  .frameParent {
    padding-right: 0;
    box-sizing: border-box;
  }
  .jobcatInner {
    flex: unset;
    align-self: stretch;
  }
  
  .jobcat {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 2rem;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 430px) {
  .largeInputs{
    width: 100%;
  }
  .largeInputsParent,
  .frameParent,
  .frameGroup{
    width:95%
  }
  .largeButtons{
    width:100%
  }
}
