.logoChild,
.logoItem {
  position: relative;
  width: 26.7px;
  height: 32px;
}
.logoItem {
  width: 159.96px;
  height: 25.92px;
}
.logo {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-2xs);
}
.frameIcon {
  position: relative;
  width: 162px;
  height: 114px;
  overflow: hidden;
  flex-shrink: 0;
}
.passwordResetEmail {
  position: relative;
  line-height: 28px;
  font-weight: 600;
}
.aResetLink {
  position: relative;
  font-size: var(--p2-semibold-size);
  line-height: 26px;
  color: var(--grey-20);
  display: inline-block;
  width: 398px;
}
.passwordResetEmailSentParent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.contactSupport {
  color: var(--logg-green);
}
.stillHaveIssuesContainer {
  position: relative;
  font-size: var(--p3-semibold-size);
  line-height: 24px;
  display: inline-block;
  width: 398px;
  color: var(--grey-20);
}
.frameContainer,
.frameGroup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-13xl);
}
.frameGroup {
  border-radius: var(--br-5xs);
  background-color: var(--white);
  box-shadow: var(--card);
  padding: var(--padding-xl);
}
.backToLogin {
  position: relative;
  font-size: var(--caption-bold-size);
  text-decoration: underline;
  line-height: 20px;
  font-weight: 500;
  color: var(--logg-green);
  display: inline-block;
  width: 398px;
  cursor: pointer;
}
.frameParent {
  display: flex;
  flex-direction: column;
  padding: 0 var(--padding-xl);
  align-items: center;
  justify-content: center;
  gap: var(--gap-xl);
}
.privacyPolicy {
  position: relative;
  font-size: var(--caption-bold-size);
  line-height: 20px;
  font-weight: 500;
  color: var(--grey-10);
}
.confirmreset {
  position: relative;
  background-color: var(--white);
  width: 100%;
  height: 788px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: var(--padding-xl) 0;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  font-size: var(--h5-semibold-size);
  color: var(--grey-900);
  font-family: var(--caption-bold);
}
