.navChild {
  position: absolute;
  top: 27px;
  left: 20px;
  width: 125px;
  height: 20px;
}
.iconshome {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.home {
  position: relative;
  line-height: 24px;
  font-weight: 500;
}
.default,
.iconshomeParent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.iconshomeParent {
  flex-direction: row;
  gap: var(--gap-5xs);
}
.default {
  border-radius: var(--br-11xs);
  width: 181px;
  overflow: hidden;
  flex-direction: column;
  padding: var(--padding-5xs);
  box-sizing: border-box;
  cursor: pointer;
}
.home2 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
}
.default2 {
  border-radius: var(--br-9xs);
  background-color: var(--grey-90);
  width: 181px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: var(--padding-5xs);
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
  cursor: pointer;
  color: var(--primary-10);
}
.accountIcon {
  position: relative;
  width: 24px;
  height: 24px;
}
.default5,
.defaultParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.default5 {
  border-radius: var(--br-11xs);
  width: 181px;
  overflow: hidden;
  padding: var(--padding-5xs);
  box-sizing: border-box;
}
.defaultParent {
  position: absolute;
  top: 87px;
  left: 20px;
  gap: var(--gap-xs);
}
.nav {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--grey-50);
}
.navPrototype {
  position: relative;
  width: 221px;
  height: 100vh;
}
.back1 {
  position: absolute;
  top: 5px;
  left: 26px;
  line-height: 155%;
  font-weight: 500;
}
.frameIcon {
  position: absolute;
  top: 0;
  left: 0;
  width: 32px;
  height: 32px;
  overflow: hidden;
}
.back {
  position: relative;
  width: 59px;
  height: 32px;
}
.activities {
  position: relative;
  font-weight: 600;
  color: var(--grey-900);
  font-size: var(--h4-semibold-size);
}
.titles {
  align-self: stretch;
  background-color: var(--white);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.04);
  overflow: hidden;
  display: none;
  flex-direction: row;
  padding: var(--padding-xs) var(--padding-4xs);
  align-items: center;
  justify-content: space-between;
  text-align: center;
}
.licenseAndCertification {
  position: relative;
  line-height: 28px;
}
.component102 {
  width: 100%;
  background-color: var(--white);
  border-bottom: 1px solid var(--grey-80);
  display: flex;
  flex-direction: row;
  padding: 1rem;
  align-items: center;
  justify-content: flex-start;
  padding-left: 10%;
}
.tab1 {
  flex: 1;
  position: relative;
  line-height: 26px;
  font-weight: 600;
}
.tab1Wrapper {
  flex: 1;
  border-radius: var(--br-9xs);
  background-color: var(--white);
  height: 44px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-9xs) var(--padding-5xs);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.tab2 {
  flex: 1;
  position: relative;
  line-height: 26px;
  font-weight: 500;
}
.tab2Wrapper,
.twoTabSwitch {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
}
.tab2Wrapper {
  flex: 1;
  border-radius: var(--br-9xs);
  height: 44px;
  padding: var(--padding-9xs) var(--padding-5xs);
  justify-content: center;
  cursor: pointer;
  color: var(--grey-20);
}
.twoTabSwitch {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-8xs);
  background-color: var(--grey-90);
  padding: var(--padding-9xs);
  justify-content: flex-start;
}
.activitiesTab {
  position: relative;
  width: 100%;
  height: 52px;
}
.booking {
  position: relative;
  line-height: 26px;
  mix-blend-mode: normal;
}
.all {
  width: 111.6px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.booking2 {
  position: relative;
  line-height: 26px;
  font-weight: 600;
  mix-blend-mode: normal;
}
.booking1 {
  border-bottom: 2px solid var(--logg-green);
  box-sizing: border-box;
  width: 111.6px;
  padding: var(--padding-5xs);
  color: var(--logg-green);
}
.allParent,
.booking1,
.tabsSubSwitch {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.tabsSubSwitch {
  background-color: var(--color-gray-100);
  border-bottom: 1px solid var(--grey-80);
  box-sizing: border-box;
  width: 558px;
  flex-direction: column;
}
.activitiesTabParent,
.component102Parent,
.prototypeSwitch {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.prototypeSwitch {
  align-items: flex-start;
  color: var(--grey-20);
}
.activitiesTabParent,
.component102Parent {
  align-items: center;
}
.activitiesTabParent {
  gap: var(--gap-5xs);
  text-align: center;
  font-size: var(--p2-semibold-size);
  color: var(--logg-green);
  width:100%
}
.component102Parent {
  background-color: var(--color-gray-100);
  height: 128px;
  padding: 3px 0;
  box-sizing: border-box;
  gap: var(--gap-3xs);
  font-size: var(--h5-semibold-size);
  color: var(--grey-900);
}
.booking6,
.sun01July {
  position: relative;
  line-height: 26px;
}
.booking6 {
  font-weight: 600;
}
.sun01July {
  color: var(--grey-20);
}
.bookingParent {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.minsAgo {
  position: relative;
  line-height: 26px;
  color: var(--grey-20);
  text-align: right;
}
.top {
  align-self: stretch;
  border-bottom: 1px dashed var(--grey-80);
  display: flex;
  flex-direction: row;
  padding: 0 0 var(--padding-5xs);
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-256xl);
}
.smithsConstructions {
  font-weight: 500;
  color: var(--logg-green);
}
.youHaveAnContainer {
  align-self: stretch;
  position: relative;
  line-height: 26px;
  color: var(--grey-800);
}
.icons20pxplaceholder {
  position: relative;
  width: 20px;
  height: 20px;
  display: none;
}
.label {
  position: relative;
  line-height: 24px;
}
.largeButtons {
  border-radius: var(--br-8xs);
  border: 1px solid var(--logg-green);
  box-sizing: border-box;
  height: 44px;
  display: none;
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-158xl);
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: var(--p3-semibold-size);
  color: var(--logg-green);
}
.content,
.largeButtons {
  align-self: stretch;
  gap: var(--gap-5xs);
}
.container,
.content,
.notifcations {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.container {
  align-self: stretch;
  padding: var(--padding-xs);
}
.notifcations {
  border-radius: var(--br-5xs);
  background-color: var(--white);
  box-shadow: var(--card);
  width: 558px;
}
.notifcationsParent,
.top1 {
  align-self: stretch;
  align-items: center;
  margin-top: 2rem;
}
.top1 {
  border-bottom: 1px dashed var(--grey-80);
  display: flex;
  flex-direction: row;
  padding: 0 0 var(--padding-5xs);
  justify-content: flex-start;
}
.notifcationsParent {
  flex-direction: column;
  gap: var(--gap-xs);
  font-size: var(--p2-semibold-size);
  color: var(--grey-0);
}
.notifcationsParent,
.notifications,
.titles1,
.titlesParent {
  display: flex;
  justify-content: flex-start;
}
.titlesParent {
  flex: 1;
  flex-direction: column;
  align-items: center;
  gap: var(--gap-5xs);
  text-align: left;
  color: var(--grey-800);
  height: 100vh;
  overflow-y: auto;
}
.notifications,
.titles1 {
  align-self: stretch;
  background-color: var(--color-gray-100);
  overflow: hidden;
  flex-direction: row;
  align-items: flex-start;
}
.titles1 {
  background-color: var(--white);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.04);
  padding: var(--padding-xs) var(--padding-4xs);
  align-items: center;
  justify-content: space-between;
  font-size: var(--p3-semibold-size);
}
.notificationsQuestionsChild {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--white);
}
.tabChild,
.tabItem {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-9xs);
  background-color: var(--color-whitesmoke-100);
  width: 374px;
  height: 36px;
}
.tabItem {
  top: 3px;
  left: 3px;
  background-color: var(--white);
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.05);
  width: 184px;
  height: 30px;
}
.notifications1,
.questionsAnswer {
  position: absolute;
  top: 6px;
  left: 48px;
  line-height: 155%;
  font-weight: 600;
}
.questionsAnswer {
  left: 245px;
  font-weight: 500;
  color: var(--gray-2);
}
.tab {
  position: absolute;
  height: 81.82%;
  width: 90.34%;
  top: 9.09%;
  right: 4.83%;
  bottom: 9.09%;
  left: 4.83%;
  overflow: hidden;
}
.notificationsQuestions {
  position: relative;
  width: 414px;
  height: 44px;
  font-size: var(--p2-medium-size);
  color: var(--logg-green);
}
.all1 {
  position: relative;
  letter-spacing: -0.02em;
  line-height: 155%;
  font-weight: 600;
  mix-blend-mode: normal;
}
.frameChild {
  position: relative;
  border-radius: var(--br-11xs) var(--br-11xs) 0 0;
  height: 2px;
}
.allGroup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-8xs);
}
.jobs {
  letter-spacing: -0.03em;
}
.jobs,
.payment1 {
  position: relative;
  line-height: 155%;
  font-weight: 500;
  mix-blend-mode: normal;
}
.notificationsLowerTab,
.titles2 {
  display: flex;
  justify-content: flex-start;
}
.titles2 {
  overflow-x: auto;
  flex-direction: row;
  align-items: flex-start;
  gap: 30px;
}
.notificationsLowerTab {
  flex-direction: column;
  padding: 1px var(--padding-5xl);
  align-items: center;
  color: var(--grey-600);
}
.span {
  color: var(--color-darkslategray-100);
}
.sun01JulyAt720pm {
  font-weight: 500;
  color: var(--grey-600);
}
.bookingSun01Container,
.minsAgo2 {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 155%;
}
.minsAgo2 {
  left: 270px;
  font-weight: 500;
  color: var(--grey-600);
  text-align: right;
  display: none;
}
.bookingSun01JulyAt720pmParent {
  align-self: stretch;
  position: relative;
  height: 25px;
  overflow: hidden;
  flex-shrink: 0;
}
.frameItem {
  position: relative;
  border-top: 1px dashed var(--grey-100);
  box-sizing: border-box;
  width: 351px;
  height: 1px;
}
.frameGroup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.iAmEnjoying {
  line-height: 155%;
  font-weight: 500;
  display: inline-block;
  width: 350px;
}
.iAmEnjoying,
.icons,
.proceed {
  position: relative;
}
.icons {
  width: 20px;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.proceed {
  line-height: 155%;
}
.iconsParent {
  display: flex;
  flex-direction: row;
  gap: var(--gap-5xs);
}
.button,
.iAmEnjoyingTheseComputerParent,
.iconsParent {
  align-items: center;
  justify-content: center;
}
.button {
  border-radius: var(--br-9xs);
  background-color: var(--logg-green);
  width: 351px;
  display: none;
  flex-direction: row;
  padding: var(--padding-4xs) var(--padding-128xl);
  box-sizing: border-box;
  text-align: center;
  font-size: var(--p3-semibold-size);
  color: var(--white);
}
.iAmEnjoyingTheseComputerParent {
  width: 350px;
  gap: var(--gap-5xs);
  color: var(--grey-700);
}
.frameParent,
.iAmEnjoyingTheseComputerParent,
.notifications2 {
  display: flex;
  flex-direction: column;
}
.frameParent {
  width: 350px;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
}
.notifications2 {
  border-radius: var(--br-5xs);
  background-color: var(--white);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  width: 374px;
  padding: var(--padding-xs);
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
}
.offer {
  font-weight: 600;
}
.sun01July2 {
  font-weight: 500;
  color: var(--color-darkslategray-100);
}
.notificationsParent {
  display: flex;
  padding: var(--padding-3xs) 0;
  align-items: flex-start;
  gap: var(--gap-base);
  text-align: left;
  color: var(--grey-900);
}
.activity,
.notificationsParent,
.signup {
  flex-direction: column;
  justify-content: flex-start;
}
.signup {
  align-self: stretch;
  background-color: var(--color-gray-100);
  overflow: hidden;
  display: none;
  align-items: center;
  font-size: var(--p2-semibold-size);
  color: var(--grey-800);
  gap: 10px;
}
.activity {
  position: relative;
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: var(--gap-3xs);
  text-align: center;
  font-size: var(--p3-semibold-size);
  color: var(--grey-10);
  font-family: var(--caption-bold);
}
@media screen and (max-width: 960px) {
  .navPrototype {
    display: none;
  }
  .titles {
    display: flex;
    align-self: stretch;
    width: auto;
  }
  .component102 {
    display: none;
  }
  .notifications,
  .titlesParent {
    display: flex;
  }
  .signup {
    display: none;
  }
}
@media screen and (max-width: 430px) {
  .notifications,
  .titles,
  .titlesParent {
    display: none;
  }
  .signup {
    display: flex;
    width:100%
  }
}
