.navChild {
  position: absolute;
  top: 27px;
  left: 20px;
  width: 125px;
  height: 20px;
}
.iconshome {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.home {
  position: relative;
  line-height: 24px;
  font-weight: 500;
}
.default,
.iconshomeParent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.iconshomeParent {
  flex-direction: row;
  gap: var(--gap-5xs);
}
.default {
  align-self: stretch;
  border-radius: var(--br-11xs);
  overflow: hidden;
  flex-direction: column;
  padding: var(--padding-5xs);
  cursor: pointer;
}
.accountIcon {
  position: relative;
  width: 24px;
  height: 24px;
}
.home5 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
}
.default5,
.defaultParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.default5 {
  align-self: stretch;
  border-radius: var(--br-9xs);
  background-color: var(--grey-90);
  overflow: hidden;
  padding: var(--padding-5xs);
  color: var(--primary-10);
}
.defaultParent {
  position: absolute;
  top: 87px;
  left: 20px;
  width: 181px;
  gap: var(--gap-xs);
}
.nav {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--grey-100);
}
.navPrototype {
  position: relative;
  width: 221px;
  height: 1325px;
}
.back1 {
  position: absolute;
  top: 5px;
  left: 26px;
  line-height: 155%;
  font-weight: 500;
}
.frameIcon {
  position: absolute;
  top: 0;
  left: 0;
  width: 32px;
  height: 32px;
  overflow: hidden;
}
.back {
  position: relative;
  width: 59px;
  height: 32px;
}
.notifications {
  position: relative;
  font-size: var(--h4-semibold-size);
  font-weight: 600;
  color: var(--grey-900);
}
.titles {
  align-self: stretch;
  background-color: var(--white);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.04);
  overflow: hidden;
  display: none;
  flex-direction: row;
  padding: var(--padding-xs) var(--padding-4xs);
  align-items: center;
  justify-content: space-between;
}
.iconsadd {
  position: relative;
  width: 16px;
  height: 16px;
  overflow: hidden;
  flex-shrink: 0;
}
.button {
  position: relative;
  line-height: 100%;
  font-weight: 600;
}
.iconsaddParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-9xs);
}
.avatarIcon,
.smallButtons {
  position: absolute;
  top: 14px;
  height: 40px;
  display: none;
}
.smallButtons {
  right: 427px;
  border-radius: var(--br-9xs);
  background-color: var(--primary-20base);
  width: 146px;
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.avatarIcon {
  left: 1139px;
  width: 40px;
}
.licenseAndCertification {
  position: absolute;
  top: 18px;
  left: 234px;
  font-size: var(--h5-semibold-size);
  line-height: 28px;
  color: var(--grey-900);
  text-align: left;
}
.icon {
  position: absolute;
  top: 14px;
  left: 52px;
  border: 1px solid var(--grey-80);
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs);
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.component102,
.frameChild {
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.component102 {
  align-self: stretch;
  background-color: var(--white);
  border-bottom: 1px solid var(--grey-80);
  box-sizing: border-box;
  height: 68px;
  color: var(--white);
}
.frameChild {
  border-radius: var(--br-9xs);
  width: 48px;
  height: 48px;
}
.clydesdaleBankPlc {
  position: relative;
  font-size: var(--p2-bold-size);
  line-height: 26px;
  font-weight: 600;
  color: var(--grey-10);
}
.div,
.frameItem {
  position: relative;
}
.div {
  line-height: 24px;
}
.frameItem {
  border-right: 1px solid var(--grey-20);
  box-sizing: border-box;
  width: 1px;
  height: 15px;
}
.parent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.clydesdaleBankPlcParent,
.frameGroup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.frameGroup {
  flex-direction: row;
  gap: var(--gap-xs);
}
.iconsmoreVertical {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
  cursor: pointer;
}
.banks,
.frameParent {
  display: flex;
  align-items: flex-start;
}
.frameParent {
  width: 558px;
  flex-direction: row;
  justify-content: space-between;
}
.banks {
  flex-direction: column;
  padding: var(--padding-base) 0;
  justify-content: flex-start;
}
.iconsadd1,
.iconsadd2 {
  position: relative;
  width: 20px;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.iconsadd2 {
  display: none;
}
.largeButtons {
  border-radius: var(--br-8xs);
  border: 1px solid var(--primary-20base);
  box-sizing: border-box;
  width: 558px;
  height: 48px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs) 0;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
  cursor: pointer;
  text-align: center;
  color: var(--primary-20base);
}
.banksParent {
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-5xl);
  text-align: left;
  color: var(--grey-20);
}
.banksParent,
.payoutpref,
.titlesParent {
  display: flex;
  justify-content: flex-start;
}
.titlesParent {
  flex: 1;
  flex-direction: column;
  align-items: center;
  gap: var(--gap-xl);
  color: var(--grey-800);
}
.payoutpref {
  position: relative;
  background-color: var(--color-gray-100);
  width: 100%;
  overflow: hidden;
  flex-direction: row;
  align-items: flex-start;
  text-align: center;
  font-size: var(--p3-semibold-size);
  color: var(--grey-10);
  font-family: var(--p3-bold);
  padding: 0.5rem;
}
@media screen and (max-width: 960px) {
  .navPrototype {
    display: none;
  }
  .titles {
    display: flex;
  }
  .component102{
    display: none;
  }
}
@media screen and (max-width: 430px) {
  .banks,
  .banksParent,
  .frameParent,
  .largeButtons {
    align-self: stretch;
    width: auto;
  }
  .component102{
    display: none;
  }
}
