.navChild {
  position: absolute;
  top: 27px;
  left: 20px;
  width: 125px;
  height: 20px;
}
.iconshome {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.home {
  position: relative;
  line-height: 24px;
  font-weight: 500;
}
.default,
.iconshomeParent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.iconshomeParent {
  flex-direction: row;
  gap: var(--gap-5xs);
}
.default {
  align-self: stretch;
  border-radius: var(--br-11xs);
  overflow: hidden;
  flex-direction: column;
  padding: var(--padding-5xs);
  cursor: pointer;
}
.accountIcon {
  position: relative;
  width: 24px;
  height: 24px;
}
.home5 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
}
.default5,
.defaultParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.default5 {
  align-self: stretch;
  border-radius: var(--br-9xs);
  background-color: var(--grey-90);
  overflow: hidden;
  padding: var(--padding-5xs);
  color: var(--primary-10);
}
.defaultParent {
  position: absolute;
  top: 87px;
  left: 20px;
  width: 181px;
  gap: var(--gap-xs);
}
.nav {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--grey-100);
}
.iconsadd,
.navPrototype {
  position: relative;
  width: 221px;
  height: 1325px;
}
.iconsadd {
  width: 16px;
  height: 16px;
  overflow: hidden;
  flex-shrink: 0;
}
.button {
  position: relative;
  line-height: 100%;
  font-weight: 600;
}
.iconsaddParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-9xs);
}
.avatarIcon,
.smallButtons {
  position: absolute;
  top: 14px;
  height: 40px;
  display: none;
}
.smallButtons {
  right: 427px;
  border-radius: var(--br-9xs);
  background-color: var(--primary-20base);
  width: 146px;
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.avatarIcon {
  left: 1139px;
  width: 40px;
}
.licenseAndCertification {
  position: absolute;
  top: 18px;
  left: 234px;
  font-size: var(--h5-semibold-size);
  line-height: 28px;
  color: var(--grey-900);
  text-align: left;
}
.icon {
  position: absolute;
  top: 14px;
  left: 52px;
  border: 1px solid var(--grey-80);
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs);
  align-items: center;
  justify-content: center;
}
.component102 {
  align-self: stretch;
  position: relative;
  background-color: var(--white);
  border-bottom: 1px solid var(--grey-80);
  box-sizing: border-box;
  height: 68px;
  overflow: hidden;
  flex-shrink: 0;
}
.back1 {
  position: absolute;
  top: 5px;
  left: 26px;
  line-height: 155%;
  font-weight: 500;
}
.frameIcon {
  position: absolute;
  top: 0;
  left: 0;
  width: 32px;
  height: 32px;
  overflow: hidden;
}
.back {
  position: relative;
  width: 59px;
  height: 32px;
}
.notifications {
  position: relative;
  font-size: var(--h4-semibold-size);
  font-weight: 600;
  color: var(--grey-900);
}
.titles {
  align-self: stretch;
  background-color: var(--white);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.04);
  overflow: hidden;
  display: none;
  flex-direction: row;
  padding: var(--padding-xs) var(--padding-4xs);
  align-items: center;
  justify-content: space-between;
  color: var(--grey-800);
}
.iconWithBg {
  position: relative;
  border-radius: var(--br-9xs);
  width: 32px;
  height: 32px;
  display: none;
}
.list1 {
  position: relative;
  line-height: 26px;
}
.left {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xs);
}
.toggleChild {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-31xl);
  background-color: var(--grey-60);
}
.toggleItem {
  position: absolute;
  height: 87.5%;
  width: 50%;
  top: 6.25%;
  right: 46.43%;
  bottom: 6.25%;
  left: 3.57%;
  border-radius: 50%;
  background-color: var(--white);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}
.toggle {
  position: relative;
  width: 56px;
  height: 32px;
  display: none;
}
.content,
.right {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.right {
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.content {
  align-self: stretch;
  flex: 1;
  justify-content: space-between;
}
.list {
  border-bottom: 1px solid var(--grey-80);
  box-sizing: border-box;
  width: 558px;
  height: 60px;
  display: flex;
  padding: var(--padding-base) 0;
  justify-content: flex-start;
  cursor: pointer;
}
.list,
.list2,
.listParent {
  flex-direction: column;
  align-items: flex-start;
}
.list2 {
  width: 558px;
  height: 60px;
  display: flex;
  padding: var(--padding-base) 0;
  box-sizing: border-box;
  justify-content: flex-start;
  cursor: pointer;
}
.listParent {
  text-align: left;
  font-size: var(--p2-bold-size);
  color: var(--grey-10);
  padding: 1rem;
}
.component102Parent,
.listParent,
.pandp {
  display: flex;
  justify-content: flex-start;
}
.component102Parent {
  flex: 1;
  flex-direction: column;
  align-items: center;
  gap: var(--gap-xl);
  color: var(--white);
}
.pandp {
  position: relative;
  background-color: var(--color-gray-100);
  width: 100%;
  overflow: hidden;
  flex-direction: row;
  align-items: flex-start;
  text-align: center;
  font-size: var(--p3-semibold-size);
  color: var(--grey-10);
  font-family: var(--p3-bold);
}
@media screen and (max-width: 960px) {
  .component102,
  .navPrototype {
    display: none;
  }
  .titles {
    display: flex;
  }
}
@media screen and (max-width: 430px) {
  .list,
  .list2,
  .listParent {
    align-self: stretch;
    width: auto;
  }
}
