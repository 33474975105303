.addLicense {
  position: relative;
  line-height: 28px;
  font-weight: 600;
}
.iconsclose {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
  cursor: pointer;
}
.addLicenseParent,
.modalTitle {
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.modalTitle {
  flex-direction: column;
  padding: var(--padding-base) var(--padding-13xl);
  justify-content: flex-start;
  font-size: var(--h5-semibold-size);
}
.label {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 26px;
  font-weight: 500;
}
.baseField,
.labelContainer {
  align-self: stretch;
  position: relative;
}
.labelContainer {
  height: 28px;
}
.baseField {
  border: 0;
  background-color: transparent;
  cursor: pointer;
}
.div,
.thisIsA {
  position: absolute;
  top: 0;
  line-height: 24px;
}
.thisIsA {
  left: 0;
}
.div {
  right: 0;
  text-align: right;
}
.helperTextContainer {
  align-self: stretch;
  position: relative;
  height: 24px;
  display: none;
  font-size: var(--p3-medium-size);
}
.input {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-11xs);
}
.baseField1 {
  border: 0;
  background-color: transparent;
  align-self: stretch;
  position: relative;
}
.inputGroup,
.inputParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
  width:100%
}
.inputParent {
  width: 398px;
  gap: var(--gap-base);
  color: var(--grey-20);
}
.uploadImage {
  position: relative;
  line-height: 26px;
  font-weight: 500;
}
.boundary {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-8xs);
  background-color: var(--grey-80);
  border: 1px dashed var(--grey-60);
  box-sizing: border-box;
}
.imageArea {
  position: absolute;
  height: 98%;
  width: calc(100% - 2px);
  top: 1%;
  right: 1px;
  bottom: 1%;
  left: 1px;
  border-radius: var(--br-9xs);
  background-color: var(--grey-90);
}
.iconsadd {
  position: absolute;
  top: calc(50% - 12px);
  left: calc(50% - 12px);
  width: 24px;
  height: 24px;
  overflow: hidden;
}
.imageFrame {
  align-self: stretch;
  flex: 1;
  position: relative;
  z-index: 0;
}
.addcancelCircleIcon {
  position: absolute;
  margin: 0 !important;
  right: -11px;
  bottom: -7px;
  width: 24px;
  height: 24px;
  display: none;
  z-index: 1;
}
.uploadImage1,
.uploadImage2 {
  width: 60px;
  height: 60px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}
.uploadImage1 {
  display: flex;
}
.uploadImage2 {
  display: none;
}
.uploadImageGroup,
.uploadImageParent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.uploadImageParent {
  align-self: stretch;
  flex-direction: column;
  padding: 0 var(--padding-xl);
  gap: var(--gap-xs);
  color: var(--grey-10);
}
.icons20pxplaceholder {
  position: relative;
  width: 20px;
  height: 20px;
  display: none;
}
.label2 {
  position: relative;
  line-height: 24px;
}
.largeButtons,
.licensemodal {
  border-radius: var(--br-8xs);
  display: flex;
  align-items: center;
}
.largeButtons {
  background-color: var(--primary-10);
  width: 397px;
  height: 48px;
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-158xl);
  box-sizing: border-box;
  justify-content: center;
  gap: var(--gap-5xs);
  text-align: center;
  font-size: var(--p3-medium-size);
  color: white;
}
.licensemodal {
  position: relative;
  background-color: var(--white);
  box-shadow: var(--popup);
  width: 461px;
  height: 518px;
  overflow: hidden;
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-2xl);
  max-width: 100%;
  max-height: 100%;
  text-align: left;
  font-size: var(--p2-bold-size);
  color: var(--color-black);
  font-family: var(--p3-bold);
}
@media screen and (max-width: 430px) {
  .inputParent,
  .largeButtons {
    width: 300px;
  }
  .licensemodal {
    width: 400px;
  }
}
