.quoteNumber {
  position: relative;
  font-weight: 500;
  display: inline-block;
  width: 318px;
  flex-shrink: 0;
}
.quoteNumber,
.span {
  line-height: 26px;
}
.x {
  font-size: var(--caption-bold-size);
  line-height: 20px;
}
.x10 {
  position: relative;
  text-align: right;
  display: inline-block;
  width: 163px;
  flex-shrink: 0;
}
.quoteNumberParent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-42xl);
}
.div,
.kvPinInsulators {
  position: relative;
  line-height: 20px;
  display: inline-block;
  width: 331px;
  flex-shrink: 0;
}
.div {
  font-size: var(--p2-semibold-size);
  line-height: 26px;
  font-weight: 500;
  color: var(--grey-10);
  text-align: right;
  width: 127px;
}
.kvPinInsulatorsParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-65xl);
}
.div1 {
  position: relative;
  line-height: 20px;
  text-align: right;
  display: inline-block;
  width: 127px;
  flex-shrink: 0;
}
.frameGroup,
.frameParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.frameGroup {
  gap: var(--gap-11xs);
  font-size: var(--caption-bold-size);
}
.frameParent {
  position: absolute;
  top: 8px;
  left: 8px;
}
.quoteCards {
  position: relative;
  background-color: var(--white);
  border-bottom: 1px solid var(--grey-80);
  box-sizing: border-box;
  width: 558px;
  height: 95px;
}
.kvPinInsulators1,
.x101 {
  position: relative;
  display: inline-block;
  flex-shrink: 0;
}
.x101 {
  line-height: 26px;
  text-align: right;
  width: 163px;
}
.kvPinInsulators1 {
  line-height: 20px;
  width: 331px;
  opacity: 0;
}
.quoteCardsParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.qn0001,
.quoteNumber3 {
  position: absolute;
  top: 18.18%;
  line-height: 26px;
  font-weight: 600;
}
.quoteNumber3 {
  left: 8px;
  color: var(--grey-20);
  text-align: left;
}
.qn0001 {
  right: 8px;
}
.success {
  position: relative;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  font-weight: 600;
}
.transactionPills {
  position: absolute;
  top: 13px;
  left: 504px;
  border-radius: var(--br-81xl);
  background-color: var(--green-100);
  display: none;
  flex-direction: row;
  padding: var(--padding-9xs) var(--padding-7xs);
  align-items: center;
  justify-content: flex-end;
  font-size: var(--font-size-5xs);
}
.quoteCards3 {
  position: relative;
  background-color: var(--white);
  width: 558px;
  height: 44px;
  text-align: right;
  color: var(--logg-green);
}
.tools {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
  text-align: left;
  font-size: var(--p2-semibold-size);
  color: var(--grey-20);
  font-family: var(--caption-bold);
}
