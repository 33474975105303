.avatarIcon {
  position: relative;
  width: 40px;
  height: 40px;
}

.smithsConstruction {
  position: relative;
  line-height: 24px;
  font-weight: 600;
}

.templeWayWitham {
  position: relative;
}

.templeWayWithamOnTheHillParent {
  gap: var(--gap-9xs);
  font-size: var(--capitalised-10-size);
  color: var(--grey-30);
}

.merge,
.smithsConstructionParent,
.templeWayWithamOnTheHillParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}

.merge {
  flex-direction: row;
  gap: var(--gap-5xs);
}

.date11112021 {
  position: relative;
  font-weight: 600;
  color: var(--grey-10);
}

.div1,
.invoiceNo {
  align-self: stretch;
  position: relative;
}

.div1 {
  font-weight: 600;
  color: var(--grey-10);
}

.date11112021Parent,
.invoiceNoParent {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
}

.invoiceNoParent {
  align-self: stretch;
  gap: var(--gap-12xs);
}

.date11112021Parent {
  gap: var(--gap-9xs);
  text-align: right;
  font-size: var(--capitalised-10-size);
  color: var(--grey-30);
}

.header {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.description {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.merge1,
.right,
.tableTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.right {
  justify-content: flex-end;
  gap: var(--gap-5xl);
  text-align: right;
}

.merge1,
.tableTitle {
  align-self: stretch;
  justify-content: space-between;
}

.tableTitle {
  border-top: 2px solid var(--grey-10);
  border-bottom: 2px solid var(--grey-10);
  flex-direction: column;
  padding: var(--padding-5xs) 0;
  align-items: flex-start;
  justify-content: flex-start;
}

.electricalInsulator {
  position: absolute;
  top: 0;
  left: 0;
  letter-spacing: -0.04em;
  font-weight: 500;
  text-align: left;
}

.div3,
.div4,
.div5,
.div6,
.div7,
.div8,
.div9 {
  position: absolute;
  top: 0;
  left: 54.66%;
  letter-spacing: -0.04em;
  font-weight: 500;
}

.div4,
.div5,
.div6,
.div7,
.div8,
.div9 {
  left: 64.87%;
}

.div5,
.div6,
.div7,
.div8,
.div9 {
  left: 76.52%;
}

.div6,
.div7,
.div8,
.div9 {
  top: 53.85%;
  left: 76.88%;
}

.div7,
.div8,
.div9 {
  top: 0;
  left: 85.13%;
}

.div8,
.div9 {
  top: 53.85%;
  left: 84.77%;
}

.div9 {
  top: 0;
  left: 96.59%;
}

.metrics {
  align-self: stretch;
  position: relative;
  height: 26px;
  overflow: hidden;
  flex-shrink: 0;
}

.quoteRow {
  align-self: stretch;
  border-bottom: 1px solid var(--grey-50);
  box-sizing: border-box;
  height: 50px;
  display: flex;
  flex-direction: column;
  padding: var(--padding-xs) 0;
  align-items: flex-start;
  justify-content: flex-start;
}

.div10,
.div12,
.div16,
.div18,
.div19,
.div21 {
  position: absolute;
  top: 0;
  left: 53.58%;
  letter-spacing: -0.04em;
  font-weight: 500;
}

.div12,
.div16,
.div18,
.div19,
.div21 {
  left: 75.81%;
}

.div16,
.div18,
.div19,
.div21 {
  left: 95.7%;
}

.div18,
.div19,
.div21 {
  left: 63.8%;
}

.div19,
.div21 {
  left: 75.45%;
}

.div21 {
  left: 84.41%;
}

.div24 {
  left: 51.79%;
}

.div24,
.div25,
.div26,
.div28,
.div30 {
  position: absolute;
  top: 0;
  letter-spacing: -0.04em;
  font-weight: 500;
}

.div25 {
  left: 63.8%;
  display: none;
}

.div26,
.div28,
.div30 {
  left: 74.37%;
}

.div28,
.div30 {
  left: 83.15%;
}

.div30 {
  left: 94.27%;
}

.qutoeMaterials {
  text-align: right;
  color: var(--grey-20);
}

.desc,
.qutoeMaterials {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.div31,
.subtotal1 {
  position: relative;
  font-weight: 600;
}

.div31 {
  text-align: right;
}

.subtotalParent {
  width: 267px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.subtotals,
.subtotals2,
.subtotals3 {
  display: flex;
  flex-direction: column;
  padding: var(--padding-5xs) 0;
  align-items: flex-start;
  justify-content: flex-start;
}

.subtotals2 {
  border-bottom: 1px solid var(--grey-50);
}

.subtotals3 {
  color: var(--primary-20base);
}

.subtotal {
  align-self: stretch;
  align-items: flex-end;
}

.descParent,
.headerParent,
.qouteprev,
.subtotal {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.descParent {
  align-self: stretch;
  align-items: flex-end;
  gap: var(--gap-5xs);
  font-size: var(--capitalised-10-size);
}

.headerParent,
.qouteprev {
  align-items: flex-start;
}

.headerParent {
  align-self: stretch;
  gap: var(--gap-base);
}

.qouteprev {
  position: relative;
  width: 100%;
  text-align: left;
  font-size: var(--button-small-text-size);
  color: var(--grey-10);
  font-family: var(--button-small-text);
}

@media screen and (max-width: 430px) {
  .headerParent {
    display: flex;
  }
}