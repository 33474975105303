.navChild {
  position: absolute;
  top: 27px;
  left: 20px;
  width: 125px;
  height: 20px;
}
.iconshome {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.home {
  position: relative;
  line-height: 24px;
  font-weight: 500;
}
.default,
.iconshomeParent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.iconshomeParent {
  flex-direction: row;
  gap: var(--gap-5xs);
}
.default {
  align-self: stretch;
  border-radius: var(--br-11xs);
  overflow: hidden;
  flex-direction: column;
  padding: var(--padding-5xs);
  cursor: pointer;
}
.accountIcon {
  position: relative;
  width: 24px;
  height: 24px;
}
.default5,
.defaultParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.default5 {
  align-self: stretch;
  border-radius: var(--br-9xs);
  background-color: var(--grey-90);
  overflow: hidden;
  padding: var(--padding-5xs);
  color: var(--primary-10);
}
.defaultParent {
  position: absolute;
  top: 87px;
  left: 20px;
  width: 181px;
  gap: var(--gap-xs);
}
.nav {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--grey-100);
}
.navPrototype {
  position: relative;
  width: 221px;
  height: 1325px;
}
.back1 {
  position: absolute;
  top: 5px;
  left: 26px;
  line-height: 155%;
  font-weight: 500;
}
.frameIcon {
  position: absolute;
  top: 0;
  left: 0;
  width: 32px;
  height: 32px;
  overflow: hidden;
}
.back,
.personal {
  position: relative;
}
.back {
  width: 59px;
  height: 32px;
}
.personal {
  font-size: var(--h4-semibold-size);
  font-weight: 600;
  color: var(--grey-900);
}
.titles {
  align-self: stretch;
  background-color: var(--white);
  overflow: hidden;
  display: none;
  flex-direction: row;
  padding: var(--padding-6xs) 0;
  align-items: center;
  justify-content: space-between;
}
.button {
  position: relative;
  line-height: 100%;
  font-weight: 600;
}
.buttonWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.avatarIcon {
  position: absolute;
  top: 14px;
  height: 40px;
}
.smallButtons {
  border-radius: var(--br-9xs);
  background-color: var(--primary-20base);
  width: 146px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  color: var(--white);
  font-size: medium;
}
.avatarIcon {
  left: 1139px;
  width: 40px;
  display: none;
}
.licenseAndCertification {
  position: absolute;
  top: 18px;
  left: 234px;
  font-size: var(--h5-semibold-size);
  line-height: 28px;
  color: var(--grey-900);
  text-align: left;
}
.icon {
  position: absolute;
  top: 14px;
  left: 52px;
  border: 1px solid var(--grey-80);
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs);
  align-items: center;
  justify-content: center;
}
.component102 {
  align-self: stretch;
  position: relative;
  background-color: var(--white);
  border-bottom: 1px solid var(--grey-80);
  box-sizing: border-box;
  height: 68px;
  overflow: hidden;
  flex-shrink: 0;
  color: var(--white);
}
.personalInformation {
  position: relative;
  line-height: 24px;
  font-weight: 600;
}
.label {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 26px;
  font-weight: 500;
}
.labelContainer {
  align-self: stretch;
  position: relative;
  height: 28px;
}
.iconsplaceholder {
  position: absolute;
  top: 12px;
  right: 8px;
  width: 24px;
  height: 24px;
  display: none;
}
.userInput {
  position: absolute;
  width: calc(100% - 16px);
  top: 11px;
  left: 8px;
  line-height: 26px;
  display: inline-block;
}
.dotChild {
  position: relative;
  border-radius: 50%;
  background-color: var(--grey-10);
  width: 8px;
  height: 8px;
}
.dot {
  position: absolute;
  top: 20px;
  left: 12px;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-6xs);
}
.baseField {
  align-self: stretch;
  position: relative;
  border-radius: var(--br-8xs);
  background-color: var(--grey-100);
  border: 1px solid var(--grey-70);
  box-sizing: border-box;
  height: 48px;
  overflow: hidden;
  flex-shrink: 0;
  color: var(--grey-10);
}
.div,
.thisIsA {
  position: absolute;
  top: 0;
  line-height: 24px;
}
.thisIsA {
  left: 0;
}
.div {
  right: 0;
  text-align: right;
}
.helperTextContainer {
  align-self: stretch;
  position: relative;
  height: 24px;
  display: none;
  font-size: var(--p3-semibold-size);
}
.input {
  width: 558px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-11xs);
}
.label1 {
  position: relative;
  line-height: 26px;
}
.tobiasFlagOfTheUnitedKingIcon {
  position: relative;
  width: 20px;
  height: 15px;
  overflow: hidden;
  flex-shrink: 0;
}
.countrySelector {
  position: absolute;
  top: 0;
  left: 0;
  border-right: 1px solid var(--grey-60);
  display: flex;
  flex-direction: row;
  padding: var(--padding-xs) var(--padding-5xs);
  align-items: center;
  justify-content: center;
  gap: var(--gap-9xs);
}
.activeSection {
  position: absolute;
  top: 0;
  left: 63px;
  border-radius: 0 var(--br-8xs) var(--br-8xs) 0;
  background-color: var(--white);
  border: 1.5px solid var(--primary-20base);
  box-sizing: border-box;
  width: 335px;
  height: 48px;
  display: none;
}
.div1 {
  position: absolute;
  top: 10px;
  left: 72px;
  line-height: 26px;
}
.field {
  align-self: stretch;
  position: relative;
  border-radius: var(--br-8xs);
  background-color: var(--grey-100);
  border: 1px solid var(--grey-70);
  box-sizing: border-box;
  height: 48px;
  color: var(--grey-10);
}
.thisIsA1,
.thisIsA2 {
  position: relative;
  line-height: 24px;
}
.thisIsA2 {
  font-weight: 500;
  display: none;
}
.helperText {
  align-self: stretch;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-9xs);
  font-size: var(--p3-semibold-size);
}
.largeInputs {
  width: 558px;
  gap: var(--gap-9xs);
}
.inputParent,
.largeInputs,
.personalInformationParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.inputParent {
  gap: var(--gap-xs);
  font-size: var(--p2-bold-size);
  color: var(--grey-20);
}
.personalInformationParent {
  gap: var(--gap-xl);
}
.companyInformation {
  align-self: stretch;
  position: relative;
  line-height: 24px;
  font-weight: 600;
}
.iconschevronDown1 {
  position: absolute;
  top: 12px;
  right: 8px;
  width: 24px;
  height: 24px;
  overflow: hidden;
}
.jobInformation {
  position: relative;
  line-height: 24px;
  font-weight: 600;
  display: inline-block;
}
.frameParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-13xl);
  width: 100%
}
.frameWrapper {
  background-color: var(--color-gray-100);
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-5xs) var(--padding-21xl);
  align-items: flex-start;
  text-align: left;
  font-size: var(--p1-semibold-size);
  color: var(--grey-0);
}
.frameWrapper,
.personalinfo,
.titlesParent {
  display: flex;
  justify-content: flex-start;
}
.titlesParent {
  flex: 1;
  flex-direction: column;
  align-items: center;
  gap: var(--gap-xs);
  color: var(--grey-800);
  width: 100%;
}
.personalinfo {
  position: relative;
  background-color: var(--color-gray-100);
  width: 100%;
  overflow-y: auto;
  flex-direction: row;
  align-items: flex-start;
  text-align: center;
  font-size: var(--p3-semibold-size);
  color: var(--grey-10);
  font-family: var(--p3-bold);
}
@media screen and (max-width: 960px) {
  .navPrototype {
    display: none;
  }
  .titles {
    display: flex;
  }
  .component102 {
    display: none;
  }
  .companyInformation,
  .input,
  .inputParent,
  .largeInputs,
  .personalInformationParent {
    align-self: stretch;
    width: auto;
  }
  .frameParent {
    flex: 1;
    
  }
  .frameWrapper {
    align-self: stretch;
    width: auto;
  }
}
@media screen and (max-width: 430px) {
  .titles {
    display: flex;
  }
}
