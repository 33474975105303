.useMyCurrent {
    position: relative;
    text-decoration: underline;
    line-height: 28px;
  }
  .iconslocationParent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-9xs);
    color: var(--logg-green);
  }