.navChild {
  position: absolute;
  top: 27px;
  left: 20px;
  width: 125px;
  height: 20px;
}
.iconshome {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.home {
  position: relative;
  line-height: 24px;
  font-weight: 500;
}
.default,
.iconshomeParent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.iconshomeParent {
  flex-direction: row;
  gap: var(--gap-5xs);
}
.default {
  align-self: stretch;
  border-radius: var(--br-11xs);
  overflow: hidden;
  flex-direction: column;
  padding: var(--padding-5xs);
  cursor: pointer;
}
.accountIcon {
  position: relative;
  width: 24px;
  height: 24px;
}
.home5 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
}
.default5,
.defaultParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.default5 {
  align-self: stretch;
  border-radius: var(--br-9xs);
  background-color: var(--grey-90);
  overflow: hidden;
  padding: var(--padding-5xs);
  color: var(--primary-10);
}
.defaultParent {
  position: absolute;
  top: 87px;
  left: 20px;
  width: 181px;
  gap: var(--gap-xs);
}
.nav {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--grey-50);
}
.navPrototype {
  position: relative;
  width: 221px;
  height: 1325px;
}
.back1 {
  position: absolute;
  top: 5px;
  left: 26px;
  line-height: 155%;
  font-weight: 500;
}
.frameIcon {
  position: absolute;
  top: 0;
  left: 0;
  width: 32px;
  height: 32px;
  overflow: hidden;
}
.back {
  position: relative;
  width: 59px;
  height: 32px;
}
.activities {
  position: relative;
  font-size: var(--h4-bold-size);
  font-weight: 600;
  color: var(--grey-900);
}
.icon,
.titles {
  flex-direction: row;
  align-items: center;
}
.titles {
  align-self: stretch;
  background-color: var(--white);
  overflow: hidden;
  display: none;
  padding: 7px 0;
  justify-content: flex-start;
  gap: 160px;
  font-size: var(--p3-semibold-size);
}
.icon {
  border: 1px solid var(--grey-80);
  display: flex;
  padding: var(--padding-5xs);
  justify-content: center;
}
.licenseAndCertification {
  position: relative;
  line-height: 28px;
}
.iconsadd {
  position: relative;
  width: 16px;
  height: 16px;
  overflow: hidden;
  flex-shrink: 0;
}
.button {
  position: relative;
  line-height: 100%;
  font-weight: 600;
}
.iconsaddParent,
.smallButtons {
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.iconsaddParent {
  display: flex;
  gap: var(--gap-9xs);
}
.smallButtons {
  border-radius: var(--br-9xs);
  background-color: var(--logg-green);
  width: 146px;
  height: 40px;
  display: none;
  padding: var(--padding-5xs) var(--padding-xl);
  box-sizing: border-box;
  text-align: center;
  font-size: var(--p3-semibold-size);
  color: var(--white);
}
.avatarIcon {
  position: relative;
  width: 40px;
  height: 40px;
  display: none;
}
.component102 {
  align-self: stretch;
  background-color: var(--white);
  border-bottom: 1px solid var(--grey-80);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: var(--padding-sm) var(--padding-33xl);
  align-items: center;
  justify-content: flex-start;
  gap: 150px;
  text-align: left;
  font-size: var(--h5-semibold-size);
  color: var(--grey-900);
}
.tab1 {
  position: relative;
  line-height: 26px;
  font-weight: 600;
}
.tab1Wrapper,
.tab2Wrapper,
.tab3Wrapper {
  position: absolute;
  width: 32.8%;
  top: 4px;
  border-radius: var(--br-9xs);
  height: 44px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-9xs) var(--padding-5xs);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.tab1Wrapper {
  right: 66.49%;
  left: 0.72%;
  background-color: var(--white);
  color: var(--logg-green);
}
.tab2Wrapper,
.tab3Wrapper {
  right: 33.69%;
  left: 33.51%;
}
.tab3Wrapper {
  width: 32.97%;
  right: 0.72%;
  left: 66.31%;
}
.twoTabFlatSwitch {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-8xs);
  background-color: var(--grey-90);
}
.promotionsTabPrototype {
  position: relative;
  width: 558px;
  height: 52px;
}
.promotionsTabPrototypeWrapper {
  background-color: var(--color-gray-100);
  display: flex;
  flex-direction: column;
  padding: var(--padding-11xs) var(--padding-215xl);
  align-items: center;
  justify-content: flex-start;
  color: var(--grey-30);
  width: 100%;
}
.label {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 26px;
  font-weight: 500;
}
.labelContainer {
  align-self: stretch;
  position: relative;
  height: 28px;
}
.iconschevronDown {
  position: absolute;
  top: 12px;
  right: 8px;
  width: 24px;
  height: 24px;
  overflow: hidden;
}
.userInput {
  position: absolute;
  width: calc(100% - 16px);
  top: 11px;
  left: 8px;
  line-height: 26px;
  display: inline-block;
}
.dotChild {
  position: relative;
  border-radius: 50%;
  background-color: var(--grey-10);
  width: 8px;
  height: 8px;
}
.dot {
  position: absolute;
  top: 20px;
  left: 12px;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-6xs);
}
.baseField {
  align-self: stretch;
  position: relative;
  border-radius: var(--br-8xs);
  background-color: var(--grey-50);
  border: 1px solid var(--grey-70);
  box-sizing: border-box;
  height: 48px;
  overflow: hidden;
  flex-shrink: 0;
  color: var(--grey-10);
}
.div,
.thisIsA {
  position: absolute;
  top: 0;
  line-height: 24px;
}
.thisIsA {
  left: 0;
}
.div {
  right: 0;
  text-align: right;
}
.helperTextContainer {
  align-self: stretch;
  position: relative;
  height: 24px;
  display: none;
  font-size: var(--p3-semibold-size);
}
.input {
  width: 558px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-11xs);
}
.boundary {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-8xs);
  background-color: var(--grey-80);
  border: 1px dashed var(--grey-60);
  box-sizing: border-box;
}
.imageArea {
  position: absolute;
  height: 98%;
  width: calc(100% - 2px);
  top: 1%;
  right: 1px;
  bottom: 1%;
  left: 1px;
  border-radius: var(--br-9xs);
  background-color: var(--grey-90);
}
.iconsadd1 {
  position: absolute;
  top: calc(50% - 12px);
  left: calc(50% - 12px);
  width: 24px;
  height: 24px;
  overflow: hidden;
}
.imageFrame {
  align-self: stretch;
  flex: 1;
  position: relative;
  z-index: 0;
}
.addcancelCircleIcon {
  position: absolute;
  margin: 0 !important;
  right: -11px;
  bottom: -7px;
  width: 24px;
  height: 24px;
  display: none;
  z-index: 1;
}
.uploadImage {
  width: 558px;
  height: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  cursor: pointer;
}
.uploadAnImage {
  position: relative;
  line-height: 24px;
}
.uploadImageParent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
  text-align: center;
  font-size: var(--p3-semibold-size);
}
.reach {
  position: relative;
  line-height: 26px;
  font-weight: 500;
}
.bg,
.bgChild {
  align-self: stretch;
}
.bgChild {
  position: relative;
  border-radius: var(--br-xl);
  background-color: var(--grey-80);
  height: 6px;
}
.bg {
  height: 16px;
  display: flex;
  flex-direction: column;
  padding: var(--padding-5xs) 0;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: center;
  z-index: 0;
}
.sliderChild,
.sliderItem {
  position: relative;
  background-color: var(--grey-10);
}
.sliderChild {
  flex: 1;
  border-radius: var(--br-xl);
  height: 6px;
}
.sliderItem {
  border-radius: 50%;
  box-shadow: var(--elevation-2);
  width: 15.78px;
  height: 16px;
  margin-left: -16px;
}
.bgParent,
.slider {
  display: flex;
  justify-content: flex-start;
}
.slider {
  margin: 0 !important;
  position: absolute;
  top: 0;
  left: 0;
  width: 16px;
  flex-direction: row;
  align-items: center;
  z-index: 1;
}
.bgParent {
  width: 558px;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
}
.labelContainer1 {
  align-self: stretch;
  position: relative;
  height: 28px;
  display: none;
  font-size: var(--p2-semibold-size);
}
.iconsplaceholder {
  position: absolute;
  top: 12px;
  right: 8px;
  width: 24px;
  height: 24px;
  display: none;
}
.userInput1 {
  position: absolute;
  width: calc(100% - 16px);
  top: 11px;
  left: 8px;
  line-height: 14.5px;
  display: inline-block;
}
.baseField1 {
  align-self: stretch;
  flex: 1;
  position: relative;
  border-radius: var(--br-8xs);
  background-color: var(--grey-50);
  border: 1px solid var(--grey-70);
  overflow: hidden;
  color: var(--grey-10);
}
.thisIsA1 {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 24px;
  display: none;
}
.helperTextContainer1 {
  align-self: stretch;
  position: relative;
  height: 24px;
  display: none;
}
.input1 {
  width: 109px;
  height: 35px;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-11xs);
  color: var(--grey-20);
}
.distanceSlider,
.input1,
.noOfPeopleParent {
  display: flex;
  justify-content: flex-start;
}
.noOfPeopleParent {
  flex-direction: row;
  align-items: center;
  gap: var(--gap-xs);
  font-size: var(--p3-semibold-size);
  color: var(--grey-10);
}
.distanceSlider {
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-5xs);
}
.baseField2 {
  align-self: stretch;
  position: relative;
  border-radius: var(--br-8xs);
  background-color: var(--grey-50);
  border: 1px solid var(--grey-70);
  box-sizing: border-box;
  height: 48px;
  overflow: hidden;
  flex-shrink: 0;
  color: var(--grey-40);
}
.input2 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-11xs);
}
.div3 {
  position: absolute;
  top: 0;
  right: 0;
  line-height: 24px;
  text-align: right;
  display: none;
}
.helperTextContainer3 {
  align-self: stretch;
  position: relative;
  height: 24px;
  font-size: var(--p3-semibold-size);
}
.userInput4 {
  position: absolute;
  width: calc(100% - 16px);
  top: 11px;
  left: 8px;
  line-height: 26px;
  display: none;
}
.input4,
.inputGroup,
.inputParent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.input4 {
  width: 100%;
  flex-direction: column;
  gap: var(--gap-11xs);
}
.inputGroup{
  width:100%
}
.inputGroup,
.inputParent {
  flex-direction: row;
  gap: var(--gap-5xl);
}
.inputParent {
  flex-direction: column;
  gap: var(--gap-base);
}
.icons20pxplaceholder {
  position: relative;
  width: 20px;
  height: 20px;
  display: none;
}
.largeButtons {
  border-radius: var(--br-8xs);
  background-color: var(--white);
  border: 1px solid var(--logg-green);
  box-sizing: border-box;
  width: 558px;
  height: 48px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-158xl);
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
}
.previewBeforePromotion {
  position: relative;
  line-height: 24px;
  color: var(--grey-20);
}
.largeButtons1,
.largeButtonsParent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.largeButtons1 {
  border-radius: var(--br-8xs);
  background-color: var(--logg-green);
  width: 558px;
  height: 48px;
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-158xl);
  box-sizing: border-box;
  justify-content: center;
  gap: var(--gap-5xs);
  color: white;
}
.frameParent {
  display: flex;
  justify-content: flex-start;
  text-align: center;
  font-size: var(--p3-semibold-size);
  color: var(--logg-green);
}
.forms,
.frameParent {
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-5xl);
}
.forms,
.formsWrapper,
.titlesParent {
  display: flex;
  justify-content: flex-start;
}
.formsWrapper {
  background-color: var(--color-gray-100);
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-5xs) var(--padding-21xl);
  align-items: flex-start;
  text-align: left;
  color: var(--grey-20);
}
.titlesParent {
  flex: 1;
  flex-direction: column;
  align-items: center;
  gap: var(--gap-12xs);
  font-size: var(--p2-semibold-size);
  color: var(--grey-800);
}
.offerpromo1 {
  flex: 1;
  background-color: var(--color-gray-100);
  overflow-y: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}
.activities1 {
  position: absolute;
  top: 17px;
  left: 143px;
  font-size: var(--h4-bold-size);
  font-weight: 600;
}
.back2 {
  position: absolute;
  top: 12px;
  left: 9px;
  width: 59px;
  height: 32px;
  color: var(--grey-800);
}
.edit2Icon {
  position: relative;
  width: 12px;
  height: 12px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.edit {
  position: relative;
  line-height: 155%;
  font-weight: 600;
}
.button1 {
  position: absolute;
  top: 12px;
  left: 328px;
  border-radius: var(--br-9xs);
  background-color: var(--white);
  border: 1px solid var(--logg-green);
  box-sizing: border-box;
  width: 66px;
  display: none;
  flex-direction: column;
  padding: var(--padding-8xs) var(--padding-xs);
  align-items: center;
  justify-content: center;
  text-align: left;
  color: var(--logg-green);
}
.new,
.titles1 {
  position: relative;
}
.titles1 {
  background-color: var(--white);
  width: 414px;
  height: 56px;
  overflow: hidden;
  flex-shrink: 0;
  font-size: var(--p3-semibold-size);
  color: var(--grey-900);
}
.new {
  letter-spacing: -0.03em;
  font-weight: 600;
}
.newWrapper {
  border-radius: var(--br-9xs);
  background-color: var(--white);
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.05);
  width: 123px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-7xs) var(--padding-59xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  color: var(--logg-green);
}
.current {
  position: relative;
  letter-spacing: -0.03em;
}
.currentWrapper,
.frameGroup {
  display: flex;
  flex-direction: row;
}
.currentWrapper {
  border-radius: var(--br-9xs);
  width: 123px;
  padding: var(--padding-7xs) var(--padding-59xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.frameGroup {
  align-self: stretch;
  align-items: flex-start;
  justify-content: flex-start;
}
.inputChild,
.tab {
  background-color: var(--grey-50);
}
.tab {
  align-self: stretch;
  height: 47px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: var(--p2-medium-size);
  color: var(--gray-2);
}
.inputChild {
  position: absolute;
  height: 64.86%;
  width: 100%;
  top: 35.14%;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-9xs);
  border: 1px solid var(--grey-70);
  box-sizing: border-box;
}
.jackDorsey,
.selectOption {
  left: 2.14%;
  line-height: 155%;
  font-weight: 500;
}
.selectOption {
  font-size: var(--p3-semibold-size);
  color: var(--grey-600);
}
.chevronUpIcon {
  position: absolute;
  height: 27.03%;
  width: 5.35%;
  top: 81.08%;
  right: -2.14%;
  bottom: -8.11%;
  left: 96.79%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.input6 {
  position: relative;
  width: 100%;
  text-align: left;
}
.frameChild {
  position: absolute;
  height: 72.88%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 27.12%;
  left: 0;
  border-radius: var(--br-5xs);
  background-color: var(--white);
  border: 1px dashed var(--gray-4);
  box-sizing: border-box;
}
.uploadIcon {
  position: absolute;
  height: 47.06%;
  width: 36.36%;
  top: 0;
  right: 34.85%;
  bottom: 52.94%;
  left: 28.79%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.x130 {
  position: absolute;
  top: 56.86%;
  left: 0;
  line-height: 155%;
  font-weight: 500;
}
.uploadParent {
  position: absolute;
  height: 43.22%;
  width: 17.65%;
  top: 16.95%;
  right: 40.91%;
  bottom: 39.83%;
  left: 41.44%;
}
.uploadAnImage1 {
  position: absolute;
  top: 96px;
  left: 62px;
  line-height: 155%;
  font-weight: 500;
  display: inline-block;
  width: 250px;
}
.rectangleParent {
  position: relative;
  width: 100vw;
  height: 118px;
  overflow: hidden;
  flex-shrink: 0;
  font-size: var(--p3-semibold-size);
  color: var(--grey-700);
}
.emailChild {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-9xs);
  background-color: var(--grey-50);
  border: 1px solid var(--grey-70);
  box-sizing: border-box;
}
.jackDorsey1 {
  position: absolute;
  top: 25%;
  left: 2.14%;
  line-height: 155%;
  font-weight: 500;
  display: none;
}
.email {
  position: absolute;
  height: 64.86%;
  width: 100%;
  top: 35.14%;
  right: 0;
  bottom: 0;
  left: 0;
}
.jackDorsey2 {
  position: absolute;
  top: 22.92%;
  left: 4.42%;
  line-height: 155%;
  font-weight: 500;
  display: none;
}
.chevronUpIcon1,
.icondeviceaccessTime {
  position: absolute;
  height: 27.03%;
  width: 11.05%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.icondeviceaccessTime {
  top: 54.05%;
  right: 14.92%;
  bottom: 18.92%;
  left: 74.03%;
  display: none;
}
.chevronUpIcon1 {
  top: 81.08%;
  right: -4.42%;
  bottom: -8.11%;
  left: 93.37%;
}
.input8 {
  position: relative;
  width: 100%;
  height: 74px;
}
.div6 {
  position: absolute;
  top: 22.92%;
  left: 85.64%;
  line-height: 155%;
  font-weight: 600;
}
.inputContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-2xs);
  text-align: left;
}
.jackDorsey5 {
  position: absolute;
  top: 25%;
  left: 4.42%;
  line-height: 155%;
  font-weight: 500;
  display: none;
}
.calendarIcon {
  position: absolute;
  height: 27.03%;
  width: 11.05%;
  top: 54.05%;
  right: 6.63%;
  bottom: 18.92%;
  left: 82.32%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.frameDiv {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xs);
  text-align: left;
  width: 100%;
}
.icons,
.proceed {
  position: relative;
}
.icons {
  width: 20px;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.proceed {
  line-height: 155%;
}
.button2,
.iconsParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.iconsParent {
  gap: var(--gap-5xs);
}
.button2 {
  border-radius: var(--br-9xs);
  background-color: var(--white);
  border: 1px solid var(--logg-green);
  box-sizing: border-box;
  width: 100%;
  padding: var(--padding-xs) 0 var(--padding-smi);
  font-size: var(--p2-medium-size);
  color: var(--logg-green);
}
.previewBeforePromoting {
  position: relative;
  font-size: var(--p3-semibold-size);
  line-height: 155%;
  font-weight: 500;
  color: var(--grey-700);
}
.button3 {
  border-radius: var(--br-9xs);
  background-color: var(--logg-green);
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: var(--padding-xs) 0 var(--padding-smi);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  font-size: var(--p2-medium-size);
  color: white;
}
.offerpromo,
.signup {
  justify-content: flex-start;
}
.signup {
  flex: 1;
  background-color: var(--white);
  overflow: hidden;
  display: none;
  flex-direction: column;
  align-items: center;
  gap: var(--gap-base);
  font-size: var(--p2-semibold-size);
  color: var(--grey-800);
}
.offerpromo {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: var(--gap-3xs);
  text-align: center;
  font-size: var(--p3-semibold-size);
  color: var(--grey-10);
  font-family: var(--caption-bold);
}
@media screen and (max-width: 960px) {
  .navPrototype {
    display: none;
  }
  .titles {
    display: flex;
  }
  .component102 {
    display: none;
  }
  .titlesParent {
    flex: 1;
    width: 100%;
  }
  .offerpromo {
    align-self: unset;
    width: 100%;
  }
}
@media screen and (max-width: 430px) {
  .offerpromo1,
  .titles {
    display: none;
  }
  .signup {
    display: flex;
  }
}
