.navChild {
  position: absolute;
  top: 27px;
  left: 20px;
  width: 125px;
  height: 20px;
}

.iconshome {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}

.home {
  position: relative;
  font-size: var(--p3-bold-size);
  line-height: 24px;
  font-weight: 600;
  font-family: var(--p3-bold);
  color: var(--primary-10);
  text-align: center;
}

.default,
.iconshomeParent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.iconshomeParent {
  flex-direction: row;
  gap: var(--gap-5xs);
  width: 100%;
}

.default {
  cursor: pointer;
  border: 0;
  padding: var(--padding-5xs);
  background-color: var(--grey-90);
  border-radius: var(--br-9xs);
  width: 181px;
  overflow: hidden;
  flex-direction: column;
  box-sizing: border-box;
}

.home1 {
  position: relative;
  font-size: var(--p3-bold-size);
  line-height: 24px;
  font-weight: 500;
  font-family: var(--p3-bold);
  color: var(--grey-10);
  text-align: center;
}

.default1 {
  cursor: pointer;
  border: 0;
  padding: var(--padding-5xs);
  background-color: transparent;
  border-radius: var(--br-11xs);
  width: 181px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
}

.accountIcon {
  position: relative;
  width: 24px;
  height: 24px;
}

.defaultParent {
  position: absolute;
  top: 87px;
  left: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xs);
}

.nav {
  position: relative;
  background-color: var(--grey-100);
  width: 221px;
  height: 100vh;
}

.back1 {
  position: absolute;
  top: 5px;
  left: 26px;
  line-height: 155%;
  font-weight: 500;
}

.frameIcon {
  position: absolute;
  top: 0;
  left: 0;
  width: 32px;
  height: 32px;
  overflow: hidden;
}

.back {
  position: relative;
  width: 59px;
  height: 32px;
}

.activities {
  position: relative;
  font-size: var(--h4-semibold-size);
  font-weight: 600;
  color: var(--grey-900);
}

.edit2Icon {
  position: relative;
  width: 12px;
  height: 12px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}

.edit {
  position: relative;
  line-height: 155%;
  font-weight: 600;
}

.edit2Parent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-9xs);
}

.button,
.titles {
  background-color: var(--white);
  display: none;
  align-items: center;
}

.button {
  border-radius: var(--br-9xs);
  border: 1px solid var(--primary-20base);
  box-sizing: border-box;
  width: 66px;
  flex-direction: column;
  padding: var(--padding-8xs) var(--padding-xs);
  justify-content: center;
  text-align: left;
  color: var(--primary-20base);
}

.titles {
  align-self: stretch;
  overflow: hidden;
  flex-direction: row;
  padding: var(--padding-xs) var(--padding-4xs);
  justify-content: space-between;
}

.iconsadd {
  position: relative;
  width: 16px;
  height: 16px;
  overflow: hidden;
  flex-shrink: 0;
}

.button1 {
  position: relative;
  line-height: 100%;
  font-weight: 600;
}

.avatarIcon,
.smallButtons {
  position: absolute;
  top: 14px;
  height: 40px;
  display: none;
}

.smallButtons {
  right: 427px;
  border-radius: var(--br-9xs);
  background-color: var(--primary-20base);
  width: 146px;
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}

.avatarIcon {
  left: 1139px;
  width: 40px;
}

.licenseAndCertification {
  position: absolute;
  top: 18px;
  left: 234px;
  font-size: var(--h5-semibold-size);
  line-height: 28px;
  color: var(--grey-900);
  text-align: left;
}

.icon {
  position: absolute;
  top: 14px;
  left: 52px;
  border: 1px solid var(--grey-80);
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs);
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.component102,
.quoteChild {
  background-color: var(--white);
}

.component102 {
  align-self: stretch;
  position: relative;
  border-bottom: 1px solid var(--grey-80);
  box-sizing: border-box;
  height: 68px;
  overflow: hidden;
  flex-shrink: 0;
  color: var(--white);
}

.quoteChild {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.tabChild,
.tabItem {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-9xs);
  background-color: var(--color-whitesmoke-100);
  width: 374px;
  height: 36px;
}

.tabItem {
  top: 3px;
  left: 3px;
  background-color: var(--white);
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.05);
  width: 184px;
  height: 30px;
}

.edit1 {
  top: 9px;
  left: 81px;
  letter-spacing: -0.03em;
  font-weight: 600;
}

.edit1,
.preview,
.tab {
  position: absolute;
}

.preview {
  top: 9px;
  left: 254px;
  letter-spacing: -0.03em;
  color: var(--gray-2);
}

.tab {
  height: 81.82%;
  width: 90.34%;
  top: 9.09%;
  right: 4.83%;
  bottom: 9.09%;
  left: 4.83%;
  overflow: hidden;
}

.quote,
.tab1 {
  position: relative;
}

.quote {
  align-self: stretch;
  height: 44px;
  display: none;
  font-size: var(--p2-bold-size);
}

.tab1 {
  line-height: 26px;
  font-weight: 600;
}

.tab1Wrapper {
  position: absolute;
  width: 49.28%;
  top: 4px;
  right: 50%;
  left: 0.72%;
  border-radius: var(--br-9xs);
  height: 44px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-9xs) var(--padding-5xs);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}

.tab2 {
  position: relative;
  line-height: 26px;
  font-weight: 500;
}

.tab2Wrapper {
  position: absolute;
  width: 49.28%;
  top: 4px;
  right: 0.72%;
  left: 50%;
  border-radius: var(--br-9xs);
  height: 44px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-9xs) var(--padding-5xs);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  color: var(--grey-30);
}

.twoTabSwitch {
  position: relative;
  border-radius: var(--br-8xs);
  background-color: var(--grey-90);
  width: 558px;
  height: 52px;
}

.twoTabSwitchWrapper {
  background-color: var(--color-gray-100);
  height: 78px;
  display: flex;
  flex-direction: column;
  padding: var(--padding-8xs);
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-end;
}

.qn0001,
.quoteNumber {
  position: relative;
  line-height: 26px;
}

.qn0001 {
  text-align: right;
}

.quoteCards {
  align-self: stretch;
  background-color: var(--white);
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs);
  align-items: flex-start;
  justify-content: space-between;
}

.qn00012 {
  position: relative;
  line-height: 26px;
  color: var(--grey-50);
  text-align: right;
}

.quoteCards2 {
  align-self: stretch;
  background-color: var(--white);
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs);
  align-items: flex-start;
  justify-content: space-between;
  cursor: pointer;
}

.success {
  position: relative;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  font-weight: 600;
}

.transactionPills {
  border-radius: var(--br-81xl);
  background-color: var(--green-100);
  display: flex;
  flex-direction: row;
  padding: var(--padding-9xs) var(--padding-7xs);
  align-items: center;
  justify-content: flex-end;
  text-align: right;
  font-size: var(--font-size-5xs);
  color: var(--primary-20base);
}

.quoteInformation {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-12xs);
}

.customerInformation1 {
  position: absolute;
  top: calc(50% - 14px);
  left: 8px;
  line-height: 26px;
  font-weight: 600;
}

.quoteCards4 {
  align-self: stretch;
  position: relative;
  background-color: var(--grey-90);
  height: 52px;
}

.input {
  position: relative;
  line-height: 26px;
  color: var(--grey-50);
  width: 100%;
}

.labelParent {
  position: absolute;
  top: 8px;
  left: 8px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
  width: 100%;
}

.iconsplaceholder {
  position: absolute;
  top: 10px;
  right: 12px;
  width: 24px;
  height: 24px;
  display: none;
}

.quoteInputs,
.quoteInputs1 {
  align-self: stretch;
  position: relative;
}

.quoteInputs1 {
  background-color: var(--white);
  border-bottom: 1px solid var(--grey-80);
  box-sizing: border-box;
  height: 45px;
}

.labels {
  gap: var(--gap-9xs);
}

.customerInfo,
.labels {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  color:black
}

.icons20pxplaceholder {
  position: relative;
  width: 20px;
  height: 20px;
  display: none;
}

.label2 {
  position: relative;
  font-size: var(--p3-bold-size);
  line-height: 24px;
  font-family: var(--p3-bold);
  color: var(--primary-20base);
  text-align: center;
}

.largeButtons {
  cursor: pointer;
  border: 1px solid var(--primary-20base);
  background-color: var(--white);
  align-self: stretch;
  border-radius: var(--br-8xs);
  box-sizing: border-box;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
}

.customerInformation,
.quoteInputs2,
.quoteInputs4 {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
}

.customerInformation {
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-base);
}

.quoteInputs2,
.quoteInputs4 {
  background-color: var(--white);
  border-bottom: 1px solid var(--grey-80);
  flex-direction: row;
  padding: var(--padding-5xs);
  justify-content: space-between;
}

.quoteInputs4 {
  flex-direction: column;
  justify-content: flex-start;
}

.giveDetailsAbout {
  align-self: stretch;
  position: relative;
  line-height: 26px;
  color: var(--grey-50);
}

.quoteInputs6 {
  align-self: stretch;
  background-color: var(--white);
  border-bottom: 1px solid var(--grey-80);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: var(--padding-9xs) var(--padding-5xs);
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-11xs);
}

.quoteNumber4 {
  position: relative;
  font-weight: 500;
  display: inline-block;
  flex-shrink: 0;
}

.quoteNumber4,
.span {
  line-height: 26px;
}

.x {
  font-size: var(--caption-regular-size);
  line-height: 20px;
}

.x10 {
  position: relative;
  text-align: right;
  display: inline-block;
  width: 163px;
  flex-shrink: 0;
}

.quoteNumberParent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.div,
.kvPinInsulators {
  position: relative;
  line-height: 20px;
  display: inline-block;
  flex-shrink: 0;
}

.div {
  font-size: var(--p2-semibold-size);
  line-height: 26px;
  font-weight: 500;
  color: var(--grey-10);
  text-align: right;
  width: 127px;
}

.kvPinInsulatorsParent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.div1 {
  position: relative;
  line-height: 20px;
  text-align: right;
  display: inline-block;
  width: 127px;
  flex-shrink: 0;
}

.frameGroup {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-11xs);
  font-size: var(--caption-regular-size);
}

.qn00013,
.qn00016 {
  position: relative;
  line-height: 26px;
  font-weight: 600;
  text-align: right;
}

.qn00016 {
  color: var(--primary-20base);
}

.youCanOnly {
  flex: 1;
  position: relative;
  line-height: 24px;
}

.inAppWarning {
  align-self: stretch;
  border-radius: var(--br-5xs);
  background-color: var(--tertiary-90);
  border: 1px solid var(--tertiary-80);
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-xs);
  align-items: flex-start;
  justify-content: flex-start;
  text-align: center;
  font-size: var(--p3-bold-size);
  color: var(--tertiary-0);
}

.qn0001Parent {
  height: 58px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  text-align: right;
}

.advancedPayment,
.quoteInformationParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}

.quoteInformationParent {
  gap: var(--gap-xs);
}

.label10 {
  position: relative;
  font-size: var(--p3-bold-size);
  line-height: 24px;
  font-family: var(--p3-bold);
  color: white;
  text-align: center;
}

.largeButtons2,
.largeButtonsParent {
  align-self: stretch;
  gap: var(--gap-5xs);
}

.largeButtons2 {
  cursor: pointer;
  border: 0;
  background-color: var(--primary-20base);
  border-radius: var(--br-8xs);
  height: 48px;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}

.largeButtonsParent {
  padding: 0 0 var(--padding-37xl);
  align-items: flex-start;
}

.largeButtonsParent,
.quoteForm,
.quoteParent,
.titlesParent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.quoteForm {
  align-self: stretch;
  color: var(--grey-20);
  padding: 10px;
}

.quoteParent,
.titlesParent {
  align-items: center;
}

.quoteParent {
  font-size: var(--p2-semibold-size);
  color: var(--primary-20base);
}

.titlesParent {
  align-self: stretch;
  flex: 1;
  height: 100vh;
  overflow-y: auto;
}

.createquote,
.createquote1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.createquote1 {
  align-self: stretch;
  background-color: var(--color-gray-100);
  overflow-y: auto;
  flex-direction: row;
}

.createquote {
  position: relative;
  width: 100%;
  flex-direction: column;
  text-align: center;
  font-size: var(--p3-bold-size);
  color: var(--grey-800);
  font-family: var(--p3-bold);
}

@media screen and (max-width: 960px) {
  .nav {
    display: none;
  }

  .titles {
    display: flex;
  }

  .component102 {
    display: none;
  }

  .createquote1 {
    display: flex;
  }

  .createquote {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}

@media screen and (max-width: 430px) {
  .quote {
    display: none;
  }

  .twoTabSwitchWrapper {
    width: 90%;
  }
  .twoTabSwitch{
    position: relative;
      border-radius: var(--br-8xs);
      background-color: var(--grey-90);
      width: 100%;
      height: 52px;
  }

  .quoteParent {
    align-self: stretch;
    width: auto;
  }

  .createquote,
  .createquote1 {
    display: flex;
  }
}