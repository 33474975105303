.logoChild,
.logoItem {
  position: relative;
  width: 26.7px;
  height: 32px;
}
.logoItem {
  width: 159.96px;
  height: 25.92px;
}
.logo {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-2xs);
}
.resetPassword {
  position: relative;
  letter-spacing: -0.02em;
  line-height: 36px;
}
.pleaseEnterYour {
  position: relative;
  font-size: var(--p2-semibold-size);
  line-height: 26px;
  color: var(--grey-20);
  display: inline-block;
  width: 398px;
}
.resetPasswordParent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.verifyFormChild {
  border: 0;
  background-color: transparent;
}
.icons20pxplaceholder {
  position: relative;
  width: 20px;
  height: 20px;
  display: none;
}
.label {
  position: relative;
  font-size: var(--p3-semibold-size);
  line-height: 24px;
  font-family: var(--caption-bold);
  color: var(--white);
  text-align: center;
}
.largeButtons,
.verifyForm {
  display: flex;
  align-items: center;
  justify-content: center;
}
.largeButtons {
  cursor: pointer;
  border: 0;
  padding: var(--padding-5xs) 0;
  background-color: var(--logg-green);
  border-radius: var(--br-8xs);
  width: 398px;
  height: 48px;
  flex-direction: row;
  box-sizing: border-box;
  gap: var(--gap-5xs);
}
.verifyForm {
  flex-direction: column;
  gap: var(--gap-xl);
}
.frameGroup,
.frameWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.frameGroup {
  align-items: center;
  gap: var(--gap-13xl);
}
.frameWrapper {
  border-radius: var(--br-5xs);
  background-color: var(--white);
  box-shadow: var(--card);
  padding: var(--padding-xl);
  align-items: flex-start;
}
.backToLogin {
  position: relative;
  font-size: var(--caption-bold-size);
  text-decoration: underline;
  line-height: 20px;
  font-weight: 500;
  color: var(--logg-green);
  display: inline-block;
  width: 398px;
  cursor: pointer;
}
.frameParent {
  display: flex;
  flex-direction: column;
  padding: var(--padding-3xs);
  align-items: center;
  justify-content: center;
  gap: var(--gap-xl);
}
.privacyPolicy {
  position: relative;
  font-size: var(--caption-bold-size);
  line-height: 20px;
  font-weight: 500;
  color: var(--grey-10);
}
.resetpassword {
  position: relative;
  background-color: var(--white);
  width: 100%;
  height: 808px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: var(--padding-xl) 0;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  font-size: var(--h4-bold1-size);
  color: var(--grey-900);
  font-family: var(--caption-bold);
}
