.addANew {
  position: relative;
  line-height: 28px;
  font-weight: 600;
}
.iconsclose {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
  cursor: pointer;
}
.addANewCardParent,
.modalTitle {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.modalTitle {
  overflow: hidden;
  flex-direction: column;
  padding: var(--padding-base) var(--padding-13xl);
  justify-content: flex-start;
}
.label {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 26px;
  font-weight: 500;
}
.baseField,
.labelContainer {
  align-self: stretch;
  position: relative;
}
.labelContainer {
  height: 28px;
}
.baseField {
  border: 0;
  background-color: transparent;
}
.div,
.thisIsA {
  position: absolute;
  top: 0;
  line-height: 24px;
}
.thisIsA {
  left: 0;
}
.div {
  right: 0;
  text-align: right;
}
.helperTextContainer {
  align-self: stretch;
  position: relative;
  height: 24px;
  display: none;
  font-size: var(--p3-medium-size);
}
.input {
  align-self: stretch;
  flex-direction: column;
  gap: var(--gap-11xs);
}
.frameParent,
.input,
.input2,
.inputGroup {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.input2 {
  flex: 1;
  flex-direction: column;
  gap: var(--gap-11xs);
}
.frameParent,
.inputGroup {
  align-self: stretch;
  flex-direction: row;
  gap: var(--gap-xl);
  flex-wrap: wrap;
}
.frameParent {
  flex-direction: column;
  gap: var(--gap-9xs);
}
.icons20pxplaceholder {
  position: relative;
  width: 20px;
  height: 20px;
  display: none;
}
.label4 {
  position: relative;
  line-height: 24px;
}
.largeButtons {
  align-self: stretch;
  border-radius: var(--br-8xs);
  background-color: var(--primary-10);
  height: 48px;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
  cursor: pointer;
  text-align: center;
  font-size: var(--p3-medium-size);
  color: var(--white);
}
.inputParent,
.modalTitleParent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.inputParent {
  width: 397px;
  align-items: flex-start;
  gap: var(--gap-5xl);
  font-size: var(--p2-bold-size);
  color: var(--grey-20);
}
.modalTitleParent {
  flex: 1;
  align-items: center;
  gap: var(--gap-base);
}
.educationmodal {
  position: relative;
  border-radius: var(--br-8xs);
  background-color: var(--white);
  box-shadow: var(--popup);
  width: 461px;
  display: flex;
  flex-direction: row;
  padding: 0 0 var(--padding-13xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
  text-align: left;
  font-size: var(--h5-semibold-size);
  color: var(--grey-1);
  font-family: var(--p3-bold);
}
@media screen and (max-width: 430px) {
  .inputParent {
    width: 300px;
  }
  .educationmodal {
    width: 400px;
  }
  
}
