.logoIcon {
  position: relative;
  width: 197.34px;
  height: 32px;
}
.greatSessionDani {
  position: relative;
  line-height: 26px;
  font-weight: 500;
  display: inline-block;
  width: 410px;
}
.frameChild {
  position: relative;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  object-fit: cover;
}
.cameronWilliamson {
  position: relative;
  line-height: 26px;
  font-weight: 600;
}
.ellipseParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
}
.icons16pxstar {
  position: relative;
  width: 16px;
  height: 16px;
  overflow: hidden;
  flex-shrink: 0;
}
.frameParent,
.starRating {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.frameParent {
  align-items: center;
  justify-content: center;
  gap: var(--gap-133xl);
  color: var(--white);
}
.greatSessionDaniWasSuperParent {
  gap: var(--gap-base);
}
.greatSessionDaniWasSuperParent,
.img,
.testimonial {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.testimonial {
  border-radius: var(--br-5xs);
  background-color: var(--color-gray-200);
  backdrop-filter: blur(60px);
  border: 1px solid var(--grey-60);
  padding: var(--padding-base);
}
.img {
  width: 50%;
  min-height: 100vh;
  padding: var(--padding-9xl) var(--padding-41xl) var(--padding-9xl)
    var(--padding-61xl);
  box-sizing: border-box;
  gap: var(--gap-496xl);
  background-image: url(/public/img1@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.joinLoggworksFor {
  position: relative;
  letter-spacing: -0.02em;
  line-height: 36px;
}
.label {
  position: relative;
  line-height: 26px;
}
.tobiasFlagOfTheUnitedKingIcon {
  position: relative;
  width: 20px;
  height: 15px;
  overflow: hidden;
  flex-shrink: 0;
}
.tobiasFlagOfTheUnitedKingWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.iconschevronDown {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.countrySelector {
  position: absolute;
  top: 0;
  left: 0;
  border-right: 1px solid var(--grey-60);
  display: flex;
  flex-direction: row;
  padding: var(--padding-xs) var(--padding-5xs);
  align-items: center;
  justify-content: center;
  gap: var(--gap-9xs);
}
.activeSection {
  position: absolute;
  top: 0;
  left: 63px;
  border-radius: 0 var(--br-8xs) var(--br-8xs) 0;
  background-color: var(--white);
  border: 1.5px solid var(--logg-green);
  box-sizing: border-box;
  width: 335px;
  height: 48px;
  display: none;
}
.div {
  position: absolute;
  top: 10px;
  left: 72px;
  line-height: 26px;
}
.iconsplaceholder {
  position: absolute;
  top: 12px;
  right: 8px;
  width: 24px;
  height: 24px;
  display: none;
}
.field {
  position: relative;
  width: 398px;
  height: 48px;
  color: var(--grey-10);
}
.thisIsA,
.thisIsA1 {
  position: relative;
  line-height: 24px;
}
.thisIsA1 {
  font-weight: 500;
  display: none;
}
.helperText,
.largeInputs {
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.helperText {
  align-self: stretch;
  display: none;
  flex-direction: row;
  align-items: center;
  font-size: var(--p3-semibold-size);
}
.largeInputs {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.label1 {
  align-self: stretch;
  position: relative;
  line-height: 26px;
}
.userInput {
  position: absolute;
  top: 10px;
  left: 12px;
  line-height: 26px;
  display: none;
}
.iconseye {
  position: absolute;
  top: 12px;
  right: 8px;
  width: 24px;
  height: 24px;
}
.dotChild {
  position: relative;
  border-radius: 50%;
  background-color: var(--grey-20);
  width: 8px;
  height: 8px;
}
.dot {
  position: absolute;
  top: 20px;
  left: 12px;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-6xs);
}
.inputs,
.largeInputs1 {
  display: flex;
  flex-direction: column;
}
.largeInputs1 {
  width: 398px;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.inputs {
  align-items: center;
  justify-content: center;
  gap: var(--gap-xs);
}
.icons20pxplaceholder {
  position: relative;
  width: 20px;
  height: 20px;
  display: none;
}
.label2 {
  position: relative;
  font-size: var(--p3-semibold-size);
  line-height: 24px;
  font-family: var(--caption-bold);
  color: var(--white);
  text-align: center;
}
.largeButtons,
.verifyForm {
  display: flex;
  align-items: center;
  justify-content: center;
}
.largeButtons {
  cursor: pointer;
  border: 0;
  padding: var(--padding-5xs) 0;
  background-color: var(--logg-green);
  border-radius: var(--br-8xs);
  width: 398px;
  height: 48px;
  flex-direction: row;
  box-sizing: border-box;
  gap: var(--gap-5xs);
}
.verifyForm {
  flex-direction: column;
  gap: var(--gap-xl);
}
.or {
  position: relative;
  line-height: 20px;
  font-weight: 500;
}
.orWrapper {
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  padding: var(--padding-9xs) var(--padding-5xs);
  align-items: center;
  justify-content: center;
}
.demarcationChild {
  position: relative;
  background-color: var(--color-silver);
  width: 398px;
  height: 1px;
  margin-top: -15px;
}
.demarcation {
  width: 398px;
  height: 28px;
  display: flex;
  flex-direction: column;
  padding: var(--padding-smi) 0 var(--padding-sm);
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-end;
  text-align: center;
  font-size: var(--caption-bold-size);
  color: var(--grey-30);
}
.iconsfacebook,
.iconsfacebook1 {
  position: relative;
  width: 20px;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.iconsfacebook1 {
  display: none;
}
.largeButtons1 {
  cursor: pointer;
  border: 0;
  padding: var(--padding-5xs) 0;
  background-color: var(--color-royalblue);
  border-radius: var(--br-8xs);
  width: 398px;
  height: 48px;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
}
.label4 {
  position: relative;
  font-size: var(--p3-semibold-size);
  line-height: 24px;
  font-family: var(--caption-bold);
  color: var(--color-dodgerblue);
  text-align: center;
}
.largeButtons2,
.socialLogin {
  display: flex;
  align-items: center;
  justify-content: center;
}
.largeButtons2 {
  cursor: pointer;
  border: 1px solid var(--color-royalblue);
  padding: var(--padding-5xs) 0;
  background-color: transparent;
  border-radius: var(--br-8xs);
  box-sizing: border-box;
  width: 398px;
  height: 48px;
  flex-direction: row;
  gap: var(--gap-5xs);
}
.socialLogin {
  flex-direction: column;
  gap: var(--gap-base);
}
.alreadyHaveAn {
  font-weight: 500;
}
.logIn {
  font-weight: 600;
  color: var(--logg-green);
}
.alreadyHaveAnContainer {
  position: relative;
  font-size: var(--p3-semibold-size);
  line-height: 24px;
  text-align: center;
  display: inline-block;
  width: 300px;
  cursor: pointer;
  color: var(--grey-800);
}
.joinLoggworksForFreeParent,
.login {
  flex-direction: column;
  justify-content: center;
}
.login {
  display: flex;
  align-items: center;
  gap: var(--gap-5xl);
  text-align: left;
  font-size: var(--p2-semibold-size);
  color: var(--grey-20);
}
.joinLoggworksForFreeParent {
  padding: 0 var(--padding-31xl) 0 0;
  gap: var(--gap-13xl);
}
.joinLoggworksForFreeParent,
.signUp,
.signUpInner {
  display: flex;
  align-items: center;
}
.signUpInner {
  align-self: stretch;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-size: var(--h4-bold1-size);
  color: var(--grey-900);
}
.signUp {
  position: relative;
  background-color: var(--white);
  width: 100%;
  overflow: hidden;
  flex-direction: row;
  justify-content: flex-start;
  cursor: pointer;
  text-align: left;
  font-size: var(--p2-semibold-size);
  color: var(--grey-90);
  font-family: var(--caption-bold);
}
@media screen and (max-width: 960px) {
  .img {
    display: none;
  }
  .joinLoggworksForFreeParent {
    padding-right: 0;
    box-sizing: border-box;
  }
  .signUp {
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 430px) {
  .field{
    width:100%
  }
  .largeInputs{
    width:100%
  }
  .largeInputs1{
    width:100%
  }
  .inputs,.largeButtons,.verifyForm,.greatSessionDaniWasSuperParent,.demarcation,
  .demarcationChild,.socialLogin,.largeButtons1,.largeButtons2,.joinLoggworksForFreeParent,.login
  {
    width:100%
  }
  .joinLoggworksForFreeParent{
    padding:0px 10px;
  }
  }
