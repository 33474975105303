.activities,
.back1 {
  position: absolute;
  top: 17px;
  left: 143px;
  font-weight: 600;
}
.back1 {
  top: 5px;
  left: 26px;
  line-height: 155%;
  font-weight: 500;
}
.back,
.frameIcon {
  position: absolute;
  height: 32px;
}
.frameIcon {
  top: 0;
  left: 0;
  width: 32px;
  overflow: hidden;
}
.back {
  top: 12px;
  left: 9px;
  width: 59px;
  font-size: var(--p3-bold-size);
  color: var(--grey-800);
}
.new,
.titles {
  position: relative;
}
.titles {
  background-color: var(--white);
  width: 414px;
  height: 56px;
  overflow: hidden;
  flex-shrink: 0;
}
.new {
  letter-spacing: -0.03em;
}
.newWrapper {
  border-radius: var(--br-9xs);
  width: 123px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-7xs) var(--padding-59xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.current {
  position: relative;
  letter-spacing: -0.03em;
  font-weight: 600;
}
.currentWrapper {
  background-color: var(--white);
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: var(--primary-20base);
}
.currentWrapper,
.pastWrapper {
  border-radius: var(--br-9xs);
  width: 123px;
  padding: var(--padding-7xs) var(--padding-59xl);
  box-sizing: border-box;
  align-items: center;
}
.frameParent,
.pastWrapper,
.profilePromotion,
.tab {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.frameParent {
  flex: 1;
  height: 30px;
  align-items: flex-start;
}
.profilePromotion,
.tab {
  align-items: center;
}
.tab {
  flex: 1;
  overflow: hidden;
}
.profilePromotion {
  align-self: stretch;
  background-color: var(--grey-100);
  height: 45px;
  font-size: var(--p2-bold-size);
  color: var(--gray-2);
}
.promotionSubTabsChild {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--white);
  width: 414px;
  height: 51px;
}
.offer {
  position: relative;
  line-height: 155%;
  font-weight: 600;
  mix-blend-mode: normal;
  color: var(--primary-20base);
}
.frameChild {
  align-self: stretch;
  position: relative;
  border-radius: var(--br-11xs) var(--br-11xs) 0 0;
  background-color: var(--primary-20base);
  height: 2px;
}
.offerParent {
  position: absolute;
  top: 0;
  left: 20px;
  width: 100px;
  height: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  gap: var(--gap-8xs);
}
.dealOfThe {
  position: relative;
  line-height: 155%;
  font-weight: 500;
  mix-blend-mode: normal;
}
.frameItem {
  align-self: stretch;
  position: relative;
  border-radius: var(--br-11xs) var(--br-11xs) 0 0;
  background-color: var(--white);
  height: 2px;
}
.dealOfTheDayParent,
.profileParent {
  position: absolute;
  top: 0;
  height: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  gap: var(--gap-8xs);
}
.dealOfTheDayParent {
  left: calc(50% - 87px);
  width: 175px;
}
.profileParent {
  right: 19px;
  width: 100px;
}
.promotionSubTabs {
  position: relative;
  border-bottom: 1px solid var(--grey-100);
  box-sizing: border-box;
  width: 414px;
  height: 51px;
  overflow: hidden;
  flex-shrink: 0;
  font-size: var(--p2-semibold-size);
  color: var(--grey-600);
}
.frameChild1 {
  position: relative;
  width: 374px;
  height: 116px;
}
.rectangleIcon {
  position: absolute;
  top: 4px;
  left: 0;
  border-radius: var(--br-81xl);
  width: 24px;
  height: 24px;
  object-fit: cover;
}
.dysonElectricals {
  position: relative;
  line-height: 155%;
  font-weight: 600;
}
.b,
.groupIcon {
  position: relative;
}
.groupIcon {
  width: 53px;
  height: 10px;
}
.b {
  letter-spacing: 0.1em;
  line-height: 155%;
  text-transform: uppercase;
}
.groupParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-9xs);
  text-align: right;
  font-size: var(--caption-block-size);
  color: var(--gray-2);
}
.dysonElectricalsParent {
  position: absolute;
  top: 0;
  left: 32px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.rectangleParent {
  position: relative;
  width: 149px;
  height: 36px;
}
.electricalWorksMaintainance,
.off5Weeks {
  position: relative;
  line-height: 155%;
}
.electricalWorksMaintainance {
  font-size: var(--caption-regular-size);
  font-weight: 500;
  color: var(--grey-600);
}
.endsIn {
  font-weight: 600;
}
.span {
  color: var(--gray-2);
}
.days043440 {
  color: var(--red-500);
}
.endsIn03Container {
  position: relative;
  line-height: 155%;
  color: var(--grey-600);
}
.frameDiv {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.icons {
  position: relative;
  width: 20px;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.button,
.iconsParent {
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.iconsParent {
  display: flex;
  gap: var(--gap-5xs);
}
.button {
  border-radius: var(--br-9xs);
  background-color: var(--white);
  border: 1px solid var(--primary-20base);
  box-sizing: border-box;
  width: 350px;
  display: none;
  padding: var(--padding-4xs) var(--padding-128xl);
  text-align: center;
  color: var(--primary-20base);
}
.frameContainer,
.frameWrapper {
  flex-direction: column;
  justify-content: flex-start;
}
.frameContainer {
  display: flex;
  align-items: flex-start;
  gap: var(--gap-xs);
}
.frameWrapper {
  align-self: stretch;
  border-radius: 0 0 var(--br-5xs) var(--br-5xs);
  background-color: var(--grey-100);
  padding: var(--padding-5xs) var(--padding-xs) var(--padding-base);
}
.frameGroup,
.frameWrapper {
  display: flex;
  align-items: flex-start;
}
.current1,
.frameGroup,
.signup {
  flex-direction: column;
  justify-content: flex-start;
}
.current1 {
  box-shadow: var(--card);
  display: flex;
  align-items: flex-end;
  text-align: left;
  font-size: var(--p3-bold-size);
  color: var(--color-black);
}
.signup {
  align-self: stretch;
  background-color: var(--white);
  overflow: auto;
  flex-shrink: 0;
  display: none;
  align-items: center;
  gap: var(--gap-mini);
}
.navChild {
  position: absolute;
  top: 27px;
  left: 20px;
  width: 125px;
  height: 20px;
}
.iconshome {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.home {
  position: relative;
  line-height: 24px;
  font-weight: 500;
}
.default,
.iconshomeParent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.iconshomeParent {
  flex-direction: row;
  gap: var(--gap-5xs);
}
.default {
  align-self: stretch;
  border-radius: var(--br-11xs);
  overflow: hidden;
  flex-direction: column;
  padding: var(--padding-5xs);
  cursor: pointer;
}
.accountIcon {
  position: relative;
  width: 24px;
  height: 24px;
}
.home5 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
}
.default5,
.defaultParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.default5 {
  align-self: stretch;
  border-radius: var(--br-9xs);
  background-color: var(--grey-90);
  overflow: hidden;
  padding: var(--padding-5xs);
  color: var(--primary-10);
}
.defaultParent {
  position: absolute;
  top: 87px;
  left: 20px;
  width: 181px;
  gap: var(--gap-xs);
}
.nav {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--grey-100);
}
.back2,
.navPrototype {
  position: relative;
  width: 221px;
  height: 1325px;
}
.back2 {
  width: 59px;
  height: 32px;
}
.activities1 {
  position: relative;
  font-size: var(--h4-semibold-size);
  font-weight: 600;
  color: var(--grey-900);
}
.titles1 {
  align-self: stretch;
  background-color: var(--white);
  overflow: hidden;
  display: none;
  flex-direction: row;
  padding: var(--padding-xs) var(--padding-4xs);
  align-items: center;
  justify-content: space-between;
}
.iconsadd {
  position: relative;
  width: 16px;
  height: 16px;
  overflow: hidden;
  flex-shrink: 0;
}
.button1 {
  position: relative;
  line-height: 100%;
  font-weight: 600;
}
.iconsaddParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-9xs);
}
.avatarIcon,
.smallButtons {
  position: absolute;
  top: 14px;
  height: 40px;
  display: none;
}
.smallButtons {
  right: 427px;
  border-radius: var(--br-9xs);
  background-color: var(--primary-20base);
  width: 146px;
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.avatarIcon {
  left: 1139px;
  width: 40px;
}
.licenseAndCertification {
  position: absolute;
  top: 18px;
  left: 234px;
  font-size: var(--h5-semibold-size);
  line-height: 28px;
  color: var(--grey-900);
  text-align: left;
}
.icon {
  position: absolute;
  top: 14px;
  left: 52px;
  border: 1px solid var(--grey-80);
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs);
  align-items: center;
  justify-content: center;
}
.component102 {
  position: relative;
  background-color: var(--white);
  border-bottom: 1px solid var(--grey-80);
  box-sizing: border-box;
  width: 100%;
  height: 68px;
  overflow: hidden;
  flex-shrink: 0;
  color: var(--white);
}
.tab1 {
  position: relative;
  line-height: 26px;
  font-weight: 500;
}
.tab1Wrapper {
  position: absolute;
  width: 32.8%;
  top: 4px;
  right: 66.49%;
  left: 0.72%;
  border-radius: var(--br-9xs);
  height: 44px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-9xs) var(--padding-5xs);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.tab2 {
  position: relative;
  line-height: 26px;
  font-weight: 600;
}
.tab2Wrapper,
.tab3Wrapper {
  position: absolute;
  top: 4px;
  border-radius: var(--br-9xs);
  height: 44px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-9xs) var(--padding-5xs);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.tab2Wrapper {
  width: 32.8%;
  right: 0.72%;
  left: 66.31%;
  background-color: var(--white);
  color: var(--primary-20base);
}
.tab3Wrapper {
  width: 32.97%;
  right: 33.69%;
  left: 33.51%;
  cursor: pointer;
}
.twoTabFlatSwitch {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-8xs);
  background-color: var(--grey-90);
}
.promotionsTabPrototype {
  position: relative;
  width: 558px;
  height: 52px;
}
.tabSwitchChild {
  position: absolute;
  top: 44.5px;
  left: -0.5px;
  border-top: 1px solid var(--grey-80);
  box-sizing: border-box;
  width: 559px;
  height: 1px;
}
.booking {
  position: relative;
  line-height: 26px;
  font-weight: 600;
  mix-blend-mode: normal;
}
.all {
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  width: 186px;
  height: 44px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs);
  align-items: center;
  justify-content: center;
  
}
.booking1 {
  position: relative;
  line-height: 26px;
  mix-blend-mode: normal;
}
.all1 {
  left: 186px;
  width: 186px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.all1,
.all2,
.tabSwitch {
  position: absolute;
  top: 0;
}
.all2 {
  left: 372px;
  width: 186px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.tabSwitch {
  height: 100%;
  width: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--color-gray-100);
  overflow: hidden;
}
.promotionsSubTab {
  position: relative;
  width: 558px;
  height: 45px;
  color: var(--grey-20);
}
.promotionsTabPrototypeParent {
  align-self: stretch;
  background-color: var(--color-gray-100);
  display: flex;
  flex-direction: column;
  padding: 0 var(--padding-215xl);
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
  font-size: var(--p2-semibold-size);
  color: var(--grey-30);
  margin-top: 26px;
}
.frameChild2 {
  position: relative;
  border-radius: var(--br-9xs);
  width: 181px;
  height: 109px;
  object-fit: cover;
}
.citiessummary {
  flex: 1;
  position: relative;
  line-height: 24px;
  font-weight: 500;
}
.citiessummaryParent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.citiessummary2 {
  position: relative;
  line-height: 24px;
  color: var(--grey-20);
}
.iconscalendarParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.citiessummary3 {
  position: relative;
  line-height: 24px;
}
.iconsclockParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-9xs);
  color: var(--grey-20);
}
.frameParent5,
.frameWrapper1 {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.frameParent5 {
  flex-direction: row;
  gap: var(--gap-xl);
}
.frameWrapper1 {
  flex-direction: column;
  color: var(--error-20);
}
.price {
  position: relative;
  line-height: 24px;
  font-weight: 500;
  display: none;
  width: 333px;
}
.frameParent4 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.citiessummary4 {
  align-self: stretch;
  position: relative;
  font-size: var(--caption-regular-size);
  line-height: 20px;
}
.frameParent3 {
  flex: 1;
  flex-direction: column;
  gap: var(--gap-5xs);
}
.frameParent3,
.promotionsList,
.rectangleGroup {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.rectangleGroup {
  width: 534px;
  flex-direction: row;
  gap: var(--gap-xl);
}
.promotionsList {
  background-color: var(--white);
  flex-direction: column;
  padding: var(--padding-base) var(--padding-xs);
}
.get20When {
  font-weight: 500;
}
.citiessummary5 {
  flex: 1;
  position: relative;
  line-height: 24px;
}
.promotionsListParent,
.titlesParent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.promotionsListParent {
  align-items: flex-start;
  text-align: left;
  color: var(--grey-20);
}
.titlesParent {
  flex: 1;
  align-items: center;
  color: var(--grey-800);
}
.curandpastpromo,
.pastpromos {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.pastpromos {
  background-color: var(--color-gray-100);
  width: 100%;
  flex-direction: row;
  font-size: var(--p3-bold-size);
  color: var(--grey-10);
}
.curandpastpromo {
  position: relative;
  width: 100%;
  flex-direction: column;
  gap: var(--gap-3xs);
  font-size: var(--h4-semibold-size);
  color: var(--grey-900);
  font-family: var(--p3-bold);
  padding: 0 1rem;
}
@media screen and (max-width: 960px) {
  .navPrototype {
    display: none;
  }
  .titles1 {
    display: flex;
  }
  .component102 {
    display: none;
  }
  .pastpromos {
    align-self: stretch;
    width: auto;
  }
}
@media screen and (max-width: 430px) {
  .signup {
    display: flex;
  }
  .pastpromos {
    display: none;
  }
}
