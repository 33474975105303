.verifyPhoneNumber {
  position: relative;
  font-size: var(--h4-bold1-size);
  letter-spacing: -0.02em;
  line-height: 36px;
  display: inline-block;
  color: var(--grey-0);
  width: 398px;
}
.div,
.edit,
.enterTheVerification {
  position: relative;
  line-height: 26px;
}
.enterTheVerification {
  display: inline-block;
  width: 398px;
}
.div,
.edit {
  font-weight: 500;
}
.edit {
  color: var(--logg-green);
  text-align: right;
}
.verifyPhoneNumberParent {
  display: flex;
  justify-content: flex-start;
}
.parent {
  display: flex;
  justify-content:center;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  width: 100%;
}
.verifyPhoneNumberParent {
  flex-direction: column;
  align-items: flex-end;
  gap: var(--gap-11xs);
}
.otpInputIcon {
  position: relative;
  border-radius: var(--br-8xs);
  width: 66.8px;
  height: 48px;
}
.frameGroup,
.otpInputParent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.frameGroup {
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
}
.resendOtp {
  position: relative;
  line-height: 26px;
  font-weight: 600;
  color: var(--logg-green);
  display: inline-block;
  width: 398px;
}
.didntGetTheOtpParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-11xs);
}
.icons20pxplaceholder {
  position: relative;
  width: 20px;
  height: 20px;
  display: none;
}
.label {
  position: relative;
  font-size: var(--p3-semibold-size);
  line-height: 24px;
  font-family: var(--caption-bold);
  color: var(--white);
  text-align: center;
}
.largeButtons {
  cursor: pointer;
  border: 0;
  padding: var(--padding-5xs) 0;
  background-color: var(--logg-green);
  border-radius: var(--br-8xs);
  width: 398px;
  height: 48px;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
}
.frameContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
}
.frameParent {
  flex-direction: column;
  padding: 0 var(--padding-31xl) 0 0;
  justify-content: center;
  gap: var(--gap-13xl);
}
.frameParent,
.verify,
.verifyInner {
  display: flex;
  align-items: center;
}
.verifyInner {
  align-self: stretch;
  flex: 1;
  flex-direction: column;
  justify-content: center;
}
.verify {
  position: relative;
  background-color: var(--white);
  width: 100%;
  overflow: hidden;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  font-size: var(--p2-semibold-size);
  color: var(--grey-20);
  font-family: var(--caption-bold);
}
@media screen and (max-width: 960px) {
  .frameParent {
    padding-right: 0;
    box-sizing: border-box;
  }
  .verifyInner {
    flex: unset;
    align-self: stretch;
  }
  .verify {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 5rem;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 430px) {
  .verifyInner {
    flex: unset;
    align-self: stretch;
  }
  .verify {
    flex-direction: column;
    gap: 234px;
    padding-top: 5rem;
    box-sizing: border-box;
  }
}
