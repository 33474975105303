@tailwind base;
@tailwind components;
@tailwind utilities;

label {
  span {
    font-weight: lighter;
  }
}
button {
  font-size: 14px;
  color: white;
}
.top_shadow {
  background: linear-gradient(180deg, #1e1e1e 0%, rgba(30, 30, 30, 0) 100%);
  width: 100%;
  height: 152px;
}

.testimonial {
  border-radius: 8px;
  border: 1px solid var(--grey-60, #ccc);
  background: rgba(39, 39, 39, 0.1);
  backdrop-filter: blur(30px);
  position: absolute;
  left: 50%;
  transform: translate(-50%);
}

.categories_nav {
  width: 100%;
  overflow-x: scroll;
  scrollbar-width: thin; /* Use "thin" to make the scrollbar thinner */
}

/* Webkit browsers */
.categories_nav::-webkit-scrollbar {
  width: 4px; /* Adjust the width as needed */
}

/* Track */
.categories_nav::-webkit-scrollbar-thumb {
  background-color: #000000;
}

/* Handle on hover */
.categories_nav::-webkit-scrollbar-thumb:hover {
  background-color: #939393;
}

/* Firefox */
.categories_nav {
  scrollbar-width: thin; /* Use "thin" to make the scrollbar thinner */
}

.categories_nav::-webkit-scrollbar {
  display: none; /* Hide Webkit scrollbar */
}

/* Track (Firefox) */
.categories_nav::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}

/* Handle (Firefox) */
.categories_nav::-webkit-scrollbar-thumb {
  background-color: #000000;
}

/* Handle on hover (Firefox) */
.categories_nav::-webkit-scrollbar-thumb:hover {
  background-color: #939393;
}
.wishlist {
  background: #0d0d0d;
  opacity: 0.6;
  color: #ffffff;
  width: 32px;
  height: 32px;
}

.form-checkbox {
  /* Remove default browser styles */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* Set the checkbox dimensions */
  width: 20px;
  height: 20px;
  /* Add a custom border */
  border: 2px solid #efefef; /* Replace #ccc with your desired grey color */
  /* Add some space between the checkbox and the label text */
  vertical-align: middle;
  /* Set a background color for the checked state */
  background-color: #fff;
  border-radius: 50%;
}

.form-checkbox-square {
  /* Remove default browser styles */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* Set the checkbox dimensions */
  width: 22px;
  height: 22px;
  /* Add a custom border */

  /* Add some space between the checkbox and the label text */
  vertical-align: middle;
  /* Set a background color for the checked state */
  background-color: #fff;
  border-radius: 4px;
}

/* Style the checked state */
.form-checkbox:checked,
.form-checkbox-square:checked {
  background-color: #2fa500; /* Replace #ccc with your desired grey color */
}

.form-checkbox-square::after {
  content: "\2713"; /* Unicode checkmark character */
  font-size: 14px; /* Adjust the size of the checkmark */
  color: white; /* Color of the checkmark symbol */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal-container {
  background: rgba(7, 26, 0, 0.25);
}

@media (max-width: 480px) {
}
@media (max-width: 768px) {
}
@media screen and (min-width: 1024px) {
  .left[hidden] {
    display: block; /* Hide the content on desktop screens */
  }
}

.getquote {
  position: fixed;
  bottom: 20px; /* Adjust the desired distance from the bottom */
  z-index: 999; /* Adjust the z-index as needed */
  color: #fff; /* Adjust the text color */
  padding: 10px 20px; /* Adjust padding as needed */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: bottom 0.3s ease; /* Add a smooth transition */
  left: 50%;
  transform: translate(-50%, -50%);
}

.others {
  width: 64px;
  height: 64px;
  overflow: hidden; /* Ensure the image doesn't overflow the container */
}

/* CSS for the image inside the container */
.others img {
  width: 100%; /* Make the image fill the container width */
  height: 100%; /* Make the image fill the container height */
  object-fit: cover; /* Stretch or cover as needed */
}

.all_promotions::-webkit-scrollbar {
  width: 0.25em; /* Adjust the width as needed */
}

.all_promotions::-webkit-scrollbar-thumb {
  background-color: transparent; /* Hide the scrollbar thumb */
  border-radius: 2px; /* Optional: Add rounded corners to the thumb */
}

.all_promotions::-webkit-scrollbar-track {
  background-color: transparent; /* Hide the scrollbar track */
}

#main::-webkit-scrollbar {
  width: 0.25em; /* Adjust the width as needed */
}

::-webkit-scrollbar-thumb {
  background-color: transparent; /* Hide the scrollbar thumb */
  border-radius: 2px; /* Optional: Add rounded corners to the thumb */
}

::-webkit-scrollbar-track {
  background-color: transparent; /* Hide the scrollbar track */
}

  