.exclamationMark1Icon {
  position: relative;
  width: 40px;
  height: 40px;
  overflow: hidden;
  flex-shrink: 0;
}
.trashLineIcon {
  position: relative;
  width: 57px;
  height: 51.2px;
  display: none;
}
.youAreAbout {
  align-self: stretch;
  position: relative;
  font-weight: 600;
}
.hStack {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xl);
}
.icons,
.proceed {
  position: relative;
}
.icons {
  width: 20px;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.proceed {
  line-height: 155%;
}
.button,
.button1,
.iconsParent {
  flex-direction: row;
  justify-content: center;
}
.iconsParent {
  display: flex;
  align-items: center;
  gap: var(--gap-5xs);
}
.button,
.button1 {
  flex: 1;
  border-radius: var(--br-9xs);
  box-sizing: border-box;
  height: 48px;
  padding: var(--padding-3xs) var(--padding-28xl) var(--padding-2xs)
    var(--padding-27xl);
}
.button {
  background-color: var(--white);
  border: 1px solid var(--grey-700);
  display: flex;
  align-items: center;
  cursor: pointer;
}
.button1 {
  background-color: red;
  border: 1px solid var(--red-500);
  color: var(--white);
  cursor: pointer;
}
.button1,
.ctas,
.endPromo,
.vStack {
  display: flex;
  align-items: center;
}
.ctas {
  align-self: stretch;
  flex-direction: row;
  justify-content: center;
  gap: var(--gap-xs);
  font-size: var(--subcopy-bold-size);
}
.endPromo,
.vStack {
  flex-direction: column;
}
.vStack {
  align-self: stretch;
  justify-content: flex-start;
  gap: var(--gap-21xl);
}
.endPromo {
  border-radius: var(--br-xs);
  background-color: var(--white);
  box-shadow: var(--modal-shadow);
  width: 352px;
  overflow: hidden;
  justify-content: center;
  padding: var(--padding-13xl) var(--padding-xl) var(--padding-5xl);
  box-sizing: border-box;
  text-align: center;
  font-size: var(--h4-semibold-size);
  color: var(--grey-700);
  font-family: var(--subcopy-bold);
}
