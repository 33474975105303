.navChild {
  position: absolute;
  top: 27px;
  left: 20px;
  width: 125px;
  height: 20px;
}
.iconshome {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.home {
  position: relative;
  line-height: 24px;
  font-weight: 600;
}
.default,
.iconshomeParent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.iconshomeParent {
  flex-direction: row;
  gap: var(--gap-5xs);
}
.default {
  border-radius: var(--br-9xs);
  background-color: var(--grey-90);
  width: 151px;
  overflow: hidden;
  flex-direction: column;
  padding: var(--padding-5xs);
  box-sizing: border-box;
  cursor: pointer;
  color: var(--primary-10);
}
.home1 {
  position: relative;
  line-height: 24px;
  font-weight: 500;
}
.default1,
.default2 {
  border-radius: var(--br-11xs);
  width: 181px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xs);
  box-sizing: border-box;
}
.default2 {
  cursor: pointer;
}
.accountIcon {
  position: relative;
  width: 24px;
  height: 24px;
}
.defaultParent {
  position: absolute;
  top: 87px;
  left: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xs);
}
.nav {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--grey-100);
}
.iconsadd,
.navPrototype {
  position: relative;
  width: 221px;
  height: 100vh;
}
.iconsadd {
  width: 16px;
  height: 16px;
  overflow: hidden;
  flex-shrink: 0;
}
.button {
  position: relative;
  line-height: 100%;
  font-weight: 600;
}
.iconsaddParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-9xs);
}
.avatarIcon,
.smallButtons {
  position: absolute;
  top: 14px;
  height: 40px;
  display: none;
}
.smallButtons {
  right: 427px;
  border-radius: var(--br-9xs);
  background-color: var(--primary-20base);
  width: 146px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xs) var(--padding-xl);
  box-sizing: border-box;
}
.avatarIcon {
  left: 1139px;
  width: 40px;
}
.licenseAndCertification {
  position: absolute;
  top: 18px;
  left: 234px;
  font-size: var(--h5-bold-size);
  line-height: 28px;
  color: var(--grey-900);
  text-align: left;
}
.icon {
  position: absolute;
  top: 14px;
  left: 52px;
  border: 1px solid var(--grey-80);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xs);
  cursor: pointer;
}
.component102 {
  position: relative;
  background-color: var(--white);
  border-bottom: 1px solid var(--grey-80);
  box-sizing: border-box;
  width: 100%;
  height: 68px;
  overflow: hidden;
  flex-shrink: 0;
}
.back1 {
  position: absolute;
  top: 5px;
  left: 26px;
  line-height: 155%;
  font-weight: 500;
}
.frameIcon {
  position: absolute;
  top: 0;
  left: 0;
  width: 32px;
  height: 32px;
  overflow: hidden;
}
.back {
  position: relative;
  width: 59px;
  height: 32px;
}
.activities {
  position: relative;
  font-size: var(--h4-semibold-size);
  font-weight: 600;
  color: var(--grey-900);
}
.titles {
  align-self: stretch;
  background-color: var(--white);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.04);
  overflow: hidden;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: var(--padding-xs) var(--padding-4xs);
  color: var(--grey-800);
}
.span {
  color: var(--gray-1);
}
.div,
.reviews1 {
  position: absolute;
}
.div {
  top: 1px;
  left: 0;
  line-height: 155%;
  font-weight: 600;
}
.reviews1 {
  top: 0;
  left: 32px;
  line-height: 26px;
  color: var(--grey-20);
}
.groupChild,
.parent {
  position: absolute;
  top: 0;
  left: 115px;
  width: 131px;
  height: 26px;
}
.groupChild {
  top: 2px;
  left: 0;
  width: 106.7px;
  height: 20px;
}
.groupContainer {
  position: relative;
  width: 246px;
  height: 26px;
}
.stars {
  position: relative;
  line-height: 24px;
  display: flex;
  align-items: center;
  width: 63px;
  flex-shrink: 0;
}
.div1 {
  position: absolute;
  height: 100%;
  width: 49.82%;
  top: 0;
  right: 50.18%;
  bottom: 0;
  left: 0;
  border-radius: var(--br-12xs) 0 0 var(--br-12xs);
  background-color: var(--primary-20base);
}
.div2,
.wrapper {
  position: relative;
}
.wrapper {
  border-radius: var(--br-12xs);
  background-color: var(--grey-90);
  width: 453px;
  height: 10px;
}
.div2 {
  line-height: 24px;
  text-align: right;
  display: flex;
  align-items: center;
  width: 42px;
  flex-shrink: 0;
}
.groupParent,
.reviewBar,
.reviewBarParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.groupParent,
.reviewBarParent {
  flex-direction: column;
  align-items: flex-start;
}
.reviewBarParent {
  gap: var(--gap-11xs);
  font-size: var(--button-small-text-size);
  color: var(--grey-10);
}
.groupParent {
  gap: var(--gap-xs);
}
.avatarIcon1 {
  position: relative;
  width: 40px;
  height: 40px;
}
.jun2021,
.peoplename {
  position: relative;
  line-height: 24px;
  display: flex;
  align-items: center;
  width: 451px;
}
.peoplename {
  font-weight: 600;
}
.jun2021 {
  font-size: var(--button-small-text-size);
  color: var(--grey-20);
}
.peoplenameParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.avatarParent,
.starRating {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.avatarParent {
  width: 558px;
  align-items: center;
  gap: var(--gap-xs);
}
.starRating {
  position: absolute;
  top: 0;
  left: 52px;
  align-items: flex-start;
}
.starRatingWrapper {
  position: relative;
  width: 132px;
  height: 16px;
}
.frameGroup {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-11xs);
}
.frameGroup,
.iAmEnjoying,
.reviews2 {
  width: 558px;
  display: flex;
}
.iAmEnjoying {
  position: relative;
  font-size: var(--p2-regular-size);
  line-height: 26px;
  color: var(--grey-20);
  align-items: center;
}
.reviews2 {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.frameChild {
  position: relative;
  border-top: 1px solid var(--grey-80);
  box-sizing: border-box;
  width: 559px;
  height: 1px;
}
.reviewsParent {
  display: flex;
  justify-content: flex-start;
  gap: var(--gap-xl);
}
.frameParent,
.frameWrapper,
.reviewsParent {
  flex-direction: column;
  align-items: flex-start;
}
.frameWrapper {
  display: flex;
  justify-content: flex-start;
  font-size: var(--p1-semibold-size);
  color: var(--grey-0);
}
.frameParent {
  background-color: var(--color-gray-100);
  padding: var(--padding-5xs) var(--padding-5xs) var(--padding-21xl);
  gap: var(--gap-5xs);
  text-align: left;
  font-size: var(--p2-regular-size);
  color: var(--primary-20base);
}
.component102Parent,
.frameParent,
.reviews {
  display: flex;
  justify-content: flex-start;
}
.component102Parent {
  flex-direction: column;
  align-items: center;
  gap: var(--gap-xs);
  color: var(--white);
  width:100%;
  height: 100vh;
  overflow-y: auto;
}
.reviews {
  position: relative;
  background-color: var(--color-gray-100);
  width: 100%;
  overflow-y: auto;
  flex-direction: row;
  align-items: flex-start;
  text-align: center;
  font-size: var(--button-small-text-size);
  color: var(--grey-10);
  font-family: var(--h5-bold);
}
@media screen and (max-width: 960px) {
  .component102,
  .navPrototype {
    display: none;
  }
  .titles {
    display: flex;
  }
  .reviewBar{
    width:100%
  }
}
@media screen and (max-width: 430px) {
  .avatarParent,
  .frameGroup,
  .frameParent,
  .frameWrapper,
  .groupParent,
  .iAmEnjoying,
  .reviewBarParent,
  .reviews2,
  .reviewsParent {
    align-self: stretch;
    width: auto;
  }
  
}
