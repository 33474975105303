.navChild {
  position: absolute;
  top: 27px;
  left: 20px;
  width: 125px;
  height: 20px;
}
.iconshome {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.icon {
  position: absolute;
  top: 14px;
  left: 52px;
  border: 1px solid var(--grey-80);
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs);
  align-items: center;
  justify-content: center;
}
.home {
  position: relative;
  line-height: 24px;
  font-weight: 500;
}
.default,
.iconshomeParent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.iconshomeParent {
  flex-direction: row;
  gap: var(--gap-5xs);
}
.default {
  align-self: stretch;
  border-radius: var(--br-11xs);
  overflow: hidden;
  flex-direction: column;
  padding: var(--padding-5xs);
  cursor: pointer;
}
.accountIcon {
  position: relative;
  width: 24px;
  height: 24px;
}
.home5 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
}
.default5,
.defaultParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.default5 {
  align-self: stretch;
  border-radius: var(--br-9xs);
  background-color: var(--grey-90);
  overflow: hidden;
  padding: var(--padding-5xs);
  color: var(--primary-10);
}
.defaultParent {
  position: absolute;
  top: 87px;
  left: 20px;
  width: 181px;
  gap: var(--gap-xs);
}
.nav {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--grey-100);
}
.navPrototype {
  position: relative;
  width: 221px;
  height: 1325px;
}
.back1 {
  position: absolute;
  top: 5px;
  left: 26px;
  line-height: 155%;
  font-weight: 500;
}
.frameIcon {
  position: absolute;
  top: 0;
  left: 0;
  width: 32px;
  height: 32px;
  overflow: hidden;
}
.back {
  position: relative;
  width: 59px;
  height: 32px;
}
.notifications {
  position: relative;
  font-size: var(--h4-semibold-size);
  font-weight: 600;
  color: var(--grey-900);
  display: inline-block;
  width: 106px;
  flex-shrink: 0;
}
.titles {
  align-self: stretch;
  background-color: var(--white);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.04);
  overflow: hidden;
  display: none;
  flex-direction: row;
  padding: var(--padding-xs) var(--padding-4xs);
  align-items: center;
  justify-content: space-between;
}
.iconsadd {
  position: relative;
  width: 16px;
  height: 16px;
  overflow: hidden;
  flex-shrink: 0;
}
.button {
  position: relative;
  line-height: 100%;
  font-weight: 600;
}
.iconsaddParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-9xs);
}
.avatarIcon,
.smallButtons {
  position: absolute;
  top: 14px;
  height: 40px;
  display: none;
}
.smallButtons {
  right: 427px;
  border-radius: var(--br-9xs);
  background-color: var(--primary-20base);
  width: 146px;
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.avatarIcon {
  left: 1139px;
  width: 40px;
}
.licenseAndCertification {
  position: absolute;
  top: 18px;
  left: 137px;
  font-size: var(--h5-semibold-size);
  line-height: 28px;
  color: var(--grey-900);
  text-align: left;
}
.component102 {
  align-self: stretch;
  position: relative;
  background-color: var(--white);
  border-bottom: 1px solid var(--grey-80);
  box-sizing: border-box;
  height: 68px;
  overflow: hidden;
  flex-shrink: 0;
  color: var(--white);
}
.imgContainerChild,
.imgContainerItem {
  position: absolute;
  top: 16px;
  left: 502px;
  width: 40px;
  height: 40px;
  display: none;
}
.imgContainerItem {
  left: 450px;
}
.contentChild,
.contentItem {
  position: relative;
  border-radius: 50%;
  background-color: var(--primary-20base);
  width: 10px;
  height: 10px;
}
.contentItem {
  background-color: var(--grey-80);
}
.content,
.smallButtons1 {
  position: absolute;
  display: flex;
  flex-direction: row;
}
.content {
  top: 178px;
  left: 238px;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}

.smallButtons1 {
  top: 12px;
  left: 466px;
  border-radius: var(--br-9xs);
  background-color: var(--white);
  border: 1px solid var(--primary-20base);
  box-sizing: border-box;
  width: 80px;
  height: 32px;
  padding: var(--padding-5xs) var(--padding-xl);
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.imgContainer {
  position: relative;
  border-radius: var(--br-5xs);
  width: 558px;
  height: 203px;
  overflow: hidden;
  flex-shrink: 0;
  background: #d7d7d7;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.avatarIcon1 {
  position: relative;
  width: 56px;
  height: 56px;
}
.dysonElectricals,
.success {
  position: relative;
  font-weight: 600;
}
.dysonElectricals {
  line-height: 28px;
}
.success {
  letter-spacing: 0.04em;
  text-transform: uppercase;
}
.transactionPills {
  border-radius: var(--br-81xl);
  background-color: var(--green-100);
  display: flex;
  flex-direction: row;
  padding: var(--padding-9xs) var(--padding-7xs);
  align-items: center;
  justify-content: flex-end;
  text-align: right;
  font-size: var(--font-size-5xs);
  color: var(--primary-20base);
}
.avatarParent,
.frameParent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.avatarParent {
  flex-direction: row;
  gap: var(--gap-xs);
}
.rookie {
  position: absolute;
  top: 0;
  left: 0;
  width: 26px;
  height: 43px;
  overflow: hidden;
  display: none;
}
.ambassadorChild,
.ambassadorItem {
  position: absolute;
  top: 6px;
  left: 1px;
  width: 24px;
  height: 24px;
}
.ambassadorItem {
  top: 31px;
  left: 7px;
  width: 12px;
  height: 12px;
}
.ambassador1,
.ambassadorInner {
  position: absolute;
  top: 0;
  left: 0;
  width: 25px;
  height: 5px;
}
.ambassador1 {
  top: 8.5px;
  left: 5.5px;
  line-height: 250%;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 6px;
}
.vectorIcon {
  position: absolute;
  top: 22px;
  left: 9.57px;
  border-radius: var(--br-12xs);
  width: 11px;
  height: 4px;
}
.ambassador,
.awards {
  width: 26px;
  height: 43px;
  overflow: hidden;
}
.ambassador {
  position: absolute;
  top: 0;
  left: 0;
}
.awards {
  position: relative;
  flex-shrink: 0;
  text-align: center;
  font-size: 2.37px;
  color: var(--grey-900);
}
.profileHeading {
  width: 558px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-257xl);
  text-align: left;
}
.icons20pxplaceholder {
  position: relative;
  width: 20px;
  height: 20px;
  display: none;
}
.label {
  position: relative;
  line-height: 24px;
}
.largeButtons {
  border-radius: var(--br-8xs);
  background-color: var(--primary-20base);
  width: 420px;
  height: 44px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs) 0;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
}
.share {
  position: relative;
  line-height: 26px;
}
.buttonsInner,
.shareParent {
  display: flex;
  align-items: center;
}
.shareParent {
  flex-direction: row;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.buttonsInner {
  border-radius: var(--br-8xs);
  background-color: var(--white);
  border: 1px solid var(--primary-20base);
  box-sizing: border-box;
  width: 122px;
  flex-direction: column;
  padding: var(--padding-5xs) var(--padding-8xl);
  justify-content: center;
  font-size: var(--p2-bold-size);
  color: var(--primary-20base);
}
.buttons {
  display: none;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
  font-size: var(--p3-semibold-size);
  color: var(--white);
}
.tab1 {
  position: relative;
  line-height: 26px;
  font-weight: 600;
}
.tab1Wrapper {
  position: absolute;
  width: 49.28%;
  top: 4px;
  right: 50%;
  left: 0.72%;
  border-radius: var(--br-9xs);
  background-color: var(--white);
  height: 44px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-9xs) var(--padding-5xs);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.tab2 {
  position: relative;
  line-height: 26px;
  font-weight: 500;
}
.tab2Wrapper {
  position: absolute;
  width: 49.28%;
  top: 4px;
  right: 0.72%;
  left: 50%;
  border-radius: var(--br-9xs);
  height: 44px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-9xs) var(--padding-5xs);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  color: var(--grey-2);
}
.twoTabSwitch {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-8xs);
  background-color: var(--grey-90);
}
.prototypeSettings {
  position: relative;
  width: 558px;
  height: 52px;
  display: none;
  font-size: var(--p2-bold-size);
  color: var(--primary-20base);
}
.profile {
  font-size: var(--h5-semibold-size);
  color: var(--grey-0);
}
.profile,
.profileSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.label1 {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 26px;
  font-weight: 500;
}
.baseField,
.labelContainer {
  align-self: stretch;
  position: relative;
}
.labelContainer {
  height: 28px;
}
.baseField {
  flex: 1;
}
.div,
.thisIsA {
  position: absolute;
  top: 0;
  line-height: 24px;
}
.thisIsA {
  left: 0;
}
.div {
  right: 0;
  text-align: right;
}
.helperTextContainer {
  align-self: stretch;
  position: relative;
  height: 24px;
  display: none;
  font-size: var(--p3-semibold-size);
}
.input,
.title {
  width: 558px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.input {
  height: 110px;
  flex-direction: column;
  gap: var(--gap-11xs);
  font-size: var(--p2-bold-size);
  color: var(--grey-20);
}
.title {
  flex-direction: row;
  padding: var(--padding-13xl) var(--padding-5xs) var(--padding-base) 0;
  box-sizing: border-box;
}
.imageFrameIcon {
  align-self: stretch;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  z-index: 0;
}
.addcancelCircle {
  position: absolute;
  margin: 0 !important;
  right: -11px;
  bottom: -7px;
  width: 24px;
  height: 24px;
  display: none;
  z-index: 1;
}
.uploadImageplaceholder {
  align-self: stretch;
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}
.boundary {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-8xs);
  background-color: var(--grey-80);
  border: 1px dashed var(--grey-60);
  box-sizing: border-box;
}
.imageArea {
  position: absolute;
  height: 98%;
  top: 1%;
  right: 1px;
  bottom: 1%;
  left: 1px;
  border-radius: var(--br-9xs);
  background-color: var(--grey-90);
}
.iconsadd1 {
  position: absolute;
  top: calc(50% - 12px);
  left: calc(50% - 12px);
  width: 24px;
  height: 24px;
  overflow: hidden;
}
.imageFrame {
  align-self: stretch;
  flex: 1;
  position: relative;
  z-index: 0;
}
.addcancelCircleIcon {
  position: absolute;
  margin: 0 !important;
  right: -11px;
  bottom: -7px;
  width: 24px;
  height: 24px;
  z-index: 1;
}
.avatar,
.uploadImage {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
}
.uploadImage {
  flex: 1;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.avatar {
  height: 78px;
  flex-direction: row;
  align-items: flex-end;
  gap: 27px;
}
.content1 {
  width: 558px;
  gap: var(--gap-xs);
}
.content1,
.gallery,
.title1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.title1 {
  width: 558px;
  flex-direction: row;
  padding: var(--padding-21xl) var(--padding-5xs) var(--padding-xl) 0;
  box-sizing: border-box;
}
.nothingHereIcon {
  position: relative;
  width: 100px;
  height: 100px;
  overflow: hidden;
  flex-shrink: 0;
}
.buttonWrapper,
.smallButtons2,
.standOutAs {
  display: flex;
  align-items: center;
  justify-content: center;
}
.standOutAs {
  position: relative;
  line-height: 26px;
  width: 304px;
}
.buttonWrapper,
.smallButtons2 {
  flex-direction: row;
}
.smallButtons2 {
  border-radius: var(--br-9xs);
  background-color: var(--primary-20base);
  width: 130px;
  height: 32px;
  padding: var(--padding-5xs) 0;
  box-sizing: border-box;
  font-size: var(--p3-semibold-size);
  color: var(--white);
}
.nothingHereParent,
.standOutAsAProfessionalGParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.nothingHereParent {
  gap: var(--gap-xs);
}
.verification {
  background-color: var(--color-gray-100);
  width: 558px;
  padding: var(--padding-5xl) 0;
  box-sizing: border-box;
  text-align: center;
  font-size: var(--p2-bold-size);
  color: var(--grey-20);
}
.verification,
.verificationStatus {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.skillsAndPrice {
  flex: 1;
  position: relative;
  line-height: 24px;
  font-weight: 600;
}
.smallButtons3,
.title2 {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
}
.smallButtons3 {
  border-radius: var(--br-9xs);
  border: 1px solid var(--primary-20base);
  width: 107px;
  height: 32px;
  padding: var(--padding-5xs) var(--padding-xl);
  justify-content: center;
  cursor: pointer;
  text-align: center;
  font-size: var(--p3-semibold-size);
  color: var(--primary-20base);
}
.title2 {
  width: 558px;
  padding: var(--padding-13xl) 0 var(--padding-xl);
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.createAService,
.smallButtons4 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.createAService {
  position: relative;
  line-height: 26px;
  width: 304px;
}
.smallButtons4 {
  border-radius: var(--br-9xs);
  background-color: var(--primary-20base);
  width: 180px;
  height: 32px;
  flex-direction: row;
  padding: var(--padding-5xs) 0;
  box-sizing: border-box;
  cursor: pointer;
  font-size: var(--p3-semibold-size);
  color: var(--white);
}
.skillsPriceTagChild,
.skillsPriceTagItem {
  position: relative;
  box-sizing: border-box;
  width: 1px;
  height: 19px;
}
.skillsPriceTagChild {
  border-right: 1px solid var(--grey-3);
  display: none;
}
.skillsPriceTagItem {
  border-right: 1px solid var(--grey-70);
}
.xIcon {
  display: none;
}
.iconsclose,
.xIcon {
  position: relative;
  width: 20px;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.skillsPriceTag1 {
  border-radius: var(--br-81xl);
  background-color: var(--white);
  border: 1px solid var(--grey-70);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: var(--padding-11xs) var(--padding-5xs);
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
}
.skillsPriceTagParent,
.verification1 {
  display: flex;
  justify-content: flex-start;
}
.skillsPriceTagParent {
  align-self: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: var(--gap-xs);
  text-align: left;
  font-size: var(--p3-semibold-size);
  color: var(--grey-2);
}
.verification1 {
  background-color: var(--color-gray-100);
  width: 558px;
  flex-direction: column;
  padding: var(--padding-5xl) 0;
  box-sizing: border-box;
  align-items: center;
  gap: var(--gap-5xs);
  text-align: center;
  font-size: var(--p2-bold-size);
  color: var(--grey-20);
}
.chatLineIcon {
  position: relative;
  width: 90px;
  height: 88.85px;
}
.buildACustom {
  align-self: stretch;
  position: relative;
  line-height: 26px;
}
.smallButtons5 {
  border-radius: var(--br-9xs);
  background-color: var(--primary-20base);
  width: 202px;
  height: 32px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs) 0;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  font-size: var(--p3-semibold-size);
  color: var(--white);
}
.chatLineParent {
  width: 413px;
  align-items: center;
  gap: var(--gap-xs);
}
.chatLineParent,
.editProfile,
.merge,
.verification2 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.verification2 {
  background-color: var(--color-gray-100);
  width: 558px;
  padding: var(--padding-5xl) 0;
  box-sizing: border-box;
  align-items: center;
  text-align: center;
  font-size: var(--p2-bold-size);
  color: var(--grey-20);
}
.editProfile,
.merge {
  align-items: flex-start;
  width:100%
}
.merge {
  text-align: left;
  font-size: var(--p1-semibold-size);
  color: var(--grey-0);
}
.editProfile {
  gap: var(--gap-xl);
}
.editProfileWrapper {
  background-color: var(--color-gray-100);
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-5xs) var(--padding-21xl);
  align-items: flex-start;
  color: var(--primary-20base);
}
.editProfileWrapper,
.editprofile,
.titlesParent {
  display: flex;
  justify-content: flex-start;
}
.titlesParent {
  flex: 1;
  flex-direction: column;
  align-items: center;
  gap: var(--gap-xs);
  color: var(--grey-800);
  width:100%
}
.editprofile {
  position: relative;
  background-color: var(--color-gray-100);
  width: 100%;
  overflow-y: auto;
  flex-direction: row;
  align-items: flex-start;
  text-align: center;
  font-size: var(--p3-semibold-size);
  color: var(--grey-10);
  font-family: var(--p3-bold);
}
@media screen and (max-width: 960px) {
  .navPrototype {
    display: none;
  }
  .titles {
    display: flex;
  }
  .component102 {
    display: none;
  }
}
@media screen and (max-width: 430px) {
  .chatLineParent,
  .content1,
  .imgContainer,
  .input,
  .merge,
  .profile,
  .profileHeading,
  .profileSection,
  .standOutAs,
  .title,
  .title1,
  .title2,
  .verification,
  .verification1,
  .verification2,
  .verificationStatus {
    align-self: stretch;
    width: auto;
  }
  .editProfile {
    flex: 1;
  }
  .editProfileWrapper {
    align-self: stretch;
    width: auto;
  }
  .gallery{
    width: 100%
  }
}
