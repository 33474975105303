.navChild {
  position: absolute;
  top: 27px;
  left: 20px;
  width: 125px;
  height: 20px;
}
.iconshome {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.home {
  position: relative;
  line-height: 24px;
  font-weight: 500;
}
.default,
.iconshomeParent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.iconshomeParent {
  flex-direction: row;
  gap: var(--gap-5xs);
}
.default {
  align-self: stretch;
  border-radius: var(--br-11xs);
  overflow: hidden;
  flex-direction: column;
  padding: var(--padding-5xs);
  cursor: pointer;
}
.accountIcon {
  position: relative;
  width: 24px;
  height: 24px;
}
.home5 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
}
.default5,
.defaultParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.default5 {
  align-self: stretch;
  border-radius: var(--br-9xs);
  background-color: var(--grey-90);
  overflow: hidden;
  padding: var(--padding-5xs);
  color: var(--primary-10);
}
.defaultParent {
  position: absolute;
  top: 87px;
  left: 20px;
  width: 181px;
  gap: var(--gap-xs);
}
.nav {
  height: 100%;
  width: 221px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--grey-100);
}
.icons16pxadd,
.navPrototype {
  position: relative;
  width: 221px;
  height: 1325px;
}
.icons16pxadd {
  width: 16px;
  height: 16px;
  overflow: hidden;
  flex-shrink: 0;
}
.button {
  position: relative;
  line-height: 100%;
  font-weight: 600;
}
.icons16pxaddParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-9xs);
}
.navBarChild,
.smallButtons {
  position: absolute;
  top: 14px;
  height: 40px;
}
.smallButtons {
  right: 235px;
  border-radius: var(--br-9xs);
  background-color: var(--primary-10);
  width: 107px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.navBarChild {
  left: 1139px;
  border-radius: 50%;
  width: 40px;
  object-fit: cover;
}
.component98 {
  position: absolute;
  top: -2px;
  left: 1024px;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  padding: var(--padding-xs) 0;
  align-items: center;
  justify-content: center;
  color: var(--grey-10);
}
.icons20pxsearch {
  position: relative;
  width: 20px;
  height: 20px;
}
.trySearchingCarpenter {
  position: relative;
  line-height: 26px;
}
.icons20pxsearchParent {
  position: absolute;
  top: 8px;
  left: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-9xs);
}
.searchBarChild {
  position: absolute;
  top: -0.5px;
  left: 263.5px;
  border-right: 1px solid var(--grey-60);
  box-sizing: border-box;
  width: 1px;
  height: 45px;
}
.iconslocationParent {
  position: absolute;
  top: 8px;
  left: 276px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.navBar,
.searchBar {
  background-color: var(--white);
  box-sizing: border-box;
}
.searchBar {
  position: absolute;
  top: 12px;
  left: 40px;
  border-radius: var(--br-9xs);
  border: 1px solid var(--grey-60);
  width: 416px;
  height: 44px;
  text-align: left;
  font-size: var(--p2-regular-size);
  color: var(--grey-50);
}
.navBar {
  position: relative;
  border-bottom: 1px solid var(--grey-80);
  width: 1219px;
  height: 68px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.icon {
  position: absolute;
  top: 0;
  left: 0;
  border: 1px solid var(--grey-80);
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs);
  align-items: center;
  justify-content: center;
}
.subscriptions {
  position: absolute;
  top: 6px;
  left: 194px;
  line-height: 28px;
}
.smallButtons1 {
  position: absolute;
  top: 0;
  right: 387px;
  border-radius: var(--br-9xs);
  background-color: var(--green-500);
  width: 107px;
  height: 40px;
  display: none;
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: var(--p3-semibold-size);
  color: var(--white);
}
.border,
.merge {
  position: relative;
}
.merge {
  width: 100%;
  height: 40px;
}
.border {
  align-self: stretch;
  border-top: 1px solid var(--grey-80);
  box-sizing: border-box;
  height: 1px;
}
.pageTitle,
.topNavigation {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.pageTitle {
  background-color: var(--white);
  width: 100%;
  padding: var(--padding-xs) var(--padding-21xl) 0;
  box-sizing: border-box;
  gap: var(--gap-xs);
  text-align: left;
  font-size: var(--h5-semibold-size);
  color: var(--grey-0);
}
.topNavigation {
  align-self: stretch;
}
.back1 {
  position: absolute;
  top: 5px;
  left: 26px;
  line-height: 155%;
  font-weight: 500;
}
.frameIcon {
  position: absolute;
  top: 0;
  left: 0;
  width: 32px;
  height: 32px;
  overflow: hidden;
}
.back {
  position: relative;
  width: 59px;
  height: 32px;
}
.notifications {
  position: relative;
  font-size: var(--h4-semibold-size);
  font-weight: 600;
  color: var(--grey-900);
}
.titles {
  align-self: stretch;
  background-color: var(--white);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.04);
  overflow: hidden;
  display: none;
  flex-direction: row;
  padding: var(--padding-xs) var(--padding-4xs);
  align-items: center;
  justify-content: space-between;
  color: var(--grey-800);
}
.frameChild {
  position: relative;
  width: 270px;
  height: 270px;
}
.helpInner {
  background-color: var(--green-50);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: 0 144px;
  align-items: flex-start;
  justify-content: flex-start;
}
.frequentlyAskedQuestions {
  position: relative;
  line-height: 24px;
  font-weight: 600;
}
.iconsmessage1 {
  position: relative;
  width: 20px;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.label {
  position: relative;
  line-height: 24px;
}
.largeButtons {
  border-radius: var(--br-8xs);
  background-color: var(--primary-10);
  width: 558px;
  height: 44px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs) 0;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
  cursor: pointer;
  text-align: center;
  font-size: var(--p3-semibold-size);
  color: var(--white);
}
.frequentlyAskedQuestionsParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.cantFindYour {
  position: relative;
  font-size: var(--p3-semibold-size);
  line-height: 24px;
  color: var(--grey-20);
}
.contactUsParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.largeButtons1 {
  border-radius: var(--br-8xs);
  background-color: var(--primary-10);
  width: 558px;
  height: 44px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs) 0;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
  color: var(--white);
}
.iconsadd {
  position: relative;
  width: 20px;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.largeButtons2,
.largeButtonsParent {
  display: flex;
  align-items: center;
  justify-content: center;
}
.largeButtons2 {
  border-radius: var(--br-8xs);
  border: 1px solid var(--primary-10);
  box-sizing: border-box;
  width: 558px;
  height: 44px;
  flex-direction: row;
  padding: var(--padding-5xs) 0;
  gap: var(--gap-5xs);
}
.largeButtonsParent {
  flex-direction: column;
  gap: var(--gap-base);
  text-align: center;
  font-size: var(--p3-semibold-size);
  color: var(--primary-10);
}
.helpUsImprove {
  position: relative;
  font-size: var(--p3-semibold-size);
  line-height: 24px;
  color: var(--grey-700);
}
.label5 {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 26px;
  font-weight: 500;
}
.labelContainer {
  align-self: stretch;
  position: relative;
  height: 28px;
  display: none;
}
.iconseye {
  position: absolute;
  top: 12px;
  right: 8px;
  width: 24px;
  height: 24px;
  display: none;
}
.userInput {
  position: absolute;
  width: calc(100% - 16px);
  top: 11px;
  left: 8px;
  line-height: 26px;
  display: inline-block;
}
.dotChild {
  position: relative;
  border-radius: 50%;
  background-color: var(--grey-10);
  width: 8px;
  height: 8px;
}
.dot {
  position: absolute;
  top: 20px;
  left: 12px;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-6xs);
}
.baseField {
  align-self: stretch;
  flex: 1;
  position: relative;
  border-radius: var(--br-8xs);
  background-color: var(--grey-100);
  border: 1px solid var(--grey-70);
  overflow: hidden;
  color: var(--grey-50);
}
.div,
.thisIsA {
  position: absolute;
  top: 0;
  line-height: 24px;
}
.thisIsA {
  left: 0;
}
.div {
  right: 0;
  text-align: right;
}
.helperTextContainer {
  align-self: stretch;
  position: relative;
  height: 24px;
  display: none;
  font-size: var(--p3-semibold-size);
}
.input,
.largeButtons5 {
  width: 558px;
  height: 136px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-11xs);
}
.largeButtons5 {
  border-radius: var(--br-8xs);
  background-color: var(--primary-10);
  height: 44px;
  flex-direction: row;
  padding: var(--padding-5xs) 0;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
  text-align: center;
  font-size: var(--p3-semibold-size);
  color: white;
}
.inputParent {
  display: flex;
  justify-content: flex-start;
  gap: var(--gap-xl);
  font-size: var(--p2-regular-size);
  color: var(--grey-20);
}
.form,
.help1,
.inputParent {
  flex-direction: column;
  align-items: flex-start;
}
.form {
  display: flex;
  justify-content: flex-start;
  gap: var(--gap-13xl);
}
.help1 {
  gap: var(--gap-xl);
  text-align: left;
  font-size: var(--p1-semibold-size);
  color: var(--grey-0);
}
.help,
.help1,
.topNavigationParent {
  display: flex;
  justify-content: flex-start;
}
.topNavigationParent {
  flex: 1;
  flex-direction: column;
  align-items: center;
  color: var(--white);
}
.help {
  position: relative;
  background-color: var(--color-gray-100);
  width: 100%;
  overflow-y: auto;
  flex-direction: row;
  align-items: flex-start;
  text-align: center;
  font-size: var(--p3-semibold-size);
  color: var(--grey-10);
  font-family: var(--p3-bold);
}
@media screen and (max-width: 960px) {
  .navPrototype,
  .topNavigation {
    display: none;
  }
  .titles {
    display: flex;
  }
}
@media screen and (max-width: 430px) {
  .cantFindYour,
  .contactUsParent,
  .form,
  .frequentlyAskedQuestions,
  .frequentlyAskedQuestionsParent,
  .input,
  .inputParent,
  .largeButtons,
  .largeButtons1,
  .largeButtons2,
  .largeButtons5,
  .largeButtonsParent {
    align-self: stretch;
    width: auto;
  }
  .helpInner{
    padding: 0px;
  }
}
