.navChild {
  position: absolute;
  top: 27px;
  left: 20px;
  width: 125px;
  height: 20px;
}
.iconshome {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.home {
  position: relative;
  font-size: var(--p3-semibold-size);
  line-height: 24px;
  font-weight: 600;
  font-family: var(--caption-bold);
  color: var(--primary-10);
  text-align: center;
}
.default,
.iconshomeParent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.iconshomeParent {
  flex-direction: row;
  gap: var(--gap-5xs);
}
.default {
  cursor: pointer;
  border: 0;
  padding: var(--padding-5xs);
  background-color: var(--grey-90);
  border-radius: var(--br-9xs);
  width: 181px;
  overflow: hidden;
  flex-direction: column;
  box-sizing: border-box;
}
.home1 {
  position: relative;
  font-size: var(--p3-semibold-size);
  line-height: 24px;
  font-weight: 500;
  font-family: var(--caption-bold);
  color: var(--grey-10);
  text-align: center;
}
.default1 {
  cursor: pointer;
  border: 0;
  padding: var(--padding-5xs);
  background-color: transparent;
  border-radius: var(--br-11xs);
  width: 181px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
}
.accountIcon {
  position: relative;
  width: 24px;
  height: 24px;
}
.defaultParent {
  position: absolute;
  top: 87px;
  left: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xs);
}
.nav {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--grey-50);
}
.iconsadd,
.navPrototype {
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.navPrototype {
  margin: 0;
  align-self: stretch;
  width: 221px;
}
.iconsadd {
  width: 16px;
  height: 16px;
}
.button {
  position: relative;
  line-height: 100%;
  font-weight: 600;
}
.iconsaddParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-9xs);
}
.avatarIcon,
.smallButtons {
  position: absolute;
  top: 14px;
  height: 40px;
}
.smallButtons {
  right: 427px;
  border-radius: var(--br-9xs);
  background-color: var(--logg-green);
  width: 146px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.avatarIcon {
  left: 1139px;
  width: 40px;
  display: none;
}
.licenseAndCertification {
  position: absolute;
  top: 18px;
  left: 234px;
  font-size: var(--h5-semibold-size);
  line-height: 28px;
  color: var(--grey-900);
  text-align: left;
}
.icon {
  position: absolute;
  top: 14px;
  left: 52px;
  border: 1px solid var(--grey-80);
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs);
  align-items: center;
  justify-content: center;
}
.component102 {
  position: relative;
  background-color: var(--white);
  border-bottom: 1px solid var(--grey-80);
  box-sizing: border-box;
  width: 100%;
  height: 68px;
  overflow: hidden;
  flex-shrink: 0;
}
.medicalCaseLine {
  position: relative;
  width: 112.5px;
  height: 102.66px;
}
.clickTheButton {
  position: relative;
  line-height: 26px;
  display: inline-block;
  width: 258px;
}
.medicalCaseLineParent {
  flex-direction: column;
  align-items: center;
  gap: var(--gap-5xl);
  font-size: var(--p2-semibold-size);
  color: var(--grey-20);
}
.component102Parent,
.items1,
.medicalCaseLineParent {
  display: flex;
  justify-content: flex-start;
}
.component102Parent {
  align-self: stretch;
  flex: 1;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.items1 {
  background-color: var(--color-gray-100);
  width: 100%;
  height: 994px;
  overflow-y: auto;
  flex-shrink: 0;
  flex-direction: row;
  align-items: flex-start;
}
.activities {
  position: absolute;
  top: 17px;
  left: 126px;
  font-size: 20px;
  font-weight: 600;
}
.back1 {
  position: absolute;
  top: 5px;
  left: 26px;
  line-height: 155%;
  font-weight: 500;
}
.back,
.frameIcon {
  position: absolute;
  height: 32px;
}
.frameIcon {
  top: 0;
  left: 0;
  width: 32px;
  overflow: hidden;
}
.back {
  top: 12px;
  left: 9px;
  width: 59px;
  color: var(--grey-800);
}
.edit2Icon {
  position: relative;
  width: 12px;
  height: 12px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.edit {
  position: relative;
  line-height: 155%;
  font-weight: 600;
}
.button1,
.titles {
  background-color: var(--white);
}
.button1 {
  position: absolute;
  top: 12px;
  left: 328px;
  border-radius: var(--br-9xs);
  border: 1px solid var(--logg-green);
  box-sizing: border-box;
  width: 66px;
  display: none;
  flex-direction: column;
  padding: var(--padding-8xs) var(--padding-xs);
  align-items: center;
  justify-content: center;
  text-align: left;
  color: var(--logg-green);
}
.titles {
  position: relative;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.04);
  width: 414px;
  height: 56px;
  overflow: hidden;
  flex-shrink: 0;
}
.medicalCaseLine1 {
  position: relative;
  width: 112.5px;
  height: 100px;
}
.clickTheButton1 {
  position: relative;
  line-height: 155%;
  font-weight: 500;
  display: inline-block;
  width: 258px;
}
.medicalCaseLineGroup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xl);
  font-size: var(--p2-semibold-size);
  color: var(--grey-600);
}
.icons {
  position: relative;
  width: 20px;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.promoteProfile {
  position: relative;
  font-size: var(--p2-medium-size);
  line-height: 155%;
  font-family: var(--caption-bold);
  color: var(--white);
  text-align: center;
}
.button2,
.iconsParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.iconsParent {
  gap: var(--gap-5xs);
}
.button2 {
  cursor: pointer;
  border: 1px solid var(--logg-green);
  padding: var(--padding-xs) 0 var(--padding-smi);
  background-color: var(--logg-green);
  border-radius: var(--br-9xs);
  box-sizing: border-box;
  width: 181px;
}
.items,
.signup {
  flex-direction: column;
  justify-content: flex-start;
}
.signup {
  background-color: var(--white);
  width: 414px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  box-sizing: border-box;
  align-items: center;
  gap: 10px;
  color: var(--grey-900);
  padding: 10px;
}
.items {
  position: relative;
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: var(--gap-3xs);
  text-align: center;
  font-size: var(--p3-semibold-size);
  color: var(--white);
  font-family: var(--caption-bold);
}
@media screen and (max-width: 960px) {
  .items1,
  .navPrototype {
    display: none;
  }
  .signup {
    display: flex;
  }
  .items {
    align-items: center;
    justify-content: flex-start;
  }
}
