.frameChild {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--white);
  width: 414px;
  height: 258px;
}
.groupChild {
  position: absolute;
  top: 0;
  left: 6px;
  width: 56px;
  height: 56px;
}
.document {
  position: absolute;
  top: 64px;
  left: 0;
  line-height: 155%;
  font-weight: 500;
  mix-blend-mode: normal;
}
.groupParent {
  position: absolute;
  top: 32px;
  left: 22px;
  width: 69px;
  height: 86px;
}
.camera {
  position: absolute;
  top: 64px;
  left: 1px;
  line-height: 155%;
  font-weight: 500;
  mix-blend-mode: normal;
}
.cameraParent,
.groupItem {
  position: absolute;
  top: 0;
  left: 0;
  width: 56px;
  height: 56px;
}
.cameraParent {
  top: 32px;
  left: 133px;
  height: 86px;
}
.gallery {
  position: absolute;
  top: 64px;
  left: 4px;
  line-height: 155%;
  font-weight: 500;
  mix-blend-mode: normal;
}
.galleryParent,
.groupIcon,
.locationParent {
  position: absolute;
  top: 32px;
  left: 233px;
  width: 56px;
  height: 86px;
}
.groupIcon,
.locationParent {
  top: 0;
  left: 1px;
  height: 56px;
}
.locationParent {
  top: 32px;
  left: 331px;
  width: 58px;
  height: 86px;
}
.offer {
  position: absolute;
  top: 64px;
  left: 10px;
  line-height: 155%;
  font-weight: 500;
  mix-blend-mode: normal;
}
.offerParent {
  position: absolute;
  top: 137px;
  left: 28px;
  width: 56px;
  height: 86px;
}
.quote {
  position: absolute;
  top: 64px;
  left: 7px;
  line-height: 155%;
  font-weight: 500;
  mix-blend-mode: normal;
}
.quoteParent {
  position: absolute;
  top: 137px;
  left: 133px;
  width: 56px;
  height: 86px;
}
.rectangleParent {
  position: relative;
  border-radius: var(--br-xs) var(--br-xs) 0 0;
  box-shadow: 0-2px 4px rgba(0, 0, 0, 0.08);
  width: 414px;
  height: 258px;
  overflow: hidden;
  max-width: 100%;
  max-height: 100%;
  text-align: center;
  font-size: var(--p3-bold-size);
  color: var(--grey-800);
  font-family: var(--p3-bold);
}
