.modalTopBarChild {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--white);
  width: 414px;
  height: 60px;
}
.addCertificate {
  position: absolute;
  top: 21px;
  left: 152px;
  font-weight: 600;
}
.evacloseOutlineIcon,
.icons {
  position: absolute;
  top: 14px;
  width: 32px;
  height: 32px;
}
.evacloseOutlineIcon {
  left: 20px;
  overflow: hidden;
}
.icons {
  left: 362px;
  display: none;
}
.modalTopBar {
  position: relative;
  width: 414px;
  height: 60px;
  overflow: hidden;
  flex-shrink: 0;
}
.input {
  border: 0;
  background-color: transparent;
  position: relative;
}
.peopleavatarIcon {
  position: relative;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  object-fit: cover;
}
.peoplename,
.yesterday {
  position: relative;
  font-weight: 600;
  display: flex;
  align-items: center;
  width: 246px;
  height: 19px;
  flex-shrink: 0;
}
.yesterday {
  font-size: var(--caption-medium-size);
  line-height: 155%;
  font-weight: 500;
  color: var(--grey-600);
  text-align: right;
  width: 62px;
  height: 15px;
}
.peoplenameParent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.citiessummary {
  position: relative;
  line-height: 155%;
  font-weight: 500;
  display: inline-block;
  width: 322px;
  height: 18px;
  flex-shrink: 0;
  mix-blend-mode: normal;
}
.groupChild {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-5xs);
  background-color: var(--primary-20base);
}
.div {
  position: absolute;
  height: 55.56%;
  width: 66.67%;
  top: 22.22%;
  left: 16.67%;
  letter-spacing: -0.04em;
  line-height: 100%;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}
.rectangleParent {
  position: relative;
  width: 24px;
  height: 18px;
  display: none;
  text-align: center;
  font-size: var(--caption-medium-size);
  color: var(--white);
}
.citiessummaryParent,
.frameGroup,
.peopleavatarParent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.citiessummaryParent {
  width: 324px;
  flex-direction: row;
  gap: var(--gap-14xl);
  font-size: var(--button-small-text-size);
  color: var(--grey-700);
}
.frameGroup,
.peopleavatarParent {
  flex-direction: column;
  gap: var(--gap-11xs);
}
.peopleavatarParent {
  flex-direction: row;
  gap: var(--gap-3xs);
}
.instanceChild {
  position: relative;
  border-top: 1px solid var(--grey-100);
  box-sizing: border-box;
  width: 375px;
  height: 1px;
}
.frameParent,
.instanceParent,
.recentChats {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.frameParent {
  width: 374px;
  gap: var(--gap-base);
}
.instanceParent,
.recentChats {
  gap: var(--gap-xl);
  text-align: left;
}
.recentChats {
  position: relative;
  background-color: var(--white);
  box-shadow: 0-2px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  align-items: center;
  gap: var(--gap-xs);
  max-width: 100%;
  text-align: center;
  font-size: var(--h4-semibold-size);
  color: var(--grey-900);
  font-family: var(--p3-bold);
  padding: 1rem;
}
