.addANew {
  position: relative;
  line-height: 28px;
  font-weight: 600;
}
.iconsclose {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.addANewCardParent {
  position: absolute;
  width: calc(100% - 64px);
  top: 16px;
  right: 32px;
  left: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.modalTitle {
  position: relative;
  width: 100%;
  height: 64px;
  overflow: hidden;
  flex-shrink: 0;
}
.label {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 26px;
  font-weight: 500;
}
.labelContainer {
  width: 100%;
  position: relative;
  height: 28px;
}
.iconschevronDown {
  position: absolute;
  top: 12px;
  right: 8px;
  width: 24px;
  height: 24px;
  overflow: hidden;
}
.userInput {
  position: absolute;
  width: calc(100% - 16px);
  top: 11px;
  left: 8px;
  line-height: 26px;
  display: inline-block;
}
.dotChild {
  position: relative;
  border-radius: 50%;
  background-color: var(--grey-10);
  width: 8px;
  height: 8px;
}
.dot {
  position: absolute;
  top: 20px;
  left: 12px;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-6xs);
}
.baseField {
  align-self: stretch;
  position: relative;
  border-radius: var(--br-8xs);
  background-color: var(--grey-100);
  border: 1px solid var(--grey-70);
  box-sizing: border-box;
  height: 48px;
  overflow: hidden;
  flex-shrink: 0;
  color: var(--grey-10);
}
.div,
.thisIsA {
  position: absolute;
  top: 0;
  line-height: 24px;
}
.thisIsA {
  left: 0;
}
.div {
  right: 0;
  text-align: right;
}
.helperTextContainer {
  align-self: stretch;
  position: relative;
  height: 24px;
  display: none;
  font-size: var(--p3-bold-size);
}
.input {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-11xs);
}
.iconsplaceholder {
  position: absolute;
  top: 12px;
  right: 8px;
  width: 24px;
  height: 24px;
  display: none;
}
.input1 {
  width: 100%;
  flex-direction: column;
  gap: var(--gap-11xs);
}
.input1,
.input2,
.inputGroup {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.input2 {
  width: 100%;
  flex-direction: column;
  gap: var(--gap-11xs);
}
.inputGroup {
  flex-direction: row;
  gap: var(--gap-xl);
  width:100%
}
.qn0001,
.quoteNumber {
  position: absolute;
  top: 18.18%;
  line-height: 26px;
  font-weight: 600;
}
.quoteNumber {
  left: 8px;
  color: var(--grey-20);
  text-align: left;
}
.qn0001 {
  right: 8px;
}
.success {
  position: relative;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  font-weight: 600;
}
.transactionPills {
  position: absolute;
  top: 13px;
  left: 504px;
  border-radius: var(--br-81xl);
  background-color: var(--green-100);
  display: none;
  flex-direction: row;
  padding: var(--padding-9xs) var(--padding-7xs);
  align-items: center;
  justify-content: flex-end;
  font-size: var(--font-size-5xs);
}
.quoteCards {
  position: relative;
  background-color: var(--white);
  width: 100%;
  height: 44px;
  text-align: right;
  color: var(--primary-20base);
}
.inputParent {
  gap: var(--gap-xs);
}
.frameWrapper,
.inputParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.icons20pxplaceholder {
  position: relative;
  width: 20px;
  height: 20px;
  display: none;
}
.label7 {
  position: relative;
  line-height: 24px;
}
.largeButtons {
  border-radius: var(--br-8xs);
  background-color: var(--primary-20base);
  width: 100%;
  height: 48px;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
  cursor: pointer;
  text-align: center;
  font-size: var(--p3-bold-size);
  color: var(--white);
}
.frameParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
  font-size: var(--p2-semibold-size);
  color: var(--grey-20);
}
.bookingfee,
.modalTitleParent {
  display: flex;
  align-items: center;
}
.modalTitleParent {
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.bookingfee {
  position: relative;
  border-radius: var(--br-8xs);
  background-color: var(--white);
  box-shadow: var(--popup);
  width: 414px;
  flex-direction: row;
  padding: 0 0.5rem var(--padding-13xl);
 
  box-sizing: border-box;
  justify-content: center;
  max-width: 100%;
  text-align: left;
  font-size: var(--h5-semibold-size);
  color: var(--grey-1);
  font-family: var(--p3-bold);
}
