.back1 {
  position: absolute;
  top: 5px;
  left: 26px;
  line-height: 155%;
  font-weight: 500;
}
.Fab{
  display: none;
}
.frameIcon {
  position: absolute;
  top: 0;
  left: 0;
  width: 32px;
  height: 32px;
  overflow: hidden;
}
.back {
  position: relative;
  width: 59px;
  height: 32px;
}
.activities {
  position: relative;
  font-size: 20px;
  font-weight: 600;
  color: var(--grey-900);
}
.titles {
  align-self: stretch;
  background-color: var(--white);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: var(--padding-xs) var(--padding-4xs);
  align-items: center;
  justify-content: space-between;
  z-index: 0;
  text-align: center;
}
.emailChild {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-9xs);
  background-color: var(--grey-50);
  border: 1px solid var(--grey-70);
  box-sizing: border-box;
}
.ddMm,
.jackDorsey {
  position: absolute;
  top: 42.5%;
  left: 2.67%;
  letter-spacing: -0.04em;
  display: none;
}
.jackDorsey {
  top: 40%;
  left: 3.21%;
  font-size: var(--h4-bold-size);
  color: var(--gray-1);
}
.email {
  position: relative;
  width: 374px;
  height: 40px;
  z-index: 0;
}
.frameIcon1,
.search {
  position: absolute;
  margin: 0 !important;
}
.frameIcon1 {
  height: 50%;
  width: 5.35%;
  top: 25%;
  right: 92.51%;
  bottom: 25%;
  left: 2.14%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  z-index: 1;
}
.search {
  top: 20%;
  left: 9.63%;
  line-height: 155%;
  font-weight: 500;
  color: var(--grey-600);
  z-index: 2;
}
.drafts,
.input {
  position: relative;
}
.input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-3xs);
}
.drafts {
  line-height: 155%;
  font-weight: 600;
  mix-blend-mode: normal;
}
.frameChild {
  position: relative;
  border-radius: var(--br-11xs) var(--br-11xs) 0 0;
  background-color: var(--logg-green);
  width: 61px;
  height: 2px;
}
.draftsParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  color: var(--logg-green);
}
.accepted,
.all {
  position: relative;
  line-height: 155%;
  font-weight: 500;
  mix-blend-mode: normal;
}
.all {
  display: inline-block;
  height: 6.82px;
  flex-shrink: 0;
}
.quotesBottomTab,
.titles1,
.topBar {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}
.quotesBottomTab,
.topBar {
  flex-direction: column;
  align-items: center;
}
.quotesBottomTab {
  flex: 1;
  text-align: center;
  color: var(--grey-600);
}
.topBar {
  align-self: stretch;
  height: 142px;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
  font-size: var(--p2-semibold-size);
  color: var(--gray-3);
}
.quoteNo {
  font-weight: 500;
}
.qn001 {
  font-weight: 600;
  color: var(--grey-800);
}
.quoteNoQn001Container1 {
  line-break: anywhere;
  width: 100%;
}
.quoteNoQn001Container {
  position: relative;
  line-height: 155%;
  display: flex;
  align-items: center;
  width: 278px;
  flex-shrink: 0;
}
.moreVerticalIcon {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.quoteNoQn001Parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-53xl);
}
.frameItem {
  position: relative;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  object-fit: cover;
}
.paid,
.peoplename {
  position: relative;
  line-height: 155%;
}
.paid {
  letter-spacing: 0.1em;
  text-transform: uppercase;
}
.paidWrapper {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-81xl);
  background-color: var(--green-100);
  display: flex;
  flex-direction: row;
  padding: var(--padding-11xs) var(--padding-5xs);
  align-items: center;
  justify-content: center;
}
.status,
.success {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 18px;
}
.status {
  left: 38px;
}
.cancelledWrapper,
.success1 {
  position: absolute;
  top: 0;
  left: 0;
}
.cancelledWrapper {
  border-radius: var(--br-81xl);
  background-color: var(--red-100);
  display: flex;
  flex-direction: row;
  padding: var(--padding-11xs) var(--padding-5xs);
  align-items: center;
  justify-content: center;
}
.success1 {
  width: 78px;
  height: 18px;
  color: var(--red-500);
}
.statusParent {
  position: relative;
  width: 78px;
  height: 18px;
  display: none;
  text-align: center;
  font-size: var(--caption-block-size);
  color: var(--logg-green);
}
.peoplenameParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-97xl);
}
.peoplename1,
.peoplename2 {
  position: relative;
  line-height: 155%;
}
.peoplename1 {
  font-weight: 500;
}
.peoplename2 {
  color: var(--logg-green);
}
.frameGroup,
.peoplenameGroup {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.peoplenameGroup {
  flex-direction: row;
  gap: var(--gap-151xl);
  font-size: var(--caption-bold-size);
  color: var(--grey-700);
}
.frameGroup {
  flex-direction: column;
}
.ellipseParent,
.frameParent {
  display: flex;
  gap: var(--gap-5xs);
}
.ellipseParent {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: var(--h4-bold-size);
  color: var(--grey-900);
}
.frameParent {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.quoteChild {
  position: relative;
  border-top: 1px solid var(--gray-6);
  box-sizing: border-box;
  width: 375px;
  height: 1px;
  display: none;
}
.quote,
.quoteParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.quoteParent {
  gap: var(--gap-5xl);
  z-index: 2;
  color: var(--grey-600);
  height: 100%;
  overflow-y: auto;
}
.buttonquoteFabprimaryIcon {
  position: absolute;
  margin: 0 !important;
  top: 376px;
  left: 694px;
  width: 52px;
  height: 52px;
  z-index: 3;
}
.wallet {
  align-self: stretch;
  flex: 1;
  background-color: var(--white);
  overflow: hidden;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  padding: 0 1rem;
}
.navChild {
  position: absolute;
  top: 27px;
  left: 20px;
  width: 125px;
  height: 20px;
}
.home {
  position: relative;
  font-size: var(--p3-semibold-size);
  line-height: 24px;
  font-weight: 600;
  font-family: var(--caption-bold);
  color: var(--primary-10);
  text-align: center;
}
.default,
.iconshomeParent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.iconshomeParent {
  flex-direction: row;
  gap: var(--gap-5xs);
}
.default {
  cursor: pointer;
  border: 0;
  padding: var(--padding-5xs);
  background-color: var(--grey-90);
  border-radius: var(--br-9xs);
  width: 181px;
  overflow: hidden;
  flex-direction: column;
  box-sizing: border-box;
}
.home1 {
  position: relative;
  font-size: var(--p3-semibold-size);
  line-height: 24px;
  font-weight: 500;
  font-family: var(--caption-bold);
  color: var(--grey-10);
  text-align: center;
}
.default1 {
  cursor: pointer;
  border: 0;
  padding: var(--padding-5xs);
  background-color: transparent;
  border-radius: var(--br-11xs);
  width: 181px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
}
.accountIcon {
  position: relative;
  width: 24px;
  height: 24px;
}
.defaultParent {
  position: absolute;
  top: 87px;
  left: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xs);
}
.nav {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--grey-50);
}
.iconsadd,
.navPrototype {
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.navPrototype {
  margin: 0;
  width: 221px;
  height: 100vh;
}
.iconsadd {
  width: 16px;
  height: 16px;
}
.button {
  position: relative;
  font-size: var(--p3-semibold-size);
  line-height: 100%;
  font-weight: 600;
  font-family: var(--caption-bold);
  color: var(--white);
  text-align: center;
}
.iconsaddParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-9xs);
}
.avatarIcon,
.smallButtons {
  position: absolute;
  top: 14px;
  height: 40px;
}
.smallButtons {
  cursor: pointer;
  border: 0;
  background-color: var(--logg-green);
  right: 10rem;
  border-radius: var(--br-9xs);
  width: 146px;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.createqoute {
  cursor: pointer;
  border: 0;
  background-color: var(--logg-green);
  right: 10rem;
  border-radius: var(--br-9xs);
  width: 146px;
  height: 40px;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.avatarIcon {
  left: 1139px;
  width: 40px;
  display: none;
}
.licenseAndCertification {
  position: absolute;
  top: 18px;
  left: 240px;
  line-height: 28px;
}
.icon {
  cursor: pointer;
  border: 1px solid var(--grey-80);
  padding: var(--padding-5xs);
  background-color: transparent;
  position: absolute;
  top: 14px;
  left: 52px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.component102 {
  position: relative;
  background-color: var(--white);
  border-bottom: 1px solid var(--grey-80);
  box-sizing: border-box;
  width: 100%;
  height: 68px;
  overflow: hidden;
  flex-shrink: 0;
}
.input1 {
  border: 0;
  background-color: transparent;
}
.booking {
  position: relative;
  line-height: 26px;
  font-weight: 600;
  mix-blend-mode: normal;
}
.all1 {
  border-bottom: 2px solid var(--logg-green);
  box-sizing: border-box;
  width: 111.6px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs);
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: var(--logg-green);
}
.booking2 {
  position: relative;
  line-height: 26px;
  mix-blend-mode: normal;
}
.payment {
  width: 111.6px;
  padding: var(--padding-5xs);
  box-sizing: border-box;
  cursor: pointer;
}
.allParent,
.payment,
.tabsSubSwitch {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.tabsSubSwitch {
  background-color: var(--color-gray-100);
  border-bottom: 1px solid var(--grey-80);
  box-sizing: border-box;
  width: 558px;
  flex-direction: column;
}
.span {
  color: var(--grey-0);
}
.citiessummary {
  position: relative;
  line-height: 24px;
  font-weight: 500;
}
.success4 {
  position: relative;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  font-weight: 600;
}
.transactionPills {
  border-radius: var(--br-81xl);
  background-color: var(--green-100);
  display: none;
  flex-direction: row;
  padding: var(--padding-9xs) var(--padding-7xs);
  align-items: center;
  justify-content: flex-end;
  text-align: right;
  font-size: var(--font-size-5xs);
  color: var(--logg-green);
}
.merge,
.mergeParent {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.merge {
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.mergeParent {
  align-self: stretch;
  justify-content: space-between;
}
.avatarIcon1 {
  position: relative;
  width: 40px;
  height: 40px;
}
.peoplename6 {
  align-self: stretch;
  font-weight: 600;
}
.date,
.peoplename6 {
  position: relative;
  line-height: 24px;
}
.dateTimeChild {
  position: relative;
  background-color: var(--grey-20);
  width: 1px;
  height: 16px;
}
.dateTime {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-9xs);
  font-size: var(--p3-semibold-size);
  color: var(--grey-20);
}
.merge1,
.profile,
.profileWrapper {
  flex: 1;
  display: flex;
  justify-content: flex-start;
}
.merge1 {
  flex-direction: column;
  align-items: flex-start;
}
.profile,
.profileWrapper {
  flex-direction: row;
}
.profile {
  align-items: center;
  gap: var(--gap-5xs);
}
.profileWrapper {
  align-items: flex-start;
}
.div {
  position: relative;
  font-size: var(--p2-semibold-size);
  line-height: 26px;
  font-weight: 600;
  color: var(--logg-green);
  text-align: right;
}
.container,
.jobs {
  align-self: stretch;
}
.container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  gap: var(--gap-9xs);
  font-size: var(--p1-semibold-size);
  color: var(--grey-0);
}
.jobs {
  gap: var(--gap-5xs);
}
.inputParent,
.jobs,
.quote2,
.quote3,
.quoteWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.quote2 {
  border-bottom: 1px solid var(--grey-80);
  box-sizing: border-box;
  width: 558px;
  padding: var(--padding-base) var(--padding-xs);
}
.quote3 {
  border-bottom: 1px solid var(--grey-80);
  box-sizing: border-box;
  width: 400px;
  padding: var(--padding-base) var(--padding-xs);
}
.inputParent,
.quoteWrapper {
  text-align: left;
  font-size: var(--p3-semibold-size);
}
.inputParent {
  flex: 1;
  gap: var(--gap-base);
  text-align: center;
  font-size: var(--p2-semibold-size);
  color: var(--grey-20);
}
.component102Parent {
  flex: 1;
  flex-direction: column;
  align-items: center;
  gap: var(--gap-xl);
  width: 100%;
  height: 100vh;
  overflow-y: auto;
}
.component102Parent,
.myquote,
.myquotes,
.myquotesInner {
  display: flex;
  justify-content: flex-start;
}
.myquotesInner {
  align-self: stretch;
  flex: 1;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  overflow-y: auto;
}
.myquote,
.myquotes {
  align-items: flex-start;
}
.myquotes {
  background-color: var(--color-gray-100);
  width: 100%;
  overflow: hidden;
  flex-direction: row;
  font-size: var(--h5-semibold-size);
  color: var(--grey-900);
}
.myquote {
  position: relative;
  width: 100%;
  flex-direction: column;
  text-align: left;
  font-size: var(--p3-semibold-size);
  color: var(--grey-800);
  font-family: var(--caption-bold);
}
@media screen and (max-width: 960px) {
  .wallet {
    display: flex;
  }
    .Fab {
      display: inline;
    }
  .component102,
  .inputParent,
  .myquotes,
  .navPrototype {
    display: none;
  }
  .myquote {
    align-items: center;
    justify-content: flex-start;
    height: 100vh;
  }
  .quote3,
  .quote2{
    width:100%
  }
  
  .ellipseParent{
    font-size: 17px;
  }
}
