.brandLogoIcon {
  width: 40px;
  height: 40px;
}
.brandLogoIcon,
.frameIcon,
.headerChild {
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.headerChild {
  width: 26px;
  height: 26px;
  display: none;
}
.frameIcon {
  width: 24px;
  height: 31px;
}
.div,
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.div {
  position: relative;
  letter-spacing: -0.04em;
  line-height: 100%;
  font-weight: 600;
  width: 16px;
  height: 10px;
  flex-shrink: 0;
}
.wrapper {
  border-radius: var(--br-5xs);
  background-color: var(--logg-green);
  width: 24px;
  height: 18px;
  flex-direction: column;
  margin-left: -14px;
}
.frameParent,
.ticker {
  width: 34px;
  height: 31px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.ticker {
  border-radius: var(--br-5xs);
  background-color: var(--logg-green);
  width: 24px;
  height: 18px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: -16px;
}
.frame,
.frameGroup{
  display: flex;
  flex-direction: row;
}
.header {
  display: none;
  flex-direction: row;
}
.frameGroup {
  width: 32px;
  height: 31px;
  align-items: center;
  justify-content: flex-start;
}
.frame,
.header {
  overflow: hidden;
  align-items: center;
}
.frame {
  width: 88px;
  height: 31px;
  flex-shrink: 0;
  justify-content: flex-start;
  gap: var(--gap-3xl);
}
.header {
  align-self: stretch;
  background-color: var(--white);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.04);
  padding: var(--padding-3xs) var(--padding-xs);
  justify-content: space-between;
  text-align: center;
  font-size: var(--caption-bold-size);
  color: var(--white);
}
.chevronUpIcon,
.mapPinIcon {
  position: absolute;
  top: 2px;
  left: 0;
  width: 20px;
  height: 20px;
  overflow: hidden;
}
.chevronUpIcon {
  height: 80%;
  width: 11.11%;
  top: 88%;
  right: -11.11%;
  bottom: -68%;
  left: 100%;
  max-width: 100%;
  max-height: 100%;
}
.manchesterEngland {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 155%;
  font-weight: 500;
}
.frame1 {
  position: absolute;
  top: 0;
  left: 24px;
  width: 180px;
  height: 25px;
  overflow: hidden;
}
.locationChild,
.mapPinParent {
  position: relative;
  width: 204px;
  height: 25px;
}
.locationChild {
  border-top: 1px solid var(--grey-300);
  box-sizing: border-box;
  width: 375px;
  height: 1px;
}
.toggleToGo {
  line-height: 155%;
  font-weight: 500;
}
.toggle,
.toggleToGo {
  position: relative;
}
.location,
.toggleToGoOnlineOrOfflineParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-87xl);
  text-align: left;
  font-size: var(--p3-semibold-size);
  color: var(--grey-700);
}
.location {
  align-self: stretch;
  border-radius: var(--br-5xs);
  background-color: var(--white);
  border: 1px solid var(--grey-300);
  overflow: hidden;
  flex-direction: column;
  padding: var(--padding-xl) var(--padding-3xs);
  gap: var(--gap-xl);
  text-align: center;
  font-size: var(--p2-semibold-size);
  color: var(--grey-900);
  margin: 0.5rem;
}
.loggJobs1 {
  position: relative;
  line-height: 155%;
}
.fa6SolidlistIcon {
  position: relative;
  width: 16px;
  height: 16px;
  overflow: hidden;
  flex-shrink: 0;
}
.listView {
  position: relative;
  font-size: var(--p2-semibold-size);
  line-height: 28px;
  font-weight: 500;
  font-family: var(--caption-bold);
  color: var(--grey-900);
  text-align: left;
}
.fa6SolidlistParent {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  width: 92px;
  height: 28px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.loggJobsParent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}
.statusChild,
.statusItem {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-5xs);
  width: 101px;
  height: 132px;
}
.statusChild {
  object-fit: cover;
}
.statusItem {
  background-color: var(--color-gray-500);
}
.statusInner {
  position: absolute;
  top: 90px;
  left: 10px;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  object-fit: cover;
}
.rectangleIcon,
.status {
  width: 101px;
  height: 132px;
}
.status {
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 0;
}
.rectangleIcon {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
}
.status1,
.status2,
.status3,
.status4,
.status5,
.status6 {
  position: relative;
  width: 101px;
  height: 132px;
  overflow: hidden;
  flex-shrink: 0;
}
.status1 {
  border-radius: var(--br-xs);
  z-index: 1;
}
.status2,
.status3,
.status4,
.status5,
.status6 {
  z-index: 2;
}
.status3,
.status4,
.status5,
.status6 {
  position: absolute;
  margin: 0 !important;
  top: 0;
  left: 363px;
  z-index: 3;
}
.status4,
.status5,
.status6 {
  left: 476px;
  z-index: 4;
}
.status5,
.status6 {
  left: 589px;
  z-index: 5;
}
.status6 {
  left: 702px;
  z-index: 6;
}
.loggJobs,
.statusParent {
  align-self: stretch;
  flex-shrink: 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.statusParent {
  height: 140px;
  overflow-x: auto;
  flex-direction: row;
  position: relative;
  gap: var(--gap-xs);
}
.loggJobs {
  background-color: var(--white);
  height: 202px;
  overflow: hidden;
  flex-direction: column;
  padding: var(--padding-smi) var(--padding-3xs);
  box-sizing: border-box;
  gap: var(--gap-lg);
  margin: 0.5rem;
}
.createJobQuote,
.quote {
  align-self: stretch;
  line-height: 155%;
}
.quote {
  position: relative;
}
.createJobQuote {
  font-size: var(--p3-semibold-size);
  font-weight: 500;
  color: var(--grey-700);
}
.createJobQuote,
.icons,
.proceed {
  position: relative;
}
.icons {
  width: 20px;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.proceed {
  font-size: var(--p2-medium-size);
  line-height: 155%;
  font-family: var(--caption-bold);
  color: var(--logg-green);
  text-align: center;
}
.button,
.iconsParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.iconsParent {
  gap: var(--gap-5xs);
}
.button {
  cursor: pointer;
  border: 1px solid var(--logg-green);
  padding: var(--padding-xs) 0 var(--padding-smi);
  background-color: var(--white);
  align-self: stretch;
  border-radius: var(--br-9xs);
}
.frame2,
.userReviews {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.frame2 {
  align-self: stretch;
  align-items: flex-start;
  gap: var(--gap-3xs);
}
.userReviews {
  background-color: var(--white);
  width: 100%;
  height: 149px;
  flex-shrink: 0;
  padding: 0 var(--padding-xl);
  box-sizing: border-box;
  align-items: center;
}
.userReviews2 {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 155%;
}
.frame3 {
  align-self: stretch;
  position: relative;
  height: 30px;
  overflow: hidden;
  flex-shrink: 0;
  font-size: var(--h4-bold-size);
  color: var(--main-text);
}
.b,
.inTheLast {
  position: absolute;
  top: 16px;
  left: 16px;
}
.inTheLast {
  top: 21px;
  left: 63px;
  font-size: var(--p2-semibold-size);
  line-height: 155%;
  font-weight: 500;
}
.parent {
  position: absolute;
  top: 0;
  left: 4px;
  border-radius: var(--br-5xs);
  background-color: var(--green-100);
  width: 370px;
  height: 61px;
}
.frame4 {
  align-self: stretch;
  position: relative;
  height: 61px;
  overflow: hidden;
  flex-shrink: 0;
}
.b1 {
  top: 14px;
  left: 16px;
}
.b1,
.group,
.unread {
  position: absolute;
}
.unread {
  top: 19px;
  left: 50px;
  font-size: var(--p2-semibold-size);
  line-height: 155%;
  font-weight: 500;
}
.group {
  top: 0;
  left: 4px;
  border-radius: var(--br-5xs);
  background-color: #e3eefd;
  width: 370px;
  height: 56px;
}
.frame5 {
  align-self: stretch;
  position: relative;
  height: 56px;
  overflow: hidden;
  flex-shrink: 0;
  color: var(--color-royalblue-100);
}
.reviewsChild {
  position: relative;
  border-top: 1px solid var(--logg-green);
  box-sizing: border-box;
  width: 88px;
  height: 1px;
}
.reviews,
.userReviews1 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: var(--p2-medium-size);
}
.userReviews1 {
  background-color: var(--white);
  overflow: hidden;
  padding: var(--padding-sm) var(--padding-xl);
  gap: 15px;
  font-size: var(--h4-bold1-size);
  color: var(--logg-green);
}
.totalBookings {
  position: absolute;
  top: 81px;
  left: 0;
  line-height: 155%;
  font-weight: 500;
}
.frameChild {
  position: absolute;
  top: 0;
  left: 13px;
  border-radius: 50%;
  border: 6px solid var(--logg-green);
  box-sizing: border-box;
  width: 72px;
  height: 72px;
}
.div2 {
  position: absolute;
  top: 23px;
  left: 34px;
  font-size: var(--p2-semibold-size);
  line-height: 155%;
  font-weight: 600;
  color: var(--logg-green);
  text-align: left;
}
.totalBookingsParent {
  position: relative;
  width: 99px;
  height: 103px;
}
.frameItem {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  border: 6px solid var(--color-sandybrown);
  box-sizing: border-box;
  width: 72px;
  height: 72px;
}
.div3 {
  position: absolute;
  top: 23px;
  left: 24px;
  line-height: 155%;
  font-weight: 600;
}
.ellipseParent {
  position: absolute;
  top: 0;
  left: 1px;
  width: 72px;
  height: 72px;
  font-size: var(--p2-semibold-size);
  color: var(--yellow-600);
}
.completedParent {
  position: relative;
  width: 74px;
  height: 103px;
}
.div4 {
  position: absolute;
  top: 23px;
  left: 19px;
  line-height: 155%;
  font-weight: 600;
}
.container,
.frameInner {
  position: absolute;
  top: 0;
  left: 0;
  width: 72px;
  height: 72px;
}
.container {
  left: 3px;
  font-size: var(--p2-semibold-size);
  color: var(--grey-800);
}
.percentageParent {
  position: relative;
  width: 77px;
  height: 103px;
}
.frameContainer,
.overallCompletedJobs {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.frameContainer {
  flex-direction: row;
  gap: 58px;
  text-align: center;
  font-size: var(--p3-semibold-size);
  color: var(--grey-700);
}
.overallCompletedJobs {
  background-color: var(--white);
  height: 192.36px;
  overflow: hidden;
  flex-shrink: 0;
  flex-direction: column;
  padding: var(--padding-smi) 19px;
  box-sizing: border-box;
  gap: var(--gap-3xl);
  color: var(--grey-1);
}
.div5 {
  position: absolute;
  top: 28px;
  left: 23px;
  line-height: 155%;
  font-weight: 600;
}
.groupIcon,
.parent1 {
  width: 80px;
  height: 80px;
}
.groupIcon {
  position: absolute;
  top: 0;
  left: 0;
}
.parent1 {
  position: relative;
}
.overallSatisfaction {
  font-weight: 500;
}
.span1 {
  color: var(--color-darkslategray-100);
}
.span {
  font-weight: 600;
  color: var(--logg-green);
}
.overallSatisfaction60Container {
  align-self: stretch;
  position: relative;
  font-size: var(--p2-semibold-size);
  line-height: 155%;
}
.responses140,
.surveyDate {
  position: relative;
  line-height: 155%;
  font-weight: 500;
  color: var(--grey-700);
}
.surveyDate {
  font-size: var(--caption-bold-size);
}
.overallSatisfaction60Parent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-8xs);
  text-align: left;
  font-size: var(--p3-semibold-size);
}
.frameDiv {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-21xl);
  text-align: center;
  font-size: var(--p2-semibold-size);
  color: var(--grey-800);
}
.customerSatisfactionMeasure {
  align-self: stretch;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  padding: var(--padding-sm) var(--padding-5xl);
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-3xs);
}
.reviews1 {
  position: relative;
  line-height: 155%;
  font-weight: 500;
  color: var(--grey-600);
}
.parent2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 9px;
}
.frameChild1 {
  position: relative;
  width: 128px;
  height: 24px;
}
.span2 {
  line-height: 155%;
  font-weight: 600;
}
.span3 {
  letter-spacing: -0.03em;
  color: var(--gray-1);
}
.outOf50 {
  line-height: 155%;
  font-weight: 500;
  color: var(--grey-700);
}
.groupParent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-2xs);
  color: var(--grey-900);
}
.customerRatings,
.frameParent1 {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
}
.frameParent1 {
  flex-direction: row;
  align-items: flex-end;
  gap: 51px;
  font-size: var(--p2-semibold-size);
  color: var(--grey-800);
}
.customerRatings {
  background-color: var(--white);
  overflow: hidden;
  flex-direction: column;
  padding: var(--padding-smi) var(--padding-5xl);
  align-items: flex-start;
  gap: var(--gap-base);
}
.seeWhoViewed {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.03em;
  font-weight: 600;
  display: flex;
  align-items: center;
  height: 22.71px;
  flex-shrink: 0;
}
.whoViewedMyProfileChild {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-5xs);
  background-color: var(--white);
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.06);
}
.whoViewedMyProfileItem {
  position: absolute;
  height: 38.4%;
  width: 17.71%;
  top: 17.6%;
  right: 76.38%;
  bottom: 44%;
  left: 5.9%;
  border-radius: 50%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.jamesBorden,
.ontarioUsa,
.pm {
  position: absolute;
  left: 26.57%;
  line-height: 155%;
}
.jamesBorden {
  top: 18.4%;
  font-weight: 600;
}
.ontarioUsa,
.pm {
  top: 38.4%;
  font-weight: 500;
  color: var(--grey-700);
  opacity: 0.7;
  mix-blend-mode: normal;
}
.pm {
  top: 68.8%;
  left: 0;
  color: var(--grey-600);
  text-align: right;
  width: 15rem;
}
.whoViewedMyProfile,
.whoViewedMyProfile1 {
  position: absolute;
  top: 0.16px;
  left: 24px;
  width: 271px;
  height: 125px;
}
.whoViewedMyProfile1 {
  left: 307px;
}
.whoViewedMyProfile2,
.whoViewedMyProfile3,
.whoViewedMyProfile4 {
  position: absolute;
  top: 0.16px;
  left: 590px;
  width: 271px;
  height: 125px;
}
.whoViewedMyProfile3,
.whoViewedMyProfile4 {
  left: 873px;
}
.whoViewedMyProfile4 {
  left: 1156px;
}
.whoViewedMyProfileParent {
  align-self: stretch;
  position: relative;
  height: 135px;
  overflow-x: auto;
  flex-shrink: 0;
  font-size: var(--p3-semibold-size);
  color: var(--grey-900);
}
.seeWhoViewedYourProfile {
  align-self: stretch;
  background-color: var(--white);
  height: 192.36px;
  display: flex;
  flex-direction: column;
  padding: var(--padding-mini) var(--padding-3xs);
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
  font-size: var(--p1-semibold-size);
}
.brandLogoIcon1,
.home2 {
  position: absolute;
  left: 0;
}
.home2 {
  top: 63.46%;
  line-height: 155%;
}
.brandLogoIcon1 {
  height: 57.69%;
  width: 97.14%;
  top: 0;
  right: 2.86%;
  bottom: 42.31%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.home1 {
  position: relative;
  width: 35px;
  height: 52px;
  color: var(--logg-green);
}
.jobs1 {
  position: absolute;
  top: 62%;
  left: 0;
  line-height: 155%;
  font-weight: 500;
}
.bibriefcaseFillIcon {
  position: absolute;
  height: 52%;
  width: 92.86%;
  top: 0;
  right: 3.57%;
  bottom: 48%;
  left: 3.57%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.jobs {
  position: relative;
  width: 28px;
  height: 50px;
}
.wallet1 {
  position: absolute;
  top: 61.22%;
  left: 0;
  line-height: 155%;
  font-weight: 500;
}
.zondiconswallet {
  position: absolute;
  height: 48.98%;
  width: 66.67%;
  top: 0;
  right: 16.67%;
  bottom: 51.02%;
  left: 16.67%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.wallet {
  position: relative;
  width: 36px;
  height: 49px;
}
.account1 {
  position: absolute;
  top: 63.46%;
  left: 0;
  line-height: 155%;
  font-weight: 500;
}
.mdiaccountIcon {
  position: absolute;
  height: 55.77%;
  width: 60.42%;
  top: 0;
  right: 18.75%;
  bottom: 44.23%;
  left: 20.83%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.account {
  position: relative;
  width: 48px;
  height: 52px;
}
.bottomNav,
.homemob {
  background-color: var(--white);
  overflow: hidden;
  align-items: center;
}
.bottomNav {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  width: 414px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-smi) var(--padding-5xl);
  box-sizing: border-box;
  justify-content: space-between;
  text-align: center;
  font-size: var(--caption-bold-size);
  color: var(--grey-600);
}
.homemob {
  display: none;
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-3xs);
}
.navChild {
  position: absolute;
  top: 27px;
  left: 20px;
  width: 125px;
  height: 20px;
}
.iconshome {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.home3 {
  position: relative;
  font-size: var(--p3-semibold-size);
  line-height: 24px;
  font-weight: 600;
  font-family: var(--caption-bold);
  color: var(--primary-10);
  text-align: center;
}
.default,
.iconshomeParent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.iconshomeParent {
  flex-direction: row;
  gap: var(--gap-5xs);
}
.default {
  cursor: pointer;
  border: 0;
  padding: var(--padding-5xs);
  background-color: var(--grey-90);
  border-radius: var(--br-9xs);
  width: 181px;
  overflow: hidden;
  flex-direction: column;
  box-sizing: border-box;
}
.home4 {
  position: relative;
  font-size: var(--p3-semibold-size);
  line-height: 24px;
  font-weight: 500;
  font-family: var(--caption-bold);
  color: var(--grey-10);
  text-align: center;
}
.default1 {
  cursor: pointer;
  border: 0;
  padding: var(--padding-5xs);
  background-color: transparent;
  border-radius: var(--br-11xs);
  width: 181px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
}
.accountIcon {
  position: relative;
  width: 24px;
  height: 24px;
}
.defaultParent {
  position: absolute;
  top: 87px;
  left: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xs);
}
.nav {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--grey-50);
}
.header1,
.navPrototype {
  align-self: stretch;
  overflow: hidden;
}
.navPrototype {
  margin: 0;
  position: relative;
  width: 221px;
  flex-shrink: 0;
}
.header1 {
  background-color: var(--white);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.04);
  display: none;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-xs);
  align-items: center;
  justify-content: space-between;
  font-size: var(--caption-bold-size);
}
.locationItem {
  align-self: stretch;
  position: relative;
  border-top: 1px solid var(--grey-300);
  box-sizing: border-box;
  height: 1px;
}
.location1 {
  align-self: stretch;
  border-radius: var(--br-5xs);
  background-color: var(--white);
  border: 1px solid var(--grey-300);
  overflow: hidden;
  display: none;
  flex-direction: column;
  padding: var(--padding-xl) var(--padding-3xs);
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xl);
  color: var(--grey-900);
}
.manchesterEngland2 {
  position: relative;
  font-size: var(--p2-semibold-size);
  line-height: 26px;
  font-weight: 500;
  font-family: var(--caption-bold);
  color: var(--logg-green);
  text-align: center;
  align-self: stretch;
  position: relative;
  line-height: 26px;
  display: inline-block;
  width: 250px;
  text-overflow: ellipsis;
  overflow: hidden;
  flex-shrink: 0;
  white-space: nowrap;
}
.iconslocationParent {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.lineDiv {
  position: relative;
  border-right: 1px solid var(--grey-80);
  box-sizing: border-box;
  width: 1px;
  height: 45px;
}
.toggleToGo2 {
  position: relative;
  line-height: 26px;
  color: black;
}
.toggleToGoOnlineOrOfflineContainer {
  flex-direction: row;
  align-items: center;
  gap: var(--gap-xl);
}
.frameParent4,
.titleBars,
.toggleToGoOnlineOrOfflineContainer {
  display: flex;
  justify-content: flex-start;
}
.frameParent4 {
  flex-direction: row;
  align-items: center;
  gap: var(--gap-13xl);
}
.titleBars {
  align-self: stretch;
  background-color: var(--white);
  border-bottom: 1px solid var(--grey-80);
  overflow: hidden;
  flex-direction: column;
  padding: var(--padding-xs) 0;
  align-items: flex-start;
  padding-left: 10%;
}
.loggJobs3 {
  position: relative;
  line-height: 28px;
  font-weight: 600;
}
.loggJobsIcon {
  cursor: pointer;
}
.loggJobsIcon,
.loggJobsIcon1 {
  position: relative;
  border-radius: var(--br-9xs);
  width: 113px;
  height: 138px;
}
.loggJobs2,
.loggJobsParent1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.loggJobsParent1{
  overflow-x: auto;
  width: 556px;
}
.loggJobs2 {
  border-radius: var(--br-5xs);
  background-color: var(--grey-50);
  width: 556px;
  overflow: hidden;
  flex-direction: column;
  padding: var(--padding-xl);
  box-sizing: border-box;
  gap: var(--gap-xl);
}
.createJobQuote1,
.quote2 {
  align-self: stretch;
  position: relative;
}
.quote2 {
  line-height: 28px;
  font-weight: 600;
}
.createJobQuote1 {
  font-size: var(--p2-semibold-size);
  line-height: 26px;
  color: var(--grey-20);
}
.quoteParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.label {
  position: relative;
  font-size: var(--p3-semibold-size);
  line-height: 24px;
  font-family: var(--caption-bold);
  color: var(--logg-green);
  text-align: center;
}
.iconsadd1 {
  position: relative;
  width: 20px;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.largeButtons {
  cursor: pointer;
  border: 1px solid var(--logg-green);
  padding: var(--padding-5xs) var(--padding-158xl);
  background-color: var(--white);
  align-self: stretch;
  border-radius: var(--br-8xs);
  box-sizing: border-box;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
}
.quote1,
.userReviewsWrapper {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.quote1 {
  border-radius: var(--br-5xs);
  background-color: var(--grey-50);
  flex-direction: column;
  padding: var(--padding-xl) var(--padding-13xl);
  gap: var(--gap-xl);
  color: var(--grey-10);
}
.userReviewsWrapper {
  flex-direction: row;
  padding: var(--padding-base) 0;
}
.div10,
.inTheLast1 {
  position: relative;
  line-height: 28px;
  font-weight: 600;
  transform: rotate(-0.38deg);
  transform-origin: 0 0;
}
.inTheLast1 {
  font-size: var(--p2-semibold-size);
  line-height: 26px;
  font-weight: 500;
}
.frameWrapper1,
.parent3 {
  display: flex;
  justify-content: flex-start;
}
.parent3 {
  flex-direction: row;
  align-items: center;
  gap: var(--gap-5xs);
}
.frameWrapper1 {
  flex: 1;
  border-radius: var(--br-9xs);
  background-color: var(--primary-90);
  height: 57px;
  flex-direction: column;
  padding: 12.9951171875px var(--padding-xs);
  box-sizing: border-box;
  align-items: flex-start;
}
.unread1 {
  position: relative;
  font-size: var(--p2-semibold-size);
  line-height: 26px;
  font-weight: 500;
}
.parent4 {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-start;
  gap: var(--gap-5xs);
  transform: rotate(-0.38deg);
  transform-origin: 0 0;
}
.frameParent6,
.frameWrapper2,
.overallCompletedJob {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.frameWrapper2 {
  flex: 1;
  border-radius: var(--br-9xs);
  background-color: var(--secondary-90);
  height: 57px;
  flex-direction: column;
  padding: 12.601165771484375px var(--padding-xs);
  box-sizing: border-box;
}
.frameParent6,
.overallCompletedJob {
  align-self: stretch;
  flex-direction: row;
  gap: var(--gap-5xl);
}
.overallCompletedJob {
  flex-direction: column;
  align-items: center;
  gap: var(--gap-base);
}
.ellipseDiv {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  border: 8px solid var(--logg-green);
  box-sizing: border-box;
  width: 96px;
  height: 96px;
}
.div12 {
  position: absolute;
  top: 34px;
  left: 29px;
  line-height: 28px;
  font-weight: 600;
}
.ellipseGroup {
  position: relative;
  width: 96px;
  height: 96px;
}
.totalBookings1 {
  position: relative;
  font-size: var(--p3-semibold-size);
  line-height: 24px;
  font-weight: 500;
  color: var(--grey-20);
}
.frameParent9 {
  flex: 1;
  height: 172px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 0 var(--padding-18xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xs);
}
.frameChild2 {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  border: 8px solid var(--tertiary-50);
  box-sizing: border-box;
  width: 96px;
  height: 96px;
}
.div13 {
  position: absolute;
  top: 34px;
  left: 34px;
  line-height: 28px;
  font-weight: 600;
}
.frameParent10 {
  flex: 1;
  height: 172px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 0 var(--padding-18xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xs);
  color: var(--tertiary-20);
}
.div14 {
  position: absolute;
  top: 34px;
  left: 26px;
  line-height: 28px;
  font-weight: 600;
}
.frameChild3 {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  background-color: var(--color-sandybrown);
  width: 96px;
  height: 96px;
}
.percentage1 {
  position: relative;
  font-size: var(--p3-semibold-size);
  line-height: 24px;
  font-weight: 500;
}
.frameParent11 {
  flex: 1;
  height: 172px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 0 var(--padding-18xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xs);
  color: var(--grey-20);
}
.frameParent7,
.frameParent8 {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
}
.frameParent8 {
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  color: var(--logg-green);
}
.frameParent7 {
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.frameChild4 {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  border: 10.7px solid var(--logg-green);
  box-sizing: border-box;
  width: 128px;
  height: 128px;
}
.div15 {
  position: absolute;
  top: 42.67px;
  left: 38.66px;
  line-height: 42.67px;
  font-weight: 600;
}
.ellipseParent1 {
  position: relative;
  width: 128px;
  height: 128px;
}
.frameWrapper3 {
  width: 168px;
  height: 168px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.overallSatisfaction60Container1,
.responses1401 {
  position: relative;
  line-height: 24px;
}
.responses1401 {
  font-weight: 500;
}
.overallSatisfaction60Group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.div16 {
  position: relative;
  font-size: var(--p1-semibold-size);
  line-height: 24px;
  font-weight: 500;
}
.frameParent13,
.surveyDateParent {
  flex-direction: column;
  align-items: flex-start;
}
.surveyDateParent {
  display: flex;
  justify-content: flex-start;
  font-size: var(--p3-semibold-size);
}
.frameParent13 {
  gap: var(--gap-5xs);
  text-align: left;
  font-size: var(--p1-semibold-size);
  color: var(--grey-20);
}
.customerSatisfaction,
.frameParent12,
.frameParent13 {
  display: flex;
  justify-content: flex-start;
}
.frameParent12 {
  align-self: stretch;
  flex-direction: row;
  align-items: center;
  gap: 64px;
  text-align: center;
  font-size: 26.67px;
  color: var(--logg-green);
}
.customerSatisfaction {
  border-radius: var(--br-5xs);
  background-color: var(--grey-50);
  width: 558px;
  height: 264px;
  flex-direction: column;
  padding: var(--padding-xl) var(--padding-13xl);
  box-sizing: border-box;
  align-items: flex-start;
  gap: var(--gap-5xl);
  color: var(--grey-10);
}
.div17 {
  position: relative;
  line-height: 28px;
  font-weight: 500;
}
.starRatingIcon {
  position: relative;
  width: 20px;
  height: 20px;
}
.parent6,
.starRatingParent {
  display: flex;
  flex-direction: row;
}
.starRatingParent {
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-11xs);
}
.parent6 {
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
}
.outOf501 {
  font-weight: 500;
  color: var(--grey-20);
}
.outOf50Container1 {
  position: relative;
  line-height: 28px;
  color: var(--grey-0);
}
.customerRatingsParent,
.frameParent14 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.frameParent14 {
  align-self: stretch;
  gap: var(--gap-5xs);
  color: var(--grey-20);
}
.customerRatingsParent {
  border-radius: var(--br-5xs);
  background-color: var(--grey-50);
  width: 558px;
  height: 172px;
  padding: var(--padding-xl) var(--padding-13xl);
  box-sizing: border-box;
  gap: 28px;
}
.bottomNav1,
.seeWhoViewedYourProfile1 {
  align-self: stretch;
  background-color: var(--white);
  display: none;
}
.seeWhoViewedYourProfile1 {
  height: 192.36px;
  flex-direction: column;
  padding: var(--padding-mini) var(--padding-3xs);
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
  font-size: var(--p1-semibold-size);
  color: var(--main-text);
}
.bottomNav1 {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  flex-direction: row;
  padding: var(--padding-smi) var(--padding-5xl);
  align-items: center;
  justify-content: space-evenly;
  gap: var(--gap-54xl);
  text-align: center;
  font-size: var(--caption-bold-size);
  color: var(--grey-600);
}
.loggJobsGroup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
}
.frameWrapper,
.seeWhoViewedYourProfileWrapper {
  background-color: var(--color-gray-100);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.seeWhoViewedYourProfileWrapper {
  width: 289px;
  flex-direction: row;
  padding: var(--padding-5xl) 0 var(--padding-base) var(--padding-5xs);
  box-sizing: border-box;
  align-items: flex-start;
}
.pm10 {
  position: absolute;
  top: 66.67%;
  right: 12px;
  line-height: 24px;
  opacity: 0.7;
  mix-blend-mode: normal;
}
.avatarIcon {
  position: relative;
  width: 40px;
  height: 40px;
}
.jamesBorden2 {
  position: relative;
  line-height: 26px;
  font-weight: 600;
}
.ontarioUsa2 {
  position: relative;
  font-size: var(--p3-semibold-size);
  line-height: 24px;
  color: var(--grey-20);
  opacity: 0.7;
  mix-blend-mode: normal;
}
.avatarParent {
  position: absolute;
  top: 16px;
  left: 12px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xs);
  text-align: left;
  font-size: var(--p2-semibold-size);
  color: var(--grey-10);
}
.seeWhoViewedMyProfile {
  position: relative;
  border-radius: var(--br-xs);
  background-color: var(--white);
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.06),
    0 1px 2px 1px rgba(0, 0, 0, 0.06);
  width: 266px;
  height: 120px;
}
.profileViews,
.profileViewsWrapper {
  background-color: var(--color-gray-100);
}
.profileViews {
  position: absolute;
  top: 4px;
  left: 8px;
  display: flex;
  flex-direction: column;
  padding: 0 0 var(--padding-21xl);
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xs);
}
.profileViewsWrapper {
  position: relative;
  width: 299px;
  height: 627px;
  overflow-y: scroll;
  overflow-x: hidden;
  flex-shrink: 0;
  text-align: right;
  font-size: var(--p3-semibold-size);
  color: var(--grey-20);
}
.frameParent15,
.frameParent5 {
  width: 299px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.frameParent5 {
  width: 968px;
  flex-direction: row;
  justify-content: space-between;
  font-size: var(--h5-semibold-size);
  color: var(--grey-900);
}
.titleBarsParent {
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-xs);
  text-align: left;
  color: var(--grey-10);
  width: fit-content;
}
.headerParent,
.home,
.homeprof,
.titleBarsParent {
  display: flex;
  justify-content: flex-start;
}
.headerParent {
  flex: 1;
  flex-direction: column;
  align-items: center;
  gap: var(--gap-3xs);
  width: 100%;
}
.home,
.homeprof {
}
.homeprof {
  background-color: var(--color-gray-100);
  overflow-y: auto;
  flex-direction: row;
  text-align: center;
  font-size: var(--p2-semibold-size);
  color: var(--white);
}
.home {
  position: relative;
  width: 100%;
  flex-direction: column;
  gap: var(--gap-3xs);
  text-align: left;
  font-size: 17px;
  color: var(--main-text);
  font-family: var(--caption-bold);
 
}
@media screen and (max-width: 1200px) {
  .homeprof {
    width: 100%;
  }
}
@media screen and (max-width: 960px) {
  .homemob {
    display: none;
  }
  .header1,
  .location1,
  .navPrototype {
    display: flex;
  }
  .titleBars {
    display: none;
  }
  .loggJobs2 {
    align-self: stretch;
    width: 95vw;
  }
  .quote1 {
    align-items: center;
    justify-content: center;
  }
  .customerRatingsParent,
  .customerSatisfaction,
  .frameParent7,
  .overallCompletedJob,
  .quote1 {
    align-self: stretch;
    width: auto;
  }
  .bottomNav1,
  .seeWhoViewedYourProfile1 {
    display: flex;
  }
  .loggJobsGroup {
    align-self: stretch;
    width: auto;
  }
  .frameWrapper {
    display: flex;
    flex-direction: column;
  }
  .profileViewsWrapper,
  .seeWhoViewedYourProfileWrapper {
    display: flex;
  }
  .frameParent15 {
    display: none;
  }
  .frameParent5 {
    align-self: stretch;
    width: auto;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 5rem;
  }
  .titleBarsParent {
    display: flex;
  }
  .headerParent {
    align-self: stretch;
    width: auto;
    flex: unset;
  }
  .home,
  .homeprof {
    align-items: center;
    justify-content: flex-start;
  }
  .homeprof {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
  .home {
    height: auto;
    margin-bottom: 7rem;
  }
  .bottomNav{
    display: none;
  }
  .header {
    display: flex;
    flex-direction: row;
  }
}
@media screen and (max-width: 430px) {
  .homemob {
    display: flex;
    width:100%;
    margin-bottom: 7rem;
  }
    .bottomNav {
      display: flex;
      width: 100%
    }
    .header {
      display: flex;
      flex-direction: row;
    }
  .bottomNav1,
  .header1,
  .homeprof,
  .location1,
  .seeWhoViewedYourProfile1 {
    display: none;
  }
}
