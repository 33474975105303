.addANew {
  position: relative;
  line-height: 28px;
  font-weight: 600;
}
.iconsclose {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
  cursor: pointer;
}
.addANewCardParent {
  position: absolute;
  width: calc(100% - 64px);
  top: 16px;
  right: 32px;
  left: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.modalTitle {
  align-self: stretch;
  position: relative;
  height: 64px;
  overflow: hidden;
  flex-shrink: 0;
}
.dealOfTheDayChild {
  position: relative;
  border-radius: var(--br-9xs) var(--br-9xs) 0 0;
  width: 358px;
  height: 118px;
  object-fit: cover;
}
.groupChild {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-31xl);
  background-color: var(--grey-90);
  width: 107px;
  height: 20px;
}
.serviceType {
  position: absolute;
  top: 0;
  left: 8px;
  line-height: 20px;
}
.groupItem,
.rectangleParent {
  position: relative;
  width: 107px;
  height: 20px;
}
.groupItem {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-31xl);
  background-color: var(--grey-90);
  width: 92px;
}
.rectangleGroup {
  position: relative;
  width: 92px;
  height: 20px;
}
.groupInner {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-31xl);
  background-color: var(--grey-90);
  width: 78px;
  height: 20px;
}
.rectangleContainer {
  position: relative;
  width: 78px;
  height: 20px;
}
.groupParent {
  position: absolute;
  top: 56px;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
  text-align: right;
}
.span {
  font-weight: 600;
  color: var(--primary-20base);
}
.countdown,
.offer {
  position: relative;
  line-height: 20px;
}
.countdown {
  font-weight: 600;
}
.countdownParent,
.offerParent {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
}
.countdownParent {
  align-items: center;
  justify-content: flex-end;
  padding: 0 0 0 var(--padding-12xs);
  gap: var(--gap-5xs);
  text-align: right;
  color: var(--error-10);
}
.offerParent {
  position: absolute;
  top: 84px;
  left: 0;
  width: 342px;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-mid) 0 0;
  gap: 0.5rem;
  color: var(--grey-10);
  flex-wrap: wrap;
}
.avatarIcon,
.businessName {
  position: relative;
  width: 24px;
  height: 24px;
}
.businessName {
  line-height: 24px;
  font-weight: 600;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 224px;
  flex-shrink: 0;
}
.avatarName {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.icons16pxstar {
  display: none;
}
.icons16pxstar,
.icons16pxstar3 {
  position: relative;
  width: 16px;
  height: 16px;
  overflow: hidden;
  flex-shrink: 0;
}
.col,
.ratings,
.starRating {
  display: flex;
  flex-direction: row;
}
.starRating {
  align-items: flex-start;
  justify-content: flex-start;
}
.col,
.ratings {
  align-items: center;
}
.ratings {
  width: 86px;
  justify-content: flex-end;
  gap: var(--gap-11xs);
  font-size: var(--caption-regular-size);
  color: var(--grey-20);
}
.col {
  width: 342px;
  justify-content: space-between;
}
.colWrapper,
.frameContainer,
.offerWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.frameContainer,
.offerWrapper {
  flex-direction: row;
  font-size: var(--caption-regular-size);
  color: var(--grey-10);
}
.frameContainer {
  position: absolute;
  top: 0;
  left: 0;
  flex-direction: column;
  gap: var(--gap-9xs);
  font-size: var(--button-small-text-size);
  color: var(--grey-0);
}
.frameGroup {
  position: absolute;
  top: 8px;
  left: 8px;
  width: 342px;
  height: 104px;
}
.button {
  position: relative;
  line-height: 100%;
  font-weight: 600;
}
.buttonWrapper,
.smallButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.smallButtons {
  position: absolute;
  top: 154px;
  left: 8px;
  border-radius: var(--br-9xs);
  border: 1px solid var(--primary-20base);
  box-sizing: border-box;
  width: 342px;
  height: 32px;
  padding: var(--padding-5xs) var(--padding-xl);
  text-align: center;
  font-size: var(--button-small-text-size);
  color: var(--primary-20base);
}
.frameParent {
  position: relative;
  background-color: var(--white);
  width: 358px;
  height: 200px;
}
.dealOfTheDay,
.previewmodal {
  border-radius: var(--br-9xs);
  box-shadow: var(--popup);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--caption-regular-size);
  color: var(--grey-20);
}
.previewmodal {
  border-radius: var(--br-8xs);
  background-color: var(--white);
  width: 560px;
  height: 409px;
  align-items: center;
  gap: 14px;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
  text-align: left;
  font-size: var(--h5-semibold-size);
  color: var(--color-black);
  font-family: var(--caption-bold);
}
@media screen and (max-width: 430px) {
  .previewmodal {
    width: 400px;
  }
}
