.brandLogoIcon,
.headerChild {
  position: absolute;
  overflow: hidden;
}
.headerChild {
  top: 17px;
  left: 248px;
  width: 26px;
  height: 26px;
  display: none;
}
.brandLogoIcon {
  top: 10px;
  left: 12px;
  width: 40px;
  height: 40px;
}
.frameIcon {
  position: relative;
  width: 24px;
  height: 31px;
  overflow: hidden;
  flex-shrink: 0;
}
.div,
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.div {
  position: relative;
  letter-spacing: -0.04em;
  line-height: 100%;
  font-weight: 600;
  width: 16px;
  height: 10px;
  flex-shrink: 0;
}
.wrapper {
  border-radius: var(--br-5xs);
  background-color: var(--logg-green);
  width: 24px;
  height: 18px;
  flex-direction: column;
  margin-left: -14px;
}
.frameParent,
.ticker {
  width: 34px;
  height: 31px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.ticker {
  border-radius: var(--br-5xs);
  background-color: var(--logg-green);
  width: 24px;
  height: 18px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: -16px;
}
.frame,
.frameGroup {
  width: 32px;
  height: 31px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.frame {
  position: absolute;
  top: 11px;
  left: 306px;
  width: 88px;
  overflow: hidden;
  align-items: center;
  gap: var(--gap-3xl);
}
.header {
  align-self: stretch;
  background-color: var(--white);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.04);
  height: 60px;
  overflow: hidden;
  flex-shrink: 0;
  color: var(--white);
  width: 100%;
}
.chevronUpIcon,
.mapPinIcon {
  position: absolute;
  top: 2px;
  left: 0;
  width: 20px;
  height: 20px;
  overflow: hidden;
}
.chevronUpIcon {
  height: 80%;
  width: 11.11%;
  top: 88%;
  right: -11.11%;
  bottom: -68%;
  left: 100%;
  max-width: 100%;
  max-height: 100%;
}
.manchesterEngland {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 155%;
  font-weight: 500;
}
.frame1 {
  position: absolute;
  top: 0;
  left: 24px;
  width: 180px;
  height: 25px;
  overflow: hidden;
}
.locationChild,
.mapPinParent {
  position: relative;
  width: 204px;
  height: 25px;
}
.locationChild {
  border-top: 1px solid var(--grey-300);
  box-sizing: border-box;
  width: 375px;
  height: 1px;
}
.toggleToGo {
  line-height: 155%;
  font-weight: 500;
}
.toggle,
.toggleToGo {
  position: relative;
}
.location,
.toggleToGoOnlineOrOfflineParent {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.toggleToGoOnlineOrOfflineParent {
  flex-direction: row;
  gap: var(--gap-87xl);
  text-align: left;
  font-size: var(--p3-semibold-size);
  color: var(--grey-700);
}
.location {
  border-radius: var(--br-5xs);
  background-color: var(--white);
  border: 1px solid var(--grey-300);
  overflow: hidden;
  flex-direction: column;
  padding: var(--padding-xl) var(--padding-3xs);
  gap: var(--gap-xl);
  font-size: var(--p2-semibold-size);
  align-self: stretch;
  margin: 0.5rem;
}
.loggJobs1 {
  position: relative;
  line-height: 28px;
  font-weight: 600;
}
.tablercarouselHorizontalIcon {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.citiessummary {
  align-self: stretch;
  position: relative;
  font-size: var(--p2-semibold-size);
  line-height: 26px;
  font-weight: 500;
  font-family: var(--caption-bold);
  color: var(--gray-1);
  text-align: left;
}
.tablercarouselHorizontalParent {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  width: 134px;
  height: 26px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.loggJobsParent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}
.frameChild {
  position: absolute;
  border-radius: var(--br-base);
  width: 35px;
  height: 32px;
  top: 50%;
  left: 10px
}
.frameChild,
.frameInner,
.frameItem,
.rectangleWrapper {
  background-color: var(--grey-50);
  border: 0.5px solid var(--color-gray-300);
  box-sizing: border-box;
}
.rectangleWrapper {
  border-radius: var(--br-5xs);
  width: 101px;
  height: 92px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
}
.frameInner,
.frameItem {
  position: relative;
  border-radius: var(--br-9xs);
  width: 35px;
  height: 32px;
}
.frameInner {
  margin-left: -17px;
}
.rectangleParent {
  width: 89px;
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.citiessummary1 {
  align-self: stretch;
  position: relative;
  line-height: 26px;
  font-weight: 600;
  display: inline-block;
  width: 27px;
  flex-shrink: 0;
}
.frameDiv,
.frameParent1 {
  display: flex;
  justify-content: flex-start;
  gap: var(--gap-xs);
}
.frameParent1 {
  width: 98px;
  height: 24px;
  flex-direction: row;
  align-items: center;
}
.frameDiv {
  flex-direction: column;
  align-items: flex-start;
}
.p {
  margin: 0;
}
.requestForElectricianContainer1 {
  line-break: anywhere;
}
.requestForElectricianContainer {
  align-self: stretch;
  position: relative;
  font-size: var(--caption-bold-size);
  line-height: 28px;
  font-weight: 500;
  display: flex;
  align-items: flex-end;
  height: 20px;
  flex-shrink: 0;
}
.fluenttextDescriptionLtr20Icon {
  position: relative;
  width: 10px;
  height: 10px;
  overflow: hidden;
  flex-shrink: 0;
  opacity: 0.5;
}
.imInNeed {
  align-self: stretch;
  position: relative;
  line-height: 26px;
  display: inline-block;
  width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  flex-shrink: 0;
  white-space: nowrap;
}
.fluenttextDescriptionLtr20Parent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-10xs);
}
.uilmoneyBillIcon {
  position: relative;
  width: 10px;
  height: 10px;
  overflow: hidden;
  flex-shrink: 0;
}
.citiessummary2 {
  align-self: stretch;
  position: relative;
  line-height: 40px;
  display: inline-block;
  width: 114px;
  flex-shrink: 0;
}
.citiessummary3,
.citiessummary4 {
  position: relative;
  line-height: 26px;
}
.citiessummary3 {
  flex: 1;
}
.citiessummary4 {
  font-weight: 500;
}
.citiessummaryWrapper,
.frameWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  cursor: pointer;
}
.citiessummaryWrapper {
  border-radius: var(--br-9xs);
  background-color: var(--logg-green);
  height: 32px;
  padding: 0 var(--padding-3xs);
  box-sizing: border-box;
  align-items: flex-start;
}
.frameWrapper {
  align-self: stretch;
  align-items: center;
  font-size: var(--p3-semibold-size);
  color: var(--white);
}
.frameContainer,
.loggJobs,
.requestForElectricianParent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.requestForElectricianParent {
  flex: 1;
  flex-direction: column;
  font-size: var(--font-size-2xs);
}
.frameContainer,
.loggJobs {
  align-self: stretch;
  border-radius: var(--br-5xs);
}
.frameContainer {
  background-color: var(--grey-50);
  border: 0.5px solid var(--color-gray-300);
  flex-direction: row;
  padding: var(--padding-base);
  gap: var(--gap-3xs);
  font-size: var(--p2-semibold-size);
  color: var(--gray-1);
}
.loggJobs {
  background-color: var(--color-gray-100);
  overflow: hidden;
  flex-direction: column;
  gap: var(--gap-lg);
  margin: 0.5rem;
}
.loggJobsWrapper,
.seeWhoViewed {
  align-self: stretch;
  display: flex;
  align-items: center;
}
.loggJobsWrapper {
  background-color: var(--color-gray-100);
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  font-size: var(--h5-semibold-size);
}
.seeWhoViewed {
  position: relative;
  letter-spacing: -0.03em;
  font-weight: 600;
  height: 22.71px;
  flex-shrink: 0;
}
.whoViewedMyProfileChild {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-5xs);
  background-color: var(--white);
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.06);
}
.whoViewedMyProfileItem {
  position: absolute;
  height: 38.4%;
  width: 17.71%;
  top: 17.6%;
  right: 76.38%;
  bottom: 44%;
  left: 5.9%;
  border-radius: 50%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.jamesBorden,
.ontarioUsa,
.pm {
  position: absolute;
  left: 26.57%;
  line-height: 155%;
}
.jamesBorden {
  top: 18.4%;
  font-size: var(--p2-semibold-size);
  font-weight: 600;
}
.ontarioUsa,
.pm {
  top: 38.4%;
  font-weight: 500;
  color: var(--grey-700);
  opacity: 0.7;
  mix-blend-mode: normal;
}
.pm {
  top: 68.8%;
  left: 77.12%;
  color: var(--grey-600);
  text-align: right;
}
.whoViewedMyProfile,
.whoViewedMyProfile1 {
  position: absolute;
  top: 0.16px;
  left: 24px;
  width: 271px;
  height: 125px;
}
.whoViewedMyProfile1 {
  left: 307px;
}
.whoViewedMyProfile2,
.whoViewedMyProfile3,
.whoViewedMyProfile4 {
  position: absolute;
  top: 0.16px;
  left: 590px;
  width: 271px;
  height: 125px;
}
.whoViewedMyProfile3,
.whoViewedMyProfile4 {
  left: 873px;
}
.whoViewedMyProfile4 {
  left: 1156px;
}
.whoViewedMyProfileParent {
  align-self: stretch;
  position: relative;
  height: 135px;
  overflow-x: auto;
  flex-shrink: 0;
  font-size: var(--p3-semibold-size);
  color: var(--grey-900);
}
.seeWhoViewedYourProfile {
  align-self: stretch;
  background-color: var(--white);
  height: 192.36px;
  display: flex;
  flex-direction: column;
  padding: var(--padding-mini) var(--padding-3xs);
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
  text-align: left;
  font-size: var(--p1-semibold-size);
  color: var(--main-text);
}
.brandLogoIcon1,
.home1 {
  position: absolute;
  left: 0;
}
.home1 {
  top: 63.46%;
  line-height: 155%;
}
.brandLogoIcon1 {
  height: 57.69%;
  width: 97.14%;
  top: 0;
  right: 2.86%;
  bottom: 42.31%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.home {
  position: relative;
  width: 35px;
  height: 52px;
  color: var(--logg-green);
}
.jobs1 {
  position: absolute;
  top: 62%;
  left: 0;
  line-height: 155%;
  font-weight: 500;
}
.bibriefcaseFillIcon {
  position: absolute;
  height: 52%;
  width: 92.86%;
  top: 0;
  right: 3.57%;
  bottom: 48%;
  left: 3.57%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.jobs {
  position: relative;
  width: 28px;
  height: 50px;
}
.wallet1 {
  position: absolute;
  top: 61.22%;
  left: 0;
  line-height: 155%;
  font-weight: 500;
}
.zondiconswallet {
  position: absolute;
  height: 48.98%;
  width: 66.67%;
  top: 0;
  right: 16.67%;
  bottom: 51.02%;
  left: 16.67%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.wallet {
  position: relative;
  width: 36px;
  height: 49px;
}
.account1 {
  position: absolute;
  top: 63.46%;
  left: 0;
  line-height: 155%;
  font-weight: 500;
}
.mdiaccountIcon {
  position: absolute;
  height: 55.77%;
  width: 60.42%;
  top: 0;
  right: 18.75%;
  bottom: 44.23%;
  left: 20.83%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.account {
  position: relative;
  width: 48px;
  height: 52px;
}
.bottomNav,
.homemob {
  background-color: var(--white);
  overflow: hidden;
  align-items: center;
}
.bottomNav {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: row;
  padding: var(--padding-smi) var(--padding-5xl);
  justify-content: center;
  gap: var(--gap-54xl);
  color: var(--grey-600);
}
.homemob {
  display: none;
  flex-direction: column;
  justify-content: flex-start;
}
.navChild {
  position: absolute;
  top: 27px;
  left: 20px;
  width: 125px;
  height: 20px;
}
.home2 {
  position: relative;
  font-size: var(--p3-semibold-size);
  line-height: 24px;
  font-weight: 600;
  font-family: var(--caption-bold);
  color: var(--primary-10);
  text-align: center;
}
.default,
.iconshomeParent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.iconshomeParent {
  flex-direction: row;
  gap: var(--gap-5xs);
}
.default {
  cursor: pointer;
  border: 0;
  padding: var(--padding-5xs);
  background-color: var(--grey-90);
  border-radius: var(--br-9xs);
  width: 181px;
  overflow: hidden;
  flex-direction: column;
  box-sizing: border-box;
}
.home3 {
  position: relative;
  font-size: var(--p3-semibold-size);
  line-height: 24px;
  font-weight: 500;
  font-family: var(--caption-bold);
  color: var(--grey-10);
  text-align: center;
}
.default1 {
  cursor: pointer;
  border: 0;
  padding: var(--padding-5xs);
  background-color: transparent;
  border-radius: var(--br-11xs);
  width: 181px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
}
.accountIcon {
  position: relative;
  width: 24px;
  height: 24px;
}
.defaultParent {
  position: absolute;
  top: 87px;
  left: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xs);
}
.nav {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--grey-50);
}
.brandLogoIcon2,
.headerItem,
.navPrototype {
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.navPrototype {
  margin: 0;
  align-self: stretch;
  width: 221px;
}
.brandLogoIcon2,
.headerItem {
  width: 40px;
  height: 40px;
}
.headerItem {
  width: 26px;
  height: 26px;
  display: none;
}
.frame2,
.header1 {
  overflow: hidden;
  flex-direction: row;
  align-items: center;
}
.frame2 {
  width: 88px;
  height: 31px;
  flex-shrink: 0;
  display: flex;
  justify-content: flex-start;
  gap: var(--gap-3xl);
}
.header1 {
  align-self: stretch;
  background-color: var(--white);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.04);
  display: none;
  padding: var(--padding-3xs) var(--padding-xs);
  justify-content: space-between;
  font-size: var(--caption-bold-size);
}
.locationItem {
  align-self: stretch;
  position: relative;
  border-top: 1px solid var(--grey-300);
  box-sizing: border-box;
  height: 1px;
}
.location1 {
  align-self: stretch;
  border-radius: var(--br-5xs);
  background-color: var(--white);
  border: 1px solid var(--grey-300);
  overflow: hidden;
  display: none;
  flex-direction: column;
  padding: var(--padding-xl) var(--padding-3xs);
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xl);
  color: var(--grey-900);
}
.avatarIcon {
  position: relative;
  width: 40px;
  height: 40px;
}
.manchesterEngland2 {
  position: relative;
  font-size: var(--p2-semibold-size);
  line-height: 26px;
  font-weight: 500;
  font-family: var(--caption-bold);
  color: var(--logg-green);
  text-align: center;
}
.iconslocationParent {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.lineDiv {
  position: relative;
  border-right: 1px solid var(--grey-80);
  box-sizing: border-box;
  width: 1px;
  height: 45px;
}
.toggleToGo2 {
  position: relative;
  line-height: 26px;
}
.frameParent4,
.toggleToGoOnlineOrOfflineContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xl);
}
.frameParent4 {
  gap: var(--gap-13xl);
}
.rectangleContainer,
.titleBars {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.titleBars {
  align-self: stretch;
  background-color: var(--white);
  border-bottom: 1px solid var(--grey-80);
  overflow: hidden;
  padding: var(--padding-xs) 0;
  justify-content: flex-start;
  gap: var(--gap-3xs);
}
.rectangleContainer {
  border-radius: var(--br-5xs);
  background-color: var(--grey-50);
  border: 0.5px solid var(--color-gray-300);
  box-sizing: border-box;
  width: 134px;
  height: 118px;
  justify-content: flex-end;
}
.frameParent7,
.frameParent8 {
  display: flex;
  justify-content: flex-start;
  gap: var(--gap-xs);
}
.frameParent8 {
  width: 128px;
  height: 32px;
  flex-direction: row;
  align-items: center;
}
.frameParent7 {
  position: absolute;
  top: 16px;
  left: 16px;
  width: 134px;
  height: 118px;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer;
  z-index: 0;
}
.statusInner {
  position: absolute;
  top: 50px;
  left: 10px;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  object-fit: cover;
}

.requestForElectricianContainer2 {
  align-self: stretch;
  position: relative;
  line-height: 28px;
  font-weight: 500;
  display: flex;
  align-items: flex-end;
  height: 20px;
  flex-shrink: 0;
}
.fluenttextDescriptionLtr20Icon1 {
  position: relative;
  width: 20px;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
  opacity: 0.5;
}
.imInNeed1 {
  align-self: stretch;
  position: relative;
  line-height: 26px;
  display: inline-block;
  width: 250px;
  text-overflow: ellipsis;
  overflow: hidden;
  flex-shrink: 0;
  white-space: nowrap;
}
.fluenttextDescriptionLtr20Group {
  align-self: stretch;
  height: 26px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xs);
}
.uilmoneyBillIcon1 {
  position: relative;
  width: 20px;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.citiessummary7 {
  align-self: stretch;
  position: relative;
  line-height: 26px;
  display: inline-block;
  flex-shrink: 0;
}
.citiessummaryContainer {
  border-radius: var(--br-9xs);
  background-color: var(--logg-green);
  display: flex;
  flex-direction: column;
  padding: 0 35px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.requestForElectricianGroup {
  position: absolute;
  top: 16px;
  left: 173px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.frameParent6 {
  align-self: stretch;
  position: relative;
  border-radius: var(--br-5xs);
  background-color: var(--grey-50);
  box-sizing: border-box;
  height: 194px;
  font-size: var(--p2-semibold-size);
  color: var(--gray-1);
}
.bottomNav1,
.seeWhoViewedYourProfile1 {
  align-self: stretch;
  background-color: var(--white);
  display: none;
}
.seeWhoViewedYourProfile1 {
  height: 192.36px;
  flex-direction: column;
  padding: var(--padding-mini) var(--padding-3xs);
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
  font-size: var(--p1-semibold-size);
  color: var(--main-text);
}
.bottomNav1 {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  flex-direction: row;
  padding: var(--padding-smi) var(--padding-5xl);
  align-items: center;
  justify-content: space-between;
  text-align: center;
  font-size: var(--caption-bold-size);
  color: var(--grey-600);
}
.loggJobsGroup,
.seeWhoViewedYourProfileWrapper {
  background-color: var(--color-gray-100);
  display: flex;
  justify-content: flex-start;
}
.loggJobsGroup {
  flex: 1;
  flex-direction: column;
  padding: var(--padding-5xs);
  align-items: center;
  gap: 44px;
  width: 556px;
}
.seeWhoViewedYourProfileWrapper {
  width: 289px;
  flex-direction: row;
  padding: var(--padding-5xl) 0 var(--padding-base) var(--padding-5xs);
  box-sizing: border-box;
  align-items: flex-start;
}
.pm10 {
  position: absolute;
  top: 66.67%;
  right: 12px;
  line-height: 24px;
  opacity: 0.7;
  mix-blend-mode: normal;
}
.avatarIcon1 {
  position: relative;
  width: 40px;
  height: 40px;
}
.jamesBorden2 {
  position: relative;
  line-height: 26px;
  font-weight: 600;
}
.ontarioUsa2 {
  position: relative;
  font-size: var(--p3-semibold-size);
  line-height: 24px;
  color: var(--grey-20);
  opacity: 0.7;
  mix-blend-mode: normal;
}
.avatarParent,
.jamesBordenParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.avatarParent {
  position: absolute;
  top: 16px;
  left: 12px;
  flex-direction: row;
  gap: var(--gap-xs);
  text-align: left;
  font-size: var(--p2-semibold-size);
  color: var(--grey-10);
}
.seeWhoViewedMyProfile {
  position: relative;
  border-radius: var(--br-xs);
  background-color: var(--white);
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.06),
    0 1px 2px 1px rgba(0, 0, 0, 0.06);
  width: 266px;
  height: 120px;
}
.profileViews,
.profileViewsWrapper {
  background-color: var(--color-gray-100);
}
.profileViews {
  position: absolute;
  top: 4px;
  left: 8px;
  display: flex;
  flex-direction: column;
  padding: 0 0 var(--padding-21xl);
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xs);
}
.profileViewsWrapper {
  position: relative;
  width: 289px;
  height: 627px;
  overflow-y: scroll;
  overflow-x: hidden;
  flex-shrink: 0;
  text-align: right;
  font-size: var(--p3-semibold-size);
  color: var(--grey-20);
}
.frameParent5,
.frameParent9 {
  display: flex;
  justify-content: space-between;
}
.frameParent9 {
  width: 299px;
  height: 695px;
  flex-shrink: 0;
  flex-direction: column;
  align-items: center;
}
.frameParent5 {
  width: 968px;
  flex-direction: row;
  font-size: var(--h5-semibold-size);
  color: var(--grey-900);
  justify-content: space-between;
  gap: 7rem
}
.headerParent,
.titleBarsParent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.titleBarsParent {
  padding: 0 var(--padding-31xl);
  align-items: flex-start;
  text-align: left;
  color: var(--grey-10);
}
.headerParent {
  flex: 1;
  align-items: center;
  gap: var(--gap-xs);
}
.homeproflist,
.homeproflist1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.homeproflist1 {
  background-color: var(--color-gray-100);
  width: 100%;
  overflow-y: auto;
  flex-shrink: 0;
  font-size: var(--p2-semibold-size);
  color: var(--white);
}
.homeproflist {
  position: relative;
  width: 100%;
  gap: var(--gap-3xs);
  text-align: center;
  font-size: var(--caption-bold-size);
  color: var(--grey-900);
  font-family: var(--caption-bold);
}
@media screen and (max-width: 960px) {
  .homemob,
  .navPrototype {
    display: none;
  }
  .header1,
  .location1 {
    display: flex;
  }
  .titleBars {
    display: none;
  }
  .bottomNav1,
  .seeWhoViewedYourProfile1 {
    display: flex;
  }
  .loggJobsGroup {
    width: 556px;
    flex: unset;
  }
  .frameParent9 {
    display: none;
  }
  .frameParent5 {
    align-self: stretch;
    width: auto;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
   
  }
  .titleBarsParent {
    width: 100%;
  }
  .headerParent {
    display: flex;
  }
  .homeproflist1 {
    width: 100%;
  }
}
@media screen and (max-width: 430px) {
  .homemob {
    display: flex;
    width: 100%;
  }
  .bottomNav1{
    width:100%
  }
  .bottomNav1,
  .header1,
  .homeproflist1,
  .location1,
  .seeWhoViewedYourProfile1 {
    display: none;
  }
  .homeproflist {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
}
