@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");


body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --caption-bold: Inter;

  /* font sizes */
  --p3-semibold-size: 14px;
  --caption-bold-size: 12px;
  --p2-semibold-size: 16px;
  --h4-bold1-size: 24px;
  --h5-semibold-size: 20px;
  --font-size-10xs-4: 2.4px;
  --font-size-5xs: 8px;
  --p1-semibold-size: 18px;
  --h4-bold-size: 17px;
  --p2-medium-size: 15px;
  --capitalised-10-size: 10px;
  --h3-semibold-size: 32px;
  --h1-size: 30px;
  --caption-block-size: 9px;
  --font-size-2xs: 11px;
  --font-size-8xs: 5px;

  /* Colors */
  --white: #fff;
  --color-limegreen-100: #93ff68;
  --primary-40: #5ac91a;
  --logg-green: #2fa500;
  --grey-20: #4b4b4b;
  --color-darkslategray-100: #3e3e3e;
  --color-darkslategray-200: #3a3a3d;
  --gray-1: #333;
  --grey-800: #323232;
  --grey-10: #2e2e2e;
  --color-royalblue: #3976ea;
  --color-royalblue-100: #136be1;
  --color-dodgerblue: #1976d2;
  --color-silver: #c4c4c4;
  --gray-4: #bdbdbd;
  --labels: #6e6e6e;
  --grey-600: #636363;
  --grey-30: #5e5e5e;
  --grey-700: #505050;
  --gray-2: #4f4f4f;
  --gray-5: #e0e0e0;
  --grey-70: #dfdfdf;
  --grey-300: #d9d9d9;
  --color-gray-100: #fefefe;
  --grey-50: #fafafa;
  --grey-500: #8a8a8a;
  --grey-40: #858585;
  --gray-3: #828282;
  --grey-1: #252525;
  --main-text: #212121;
  --grey-900: #121212;
  --grey-0: #0d0d0d;
  --color-gray-500: rgba(0, 0, 0, 0.3);
  --color-gray-300: rgba(0, 0, 0, 0.5);
  --color-gray-200: rgba(39, 39, 39, 0.1);
  --grey-60: #ccc;
  --color-whitesmoke-100: #f7f7f7;
  --grey-90: #f6f6f6;
  --gray-6: #f2f2f2;
  --color-whitesmoke-200: #ededed;
  --grey-80: #ececec;
  --primary-90: #f0ffeb;
  --green-100: #edf9e6;
  --red: #eb5757;
  --tertiary-10: #ed721c;
  --color-chocolate-100: #cb7601;
  --tertiary-0: #e35917;
  --primary-70: #b3e598;
  --tertiary-100: #fffbf5;
  --color-green-100: #298f00;
  --primary-10: #009100;
  --red-500: #df4539;
  --red-100: #fde8d7;
  --yellow-600: #a27f01;
  --yellow-100: #fcf8c9;
  --color-aliceblue: #ebf3ff;
  --primary-80: #d2f0c1;
  --tertiary-90: #fff3e2;
  --tertiary-80: #fee1b7;
  --grey-501: #a6a6a6;
  --yellow-500: #e2b801;
  --color-sandybrown: #feb95a;
  --tertiary-20: #f3811f;
  --tertiary-50: #feaa3b;
  --secondary-90: #ede7f8;
  --color-black: #000;
  --error-0: #ca0904;

  /* Gaps */
  --gap-13xl: 32px;
  --gap-5xl: 24px;
  --gap-base: 16px;
  --gap-5xs: 8px;
  --gap-xl: 20px;
  --gap-xs: 12px;
  --gap-9xs: 4px;
  --gap-496xl: 515px;
  --gap-133xl: 152px;
  --gap-11xs: 2px;
  --gap-6xs: 7px;
  --gap-257xl: 276px;
  --gap-102xl: 121px;
  --gap-256xl: 275px;
  --gap-316xl: 335px;
  --gap-4xl: 23px;
  --gap-14xl: 33px;
  --gap-3xs: 10px;
  --gap-21xl: 40px;
  --gap-7xs: 6px;
  --gap-56xl: 75px;
  --gap-8xs: 5px;
  --gap-191xl: 210px;
  --gap-62xl: 81px;
  --gap-lg: 18px;
  --gap-2xs: 11px;
  --gap-12xs: 1px;
  --gap-12xl: 31px;
  --gap-231xl: 250px;
  --gap-65xl: 84px;
  --gap-42xl: 61px;
  --gap-213xl: 232px;
  --gap-87xl: 106px;
  --gap-3xl: 22px;
  --gap-54xl: 73px;
  --gap-10xs: 3px;
  --gap-53xl: 72px;
  --gap-151xl: 170px;
  --gap-97xl: 116px;
  --gap-181xl: 200px;

  /* Paddings */
  --padding-31xl: 50px;
  --padding-xl: 20px;
  --padding-5xs: 8px;
  --padding-smi: 13px;
  --padding-sm: 14px;
  --padding-9xs: 4px;
  --padding-xs: 12px;
  --padding-9xl: 28px;
  --padding-41xl: 60px;
  --padding-61xl: 80px;
  --padding-base: 16px;
  --padding-158xl: 177px;
  --padding-8xl: 27px;
  --padding-7xs: 6px;
  --padding-11xs: 2px;
  --padding-33xl: 52px;
  --padding-8xs: 5px;
  --padding-59xl: 78px;
  --padding-5xl: 24px;
  --padding-21xl: 40px;
  --padding-lg: 18px;
  --padding-3xs: 10px;
  --padding-4xs: 9px;
  --padding-128xl: 147px;
  --padding-mini: 15px;
  --padding-215xl: 234px;
  --padding-37xl: 56px;
  --padding-25xl: 44px;
  --padding-13xl: 32px;
  --padding-18xl: 37px;
  --padding-138xl: 157px;

  /* border radiuses */
  --br-8xs: 5px;
  --br-5xs: 8px;
  --br-9xs: 4px;
  --br-3xs: 10px;
  --br-12xs: 1px;
  --br-81xl: 100px;
  --br-11xs: 2px;
  --br-xl: 20px;
  --br-5xl: 24px;
  --br-7xs: 6px;
  --br-base: 16px;
  --br-31xl: 50px;
  --br-xs: 12px;
  --br-10xs: 3px;

  /* Effects */
  --card: 0px 2px 4px rgba(0, 0, 0, 0.08);
  --card1: 0px -2px 4px rgba(0, 0, 0, 0.08), 0px 2px 4px rgba(0, 0, 0, 0.1);
  --elevation-2: 0px 0px 1px rgba(0, 0, 0, 0.02),
    0px 2px 4px rgba(0, 0, 0, 0.08);
  --popup: 0px 1px 2px rgba(0, 0, 0, 0.08), 0px 8px 16px rgba(0, 0, 0, 0.15);
}

body {
  margin: 0;
  line-height: normal;
}

:root {
  /* fonts */
  --p3-bold: Inter;
  --p2-regular: Inter;

  /* font sizes */
  --p3-bold-size: 14px;
  --caption-regular-size: 12px;
  --p2-regular-size: 16px;
  --h2-size: 24px;
  --font-size-5xs: 8px;
  --h5-bold-size: 20px;
  --p2-bold-size: 15px;
  --p1-semibold-size: 18px;
  --h4-semibold-size: 17px;
  --font-size-3xs: 10px;
  --font-size-8xs: 5px;
  --font-size-7xl-7: 26.7px;
  --caption-block-size: 9px;

  /* Colors */
  --white: #fff;
  --primary-40: #5ac91a;
  --primary-20base: #2fa500;
  --grey-20: #4b4b4b;
  --color-darkslategray-100: #3e3e3e;
  --gray-1: #333;
  --grey-800: #323232;
  --grey-10: #2e2e2e;
  --color-royalblue: #3976ea;
  --color-royalblue-100: #136be1;
  --color-dodgerblue: #1976d2;
  --color-silver: #c4c4c4;
  --labels: #6e6e6e;
  --grey-600: #636363;
  --grey-30: #5e5e5e;
  --grey-700: #505050;
  --gray-2: #4f4f4f;
  --grey-70: #dfdfdf;
  --grey-300: #d9d9d9;
  --color-gray-100: #fefefe;
  --grey-100: #fafafa;
  --grey-500: #8a8a8a;
  --color-gray-400: #858585;
  --grey-1: #252525;
  --main-text: #212121;
  --grey-900: #121212;
  --grey-0: #0d0d0d;
  --color-gray-300: rgba(0, 0, 0, 0.5);
  --color-gray-500: rgba(0, 0, 0, 0.3);
  --color-gray-200: rgba(39, 39, 39, 0.1);
  --grey-60: #ccc;
  --color-whitesmoke-100: #f7f7f7;
  --grey-90: #f6f6f6;
  --gray-6: #f2f2f2;
  --grey-1001: #eee;
  --color-whitesmoke-200: #ededed;
  --grey-80: #ececec;
  --color-honeydew-100: #f0ffeb;
  --green-100: #edf9e6;
  --primary-10: #009100;
  --tertiary-90: #fff3e2;
  --tertiary-80: #fee1b7;
  --tertiary-0: #e35917;
  --yellow-100: #fcf8c9;
  --yellow-500: #e2b801;
  --yellow-600: #a27f01;
  --color-black: #000;
  --color-aliceblue: #ebf3ff;
  --primary-80: #d2f0c1;
  --color-sandybrown: #feb95a;
  --tertiary-20: #f3811f;
  --tertiary-50: #feaa3b;
  --color-lavender-100: #ede7f8;
  --error-0: #ca0904;
  --red-100: #fde8d7;
  --red-500: #df4539;

  /* Gaps */
  --gap-13xl: 32px;
  --gap-5xl: 24px;
  --gap-base: 16px;
  --gap-5xs: 8px;
  --gap-xl: 20px;
  --gap-xs: 12px;
  --gap-9xs: 4px;
  --gap-496xl: 515px;
  --gap-133xl: 152px;
  --gap-6xs: 7px;
  --gap-11xs: 2px;
  --gap-213xl: 232px;
  --gap-12xs: 1px;
  --gap-65xl: 84px;
  --gap-42xl: 61px;
  --gap-3xs: 10px;
  --gap-91xl: 110px;
  --gap-4xl: 23px;
  --gap-12xl: 31px;
  --gap-9xl: 28px;
  --gap-45xl: 64px;
  --gap-lg: 18px;
  --gap-53xl: 72px;
  --gap-2xs: 11px;
  --gap-151xl: 170px;
  --gap-97xl: 116px;
  --gap-181xl: 200px;

  /* Paddings */
  --padding-31xl: 50px;
  --padding-xl: 20px;
  --padding-5xs: 8px;
  --padding-smi: 13px;
  --padding-sm: 14px;
  --padding-9xs: 4px;
  --padding-xs: 12px;
  --padding-9xl: 28px;
  --padding-41xl: 60px;
  --padding-61xl: 80px;
  --padding-base: 16px;
  --padding-158xl: 177px;
  --padding-7xs: 6px;
  --padding-21xl: 40px;
  --padding-37xl: 56px;
  --padding-25xl: 44px;
  --padding-3xs: 10px;
  --padding-8xs: 5px;
  --padding-13xl: 32px;
  --padding-5xl: 24px;
  --padding-18xl: 37px;
  --padding-16xl: 35px;
  --padding-17xl: 36px;
  --padding-smi-6: 12.6px;
  --padding-11xs: 2px;

  /* border radiuses */
  --br-8xs: 5px;
  --br-5xs: 8px;
  --br-81xl: 100px;
  --br-9xs: 4px;
  --br-11xs: 2px;
  --br-xs: 12px;
  --br-31xl: 50px;
  --br-7xs: 6px;
  --br-10xs: 3px;
  --br-xl: 20px;
  --br-base: 16px;

  /* Effects */
  --popup: 0px 1px 2px rgba(0, 0, 0, 0.08), 0px 8px 16px rgba(0, 0, 0, 0.15);
  --card: 0px 2px 4px rgba(0, 0, 0, 0.08);
}

body {
  margin: 0;
  line-height: normal;
}

:root {
  /* fonts */
  --p3-bold: Inter;

  /* font sizes */
  --p3-bold-size: 14px;
  --caption-regular-size: 12px;
  --p2-semibold-size: 16px;
  --h4-bold-size: 17px;
  --font-size-5xs: 8px;
  --h5-semibold-size: 17px;
  --p1-semibold-size: 18px;
  --p2-bold-size: 15px;
  --h4-semibold-size: 17px;
  --caption-block-size: 9px;
  --font-size-10xs-4: 2.4px;
  --capitalised-10-size: 10px;
  --h3-semibold-size: 32px;
  --h1-size: 30px;
  --font-size-8xs: 5px;
  --font-size-2xs: 11px;

  /* Colors */
  --white: #fff;
  --color-limegreen-100: #93ff68;
  --primary-40: #5ac91a;
  --primary-20base: #2fa500;
  --grey-20: #4b4b4b;
  --color-darkslategray-100: #3e3e3e;
  --color-darkslategray-200: #3a3a3d;
  --gray-1: #333;
  --grey-800: #323232;
  --grey-10: #2e2e2e;
  --color-royalblue: #3976ea;
  --color-royalblue-100: #136be1;
  --color-dodgerblue: #1976d2;
  --color-silver: #c4c4c4;
  --color-silver-100: #c0c0c0;
  --gray-4: #bdbdbd;
  --labels: #6e6e6e;
  --grey-600: #636363;
  --grey-30: #5e5e5e;
  --grey-700: #505050;
  --gray-2: #4f4f4f;
  --gray-5: #e0e0e0;
  --grey-70: #dfdfdf;
  --grey-300: #d9d9d9;
  --color-gray-100: #fefefe;
  --grey-100: #fafafa;
  --grey-500: #8a8a8a;
  --grey-40: #858585;
  --gray-3: #828282;
  --grey-1: #252525;
  --main-text: #212121;
  --grey-900: #121212;
  --grey-0: #0d0d0d;
  --color-gray-300: rgba(0, 0, 0, 0.5);
  --color-gray-500: rgba(0, 0, 0, 0.3);
  --color-gray-200: rgba(39, 39, 39, 0.1);
  --grey-60: #ccc;
  --color-whitesmoke-100: #f7f7f7;
  --grey-90: #f6f6f6;
  --gray-6: #f2f2f2;
  --grey-80: #ececec;
  --primary-90: #f0ffeb;
  --green-100: #edf9e6;
  --color-black: #000;
  --tertiary-90: #fff3e2;
  --tertiary-80: #fee1b7;
  --tertiary-10: #ed721c;
  --color-chocolate-100: #cb7601;
  --tertiary-0: #e35917;
  --color-green-100: #298f00;
  --primary-10: #009100;
  --red-100: #fde8d7;
  --red-500: #df4539;
  --error-20: #f02d16;
  --error-0: #ca0904;
  --red: #eb5757;
  --primary-70: #b3e598;
  --tertiary-100: #fffbf5;
  --yellow-600: #a27f01;
  --yellow-100: #fcf8c9;
  --color-aliceblue: #ebf3ff;
  --primary-80: #d2f0c1;
  --color-sandybrown: #feb95a;
  --tertiary-20: #f3811f;
  --tertiary-50: #feaa3b;

  /* Gaps */
  --gap-13xl: 32px;
  --gap-5xl: 24px;
  --gap-base: 16px;
  --gap-5xs: 8px;
  --gap-xl: 20px;
  --gap-xs: 12px;
  --gap-9xs: 4px;
  --gap-496xl: 515px;
  --gap-133xl: 152px;
  --gap-11xs: 2px;
  --gap-6xs: 7px;
  --gap-213xl: 232px;
  --gap-12xs: 1px;
  --gap-151xl: 170px;
  --gap-97xl: 116px;
  --gap-53xl: 72px;
  --gap-3xs: 10px;
  --gap-14xl: 33px;
  --gap-181xl: 200px;
  --gap-2xs: 11px;
  --gap-8xs: 5px;
  --gap-257xl: 276px;
  --gap-102xl: 121px;
  --gap-256xl: 275px;
  --gap-316xl: 335px;
  --gap-4xl: 23px;
  --gap-mini: 15px;
  --gap-12xl: 31px;
  --gap-56xl: 75px;
  --gap-231xl: 250px;
  --gap-94xl: 113px;
  --gap-21xl: 40px;
  --gap-7xs: 6px;
  --gap-191xl: 210px;
  --gap-62xl: 81px;
  --gap-lg: 18px;
  --gap-65xl: 84px;
  --gap-42xl: 61px;
  --gap-54xl: 73px;
  --gap-87xl: 106px;
  --gap-3xl: 22px;
  --gap-10xs: 3px;

  /* Paddings */
  --padding-31xl: 50px;
  --padding-xl: 20px;
  --padding-5xs: 8px;
  --padding-smi: 13px;
  --padding-sm: 14px;
  --padding-9xs: 4px;
  --padding-xs: 12px;
  --padding-9xl: 28px;
  --padding-41xl: 60px;
  --padding-61xl: 80px;
  --padding-base: 16px;
  --padding-13xl: 32px;
  --padding-158xl: 177px;
  --padding-7xs: 6px;
  --padding-37xl: 56px;
  --padding-8xs: 5px;
  --padding-4xs: 9px;
  --padding-11xs: 2px;
  --padding-21xl: 40px;
  --padding-215xl: 234px;
  --padding-33xl: 52px;
  --padding-128xl: 147px;
  --padding-10xs: 3px;
  --padding-3xs: 10px;
  --padding-8xl: 27px;
  --padding-59xl: 78px;
  --padding-5xl: 24px;
  --padding-lg: 18px;
  --padding-mini: 15px;
  --padding-34xl: 53px;
  --padding-18xl: 37px;
  --padding-138xl: 157px;

  /* border radiuses */
  --br-8xs: 5px;
  --br-5xs: 8px;
  --br-81xl: 100px;
  --br-9xs: 4px;
  --br-31xl: 50px;
  --br-11xs: 2px;
  --br-base: 16px;
  --br-xl: 20px;
  --br-3xs: 10px;
  --br-12xs: 1px;
  --br-5xl: 24px;
  --br-7xs: 6px;
  --br-xs: 12px;
  --br-10xs: 3px;

  /* Effects */
  --popup: 0px 1px 2px rgba(0, 0, 0, 0.08), 0px 8px 16px rgba(0, 0, 0, 0.15);
  --elevation-2: 0px 0px 1px rgba(0, 0, 0, 0.02),
    0px 2px 4px rgba(0, 0, 0, 0.08);
  --card: 0px -2px 4px rgba(0, 0, 0, 0.08), 0px 2px 4px rgba(0, 0, 0, 0.1);
  --card1: 0px 2px 4px rgba(0, 0, 0, 0.08);
}
