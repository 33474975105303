.addANew {
  position: relative;
  line-height: 28px;
  font-weight: 600;
}
.iconsclose {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
  cursor: pointer;
}
.addANewCardParent {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.modalTitle {
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: var(--padding-base) var(--padding-13xl);
  align-items: center;
  justify-content: flex-start;
}
.iconWithBg {
  position: relative;
  border-radius: var(--br-9xs);
  width: 32px;
  height: 32px;
}
.iconsplaceholder {
  position: relative;
  width: 24px;
  height: 24px;
  display: none;
}
.list1 {
  position: relative;
  line-height: 26px;
}
.left {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xs);
}
.iconschevronRight {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.toggleChild {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-31xl);
  background-color: var(--grey-60);
}
.toggleItem {
  position: absolute;
  height: 87.5%;
  width: 50%;
  top: 6.25%;
  right: 46.43%;
  bottom: 6.25%;
  left: 3.57%;
  border-radius: 50%;
  background-color: var(--white);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}
.toggle {
  position: relative;
  width: 56px;
  height: 32px;
  display: none;
}
.right {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.content,
.list {
  align-self: stretch;
  display: flex;
}
.content {
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.list {
  border-bottom: 1px solid var(--grey-80);
  box-sizing: border-box;
  height: 60px;
  flex-direction: column;
  padding: var(--padding-base) 0;
  justify-content: flex-start;
  cursor: pointer;
}
.list,
.list4,
.listParent {
  align-items: flex-start;
}
.list4 {
  align-self: stretch;
  height: 60px;
  display: flex;
  flex-direction: column;
  padding: var(--padding-base) 0;
  box-sizing: border-box;
  justify-content: flex-start;
  cursor: pointer;
}
.listParent {
  width: 397px;
  font-size: var(--p2-bold-size);
  color: var(--grey-10);
}
.landcmodal,
.listParent,
.modalTitleParent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.modalTitleParent {
  align-self: stretch;
  align-items: center;
}
.landcmodal {
  position: relative;
  border-radius: var(--br-8xs);
  background-color: var(--white);
  box-shadow: var(--popup);
  width: 461px;
  padding: 0 0 var(--padding-xl);
  box-sizing: border-box;
  align-items: flex-start;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
  text-align: left;
  font-size: var(--h5-semibold-size);
  color: var(--color-black);
  font-family: var(--p3-bold);
}
@media screen and (max-width: 430px) {
  .listParent {
    width: 300px;
  }
  .landcmodal {
    width: 400px;
  }
}
