.navChild {
  position: absolute;
  top: 27px;
  left: 20px;
  width: 125px;
  height: 20px;
}
.iconshome {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.home {
  position: relative;
  line-height: 24px;
  font-weight: 500;
}
.default,
.iconshomeParent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.iconshomeParent {
  flex-direction: row;
  gap: var(--gap-5xs);
}
.default {
  align-self: stretch;
  border-radius: var(--br-11xs);
  overflow: hidden;
  flex-direction: column;
  padding: var(--padding-5xs);
  cursor: pointer;
}
.accountIcon {
  position: relative;
  width: 24px;
  height: 24px;
}
.home5 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
}
.default5,
.defaultParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.default5 {
  align-self: stretch;
  border-radius: var(--br-9xs);
  background-color: var(--grey-90);
  overflow: hidden;
  padding: var(--padding-5xs);
  color: var(--primary-10);
}
.defaultParent {
  position: absolute;
  top: 87px;
  left: 20px;
  width: 181px;
  gap: var(--gap-xs);
}
.nav {
  height: 100%;
  width: 221px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--grey-100);
}
.iconsadd,
.navPrototype {
  position: relative;
  width: 221px;
  height: 1325px;
}
.iconsadd {
  width: 16px;
  height: 16px;
  overflow: hidden;
  flex-shrink: 0;
}
.button {
  position: relative;
  line-height: 100%;
  font-weight: 600;
}
.iconsaddParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-9xs);
}
.avatarIcon,
.smallButtons {
  position: absolute;
  top: 14px;
  height: 40px;
  display: none;
}
.smallButtons {
  right: 427px;
  border-radius: var(--br-9xs);
  background-color: var(--primary-20base);
  width: 146px;
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.avatarIcon {
  left: 1139px;
  width: 40px;
}
.licenseAndCertification {
  top: 18px;
  left: 0px;
  font-size: var(--h5-semibold-size);
  line-height: 28px;
  color: var(--grey-900);
  text-align: left;
}
.component102 {
  background-color: var(--white);
  border-bottom: 1px solid var(--grey-80);
  box-sizing: border-box;
  width: 100%;
  padding: 1rem;
  flex-shrink: 0;
  display: flex;
  justify-content: start;
  padding-left: 10%;
}
.imgContainerChild,
.imgContainerItem {
  position: absolute;
  top: 16px;
  left: 502px;
  width: 40px;
  height: 40px;
  display: none;
}
.imgContainerItem {
  left: 450px;
}
.contentChild,
.contentItem {
  position: relative;
  border-radius: 50%;
  background-color: var(--primary-20base);
  width: 10px;
  height: 10px;
}
.contentItem {
  background-color: var(--grey-80);
}
.content,
.smallButtons1 {
  position: absolute;
  display: flex;
  flex-direction: row;
}
.content {
  top: 178px;
  left: 238px;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.smallButtons1 {
  top: 12px;
  left: 80%;
  border-radius: var(--br-9xs);
  background-color: var(--white);
  border: 1px solid var(--primary-20base);
  box-sizing: border-box;
  width: 80px;
  height: 32px;
  padding: var(--padding-5xs) var(--padding-xl);
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.imgContainer {
  position: relative;
  border-radius: var(--br-5xs);
  width: 558px;
  height: 203px;
  background-color: #d7d7d7;
  overflow: hidden;
  flex-shrink: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.avatarIcon1 {
  position: relative;
  width: 56px;
  height: 56px;
}
.dysonElectricals,
.success {
  position: relative;
  font-weight: 600;
}
.dysonElectricals {
  line-height: 28px;
}
.success {
  letter-spacing: 0.04em;
  text-transform: uppercase;
}
.transactionPills {
  border-radius: var(--br-81xl);
  background-color: var(--green-100);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  text-align: right;
  font-size: var(--font-size-5xs);
  color: var(--primary-20base);
}
.avatarParent,
.frameGroup {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.avatarParent {
  flex-direction: row;
  gap: var(--gap-xs);
}
.rookie {
  position: absolute;
  top: 0;
  left: 0;
  width: 26px;
  height: 43px;
  overflow: hidden;
  display: none;
}
.ambassadorChild,
.ambassadorItem {
  position: absolute;
  top: 6px;
  left: 1px;
  width: 24px;
  height: 24px;
}
.ambassadorItem {
  top: 31px;
  left: 7px;
  width: 12px;
  height: 12px;
}
.ambassadorInner,
.vectorIcon {
  position: absolute;
  top: 0;
  left: 0;
  width: 25px;
  height: 5px;
}
.vectorIcon {
  top: 22px;
  left: 9.57px;
  border-radius: var(--br-12xs);
  width: 11px;
  height: 4px;
}
.ambassador,
.awards {
  width: 26px;
  height: 43px;
  overflow: hidden;
}
.ambassador {
  position: absolute;
  top: 0;
  left: 0;
}
.awards {
  position: relative;
  flex-shrink: 0;
  text-align: center;
  font-size: 2.37px;
  color: var(--grey-900);
}
.profileHeading {
  width: 558px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-257xl);
  text-align: left;
}
.icons20pxplaceholder {
  position: relative;
  width: 20px;
  height: 20px;
  display: none;
}
.label {
  position: relative;
  line-height: 24px;
}
.largeButtons {
  border-radius: var(--br-8xs);
  background-color: var(--primary-20base);
  width: 420px;
  height: 44px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs) 0;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
  cursor: pointer;
}
.share {
  position: relative;
  line-height: 26px;
}
.buttonsInner,
.shareParent {
  display: flex;
  align-items: center;
}
.shareParent {
  flex-direction: row;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.buttonsInner {
  border-radius: var(--br-8xs);
  background-color: var(--white);
  border: 1px solid var(--primary-20base);
  box-sizing: border-box;
  width: 122px;
  flex-direction: column;
  padding: var(--padding-5xs) var(--padding-8xl);
  justify-content: center;
  font-size: var(--p2-semibold-size);
  color: var(--primary-20base);
}
.buttons,
.tab1Wrapper {
  display: flex;
  flex-direction: row;
}
.buttons {
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
  font-size: var(--p3-bold-size);
  color: var(--white);
}
.tab1Wrapper {
  position: absolute;
  width: 48.57%;
  top: 4px;
  right: 50%;
  left: 1.43%;
  border-radius: var(--br-9xs);
  height: 44px;
  padding: var(--padding-9xs) var(--padding-5xs);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.tab2 {
  position: relative;
  line-height: 26px;
  font-weight: 600;
}
.tab2Wrapper {
  position: absolute;
  width: 49.28%;
  top: 4px;
  right: 0.72%;
  left: 50%;
  border-radius: var(--br-9xs);
  background-color: var(--white);
  height: 44px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-9xs) var(--padding-5xs);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  color: var(--primary-20base);
}
.twoTabSwitch {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-8xs);
  background-color: var(--grey-90);
}
.prototypeSettings {
  position: relative;
  width: 558px;
  height: 52px;
  font-size: var(--p2-semibold-size);
  color: var(--grey-30);
}
.profile {
  font-size: var(--h5-semibold-size);
  color: var(--grey-0);
}
.profile,
.profileSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.iconWithBg {
  position: relative;
  border-radius: var(--br-9xs);
  width: 32px;
  height: 32px;
  display: none;
}
.left {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xs);
}
.toggleChild {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-31xl);
  background-color: var(--grey-60);
}
.toggleItem {
  position: absolute;
  height: 87.5%;
  width: 50%;
  top: 6.25%;
  right: 46.43%;
  bottom: 6.25%;
  left: 3.57%;
  border-radius: 50%;
  background-color: var(--white);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}
.toggle {
  position: relative;
  width: 56px;
  height: 32px;
  display: none;
}
.content2,
.right {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.right {
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.content2 {
  align-self: stretch;
  flex: 1;
  justify-content: space-between;
}
.list {
  border-bottom: 1px solid var(--grey-80);
  box-sizing: border-box;
  width: 559px;
  height: 60px;
  display: flex;
  flex-direction: column;
  padding: var(--padding-base) 0;
  align-items: flex-start;
  justify-content: flex-start;
  cursor: pointer;
}
.iconschevronRight2 {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.toggle2 {
  position: relative;
  width: 56px;
  height: 32px;
}
.list4 {
  border-bottom: 1px solid var(--grey-80);
  box-sizing: border-box;
  width: 559px;
  height: 60px;
  padding: var(--padding-base) 0;
}
.content1,
.list4,
.mergeHeaderContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.content1 {
  text-align: left;
  font-size: var(--p2-semibold-size);
  color: var(--grey-10);
}
.mergeHeaderContent {
  gap: var(--gap-xs);
}
.logout,
.privacyPolicy {
  position: relative;
  line-height: 26px;
  font-weight: 500;
}
.privacyPolicy {
  flex: 1;
}
.frameChild {
  position: relative;
  border-right: 1px solid var(--grey-20);
  box-sizing: border-box;
  width: 1px;
  height: 29px;
}
.partners,
.termsCondition {
  flex: 1;
  position: relative;
  line-height: 26px;
  font-weight: 500;
  text-align: center;
}
.partners {
  text-align: right;
}
.privacyPolicyParent {
  width: 559px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
  text-align: left;
}
.footer,
.version100Parent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xl);
  text-align: center;
  color: var(--grey-20);
}
.footer {
  gap: var(--gap-13xl);
  text-align: left;
  font-size: var(--p2-semibold-size);
  color: var(--error-0);
}
.profileSettings,
.profileSettingsWrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.profileSettings {
  flex-direction: column;
  gap: var(--gap-21xl);
}
.profileSettingsWrapper {
  background-color: var(--color-gray-100);
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-5xs) var(--padding-21xl);
}
.connectSocialAccounts {
  position: absolute;
  top: 16px;
  left: 16px;
  line-height: 26px;
  font-weight: 500;
}
.frameChild1,
.frameInner,
.groupIcon {
  position: absolute;
  height: 38.24%;
  width: 14.29%;
  top: 47.06%;
  right: 81.32%;
  bottom: 14.71%;
  left: 4.4%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.frameChild1,
.groupIcon {
  right: 56.04%;
  left: 29.67%;
}
.frameChild1 {
  right: 30.77%;
  left: 54.95%;
}
.connectSocialAccountsParent {
  position: relative;
  border-radius: var(--br-5xs);
  background-color: var(--grey-100);
  border: 1px solid var(--grey-90);
  box-sizing: border-box;
  width: 364px;
  height: 136px;
  overflow: hidden;
  flex-shrink: 0;
}
.groupChild {
  position: absolute;
  height: 4.55%;
  width: 1.69%;
  top: 23.08%;
  right: 96.26%;
  bottom: 72.38%;
  left: 2.05%;
  background-color: var(--primary-20base);
}
.groupChild1,
.groupChild2,
.groupInner,
.groupItem {
  position: absolute;
  height: 4.55%;
  width: 1.69%;
  top: 2.11%;
  right: 47.28%;
  bottom: 93.35%;
  left: 51.03%;
  background-color: var(--color-royalblue-100);
}
.groupChild1,
.groupChild2,
.groupInner {
  top: 10.14%;
  right: 70.59%;
  bottom: 85.32%;
  left: 27.72%;
  background-color: var(--red);
}
.groupChild1,
.groupChild2 {
  height: 4.54%;
  top: 97.2%;
  right: 62.14%;
  bottom: -1.74%;
  left: 36.16%;
  background-color: var(--primary-20base);
  transform: rotate(-163.87deg);
  transform-origin: 0 0;
  opacity: 0.47;
}
.groupChild2 {
  height: 4.42%;
  width: 1.74%;
  top: 9.44%;
  right: 83.38%;
  bottom: 86.13%;
  left: 14.89%;
  transform: rotate(-92.71deg);
  opacity: 0.16;
}
.groupChild3,
.groupChild4,
.groupChild5,
.groupChild6,
.groupChild7,
.groupChild8 {
  position: absolute;
  height: 4.53%;
  width: 1.69%;
  top: 86.71%;
  right: 79.7%;
  bottom: 8.75%;
  left: 18.6%;
  background-color: var(--primary-20base);
  transform: rotate(-162.32deg);
  transform-origin: 0 0;
  opacity: 0.62;
}
.groupChild4,
.groupChild5,
.groupChild6,
.groupChild7,
.groupChild8 {
  height: 4.45%;
  width: 1.73%;
  top: 81.28%;
  right: 32.39%;
  bottom: 14.27%;
  left: 65.89%;
  transform: rotate(-64deg);
  opacity: 0.4;
}
.groupChild5,
.groupChild6,
.groupChild7,
.groupChild8 {
  height: 4.53%;
  width: 1.7%;
  top: 83.89%;
  right: 90.65%;
  bottom: 11.59%;
  left: 7.66%;
  transform: rotate(-156.11deg);
  opacity: 0.28;
}
.groupChild6,
.groupChild7,
.groupChild8 {
  height: 4.54%;
  width: 1.69%;
  top: 54.9%;
  right: 92.88%;
  bottom: 40.56%;
  left: 5.43%;
  transform: rotate(168.55deg);
  opacity: 0.6;
}
.groupChild7,
.groupChild8 {
  height: 4.42%;
  width: 1.74%;
  top: 9.44%;
  right: 2.65%;
  bottom: 86.13%;
  left: 95.61%;
  transform: rotate(-91.73deg);
  opacity: 0.78;
}
.groupChild8 {
  height: 4.51%;
  width: 1.7%;
  top: 5.1%;
  right: 58.02%;
  bottom: 90.39%;
  left: 40.28%;
  transform: rotate(-145.82deg);
  opacity: 0.25;
}
.groupChild10,
.groupChild11,
.groupChild12,
.groupChild13,
.groupChild14,
.groupChild9 {
  position: absolute;
  height: 4.44%;
  width: 1.73%;
  top: 12.23%;
  right: 96.55%;
  bottom: 83.33%;
  left: 1.72%;
  background-color: var(--color-royalblue-100);
  transform: rotate(-109.53deg);
  transform-origin: 0 0;
  opacity: 0.74;
}
.groupChild10,
.groupChild11,
.groupChild12,
.groupChild13,
.groupChild14 {
  height: 4.54%;
  width: 1.69%;
  top: 60.77%;
  right: 2.63%;
  bottom: 34.69%;
  left: 95.68%;
  transform: rotate(169.59deg);
  opacity: 0.55;
}
.groupChild11,
.groupChild12,
.groupChild13,
.groupChild14 {
  height: 4.44%;
  width: 1.73%;
  top: 34.31%;
  right: 83.07%;
  bottom: 61.26%;
  left: 15.2%;
  transform: rotate(108.04deg);
  opacity: 0.2;
}
.groupChild12,
.groupChild13,
.groupChild14 {
  height: 4.46%;
  width: 1.72%;
  top: 100%;
  right: 89.81%;
  bottom: -4.46%;
  left: 8.47%;
  transform: rotate(-122deg);
  opacity: 0.03;
}
.groupChild13,
.groupChild14 {
  height: 4.43%;
  width: 1.73%;
  top: 0;
  right: 29.67%;
  bottom: 95.57%;
  left: 68.59%;
  transform: rotate(75.42deg);
  opacity: 0.33;
}
.groupChild14 {
  top: 39.86%;
  right: 9.17%;
  bottom: 55.71%;
  left: 89.1%;
  transform: rotate(78.39deg);
  opacity: 0.75;
}
.groupChild15,
.groupChild16,
.groupChild17,
.groupChild18,
.groupChild19,
.groupChild20,
.groupChild21 {
  position: absolute;
  height: 4.42%;
  width: 1.74%;
  top: 97.9%;
  right: 14.81%;
  bottom: -2.32%;
  left: 83.45%;
  background-color: var(--color-chocolate-100);
  transform: rotate(87.78deg);
  transform-origin: 0 0;
  opacity: 0.32;
}
.groupChild16,
.groupChild17,
.groupChild18,
.groupChild19,
.groupChild20,
.groupChild21 {
  height: 4.53%;
  width: 1.69%;
  top: 75.81%;
  right: 64.51%;
  bottom: 19.66%;
  left: 33.8%;
  transform: rotate(-21.83deg);
}
.groupChild17,
.groupChild18,
.groupChild19,
.groupChild20,
.groupChild21 {
  height: 4.46%;
  width: 1.72%;
  top: 22.73%;
  right: 25.97%;
  bottom: 72.82%;
  left: 72.31%;
  transform: rotate(-60deg);
  opacity: 0.88;
}
.groupChild18,
.groupChild19,
.groupChild20,
.groupChild21 {
  height: 4.55%;
  width: 1.69%;
  top: 91.26%;
  right: 41.84%;
  bottom: 4.19%;
  left: 56.47%;
  transform: rotate(179.48deg);
  opacity: 0.86;
}
.groupChild19,
.groupChild20,
.groupChild21 {
  height: 4.51%;
  width: 1.7%;
  top: 33.22%;
  right: 21.94%;
  bottom: 62.27%;
  left: 76.36%;
  transform: rotate(148.89deg);
  opacity: 0.06;
}
.groupChild20,
.groupChild21 {
  height: 4.45%;
  width: 1.72%;
  top: 81.82%;
  right: 54.34%;
  bottom: 13.73%;
  left: 43.93%;
  transform: rotate(60.5deg);
  opacity: 0.34;
}
.groupChild21 {
  height: 4.5%;
  width: 1.7%;
  top: 76.54%;
  right: 13.15%;
  bottom: 18.95%;
  left: 85.14%;
  transform: rotate(-36.23deg);
  opacity: 0.68;
}
.groupChild22,
.groupChild23,
.groupChild24,
.groupChild25 {
  position: absolute;
  height: 4.43%;
  width: 1.73%;
  top: 24.71%;
  right: 76.78%;
  bottom: 70.87%;
  left: 21.48%;
  background-color: var(--red);
  transform: rotate(97.16deg);
  transform-origin: 0 0;
  opacity: 0.26;
}
.groupChild23,
.groupChild24,
.groupChild25 {
  height: 4.44%;
  top: 13.29%;
  right: 5.36%;
  bottom: 82.27%;
  left: 92.91%;
  transform: rotate(110.03deg);
  opacity: 0.19;
}
.groupChild24,
.groupChild25 {
  height: 4.5%;
  width: 1.7%;
  top: 65.73%;
  right: 96.92%;
  bottom: 29.77%;
  left: 1.38%;
  transform: rotate(-143.77deg);
  opacity: 0.67;
}
.groupChild25 {
  height: 4.42%;
  width: 1.73%;
  top: 28.62%;
  right: 6.59%;
  bottom: 66.96%;
  left: 91.67%;
  transform: rotate(-93.7deg);
  opacity: 0.76;
}
.rectangleParent {
  position: absolute;
  height: 159.93%;
  width: 39.84%;
  top: 8.89%;
  right: 4.81%;
  bottom: -68.82%;
  left: 55.35%;
  opacity: 0.3;
}
.giftBoxIcon {
  position: relative;
  width: 41px;
  height: 44.58px;
}
.referABusiness {
  position: relative;
  font-size: var(--p2-semibold-size);
  line-height: 26px;
  color: var(--grey-700);
}
.giftBoxParent,
.referEarnParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-11xs);
}
.giftBoxParent {
  position: absolute;
  top: 16px;
  left: 24px;
  flex-direction: row;
  gap: var(--gap-base);
}
.referAndEarn {
  position: relative;
  border-radius: var(--br-5xs);
  background-color: var(--green-100);
  border: 1px solid var(--grey-300);
  box-sizing: border-box;
  width: 364px;
  height: 90px;
  overflow: hidden;
  flex-shrink: 0;
  font-size: var(--h5-semibold-size);
  color: var(--grey-900);
}
.frameContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
  text-align: left;
  font-size: var(--p2-semibold-size);
  color: var(--grey-10);
}
.frameParent {
  flex-direction: row;
  align-items: center;
  gap: var(--gap-94xl);
  color: var(--primary-20base);
}
.component102Parent,
.frameParent,
.settings1 {
  display: flex;
  justify-content: flex-start;
}
.component102Parent {
  flex-direction: column;
  align-items: center;
  gap: var(--gap-xs);
  color: var(--white);
  margin-left: 2rem;
  width:100%
}
.settings1 {
  background-color: var(--color-gray-100);
  overflow-y: auto;
  flex-direction: row;
  align-items: flex-start;
  width:100%;
}
.component29Child {
  background-color: var(--color-whitesmoke-100);
}
.component29Child,
.rectangleIcon,
.rectangleWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 414px;
  height: 184px;
}
.rectangleIcon {
  object-fit: cover;
}
.rectangleWrapper {
  overflow: hidden;
}
.frameDiv,
.rectangleContainer,
.rectangleFrame,
.rectangleWrapper1 {
  position: absolute;
  top: 0;
  left: 414px;
  width: 414px;
  height: 184px;
  overflow: hidden;
}
.frameDiv,
.rectangleFrame,
.rectangleWrapper1 {
  left: 828px;
}
.frameDiv,
.rectangleWrapper1 {
  left: 1242px;
}
.rectangleWrapper1 {
  left: 1656px;
}
.component29Item,
.groupDiv {
  position: absolute;
  top: 0;
  left: 0;
  width: 2070px;
  height: 184px;
}
.component29Item {
  top: 77px;
  background: linear-gradient(
    180deg,
    rgba(34, 34, 34, 0),
    rgba(34, 34, 34, 0.7)
  );
  width: 414px;
  height: 107px;
  opacity: 0.8;
}
.component29Inner {
  position: absolute;
  top: 166px;
  left: 180px;
  width: 54px;
  height: 6px;
}
.edit2Icon {
  position: relative;
  width: 12px;
  height: 12px;
  overflow: hidden;
  flex-shrink: 0;
}
.edit {
  position: relative;
  line-height: 155%;
  font-weight: 600;
}
.button2 {
  position: absolute;
  top: 12px;
  left: 336px;
  border-radius: var(--br-9xs);
  background-color: var(--white);
  border: 1px solid var(--primary-20base);
  box-sizing: border-box;
  width: 66px;
  display: flex;
  flex-direction: column;
  padding: var(--padding-8xs) var(--padding-xs);
  align-items: center;
  justify-content: center;
}
.component29 {
  position: relative;
  width: 414px;
  height: 184px;
  overflow: hidden;
  flex-shrink: 0;
  font-size: var(--p3-bold-size);
  color: var(--primary-20base);
}
.groupChild26 {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-81xl);
  width: 48px;
  height: 48px;
  object-fit: cover;
}
.rectangleWrapper2 {
  position: relative;
  width: 48px;
  height: 48px;
}
.smithsConstruction {
  position: relative;
  line-height: 155%;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.applianceRepair {
  position: absolute;
  top: -2px;
  left: 0;
  line-height: 155%;
  font-weight: 500;
}
.applianceRepairWrapper {
  position: relative;
  width: 99px;
  height: 14px;
  overflow: hidden;
  flex-shrink: 0;
  font-size: var(--caption-regular-size);
  color: var(--grey-600);
}
.groupParent,
.rookieInner {
  position: absolute;
  top: 0;
  left: 0;
}
.groupParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
}
.rookieInner {
  width: 25px;
  height: 5px;
  display: none;
}
.rookieChild1 {
  position: absolute;
  top: 22px;
  left: 7.5px;
  border-radius: var(--br-12xs);
  width: 11px;
  height: 4px;
}
.intermediate1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 26px;
  height: 43px;
  overflow: hidden;
  display: none;
  color: var(--grey-100);
}
.skilled1,
.skilledInner {
  position: absolute;
  top: 0;
  left: 10px;
  width: 5px;
  height: 5px;
}
.skilled1 {
  left: 0;
  width: 26px;
  height: 43px;
  overflow: hidden;
  display: none;
  color: var(--grey-900);
}
.semiProInner {
  position: absolute;
  top: 0;
  left: 8px;
  width: 10px;
  height: 5px;
}
.legendary,
.lengendaryInner,
.professionalInner {
  position: absolute;
  top: 0;
  left: 5px;
  width: 15px;
  height: 5px;
}
.legendary,
.lengendaryInner {
  left: 3px;
  width: 20px;
}
.legendary {
  top: 8.5px;
  left: 5.5px;
  line-height: 250%;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 6px;
}
.awards1 {
  position: absolute;
  top: 2px;
  left: 348px;
  width: 26px;
  height: 43px;
  overflow: hidden;
  text-align: center;
  font-size: 2.37px;
  color: var(--white);
}
.frameParent2 {
  width: 374px;
  height: 48px;
  overflow: hidden;
  flex-shrink: 0;
}
.frameParent2,
.icons,
.proceed {
  position: relative;
}
.icons {
  width: 20px;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.proceed {
  line-height: 155%;
}
.proceedWrapper {
  flex-direction: row;
}
.button3,
.iconsParent,
.proceedWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.iconsParent {
  flex-direction: row;
  gap: var(--gap-5xs);
}
.button3 {
  border-radius: var(--br-9xs);
  background-color: var(--primary-20base);
  width: 181px;
  height: 40px;
  flex-direction: column;
  padding: var(--padding-3xs) 0;
  box-sizing: border-box;
  cursor: pointer;
}
.icons1 {
  position: relative;
  width: 20px;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.button4 {
  border-radius: var(--br-9xs);
  background-color: var(--white);
  border: 1px solid var(--primary-20base);
  box-sizing: border-box;
  width: 181px;
  height: 40px;
  display: flex;
  flex-direction: column;
  padding: var(--padding-3xs) var(--padding-34xl) var(--padding-3xs)
    var(--padding-33xl);
  align-items: center;
  justify-content: center;
  color: var(--primary-20base);
}
.buttonParent,
.frameParent1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.buttonParent {
  flex-direction: row;
  gap: var(--gap-xs);
  text-align: center;
  font-size: var(--p3-bold-size);
  color: var(--white);
}
.frameParent1 {
  flex-direction: column;
  gap: var(--gap-xl);
}
.settingsChild,
.title {
  background-color: var(--white);
}
.title {
  display: flex;
  flex-direction: column;
  padding: var(--padding-base) var(--padding-xl) var(--padding-xl);
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--h4-semibold-size);
  color: var(--grey-900);
}
.settingsChild {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.tabChild,
.tabItem {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-9xs);
  background-color: var(--grey-100);
  width: 374px;
  height: 36px;
}
.tabItem {
  top: 3px;
  left: 187px;
  background-color: var(--white);
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.05);
  width: 184px;
  height: 30px;
}
.account {
  top: 6px;
  left: 63px;
  line-height: 155%;
  font-weight: 500;
  cursor: pointer;
}
.account,
.settings3,
.tab {
  position: absolute;
}
.settings3 {
  top: 6px;
  left: 250px;
  line-height: 155%;
  font-weight: 600;
  color: var(--primary-20base);
}
.tab {
  height: 81.82%;
  width: 90.34%;
  top: 9.09%;
  right: 4.83%;
  bottom: 9.09%;
  left: 4.83%;
  overflow: hidden;
}
.settings2 {
  position: relative;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
  width: 414px;
  height: 44px;
  text-align: center;
  font-size: var(--p2-bold-size);
  color: var(--grey-700);
}
.accountChild {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--white);
  width: 374px;
  height: 61px;
}
.accountItem {
  position: absolute;
  top: 60.5px;
  left: -0.5px;
  border-top: 1px solid var(--grey-100);
  box-sizing: border-box;
  width: 375px;
  height: 1px;
  display: none;
}
.chevronDownIcon {
  position: absolute;
  height: 22.95%;
  width: 5.44%;
  top: 71.69%;
  right: -1.69%;
  bottom: 5.36%;
  left: 96.26%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.toggleChild11 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-31xl);
  background-color: var(--color-silver-100);
}
.toggleChild12 {
  position: absolute;
  height: 83.33%;
  width: 45.45%;
  top: 8.33%;
  right: 50%;
  bottom: 8.33%;
  left: 4.55%;
  border-radius: 50%;
  background-color: var(--white);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}
.toggle7 {
  position: absolute;
  top: 20px;
  left: 330px;
  width: 44px;
  height: 24px;
  display: none;
}
.personal {
  position: relative;
  line-height: 155%;
  font-weight: 500;
}
.iconsContainer {
  position: absolute;
  top: 20px;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xs);
}
.account1 {
  position: relative;
  width: 374px;
  height: 61px;
  overflow: hidden;
  flex-shrink: 0;
}
.chevronDownIcon2 {
  position: absolute;
  height: 22.95%;
  width: 5.44%;
  top: 71.69%;
  right: -1.69%;
  bottom: 5.36%;
  left: 96.26%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  display: none;
}
.logOutIcon,
.toggle9 {
  position: absolute;
  top: 20px;
  left: 330px;
  width: 44px;
  height: 24px;
}
.logOutIcon {
  top: 3px;
  left: 0;
  width: 18px;
  height: 18px;
  overflow: hidden;
}
.logout1 {
  position: absolute;
  top: 0;
  left: 26px;
  line-height: 155%;
  font-weight: 500;
}
.logOutParent {
  position: relative;
  width: 80px;
  height: 25px;
  color: var(--red-500);
}
.version1001 {
  position: relative;
  font-size: var(--p3-bold-size);
  line-height: 155%;
  font-weight: 500;
  color: var(--grey-600);
}
.frameChild6 {
  position: relative;
  background-color: var(--grey-600);
  border-right: 1px solid var(--gray-2);
  box-sizing: border-box;
  width: 1px;
  height: 20px;
}
.privacyPolicyGroup {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
  font-size: var(--p3-bold-size);
  color: var(--grey-600);
}
.home7 {
  position: absolute;
  top: 63.46%;
  left: 0;
  line-height: 155%;
  font-weight: 500;
}
.brandLogoIcon {
  height: 57.69%;
  width: 97.14%;
  top: 0;
  right: 0;
  bottom: 42.31%;
  left: 2.86%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.brandLogoIcon,
.home6,
.wallet1 {
  position: absolute;
}
.home6 {
  height: 65.82%;
  width: 8.45%;
  top: 17.72%;
  right: 85.75%;
  bottom: 16.46%;
  left: 5.8%;
}
.wallet1 {
  top: 61.22%;
  left: 0;
  line-height: 155%;
  font-weight: 500;
}
.zondiconswallet {
  position: absolute;
  height: 48.98%;
  width: 66.67%;
  top: 0;
  right: 16.67%;
  bottom: 51.02%;
  left: 16.67%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.wallet {
  position: absolute;
  height: 62.03%;
  width: 8.7%;
  top: 21.52%;
  right: 35.51%;
  bottom: 16.46%;
  left: 55.8%;
}
.account8 {
  position: absolute;
  top: 63.46%;
  left: 0;
  line-height: 155%;
}
.mdiaccountIcon {
  position: absolute;
  height: 55.77%;
  width: 58%;
  top: 0;
  right: 20%;
  bottom: 44.23%;
  left: 22%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.account7 {
  position: absolute;
  height: 65.82%;
  width: 12.08%;
  top: 17.72%;
  right: 6.04%;
  bottom: 16.46%;
  left: 81.88%;
  color: var(--primary-20base);
}
.jobs1 {
  position: absolute;
  top: 62%;
  left: 0;
  line-height: 155%;
  font-weight: 500;
}
.bibriefcaseFillIcon {
  position: absolute;
  height: 52%;
  width: 92.86%;
  top: 0;
  right: 3.57%;
  bottom: 48%;
  left: 3.57%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.jobs {
  position: absolute;
  height: 63.29%;
  width: 6.76%;
  top: 20.25%;
  right: 61.59%;
  bottom: 16.46%;
  left: 31.64%;
}
.bottomNav,
.nav1 {
  width: 414px;
  height: 79px;
}
.bottomNav {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--white);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
}
.nav1 {
  position: relative;
  text-align: center;
  font-size: var(--caption-regular-size);
  color: var(--grey-600);
}
.settingsmob {
  background-color: var(--white);
  height: 1010px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  font-size: var(--p2-semibold-size);
  color: var(--grey-800);
}
.settings {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-3xs);
  text-align: center;
  font-size: var(--p3-bold-size);
  color: var(--grey-10);
  font-family: var(--p3-bold);
}
@media screen and (max-width: 960px) {
  .component102,
  .frameContainer,
  .navPrototype {
    display: none;
  }
}
@media screen and (max-width: 430px) {
  .settings1 {
    display: none;
  }
  .settingsmob {
    display: flex;
  }
  .imgContainer {
    align-self: stretch;
    width: auto;
    height: 190px;
  }
}
