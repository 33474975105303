.navChild {
  position: absolute;
  top: 27px;
  left: 20px;
  width: 125px;
  height: 20px;
}
.iconshome {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.home {
  position: relative;
  line-height: 24px;
  font-weight: 500;
}
.default,
.iconshomeParent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.iconshomeParent {
  flex-direction: row;
  gap: var(--gap-5xs);
}
.default {
  align-self: stretch;
  border-radius: var(--br-11xs);
  overflow: hidden;
  flex-direction: column;
  padding: var(--padding-5xs);
  cursor: pointer;
}
.accountIcon {
  position: relative;
  width: 24px;
  height: 24px;
}
.home5 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
}
.default5,
.defaultParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.default5 {
  align-self: stretch;
  border-radius: var(--br-9xs);
  background-color: var(--grey-90);
  overflow: hidden;
  padding: var(--padding-5xs);
  color: var(--primary-10);
}
.defaultParent {
  position: absolute;
  top: 87px;
  left: 20px;
  width: 181px;
  gap: var(--gap-xs);
}
.nav {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--grey-50);
}
.navPrototype {
  position: relative;
  width: 221px;
  height: 1325px;
}
.back1 {
  position: absolute;
  top: 5px;
  left: 26px;
  line-height: 155%;
  font-weight: 500;
}
.frameIcon {
  position: absolute;
  top: 0;
  left: 0;
  width: 32px;
  height: 32px;
  overflow: hidden;
}
.back {
  position: relative;
  width: 59px;
  height: 32px;
}
.activities {
  position: relative;
  font-size: var(--h4-bold-size);
  font-weight: 600;
  color: var(--grey-900);
}
.icon,
.titles {
  flex-direction: row;
  align-items: center;
}
.titles {
  align-self: stretch;
  background-color: var(--white);
  overflow: hidden;
  display: none;
  padding: var(--padding-xs) var(--padding-4xs);
  justify-content: flex-start;
  gap: var(--gap-231xl);
  font-size: var(--p3-semibold-size);
}
.icon {
  border: 1px solid var(--grey-80);
  display: flex;
  padding: var(--padding-5xs);
  justify-content: center;
}
.licenseAndCertification {
  position: relative;
  line-height: 28px;
}
.iconsadd {
  position: relative;
  width: 16px;
  height: 16px;
  overflow: hidden;
  flex-shrink: 0;
}
.button {
  position: relative;
  line-height: 100%;
  font-weight: 600;
}
.iconsaddParent,
.smallButtons {
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.iconsaddParent {
  display: flex;
  gap: var(--gap-9xs);
}
.smallButtons {
  border-radius: var(--br-9xs);
  background-color: var(--logg-green);
  width: 146px;
  height: 40px;
  display: none;
  padding: var(--padding-5xs) var(--padding-xl);
  box-sizing: border-box;
  text-align: center;
  font-size: var(--p3-semibold-size);
  color: var(--white);
}
.avatarIcon {
  position: relative;
  width: 40px;
  height: 40px;
  display: none;
}
.component102 {
  align-self: stretch;
  background-color: var(--white);
  border-bottom: 1px solid var(--grey-80);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: var(--padding-sm) var(--padding-33xl);
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-231xl);
  text-align: left;
  font-size: var(--h5-semibold-size);
  color: var(--grey-900);
}
.tab1 {
  position: relative;
  line-height: 26px;
  font-weight: 600;
}
.tab1Wrapper,
.tab2Wrapper,
.tab3Wrapper {
  position: absolute;
  width: 32.8%;
  top: 4px;
  border-radius: var(--br-9xs);
  height: 44px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-9xs) var(--padding-5xs);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.tab1Wrapper {
  right: 66.49%;
  left: 0.72%;
  background-color: var(--white);
  color: var(--logg-green);
}
.tab2Wrapper,
.tab3Wrapper {
  right: 33.69%;
  left: 33.51%;
}
.tab3Wrapper {
  width: 32.97%;
  right: 0.72%;
  left: 66.31%;
}
.twoTabFlatSwitch {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-8xs);
  background-color: var(--grey-90);
}
.promotionsTabPrototype {
  position: relative;
  width: 558px;
  height: 52px;
}
.promotionsTabPrototypeWrapper {
  align-self: stretch;
  background-color: var(--color-gray-100);
  display: flex;
  flex-direction: column;
  padding: var(--padding-11xs) var(--padding-215xl);
  align-items: center;
  justify-content: flex-start;
  color: var(--grey-30);
  width: 100%;
}
.label {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 26px;
  font-weight: 500;
}
.labelContainer {
  align-self: stretch;
  position: relative;
  height: 28px;
}
.iconschevronDown {
  position: absolute;
  top: 12px;
  right: 8px;
  width: 24px;
  height: 24px;
  overflow: hidden;
}
.userInput {
  position: absolute;
  width: calc(100% - 16px);
  top: 11px;
  left: 8px;
  line-height: 26px;
  display: inline-block;
}
.dotChild {
  position: relative;
  border-radius: 50%;
  background-color: var(--grey-10);
  width: 8px;
  height: 8px;
}
.dot {
  position: absolute;
  top: 20px;
  left: 12px;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-6xs);
}
.baseField {
  align-self: stretch;
  position: relative;
  border-radius: var(--br-8xs);
  background-color: var(--grey-50);
  border: 1px solid var(--grey-70);
  box-sizing: border-box;
  height: 48px;
  overflow: hidden;
  flex-shrink: 0;
  color: var(--grey-10);
}
.div,
.thisIsA {
  position: absolute;
  top: 0;
  line-height: 24px;
}
.thisIsA {
  left: 0;
}
.div {
  right: 0;
  text-align: right;
}
.helperTextContainer {
  align-self: stretch;
  position: relative;
  height: 24px;
  display: none;
  font-size: var(--p3-semibold-size);
}
.input {
  width: 558px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-11xs);
}
.imgIcon {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-9xs) var(--br-9xs) 0 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.imgWrapper {
  align-self: stretch;
  flex: 1;
  position: relative;
}
.avatarIcon1,
.businessName {
  position: relative;
  width: 20px;
  height: 20px;
}
.businessName {
  line-height: 24px;
  font-weight: 600;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 318px;
  height: 24px;
  flex-shrink: 0;
}
.businessNameAndAvatar,
.starRating {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.businessNameAndAvatar {
  align-self: stretch;
  align-items: center;
  gap: var(--gap-9xs);
}
.starRating {
  align-items: flex-start;
}
.ratingNumber {
  position: relative;
  line-height: 20px;
}
.businessNameAndAvatarParent,
.ratings {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.ratings {
  flex-direction: row;
  align-items: center;
  font-size: var(--caption-bold-size);
  color: var(--grey-20);
}
.businessNameAndAvatarParent {
  flex-direction: column;
  align-items: flex-start;
}
.jobCategory {
  align-self: stretch;
  position: relative;
  font-size: var(--caption-bold-size);
  line-height: 20px;
  color: var(--grey-20);
}
.frameGroup {
  gap: var(--gap-11xs);
}
.descriptionInner,
.frameGroup {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.icons20pxplaceholder {
  position: relative;
  width: 20px;
  height: 20px;
  display: none;
}
.largeButtons {
  align-self: stretch;
  border-radius: var(--br-8xs);
  background-color: var(--logg-green);
  border: 1px solid var(--logg-green);
  box-sizing: border-box;
  height: 28px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs) 0;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
  text-align: center;
  font-size: var(--caption-bold-size);
  color: var(--white);
}
.description,
.promotions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.description {
  align-self: stretch;
  border-radius: 0 0 var(--br-5xs) var(--br-5xs);
  background-color: var(--white);
  padding: var(--padding-5xs);
  gap: var(--gap-9xs);
}
.promotions {
  border-radius: var(--br-9xs);
  box-shadow: var(--card);
  width: 363px;
  height: 258px;
  overflow: hidden;
  flex-shrink: 0;
  font-size: var(--p3-semibold-size);
  color: var(--grey-0);
}
.reach {
  position: relative;
  line-height: 26px;
  font-weight: 500;
}
.bg,
.bgChild {
  align-self: stretch;
}
.bgChild {
  position: relative;
  border-radius: var(--br-xl);
  background-color: var(--grey-80);
  height: 6px;
}
.bg {
  height: 16px;
  display: flex;
  flex-direction: column;
  padding: var(--padding-5xs) 0;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: center;
  z-index: 0;
}
.sliderChild,
.sliderItem {
  position: relative;
  background-color: var(--grey-10);
}
.sliderChild {
  flex: 1;
  border-radius: var(--br-xl);
  height: 6px;
}
.sliderItem {
  border-radius: 50%;
  box-shadow: var(--elevation-2);
  width: 15.78px;
  height: 16px;
  margin-left: -16px;
}
.bgParent,
.slider {
  display: flex;
  justify-content: flex-start;
}
.slider {
  margin: 0 !important;
  position: absolute;
  top: 0;
  left: 0;
  width: 16px;
  flex-direction: row;
  align-items: center;
  z-index: 1;
}
.bgParent {
  width: 558px;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
}
.labelContainer1 {
  align-self: stretch;
  position: relative;
  height: 28px;
  display: none;
  font-size: var(--p2-semibold-size);
}
.iconsplaceholder {
  position: absolute;
  top: 12px;
  right: 8px;
  width: 24px;
  height: 24px;
  display: none;
}
.userInput1 {
  position: absolute;
  width: calc(100% - 16px);
  top: 11px;
  left: 8px;
  line-height: 14.5px;
  display: inline-block;
}
.baseField1 {
  align-self: stretch;
  flex: 1;
  position: relative;
  border-radius: var(--br-8xs);
  background-color: var(--grey-50);
  border: 1px solid var(--grey-70);
  overflow: hidden;
  color: var(--grey-10);
}
.thisIsA1 {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 24px;
  display: none;
}
.helperTextContainer1 {
  align-self: stretch;
  position: relative;
  height: 24px;
  display: none;
}
.input1 {
  width: 109px;
  height: 35px;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-11xs);
  color: var(--grey-20);
}
.distanceSlider,
.input1,
.noOfPeopleParent {
  display: flex;
  justify-content: flex-start;
}
.noOfPeopleParent {
  flex-direction: row;
  align-items: center;
  gap: var(--gap-xs);
  font-size: var(--p3-semibold-size);
  color: var(--grey-10);
}
.distanceSlider {
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-5xs);
}
.userInput2 {
  position: absolute;
  width: calc(100% - 16px);
  top: 11px;
  left: 8px;
  line-height: 26px;
  display: none;
}
.distanceSliderParent,
.input2,
.inputGroup,
.inputParent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.input2 {
  width: 100%;
  flex-direction: column;
  gap: var(--gap-11xs);
}
.distanceSliderParent,
.inputGroup,
.inputParent {
  flex-direction: row;
  gap: var(--gap-5xl);
}
.inputGroup{
  width:100%
}
.distanceSliderParent,
.inputParent {
  flex-direction: column;
  gap: var(--gap-base);
  align-items: center;
}
.inputParent {
  align-items: center;
}
.qn0001,
.quoteNumber {
  position: absolute;
  top: 18.18%;
  line-height: 26px;
}
.quoteNumber {
  left: 8px;
  font-weight: 500;
  text-align: left;
}
.qn0001 {
  right: 8px;
}
.success {
  position: relative;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  font-weight: 600;
}
.transactionPills {
  position: absolute;
  top: 13px;
  left: 504px;
  border-radius: var(--br-81xl);
  background-color: var(--green-100);
  display: none;
  flex-direction: row;
  padding: var(--padding-9xs) var(--padding-7xs);
  align-items: center;
  justify-content: flex-end;
  font-size: var(--font-size-5xs);
  color: var(--logg-green);
}
.quoteCards {
  position: relative;
  background-color: var(--white);
  width: 100%;
  height: 44px;
}
.label4 {
  position: relative;
  line-height: 24px;
}
.largeButtons1 {
  border-radius: var(--br-8xs);
  background-color: var(--logg-green);
  width: 558px;
  height: 48px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-158xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
  text-align: center;
  font-size: var(--p3-semibold-size);
  color: white;
}
.quoteCardsParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xs);
  text-align: right;
}
.frameParent {
  align-self: stretch;
  flex-direction: column;
  align-items: center;
  gap: var(--gap-13xl);
  text-align: left;
  color: var(--grey-20);
}
.frameParent,
.profilepromo1,
.titles1,
.titlesParent {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}
.titlesParent {
  flex: 1;
  flex-direction: column;
  align-items: center;
  gap: var(--gap-8xs);
  font-size: var(--p2-semibold-size);
  color: var(--grey-800);
}
.profilepromo1,
.titles1 {
  flex-direction: row;
}
.profilepromo1 {
  background-color: var(--color-gray-100);
  width: 1440px;
  overflow-y: auto;
  align-items: flex-start;
}
.titles1 {
  align-self: stretch;
  background-color: var(--white);
  overflow: hidden;
  padding: var(--padding-xs) var(--padding-4xs);
  align-items: center;
  gap: var(--gap-56xl);
  font-size: var(--p3-semibold-size);
}
.new {
  position: relative;
  letter-spacing: -0.03em;
  font-weight: 600;
}
.newWrapper {
  border-radius: var(--br-9xs);
  background-color: var(--white);
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.05);
  width: 123px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-7xs) var(--padding-59xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  color: var(--logg-green);
}
.current {
  position: relative;
  letter-spacing: -0.03em;
}
.currentWrapper {
  border-radius: var(--br-9xs);
  width: 123px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-7xs) var(--padding-59xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.frameContainer {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.inputChild,
.tab {
  background-color: var(--grey-50);
}
.tab {
  align-self: stretch;
  height: 47px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--gray-2);
}
.inputChild {
  position: absolute;
  height: 64.86%;
  width: 100%;
  top: 35.14%;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-9xs);
  border: 1px solid var(--grey-70);
  box-sizing: border-box;
}
.jackDorsey,
.selectOption {
  left: 2.14%;
  line-height: 155%;
  font-weight: 500;
}
.selectOption {
  font-size: var(--p3-semibold-size);
  color: var(--grey-600);
}
.chevronUpIcon {
  position: absolute;
  height: 27.03%;
  width: 5.35%;
  top: 81.08%;
  right: -2.14%;
  bottom: -8.11%;
  left: 96.79%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.input4 {
  position: relative;
  width: 374px;
  height: 74px;
}
.preview {
  position: absolute;
  top: 0;
  left: 0;
  letter-spacing: -0.02em;
  line-height: 155%;
  font-weight: 500;
}
.previewWrapper {
  position: relative;
  width: 52px;
  height: 22px;
}
.groupChild,
.unsplashmlo6ilubadaIcon {
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
}
.groupChild {
  height: 100%;
  bottom: 0;
  border-radius: var(--br-5xs);
  background-color: var(--white);
}
.unsplashmlo6ilubadaIcon {
  height: 54.95%;
  bottom: 45.05%;
  border-radius: var(--br-5xs) var(--br-5xs) 0 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.electrician {
  position: absolute;
  top: 58.91%;
  left: 80.75%;
  line-height: 155%;
  font-weight: 500;
}
.icons,
.proceed {
  position: relative;
}
.icons {
  width: 20px;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.proceed {
  line-height: 155%;
  color: white;
}
.button1,
.iconsParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.iconsParent {
  gap: var(--gap-5xs);
}
.button1 {
  position: absolute;
  top: 154px;
  left: 12px;
  border-radius: var(--br-9xs);
  background-color: var(--logg-green);
  width: 350px;
  padding: var(--padding-4xs) 0;
  box-sizing: border-box;
  text-align: center;
  font-size: var(--p3-semibold-size);
  color: var(--white);
}
.frameChild {
  position: relative;
  border-radius: var(--br-81xl);
  width: 24px;
  height: 24px;
  object-fit: cover;
}
.dysonElectricals {
  position: relative;
  letter-spacing: -0.02em;
}
.frameItem {
  position: relative;
  width: 53px;
  height: 10px;
}
.div4 {
  position: relative;
  letter-spacing: -0.04em;
  font-weight: 500;
}
.groupParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-9xs);
  text-align: right;
  font-size: var(--caption-block-size);
  color: var(--gray-2);
}
.dysonElectricalsParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-12xs);
}
.rectangleGroup {
  position: absolute;
  height: 13.37%;
  top: 58.91%;
  bottom: 27.72%;
  left: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-9xs);
  text-align: left;
  color: var(--grey-900);
}
.rectangleParent {
  position: relative;
  box-shadow: var(--card1);
  width: 374px;
  height: 202px;
  text-align: right;
  font-size: var(--caption-bold-size);
  color: var(--grey-700);
}
.frameParent1,
.profilePromotions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.frameParent1 {
  gap: var(--gap-3xs);
}
.profilePromotions {
  font-size: var(--p3-semibold-size);
}
.emailChild {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-9xs);
  background-color: var(--grey-50);
  border: 1px solid var(--grey-70);
  box-sizing: border-box;
}
.jackDorsey1 {
  position: absolute;
  top: 25%;
  left: 2.14%;
  line-height: 155%;
  font-weight: 500;
  display: none;
}
.email {
  position: absolute;
  height: 64.86%;
  width: 100%;
  top: 35.14%;
  right: 0;
  bottom: 0;
  left: 0;
}
.jackDorsey2 {
  position: absolute;
  top: 25%;
  left: 4.42%;
  line-height: 155%;
  font-weight: 500;
  display: none;
}
.calendarIcon {
  position: absolute;
  height: 27.03%;
  width: 11.05%;
  top: 54.05%;
  right: 6.63%;
  bottom: 18.92%;
  left: 82.32%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.input6 {
  position: relative;
  height: 74px;
  width:100%
}
.frameDiv,
.inputParent1,
.inputParent2 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xs);
}
.inputParent2{
  width:100%
}
.frameDiv,
.inputParent1 {
  flex-direction: column;
  gap: var(--gap-xl);
  width: 100%;
}
.frameDiv {
  text-align: left;
  font-size: var(--p2-semibold-size);
}
.paymentChild {
  top: 0;
  left: 0;
  background-color: var(--white);
  box-shadow: 0-1px 2px rgba(0, 0, 0, 0.03);
  width: 414px;
  height: 112px;
}
.button2 {
  top: 52px;
  left: 20px;
  border-radius: var(--br-9xs);
  background-color: var(--logg-green);
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: var(--padding-xs) 0 var(--padding-smi);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.payment {
  width: 100%;
  height: 112px;
  overflow: hidden;
  flex-shrink: 0;
  color: var(--grey-500);
}
.profilemob,
.profilepromo {
  flex-direction: column;
  justify-content: flex-start;
}
.profilemob {
  background-color: var(--white);
  width: 414px;
  height: 896px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  align-items: center;
  gap: var(--gap-12xl);
  font-size: var(--p2-medium-size);
  color: var(--grey-800);
}
.profilepromo {
  position: relative;
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: var(--gap-3xs);
  text-align: center;
  font-size: var(--p3-semibold-size);
  color: var(--grey-10);
  font-family: var(--caption-bold);
}
@media screen and (max-width: 960px) {
  .navPrototype {
    display: none;
  }
  .titles {
    display: flex;
  }
  .component102 {
    display: none;
  }
  .profilepromo1 {
    align-self: stretch;
    width: 100%;
  }
}
@media screen and (max-width: 430px) {
  .profilepromo1,
  .titles {
    display: none;
  }
  .profilemob {
    display: flex;
  }
}
