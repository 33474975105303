.groupChild {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-31xl);
  width: 32px;
  height: 32px;
  object-fit: cover;
}
.smithsConstruction {
  position: absolute;
  top: 0;
  left: 36px;
  letter-spacing: -0.04em;
}
.div,
.smithmarkeygmailcom,
.templeWayWitham {
  position: absolute;
  top: 9px;
  left: 36px;
  letter-spacing: -0.03em;
}
.div,
.smithmarkeygmailcom {
  top: 18px;
  color: var(--grey-30);
}
.smithmarkeygmailcom {
  top: 27px;
  text-decoration: none;
}
.rectangleParent {
  position: absolute;
  top: 0;
  left: 0;
  width: 116px;
  height: 33px;
  text-align: left;
}
.date11112021,
.div1,
.div2,
.dueDate,
.invoiceNo {
  position: absolute;
  top: 10px;
  left: 13px;
  letter-spacing: -0.04em;
}
.date11112021,
.div1,
.div2,
.dueDate {
  top: 28px;
  left: 16px;
}
.date11112021,
.div1,
.div2 {
  top: 18px;
  left: 18px;
  font-weight: 600;
}
.date11112021,
.div2 {
  top: 0;
  left: 0;
}
.div2 {
  top: 36px;
  left: 13px;
}
.invoiceNoParent {
  position: absolute;
  top: 0;
  left: 298px;
  width: 37px;
  height: 42px;
}
.description {
  top: 5px;
  left: 1px;
  text-align: left;
}
.description,
.rate,
.subtotal {
  position: absolute;
  letter-spacing: -0.04em;
}
.rate {
  top: 5px;
  left: 173px;
  text-align: left;
}
.subtotal {
  top: 132px;
}
.discount,
.subtotal,
.tax {
  left: 174px;
  text-align: left;
}
.discount {
  position: absolute;
  top: 146px;
  letter-spacing: -0.04em;
}
.tax {
  top: 160px;
}
.b,
.b1,
.b2,
.b3,
.tax,
.total {
  position: absolute;
  letter-spacing: -0.04em;
}
.total {
  top: 178px;
  left: 174px;
  color: var(--logg-green);
  text-align: left;
}
.b,
.b1,
.b2,
.b3 {
  top: 132px;
  left: 318px;
}
.b1,
.b2,
.b3 {
  top: 146px;
  left: 323px;
}
.b2,
.b3 {
  top: 160px;
}
.b3 {
  top: 178px;
  left: 319px;
  color: var(--logg-green);
}
.qty {
  left: 207px;
  text-align: left;
}
.amount,
.discount1,
.qty,
.tax1 {
  position: absolute;
  top: 5px;
  letter-spacing: -0.04em;
}
.discount1 {
  left: 240px;
  text-align: left;
}
.amount,
.tax1 {
  left: 283px;
}
.amount {
  left: 313px;
}
.groupInner,
.groupItem {
  position: absolute;
  top: -0.5px;
  left: -0.5px;
  border-top: 1px solid var(--color-black);
  box-sizing: border-box;
  width: 336px;
  height: 1px;
}
.groupInner {
  top: 15.5px;
}
.div3,
.electricalInsulator {
  position: absolute;
  letter-spacing: -0.04em;
}
.electricalInsulator {
  top: 0;
  left: 0.3%;
  font-weight: 500;
  text-align: left;
}
.div3 {
  left: 52.84%;
}
.div3,
.div4,
.div5 {
  top: 0;
  font-weight: 500;
}
.div4 {
  position: absolute;
  left: 62.69%;
  letter-spacing: -0.04em;
}
.div5 {
  left: 76.12%;
}
.div5,
.div6,
.div7,
.div8,
.div9 {
  position: absolute;
  letter-spacing: -0.04em;
}
.div6 {
  top: 40%;
  left: 76.72%;
  color: var(--labels);
}
.div7,
.div8,
.div9 {
  left: 85.07%;
}
.div7 {
  top: 40%;
  color: var(--labels);
}
.div8,
.div9 {
  top: 0;
  font-weight: 500;
}
.div9 {
  left: 97.01%;
}
.component22Child {
  position: absolute;
  height: 2.5%;
  width: 100.15%;
  top: 98.75%;
  right: -0.07%;
  bottom: -1.25%;
  left: -0.07%;
  border-top: 0.5px solid var(--labels);
  box-sizing: border-box;
}
.component22 {
  position: absolute;
  top: 24px;
  left: 0;
  width: 335px;
  height: 20px;
}
.div10,
.div11,
.div12,
.div16 {
  position: absolute;
  top: 0;
  left: 51.64%;
  letter-spacing: -0.04em;
  font-weight: 500;
}
.div11,
.div12,
.div16 {
  left: 63.58%;
}
.div12,
.div16 {
  left: 75.52%;
}
.div16 {
  left: 96.42%;
}
.component23,
.lineDiv {
  position: absolute;
  top: 50px;
  left: 0;
  width: 335px;
  height: 20px;
}
.lineDiv {
  height: 0.27%;
  width: 48.08%;
  top: 93.34%;
  right: 0.21%;
  bottom: 6.39%;
  left: 51.71%;
  border-top: 0.5px solid var(--labels);
  box-sizing: border-box;
}
.div17,
.div18,
.div19,
.div22 {
  position: absolute;
  top: 0;
  left: 51.94%;
  letter-spacing: -0.04em;
  font-weight: 500;
}
.div18,
.div19,
.div22 {
  left: 63.28%;
}
.div19,
.div22 {
  left: 75.22%;
}
.div22 {
  left: 84.48%;
}
.component24 {
  position: absolute;
  top: 76px;
  left: 0;
  width: 335px;
  height: 20px;
}
.div24 {
  left: 50.75%;
}
.div24,
.div25,
.div26,
.div29,
.div30 {
  position: absolute;
  top: 0;
  letter-spacing: -0.04em;
  font-weight: 500;
}
.div25 {
  left: 63.28%;
  display: none;
}
.div26,
.div29,
.div30 {
  left: 74.33%;
}
.div29,
.div30 {
  left: 83.58%;
}
.div30 {
  left: 95.52%;
}
.component25,
.descriptionParent {
  position: absolute;
  top: 102px;
  left: 0;
  width: 335px;
  height: 20px;
}
.descriptionParent {
  top: 58px;
  width: 336px;
  height: 184px;
}
.groupParent {
  position: relative;
  width: 100%;
  height: 242px;
  text-align: right;
  font-size: var(--font-size-8xs);
  color: var(--gray-1);
  font-family: var(--caption-bold);
}
