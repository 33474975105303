.imInNeed,
.requestForElectrician {
  align-self: stretch;
  position: relative;
}
.requestForElectrician {
  line-height: 24px;
  font-weight: 600;
}
.imInNeed {
  font-size: var(--caption-regular-size);
  line-height: 20px;
  color: var(--grey-20);
  display: -webkit-inline-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.requestForElectricianParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.button {
  position: relative;
  font-size: var(--button-small-text-size);
  line-height: 100%;
  font-weight: 600;
  font-family: var(--button-small-text);
  color: var(--white);
  text-align: center;
}
.buttonWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.frameParent,
.smallButtons {
  align-self: stretch;
  border-radius: var(--br-9xs);
  display: flex;
}
.smallButtons {
  cursor: pointer;
  border: 0;
  padding: var(--padding-5xs) 0;
  background-color: var(--primary-20base);
  height: 32px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}
.frameParent {
  background-color: var(--white);
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xs);
  gap: var(--gap-5xs);
}
.helloIAm,
.pm {
  position: relative;
}
.helloIAm {
  align-self: stretch;
  line-height: 24px;
}
.pm {
  font-size: var(--caption-regular-size);
  line-height: 20px;
  color: var(--grey-20);
  text-align: right;
}
.helloIAmInterestedInThisParent,
.reqmessagecomp {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}
.helloIAmInterestedInThisParent {
  align-self: stretch;
  gap: var(--gap-5xs);
  color: var(--grey-0);
  
}
.reqmessagecomp {
  border-radius: var(--br-9xs) 0 var(--br-9xs) var(--br-9xs);
  background-color: var(--primary-80);
  width: 390px;
  padding: var(--padding-5xs) var(--padding-base) var(--padding-5xs)
    var(--padding-xs);
  box-sizing: border-box;
  gap: var(--gap-3xs);
  text-align: left;
  font-size: var(--button-small-text-size);
  color: var(--grey-10);
  font-family: var(--button-small-text);
}
