.addANew {
  position: relative;
  line-height: 28px;
  font-weight: 600;
}
.iconsclose {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
  cursor: pointer;
}
.addANewCardParent {
  position: absolute;
  width: calc(100% - 64px);
  top: 16px;
  right: 32px;
  left: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.modalTitle {
  position: relative;
  width: 461px;
  height: 64px;
  overflow: hidden;
  flex-shrink: 0;
}
.iconWithBg {
  position: relative;
  border-radius: var(--br-9xs);
  width: 32px;
  height: 32px;
}
.iconsplaceholder {
  position: relative;
  width: 24px;
  height: 24px;
  display: none;
}
.list1 {
  position: relative;
  line-height: 26px;
}
.left {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xs);
}
.iconschevronRight {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.toggleChild {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-31xl);
  background-color: var(--grey-60);
}
.toggleItem {
  position: absolute;
  height: 87.5%;
  width: 50%;
  top: 6.25%;
  right: 46.43%;
  bottom: 6.25%;
  left: 3.57%;
  border-radius: 50%;
  background-color: var(--white);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}
.toggle {
  position: relative;
  width: 56px;
  height: 32px;
  display: none;
}
.content,
.right {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.right {
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.content {
  align-self: stretch;
  flex: 1;
  justify-content: space-between;
}
.list {
  box-sizing: border-box;
  width: 397px;
  height: 60px;
  padding: var(--padding-base) 0;
  align-items: flex-start;
  cursor: pointer;
}
.list,
.list2 {
  border-bottom: 1px solid var(--grey-80);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.list2,
.list4 {
  width: 397px;
  height: 60px;
  padding: var(--padding-base) 0;
  box-sizing: border-box;
  align-items: flex-start;
}
.additem,
.list4,
.listParent,
.modalTitleParent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.listParent {
  width: 397px;
  align-items: flex-start;
  font-size: var(--p2-regular-size);
  color: var(--grey-10);
}
.additem,
.modalTitleParent {
  align-items: center;
}
.additem {
  position: relative;
  border-radius: var(--br-9xs);
  background-color: var(--white);
  box-shadow: var(--popup);
  padding: 0 0 var(--padding-xl);
  box-sizing: border-box;
  align-items: flex-start;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
  text-align: left;
  font-size: var(--h5-semibold-size);
  color: var(--color-black);
  font-family: var(--p2-regular);
}
