.component27Child {
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow-y: auto;
  max-height: 100%;
  background-size:cover; 
  background-repeat: no-repeat;
  background-position: center;
}
.component27Item {
  position: absolute;
  top: 90%;
  left: 100%;
  background: linear-gradient(180deg, #212121 3.88%, rgba(33, 33, 33, 0));
  width: 100%;
  height: 296px;
  transform: rotate(180deg);
  transform-origin: 0 0;
  padding: 1rem;
}
.component27Inner {
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(180deg, #000, rgba(113, 113, 113, 0));
  width: 100%;
  height: 208px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  gap: 10px;
}
.frameChild {
  position: relative;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  object-fit: cover;
}
.crosbyOdennehy,
.mins {
  position: relative;
  line-height: 155%;
  font-weight: 600;
}
.mins {
  font-size: var(--p3-semibold-size);
  font-weight: 500;
  color: var(--grey-300);
}
.crosbyOdennehyParent {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.frameWrapper {
  position: relative;
  width: 144px;
  height: 47px;
}
.ellipseParent {
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
}
.name {
  top: 39px;
  left: 20px;
  width: 90%;
  height: 48px;
  display: flex;
  justify-content: space-between;

}
.frameChild1,
.frameInner,
.frameItem {
  position: relative;
  border-radius: var(--br-10xs);
  background-color: var(--primary-40);
  width: 100%;
  height: 3px;
}
.frameChild1,
.frameInner {
  background-color: var(--grey-500);
  width: 100%;
}
.frameChild1 {
  width: 82px;
}
.rectangleParent {
  top: 20px;
  left: 19px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
  width:100%
}
.imInNeed,
.jobDetail {
  position: relative;
  line-height: 155%;
}
.imInNeed {
  font-size: var(--p2-semibold-size);
  font-weight: 500;
  display: inline-block;
  width: 100%;
  overflow: auto; 
  text-overflow: ellipsis;
  height: 80px;
  flex-shrink: 0;
  mix-blend-mode: normal;
}
.jobDetailParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.icons {
  position: relative;
  width: 20px;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.iconsParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
}
.button,
.frameParent {
  width: 100%;
  display: flex;
}
.button {
  border-radius: var(--br-9xs);
  background-color: var(--logg-green);
  height: 48px;
  flex-direction: row;
  padding: var(--padding-xs) 0 var(--padding-smi);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: var(--p2-medium-size);
}
.frameParent {
  top: 100%;
  left: 0;
  height: 190px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
  font-size: var(--h4-bold-size);
  transform: rotate(180deg);
}
.evacloseOutlineIcon {
  top: 39px;
  left: 80%;
  width: 32px;
  height: 32px;
  overflow: hidden;
}
.component27,
.signup {
  width: 100%;
}
.component27 {
  position: relative;
  height: 100vh;
  flex-shrink: 0;
}
.signup {
  background-color: var(--white);
  display: none;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.loggJobs {
  position: absolute;
  top: 64px;
  left: 40px;
  line-height: 28px;
  font-weight: 600;
}
.avatarIcon {
  position: relative;
  width: 40px;
  height: 40px;
}
.annetteBlack,
.m {
  align-self: stretch;
  position: relative;
}
.annetteBlack {
  line-height: 26px;
  font-weight: 600;
}
.m {
  font-size: var(--caption-bold-size);
  line-height: 20px;
  color: var(--grey-20);
  opacity: 0.7;
  mix-blend-mode: normal;
}
.annetteBlackParent,
.avatarParent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.annetteBlackParent {
  flex: 1;
  flex-direction: column;
}
.avatarParent {
  width: 282px;
  flex-direction: row;
  gap: var(--gap-xs);
}
.loggJobbsProfile,
.loggJobbsProfile1 {
  padding: var(--padding-xs) var(--padding-5xs);
}
.loggJobbsProfile {
  border-radius: var(--br-9xs);
  background-color: var(--primary-80);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.loggJobbsProfile1 {
  cursor: pointer;
}
.loggJobbsProfile1,
.loggJobbsProfile2,
.loggJobbsProfileParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.loggJobbsProfile2 {
  padding: var(--padding-xs) var(--padding-5xs);
}
.loggJobbsProfileParent {
  position: absolute;
  top: 104px;
  left: 33px;
  font-size: var(--p2-semibold-size);
  color: var(--grey-0);
}
.closeIcon {
  position: absolute;
  top: 16px;
  left: 40px;
  width: 32px;
  height: 32px;
  cursor: pointer;
}
.loggJobsSidebar {
  align-self: stretch;
  position: relative;
  background-color: var(--color-gray-100);
  width: 362px;
  overflow: auto;
  flex-shrink: 0;
}
.topSectionChild {
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  background: linear-gradient(180deg, #4f4f4f, rgba(113, 113, 113, 0));
  height: 117px;
}
.progressBarChild,
.progressBarItem {
  flex: 1;
  position: relative;
  border-radius: var(--br-9xs);
  background-color: var(--primary-40);
  height: 4px;
}
.progressBarItem {
  background-color: var(--grey-80);
}
.progressBar,
.progressBarWrapper {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
}
.progressBar {
  flex-direction: row;
  align-items: center;
  gap: var(--gap-xl);
}
.progressBarWrapper {
  flex-direction: column;
  padding: var(--padding-xs) var(--padding-xl);
  align-items: flex-start;
}
.m8 {
  align-self: stretch;
  position: relative;
  font-size: var(--caption-bold-size);
  line-height: 20px;
  color: var(--grey-90);
  opacity: 0.7;
  mix-blend-mode: normal;
}
.avatarParent5,
.topSectionInner {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.avatarParent5 {
  flex-direction: row;
  gap: var(--gap-xs);
}
.topSectionInner {
  flex-direction: column;
  padding: var(--padding-9xs) 0 var(--padding-9xs) var(--padding-xl);
}
.topSection,
.topSection1 {
  position: absolute;
  top: 0;
  left: 0;
}
.topSection1 {
  width: 100%;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.topSection {
  width: 408px;
  height: 117px;
}
.frameIcon,
.jobStatus {
  position: relative;
  cursor: pointer;
}
.jobStatus {
  width: 408px;
  height: 100vh;
  background-image: url(/public/job-status1@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.frameIcon {
  width: 48px;
  height: 48px;
}
.imInNeed1,
.requestForElectrician {
  position: relative;
}
.requestForElectrician {
  line-height: 28px;
  font-weight: 600;
  color: var(--grey-50);
}
.imInNeed1 {
  font-size: var(--p2-semibold-size);
  line-height: 26px;
  overflow: auto; 
  text-overflow: ellipsis;
}
.citiessummary,
.iconsmoney {
  position: relative;
  flex-shrink: 0;
}
.iconsmoney {
  width: 20px;
  height: 20px;
  overflow: hidden;
}
.citiessummary {
  line-height: 24px;
  display: inline-block;
  width: 308px;
}
.frameParent1,
.iconsmoneyParent {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
}
.iconsmoneyParent {
  flex-direction: row;
  align-items: center;
  gap: var(--gap-9xs);
}
.frameParent1 {
  flex-direction: column;
  align-items: flex-start;
}
.citiessummary2 {
  position: relative;
  line-height: 24px;
}
.frameContainer,
.requestForElectricianParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.frameContainer {
  font-size: var(--p3-semibold-size);
}
.requestForElectricianParent {
  height: 306px;
  width: 300px;
}
.icons20pxplaceholder {
  position: relative;
  width: 20px;
  height: 20px;
  display: none;
}
.label {
  position: relative;
  font-size: var(--p3-semibold-size);
  line-height: 24px;
  font-family: var(--caption-bold);
  color: var(--white);
  text-align: center;
}
.largeButtons {
  cursor: pointer;
  border: 0;
  padding: var(--padding-5xs) 0;
  background-color: var(--logg-green);
  align-self: stretch;
  border-radius: var(--br-8xs);
  height: 48px;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.frameGroup {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
  width: fit-container;
}
.jobStatusParent,
.loggJobsDescription {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.loggJobsDescription {
  border-radius: var(--br-9xs);
  background-color: var(--grey-10);
  border: 1px solid var(--grey-20);
  box-sizing: border-box;
  width: 322px;
  padding: var(--padding-xl) var(--padding-3xs);
  align-items: flex-start;
  font-size: var(--h5-semibold-size);
  color: var(--grey-80);
}
.jobStatusParent {
  align-items: center;
  gap: var(--gap-12xl);
}
.jobview1,
.jobviewInner {
  display: flex;
  justify-content: space-between;
}
.jobviewInner {
  flex: 1;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  font-size: var(--p2-semibold-size);
  color: var(--grey-50);
}
.jobview1 {
  background-color: var(--grey-0);
  width: 100%;
  overflow: hidden;
  flex-direction: row;
  align-items: flex-start;
  font-size: var(--h5-semibold-size);
  color: var(--grey-900);
}
.jobview {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  font-size: var(--p2-semibold-size);
  color: var(--white);
  font-family: var(--caption-bold);
}
@media screen and (max-width: 960px) {
  .signup {
    display: flex;
  }
  .jobview1 {
    display: none;
  }
}
