.activities {
  position: absolute;
  top: 17px;
  left: 143px;
  font-size: 17px;
  font-weight: 600;
}
.back1 {
  position: absolute;
  top: 5px;
  left: 26px;
  line-height: 155%;
  font-weight: 500;
}
.back,
.frameIcon {
  position: absolute;
  height: 32px;
}
.frameIcon {
  top: 0;
  left: 0;
  width: 32px;
  overflow: hidden;
}
.back {
  top: 12px;
  left: 9px;
  width: 59px;
  color: var(--grey-800);
}
.edit2Icon {
  position: relative;
  width: 12px;
  height: 12px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.edit {
  position: relative;
  line-height: 155%;
  font-weight: 600;
}
.button,
.edit2Parent {
  align-items: center;
  justify-content: center;
}
.edit2Parent {
  display: flex;
  flex-direction: row;
  gap: var(--gap-9xs);
}
.button {
  position: absolute;
  top: 12px;
  left: 328px;
  border-radius: var(--br-9xs);
  background-color: var(--white);
  border: 1px solid var(--logg-green);
  box-sizing: border-box;
  width: 66px;
  display: none;
  flex-direction: column;
  padding: var(--padding-8xs) var(--padding-xs);
  text-align: left;
  color: var(--logg-green);
}
.new,
.titles {
  position: relative;
}
.titles {
  align-self: stretch;
  background-color: var(--white);
  height: 56px;
  overflow: hidden;
  flex-shrink: 0;
}
.new {
  letter-spacing: -0.03em;
  font-weight: 600;
}
.newWrapper {
  border-radius: var(--br-9xs);
  background-color: var(--white);
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.05);
  width: 123px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-7xs) var(--padding-59xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  color: var(--logg-green);
}
.current {
  position: relative;
  letter-spacing: -0.03em;
}
.currentWrapper {
  border-radius: var(--br-9xs);
  width: 123px;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.frameParent,
.tab {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.tab {
  background-color: var(--grey-50);
  height: 47px;
  overflow: hidden;
  flex-shrink: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: var(--p2-medium-size);
}
.label {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 26px;
  font-weight: 500;
}
.labelContainer {
  height: 28px;
}
.baseField,
.labelContainer {
  align-self: stretch;
  position: relative;
}
.input {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-11xs);
  text-align: left;
  color: var(--grey-20);
}
.loudspeaker1Icon {
  position: relative;
  width: 120px;
  height: 120px;
  overflow: hidden;
  flex-shrink: 0;
  opacity: 0.7;
}
.youCanPromote {
  position: relative;
  line-height: 155%;
  font-weight: 500;
  color: var(--grey-700);
  display: inline-block;
  width: 230px;
}
.promomob,
.tabParent {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-14xl);
}
.tabParent {
  align-self: stretch;
  display: flex;
  font-size: var(--p2-semibold-size);
  color: var(--gray-2);
  padding: 0 0.5rem;
}
.promomob {
  background-color: var(--white);
  width: 414px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.navChild {
  position: absolute;
  top: 27px;
  left: 20px;
  width: 125px;
  height: 20px;
}
.iconshome {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.home {
  position: relative;
  line-height: 24px;
  font-weight: 500;
}
.default,
.iconshomeParent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.iconshomeParent {
  flex-direction: row;
  gap: var(--gap-5xs);
}
.default {
  align-self: stretch;
  border-radius: var(--br-11xs);
  overflow: hidden;
  flex-direction: column;
  padding: var(--padding-5xs);
  cursor: pointer;
}
.accountIcon {
  position: relative;
  width: 24px;
  height: 24px;
}
.home5 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
}
.default5,
.defaultParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.default5 {
  align-self: stretch;
  border-radius: var(--br-9xs);
  background-color: var(--grey-90);
  overflow: hidden;
  padding: var(--padding-5xs);
  color: var(--primary-10);
}
.defaultParent {
  position: absolute;
  top: 87px;
  left: 20px;
  width: 181px;
  gap: var(--gap-xs);
}
.nav {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--grey-50);
}
.navPrototype {
  position: relative;
  width: 221px;
  height: 1325px;
}
.titles1 {
  align-self: stretch;
  position: relative;
  background-color: var(--white);
  height: 56px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  font-size: var(--p3-semibold-size);
}
.icon {
  border: 1px solid var(--grey-80);
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs);
  align-items: center;
  justify-content: center;
}
.licenseAndCertification {
  position: relative;
  line-height: 28px;
}
.iconsadd {
  position: relative;
  width: 16px;
  height: 16px;
  overflow: hidden;
  flex-shrink: 0;
}
.button2 {
  position: relative;
  line-height: 100%;
  font-weight: 600;
}
.smallButtons {
  border-radius: var(--br-9xs);
  background-color: var(--logg-green);
  width: 146px;
  height: 40px;
  display: none;
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: var(--p3-semibold-size);
  color: var(--white);
}
.avatarIcon {
  position: relative;
  width: 40px;
  height: 40px;
  display: none;
}
.component102 {
  align-self: stretch;
  background-color: var(--white);
  border-bottom: 1px solid var(--grey-80);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: var(--padding-sm) var(--padding-33xl);
  align-items: center;
  justify-content: flex-start;
  gap: 142px;
  text-align: left;
  font-size: var(--h5-semibold-size);
}
.tab1 {
  position: relative;
  line-height: 26px;
  font-weight: 600;
}
.tab1Wrapper {
  position: absolute;
  width: 32.8%;
  top: 4px;
  right: 66.49%;
  left: 0.72%;
  border-radius: var(--br-9xs);
  background-color: var(--white);
  height: 44px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-9xs) var(--padding-5xs);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: var(--logg-green);
}
.tab2 {
  position: relative;
  line-height: 26px;
  font-weight: 500;
}
.tab2Wrapper,
.tab3Wrapper {
  position: absolute;
  width: 32.8%;
  top: 4px;
  right: 33.69%;
  left: 33.51%;
  border-radius: var(--br-9xs);
  height: 44px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-9xs) var(--padding-5xs);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.tab3Wrapper {
  width: 32.97%;
  right: 0.72%;
  left: 66.31%;
}
.twoTabFlatSwitch {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-8xs);
  background-color: var(--grey-90);
}
.promotionsTabPrototype {
  position: relative;
  width: 558px;
  height: 52px;
}
.promotionsTabPrototypeWrapper {
  align-self: stretch;
  background-color: var(--color-gray-100);
  display: flex;
  flex-direction: column;
  padding: var(--padding-11xs) 0;
  align-items: center;
  justify-content: flex-start;
  color: var(--grey-30);
}
.div,
.thisIsA {
  position: absolute;
  top: 0;
  line-height: 24px;
}
.thisIsA {
  left: 0;
}
.div {
  right: 0;
  text-align: right;
}
.helperTextContainer {
  align-self: stretch;
  position: relative;
  height: 24px;
  display: none;
  font-size: var(--p3-semibold-size);
}
.input1 {
  width: 558px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-11xs);
  text-align: left;
  color: var(--grey-20);
}
.youCanPromote1 {
  position: relative;
  line-height: 26px;
  display: inline-block;
  width: 272px;
}
.loudspeaker1Parent,
.titlesParent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.loudspeaker1Parent {
  gap: var(--gap-xs);
  color: var(--grey-40);
}
.titlesParent {
  flex: 1;
  gap: 26px;
  font-size: var(--p2-semibold-size);
  color: var(--grey-900);
}
.navPrototypeParent,
.promotion {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  color: var(--grey-10);
}
.promotion {
  position: relative;
  background-color: var(--color-gray-100);
  width: 100%;
  overflow: hidden;
  flex-direction: column;
  justify-content: flex-start;
  font-size: var(--p3-semibold-size);
  color: var(--grey-900);
  font-family: var(--caption-bold);
}
@media screen and (max-width: 960px) {
  .navPrototype,
  .tabParent {
    display: none;
  }
  .titles1 {
    display: flex;
  }
  .component102 {
    display: none;
  }
  .navPrototypeParent {
    align-self: stretch;
    width: auto;
  }
}
@media screen and (max-width: 430px) {
  .promomob,
  .tabParent {
    display: flex;
    width: 100%;
  }
  .navPrototypeParent,
  .titles1 {
    display: none;
  }
}
