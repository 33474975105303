.jobDetails {
  position: absolute;
  top: calc(50% - 16px);
  left: 32px;
  line-height: 28px;
  font-weight: 600;
  display: none;
}
.iconsclose {
  position: absolute;
  top: calc(50% - 12px);
  right: 32px;
  width: 24px;
  height: 24px;
  overflow: hidden;
}
.modalTitle,
.priceTag1Icon {
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.modalTitle {
  align-self: stretch;
  background-color: var(--white);
  height: 64px;
}
.priceTag1Icon {
  width: 100px;
  height: 100px;
}
.yourPromotionRequest {
  position: relative;
  line-height: 28px;
  font-weight: 600;
  display: inline-block;
  width: 294px;
}
.youWillRecieve {
  position: relative;
  font-size: var(--p3-bold-size);
  line-height: 24px;
  color: var(--grey-20);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 308px;
}
.frameWrapper,
.yourPromotionRequestHasBeeParent {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.yourPromotionRequestHasBeeParent {
  justify-content: center;
  gap: var(--gap-9xs);
}
.frameWrapper {
  justify-content: flex-start;
}
.icons20pxplaceholder {
  position: relative;
  width: 20px;
  height: 20px;
  display: none;
}
.label {
  position: relative;
  font-size: var(--p3-bold-size);
  line-height: 24px;
  font-family: var(--p3-bold);
  color: var(--white);
  text-align: center;
}
.largeButtons {
  cursor: pointer;
  border: 0;
  padding: var(--padding-5xs) var(--padding-158xl);
  background-color: var(--primary-20base);
  border-radius: var(--br-8xs);
  width: 306px;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  gap: var(--gap-5xs);
}
.priceTag1Parent,
.promosubmited {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.priceTag1Parent {
  gap: var(--gap-xl);
  text-align: center;
}
.promosubmited {
  border-radius: var(--br-8xs);
  background-color: var(--white);
  box-shadow: var(--popup);
  width: 461px;
  height: 386px;
  overflow: hidden;
  text-align: left;
  font-size: var(--h5-semibold-size);
  color: var(--grey-0);
  font-family: var(--p3-bold);
}
@media screen and (max-width: 430px) {
  .promosubmited {
    width: 400px;
  }
}
