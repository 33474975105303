.evacloseOutlineIcon {
  position: relative;
  width: 32px;
  height: 32px;
  overflow: hidden;
  flex-shrink: 0;
}
.addCertificate {
  position: relative;
  font-weight: 600;
}
.modalTopBar {
  align-self: stretch;
  background-color: var(--white);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-sm) 0;
  gap: 114px;
  text-align: center;
}
.frameChild {
  position: relative;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  object-fit: cover;
}
.crosbyOdennehy {
  position: relative;
  line-height: 120%;
  font-weight: 600;
}
.ellipseParent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
  font-size: var(--h3-size);
}
.imInNeed,
.requestForElectrician {
  align-self: stretch;
  position: relative;
  font-weight: 600;
  mix-blend-mode: normal;
}
.imInNeed {
  font-size: var(--p1-medium-size);
  line-height: 155%;
  font-weight: 500;
  color: var(--grey-700);
}
.requestForElectricianParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.citiessummary,
.location {
  align-self: stretch;
  position: relative;
  line-height: 155%;
  font-weight: 500;
}
.citiessummary {
  color: var(--grey-900);
}
.locationParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
  font-size: var(--subcopy-medium-size);
  color: var(--grey-600);
}
.div {
  font-size: var(--p1-medium-size);
  line-height: 155%;
  font-weight: 500;
  color: var(--grey-900);
}
.div,
.icons,
.proceed {
  position: relative;
}
.icons {
  width: 20px;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.proceed {
  line-height: 155%;
}
.iconsParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
}
.button,
.jobdetailscomp {
  display: flex;
  box-sizing: border-box;
}
.button {
  border-radius: var(--br-9xs);
  background-color: var(--logg-green);
  height: 48px;
  flex-direction: row;
  padding: var(--padding-xs) 0 var(--padding-smi);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: var(--p2-medium-size);
  color: white;
  width: 100%;
}
.jobdetailscomp {
  border-radius: var(--br-base) var(--br-base) 0 0;
  background-color: var(--white);
  width: 370px;
  height: 519px;
  overflow: hidden;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-3xs);
  gap: 19px;
  text-align: left;
  font-size: var(--h4-semibold-size);
  color: var(--grey-900);
  font-family: var(--p2-bold);
}
