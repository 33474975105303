.addASkill {
  position: relative;
  line-height: 28px;
  font-weight: 600;
}
.iconsclose {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.addASkillOrPriceTagParent {
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: var(--padding-base) var(--padding-13xl);
  align-items: center;
  justify-content: space-between;
}
.label {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 26px;
  font-weight: 500;
}
.baseField,
.labelContainer {
  align-self: stretch;
  position: relative;
}
.labelContainer {
  height: 28px;
}
.baseField {
  border: 0;
  background-color: transparent;
}
.div,
.thisIsA {
  position: absolute;
  top: 0;
  line-height: 24px;
}
.thisIsA {
  left: 0;
}
.div {
  right: 0;
  text-align: right;
}
.helperTextContainer {
  align-self: stretch;
  position: relative;
  height: 24px;
  display: none;
  font-size: var(--p3-semibold-size);
}
.input,
.inputParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-11xs);
}
.inputParent {
  gap: var(--gap-base);
}
.iconsadd {
  position: relative;
  width: 20px;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.label2 {
  position: relative;
  line-height: 24px;
}
.largeButtons {
  align-self: stretch;
  border-radius: var(--br-8xs);
  background-color: var(--primary-20base);
  height: 48px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs) 0;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
  text-align: center;
  font-size: var(--p3-semibold-size);
  color: var(--white);
}
.addskill,
.frameParent {
  width: 397px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
  font-size: var(--p2-bold-size);
  color: var(--grey-20);
}
.addskill {
  position: relative;
  border-radius: var(--br-8xs);
  background-color: var(--white);
  box-shadow: var(--popup);
  width: 461px;
  height: 353px;
  overflow: hidden;
  align-items: center;
  gap: var(--gap-xl);
  max-width: 100%;
  max-height: 100%;
  text-align: left;
  font-size: var(--h5-semibold-size);
  color: var(--color-black);
  font-family: var(--p3-bold);
}
@media screen and (max-width: 430px) {
  .frameParent {
    width: 300px;
  }
  .addskill {
    width: 400px;
  }
}
